import Container from 'components/UI/atoms/Container';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotFountContainer = styled(Container)`
  text-align: center;
  img {
    width: 600px;
  }

  .text {
    font-size: 18px;
    span {
      color: #0072ce;
    }
    .bold {
      font-weight: bold;
      display: block;
      margin-top: 15px;
    }
  }

  .link {
    margin-top: 110px;
    a {
      display: inline-block;
      line-height: 60px;
      padding: 0 75px;
      border: 1px solid #0072ce;
      border-radius: 30px;
      background-color: #0072ce;
      color: #fff;
      transition: all 0.3s;
      &:hover {
        background-color: #fff;
        color: #0072ce;
      }
    }
  }
`;

function NotFound() {
  const navigate = useNavigate();

  const [count, countSet] = useState(5);

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        countSet(count - 1);
      }, 1000);
    } else {
      navigate(-1);
    }
  }, [count, navigate]);

  return (
    <>
      <NotFountContainer>
        <p className="img">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/page_notice.png`} />
        </p>
        <p className="text">
          요청하신 페이지를 찾을 수 없습니다. URL을 확인해주시기 바랍니다.
          <span className="bold">{count}초 후 자동으로 이동합니다</span>
        </p>
      </NotFountContainer>
    </>
  );
}

export default NotFound;
