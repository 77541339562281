import Footer from 'components/UI/organisms/Footer';
import Header from 'components/UI/organisms/Header';
import Grid from 'components/UI/atoms/Grid';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux_modules';

/**
 * Header Footer 포함 레이아웃
 */
function MainLayout() {
  const location = useLocation();
  const { loginInfo } = useSelector((state: RootState) => ({
    loginInfo: state.login,
  }));

  // 2.0 화면 출력시, 경로에 job 이 포함되어 있으면 Header, Footer 레이아웃 포함 안함
  const gmpJob = location.pathname.includes('job') && process.env.REACT_APP_GMP_NOT_USED !== 'true' && !loginInfo.data?.isAdmin;

  return (
    <>
      {gmpJob ? (
        <Outlet />
      ) : (
        <>
          <Header />
          <Grid top={80} bottom={80}>
            <Outlet />
          </Grid>
          <Footer />
        </>
      )}
    </>
  );
}

export default MainLayout;
