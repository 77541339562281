import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IMemberProps } from '../../../../../common/interface/props';
import ProgressForm from '../../../../form/ProgressForm';
import Container from '../../../../UI/atoms/Container';
import Grid from '../../../../UI/atoms/Grid';

function Progress({ solType, text }: IMemberProps) {
  const [bannerNumber, setBannerNumber] = React.useState(1);

  React.useEffect(() => {
    setBannerNumber(Math.floor(Math.random() * 2) + 1);
  }, []);

  return (
    <>
      <Container noBody={true}>
        <ProgressForm solType={solType} />
      </Container>

      <Grid top={80} />
      <Link to={`/GMPAdminLogin?utm_source=plto&utm_medium=banner&utm_campaign=wakeup&utm_content=complete_banner_${bannerNumber}`} target="_blank">
        <BannerWrap id="banner">
          <img src={`/images/banner${bannerNumber}.png`} alt="" />
          {/* {bannerNumber === 3 && <span>{['일괄상품등록', '주문동기화', '자동재고관리', '운송장 즉시출력'][bannerText]}</span>} */}
        </BannerWrap>
      </Link>
    </>
  );
}

const BannerWrap = styled.div`
  width: 100%;
  height: 200px;
  position: sticky;
  bottom: 0;
  cursor: pointer;
  & > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`;

export default Progress;
