import { IMemberProps } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import ShopLoginForm from '../../../../UI/organisms/ShopLoginForm';
import Container from '../../../../UI/atoms/Container';
import Banner from '../../../../UI/molecules/Banner';

export default function ShopLogin({ solType }: IMemberProps) {
  return (
    <>
      {/* <Banner imgPath="/images/bg_title4.png" text="사이트 선택" /> */}

      <Container noBody={true}>
        <ShopLoginForm solType={solType} />
      </Container>
    </>
  );
}
