import { IMemberProps } from '../../../../../../common/interface/props';
import Grid from '../../../../../UI/atoms/Grid';

import ResetPasswordGuide from '../../../../../UI/organisms/ResetPasswordGuide';
import ResetPasswordForm from '../../../../../UI/organisms/ResetPasswordForm';

function ResetPasswordTemplate({ solType }: IMemberProps) {
  return (
    <>
      <Grid top={30} bottom={30}>
        <ResetPasswordGuide />
      </Grid>
      <Grid bottom={50}>
        <ResetPasswordForm solType={solType} />
      </Grid>
    </>
  );
}
export default ResetPasswordTemplate;
