import styled from 'styled-components';

interface IProps {
  width?: string;
  fontSize?: string;
  height?: string;
}

const TextArea = styled.textarea<IProps>`
  width: ${({ width }) => (width ? width : '100%')};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : '')};
  ${({ height }) => (height ? `height: ${height};` : '')};
  padding: 8px;
  border: 1px solid #e2e2e2;
  background: none;
  border-radius: 5px;
`;

export default TextArea;
