import { IMemberProps } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import CreateAccountAgree from '../../../../UI/organisms/CreateAccountAgree';
import CreateAccountForm from '../../../../UI/organisms/CreateAccountForm';
import CreateAccountGuide from '../../../../UI/organisms/CreateAccountGuide';
import Container from '../../../../UI/atoms/Container';
import Banner from '../../../../UI/molecules/Banner';

function CreactAccount({ solType }: IMemberProps) {
  return (
    <>
      <Banner imgPath="/images/bg_main.png" text="회원가입" />
      <Container noBody={true}>
        <Grid top={60}>
          <CreateAccountGuide solType={solType} />
        </Grid>
        <Grid top={60}>
          <CreateAccountAgree />
        </Grid>
        <Grid top={60} bottom={60}>
          <CreateAccountForm solType={solType} />
        </Grid>
      </Container>
    </>
  );
}

export default CreactAccount;
