import { IMemberProps } from 'common/interface/props';
import ShopLoginForm from 'components/UI/organisms/ShopLoginForm';
import Container from 'components/UI/atoms/Container';

export default function ShopLogin({ solType }: IMemberProps) {
  return (
    <>
      <Container noBody={true}>
        <ShopLoginForm solType={solType} />
      </Container>
    </>
  );
}
