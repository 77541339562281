import { IMemberProps } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import Container from '../../../../UI/atoms/Container';
import CreateAccountGuide from 'components/UI/organisms/CreateAccountGuide';
import Button from 'components/UI/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export default function Guide({ solType }: IMemberProps) {
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  async function onSubmit() {
    navigate(`/all/job/shop-login`);
    return;
  }

  return (
    <>
      <Container noBody={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreateAccountGuide solType={'ALL'} />
          <Grid display="flex" justifyContent="center" top={30}>
            <Button size="medium" width={200} color="main">
              다음
            </Button>
          </Grid>
        </form>
      </Container>
    </>
  );
}
