/* 액션 타입 만들기 */
// Ducks 패턴을 따를땐 액션의 이름에 접두사를 넣어주세요.

// 이렇게 하면 다른 모듈과 액션 이름이 중복되는 것을 방지 할 수 있습니다.
export const LOGIN_AGREE = 'login_agree/login_AGREE';

/* 액션 생성함수 만들기 */
// 액션 생성함수를 만들고 export 키워드를 사용해서 내보내주세요.

//Step2. Request 함수에서 받은 값을 redux에 집어넣음
export const loginSuccess = (loginInfo: any) => {
  return {
    type: LOGIN_AGREE,
    data: loginInfo,
  };
};

interface ILoginState {
  status: string;
  data?: {
    isLogined: boolean;
    isAdmin: boolean;
    isSolExpired: boolean;
  };
}
/* 초기 상태 선언 */
const initialState: ILoginState = {
  status: 'INIT',
  data: {
    isLogined: false,
    isAdmin: false,
    isSolExpired: false,
  },
};

// 리듀서선언 (export default 로 내보내주세요.)
// Step3. 액션 생성함수에서 return받은 상태를 reducer를 통해 redux상태에 집어넣음
export default function loginSuccessReducer(state = initialState, action: any): ILoginState {
  switch (action.type) {
    case LOGIN_AGREE:
      return {
        ...state,
        status: action.data ? 'SUCCESS' : 'FAILURE',
        data: action.data,
      };
    default:
      return state;
  }
}

//Step1. 비지니스로직 작성하는곳
export const loginSuccessRequest = (loginInfo: any) => {
  return async (dispatch: any) => {
    dispatch(loginSuccess(loginInfo));
  };
};
