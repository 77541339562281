import { ReactNode } from 'react';
import styled from 'styled-components';
import Grid from '../../atoms/Grid';
import Text from '../../atoms/Text';

interface IProps {
  inputNumber?: number;

  smartStore?: boolean;
  // 라벨
  label: string | ReactNode;

  // 라벨 크기
  labelWidth: number;

  // input
  input?: ReactNode;

  //
  error?: ReactNode;

  // 추가정보
  adornment?: ReactNode;

  //
  children?: ReactNode;
}

const InputWrapGrid = styled(Grid)`
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`;

const LabelGrid = styled(Grid)`
  @media ${({ theme }) => theme.device.tablet} {
    padding-bottom: 10px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: 10px;
  }
`;

const InputGrid = styled(Grid)`
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`;

function InputWrap(props: IProps) {
  return (
    <InputWrapGrid display="flex">
      <LabelGrid width={props.labelWidth}>
        {/* {props.siteCode === 'A077' ? <Text>http://smartstore.naver.com/{props.label}</Text> : <Text>{props.label}</Text>} */}
        <Text fontWeight={600} fontSize="0.88rem">
          {props.label}
        </Text>
      </LabelGrid>
      <InputGrid display="flex" alignItems="baseline">
        {props.input ? (
          props.smartStore && props.inputNumber === 2 ? (
            <>
              http://smartstore.naver.com/
              <Grid style={{ fontSize: '0.88rem' }}>
                {props.input}
                {props.error}
              </Grid>
              {props.adornment}
            </>
          ) : (
            <>
              <Grid style={{ fontSize: '0.88rem' }}>
                {props.input}
                {props.error}
              </Grid>
              {props.adornment}
            </>
          )
        ) : (
          <>{props.children}</>
        )}
      </InputGrid>
    </InputWrapGrid>
  );
}

export default InputWrap;
