import shopLogin from './shop-login';
import wakeupLogin from './wakeup-login';
import agreeInfo from './agree';
import login from './login';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  // signup,
  login,
  // auth,
  shopLogin,
  wakeupLogin,
  agreeInfo,
});

export default rootReducer;

//스토어에서 관리하고 있는 상태를 조회하기 위해서 useSelector를 사용 할 때 필요로 합니다.
export type RootState = ReturnType<typeof rootReducer>;
