import { WAKEUP_FORM_DATA } from 'common/common.constants';
import { axiosCustom } from 'common/common.function';
import { WakeUp } from 'common/interface/props';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import styled from 'styled-components';
import Swal from 'sweetalert2';

// atomic components
import BoxWrap from 'components/UI/molecules/BoxWrap';
import TitleWrap from 'components/UI/molecules/TitleWrap';
import Progress from 'components/UI/atoms/Progress';
import Grid from 'components/UI/atoms/Grid';
import Text from 'components/UI/atoms/Text';
import Button from 'components/UI/atoms/Button';
import ChannelIOButton from 'components/UI/organisms/ChannelIOButton';

interface IProgressForm {
  solType: WakeUp;
}

interface IScrapProdResult {
  result: boolean;
  message: string;
}
interface IScrapProdPartCreate {
  jobId: number;
  scrapAllCount: number;
}
interface IScrapProdPartOneResult {
  result: boolean;
  allCount: number;
  scrapAllCount: number;
  realEndCount: number;
}
interface IScrapProdPartEnd {
  allCount: number;
  scrapAllCount: number;
}

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export default function ProgressForm({ solType }: IProgressForm) {
  const color = solType === 'COUPANG' ? 'main' : 'wakeup';
  const token = sessionStorage.getItem('wakeupInfo');
  const jsonToken = JSON.parse(token!);

  // url get jobId
  // 기본값 없으면 에러남
  const { jobId = '0' } = useParams<{ jobId: string }>();

  // 프로그래스바
  const [progress, setProgress] = useState(0.5);

  // View
  const progressView = progress > 100 ? 100 : Math.round(progress);

  // 노출 메세지
  const [message, setMessage] = useState('수집할 상품 확인 중...');

  // 도움말 목록 노출여부
  const [helpListOpen, setHelpListOpen] = useState(false);

  // 성공 메세지
  const successMessage = `상품 등록 작업이 완료되었습니다. 판매 요청을 진행해주세요.`;

  const sockets: any = {};

  // 프로그래스 타입별 비중
  const progressTypePersent = {
    scrapProd: 9.5, // 상품 수집
    scrapProdPart: 80, // 상품 수집 분할 & WakeUp 상품등록 및 EMP 상품 저장
    scrapProdRealEnd: 10,
  };

  useEffect(() => {
    if (Math.round(progress) >= 100 && ['수집할 상품 확인 중...', '상품 수집 및 등록중...'].includes(message)) {
      setMessage(successMessage);
    }
  }, [progress]);

  useEffect(() => {
    fetchData();

    async function fetchData() {
      // db 조회

      // 작업정보 조회
      const jobData = await getJobInfo(parseInt(jobId));
      // http://localhost:3000/wemakeprice/job/9172

      jobData &&
        (() => {
          //http://localhost:3000/wemakeprice/job/9172
          switch (jobData.state) {
            case 'FAILURE':
              scrapProdResult({ result: false, message: jobData.message });
              break;
            case 'SUCCESS':
              scrapProdResult({ result: true, message: jobData.message });

              jobData.child.map((one: any) => {
                // 상품수집 분할이 완료됐을 경우 소켓 불필요
                if (one.progress < 100) {
                  // 상품 수집 분할 연결
                  sockets[one.jobId] = io(`${process.env.REACT_APP_BACKEND_ENDPOINT}`, {
                    query: { jobId: one.jobId },
                    transports: ['websocket'],
                  });

                  // 상품 수집 분할 1건 완료
                  sockets[one.jobId].on('scrapProdPartOneResult', ({ result, allCount, realEndCount }: IScrapProdPartOneResult) => {
                    scrapProdPartOneResult({ result, allCount, scrapAllCount: jobData.scrapCount, realEndCount: realEndCount });
                  });

                  // 상품 수집 분할 1건 완료
                  sockets[one.jobId].on('scrapProdPartEnd', ({ allCount }: IScrapProdPartEnd) => {
                    scrapProdPartEnd({ allCount, scrapAllCount: jobData.scrapCount });
                  });

                  // 상품 수집 분할 1건 완료
                  sockets[one.jobId].on('realFinish', () => {
                    setProgress((progress) => progress + progressTypePersent.scrapProdRealEnd);
                  });
                }

                // 상품 수집 분할 진행 상황 더하기
                progressCal(one.progress, one.scrapCount, jobData.scrapCount);

                return true;
              });

              // 상품 수집 분할 전체 완료 시 || 상품수집이 종료된 후 24시간이 지났을 경우 = 10% 더함
              (jobData.allEndDate || Math.abs(+new Date() - +new Date(jobData.endDate)) / 36e5 > 24) &&
                setProgress((progress) => progress + progressTypePersent.scrapProdRealEnd);

              break;
            default:
              //  소켓 연결
              sockets[jobId] = io(`${process.env.REACT_APP_BACKEND_ENDPOINT}`, {
                query: { jobId: jobId },
                transports: ['websocket'],
              });

              //  상품 수집 완료
              sockets[jobId].on('scrapProdResult', ({ result, message }: IScrapProdResult) => {
                scrapProdResult({ result, message });
              });

              // 상품 수집 분할 연결
              sockets[jobId].on('scrapProdPartCreate', async ({ jobId: scrapProdPartJobId, scrapAllCount }: IScrapProdPartCreate) => {
                // 상품 수집 분할 연결
                sockets[scrapProdPartJobId] = io(`${process.env.REACT_APP_BACKEND_ENDPOINT}`, {
                  query: { jobId: scrapProdPartJobId },
                  transports: ['websocket'],
                });

                // 상품 수집 분할 1건 완료
                sockets[scrapProdPartJobId].on('scrapProdPartOneResult', ({ result, allCount, realEndCount }: IScrapProdPartOneResult) => {
                  scrapProdPartOneResult({ result, allCount, scrapAllCount, realEndCount });
                });

                // 상품 수집 분할 1건 완료
                sockets[scrapProdPartJobId].on('scrapProdPartEnd', ({ allCount }: IScrapProdPartEnd) => {
                  scrapProdPartEnd({ allCount, scrapAllCount });
                });
                // 상품 수집 분할 1건 완료
                sockets[scrapProdPartJobId].on('realFinish', () => {
                  setProgress((progress) => progress + progressTypePersent.scrapProdRealEnd);
                });
              });
              break;
          }
        })();

      // 작업정보 조회
      async function getJobInfo(jobId: number) {
        try {
          return (await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/info/${jobId}`)).result;
        } catch (error: any) {
          await Swal.fire({
            title: '실패',
            html: `${error.message}`,
            icon: 'error',
          });

          return false;
        }
      }

      // 상품수집완료 소켓 처리
      function scrapProdResult({ result, message }: IScrapProdResult) {
        if (!result) {
          setMessage(message);

          setProgress(100);
        } else {
          setMessage('상품 수집 및 등록중...');

          setProgress((progress) => progress + progressTypePersent.scrapProd);
        }
      }

      // 상품수집분할 1건 완료 처리
      function scrapProdPartOneResult({ result, allCount, scrapAllCount, realEndCount }: IScrapProdPartOneResult) {
        result ? progressCal((1 / allCount) * 90, allCount, scrapAllCount, realEndCount) : progressCal(100, allCount, scrapAllCount, realEndCount);
      }

      function scrapProdPartEnd({ allCount, scrapAllCount }: IScrapProdPartEnd) {
        progressCal(10, allCount, scrapAllCount);
      }

      // 프로그래스바 계산 공통 함수
      function progressCal(scrapProdPartPersent: number, scrapProdPartCount: number, scrapProdCount: number, realEndCount?: number) {
        /*
              ex)  (상품수집분할 최대 갯수가 3개를 예로 들 경우)
              상품수집  : 5개
              상품수집분할 : [a:3, b:2]  (a 작업은 3개, b 작업은 2개 처리)
              
              상품 1개 수집 완료마다 해당 함수 실행
    
              - 상품수집분할 비율 
                - 상품수집 : 90
                - WakeUp 상품등록 & EMP 저장 : 10
    
                (상품수집분할 1개 비율) * (상품수집분할 전체 비중) * (프로그래스바 비율)
                ((상품 1개 / 상품수집분할 총 수) * 상품수집분할 비율(상품수집)) * (상품수집분할 총 수 / 상품수집 총 수)) * (상품수집분할 프로그래스바 비율)
                ((1 / 3) * 90) * (3 / 5) * (90 / 100)
              */
        const calResult = scrapProdPartPersent * (scrapProdPartCount / scrapProdCount) * (progressTypePersent.scrapProdPart / 100);

        setProgress((progress) => progress + calResult);
      }
    }

    // function scrapProdSocket
  }, [jobId]);

  //Math.ceil(progress * 1000) / 1000

  return (
    <>
      <TitleWrap title="상품 등록 진행현황" subject={`작업번호 : ${jobId}`} step={3} direction="row" />
      <BoxWrap>
        <BoxWrap.Bottom>
          <Grid display="flex" justifyContent="center">
            <Grid width={900} top={30}>
              <Text color={color} fontSize="3rem" fontWeight={100} textAlign="center">
                {progressView}%
              </Text>

              <Grid top={10} />
              <Text textAlign="center" fontSize="0.88rem" dangerouslySetInnerHTML={{ __html: message || '' }}></Text>

              <Grid top={20} />
              <Progress progress={progressView} color={color} />

              <Grid top={40} />
              <Text fontSize="0.9rem" fontWeight={500}>
                ※ 순차적으로 작업 중입니다. 바로 진행이 되지 않더라도 잠시만 기다려주세요.
              </Text>

              <Text
                fontSize="0.9rem"
                fontWeight={500}
                cursor="pointer"
                onClick={() => {
                  setHelpListOpen(!helpListOpen);
                }}
              >
                ※ 등록 불가한 상품 유형 보기
                <img src={`/images/arrow_down.svg`} style={{ paddingLeft: 7, transform: `rotateX(${helpListOpen ? '180deg' : '0deg'})` }} alt="" />
              </Text>

              {helpListOpen && (
                <>
                  <Grid top={10} />
                  <Text fontWeight={300} fontSize="0.88rem" whiteSpace={'pre-wrap'}>
                    {WAKEUP_FORM_DATA[solType].restrictionType}
                  </Text>
                </>
              )}

              {progressView >= 100 && (
                <Grid top={40} textAlign="center">
                  {message === successMessage ? (
                    <>
                      <ButtonWrap>
                        <Grid left={10} right={10} width={220}>
                          <a href={`${WAKEUP_FORM_DATA[solType].adminUrl}`} target="_blank" rel="noreferrer">
                            <Button color="main" variant="outlined">
                              {WAKEUP_FORM_DATA[solType].name.type5}
                            </Button>
                          </a>
                        </Grid>

                        {/* {solType === 'COUPANG' && (
                          <Grid left={10} right={10} width={200}>
                            <Link to="/GMPAdminLogin?utm_source=plto&utm_medium=btn&utm_campaign=wakeup&utm_content=complete_goplto" target="_blank">
                              <Button color="main" variant="contained">
                                주문 수집 하기
                              </Button>
                            </Link>
                          </Grid>
                        )} */}
                      </ButtonWrap>

                      {solType !== 'COUPANG' && (
                        <Grid top={20}>
                          <Text textAlign="center" color="wakeup" fontSize="1.1rem" fontWeight={400}>
                            작업결과에 대해 가입메일로 엑셀파일 발송했으니 확인 부탁드립니다.
                          </Text>
                        </Grid>
                      )}
                      {solType === 'COUPANG' && (
                        <Grid top={20}>
                          <Text textAlign="center" color="wakeup" fontSize="1.1rem" fontWeight={400}>
                            작업결과에 대해 가입메일로 10분 후에 엑셀파일이 발송되니 확인 부탁드립니다.
                          </Text>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Link to={`/${solType.toLowerCase()}/job/shop-login`}>
                      <Button color="main" variant="outlined" width={300}>
                        다른 쇼핑몰 상품 등록하기
                      </Button>
                    </Link>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </BoxWrap.Bottom>
      </BoxWrap>
      <ChannelIOButton solType={solType} />
    </>
  );
}
