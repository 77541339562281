import styled from 'styled-components';
import Container from 'components/UI/atoms/Container';
import Grid from 'components/UI/atoms/Grid';
import Text from 'components/UI/atoms/Text';
import Button from 'components/UI/atoms/Button';
import { Link } from 'react-router-dom';
const Wrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
`;

const Box = styled.div`
  width: 100%;
  max-width: calc(50% - 25px);
  height: 420px;
  position: relative;
  margin-bottom: 120px;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 530px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 530px;
    height: 280px;
  }
`;

function Home() {
  return (
    <Container>
      <Wrap>
        <Box>
          <Grid>
            <Text>웨이크업 통합 바로가기</Text>
            <Link to={`/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                웨이크업 통합 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>웨이크업 ALL 바로가기</Text>
            <Link to={`/all/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                웨이크업 ALL 웨이크업 바로가기
              </Button>
            </Link>
            <Link to={`/all/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                웨이크업 ALL 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>쿠팡</Text>
            <Link to={`/coupang/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                쿠팡 웨이크업 바로가기
              </Button>
            </Link>

            <Link to={`/coupang/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                쿠팡 어드민 바로가기
              </Button>
            </Link>
            <Link to={`/coupang-prod-compare/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                쿠팡 상품비교 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>위메프</Text>
            <Link to={`/wemakeprice/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                위메프 웨이크업 바로가기
              </Button>
            </Link>
            <Link to={`/wemakeprice/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                위메프 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>11번가</Text>
            <Link to={`/st11/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                11번가 바로가기
              </Button>
            </Link>
            <Link to={`/st11/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                11번가 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>올웨이즈</Text>
            <Link to={`/alwayz/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                올웨이즈 웨이크업 바로가기
              </Button>
            </Link>
            <Link to={`/alwayz/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                올웨이즈 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>카카오톡 스토어</Text>
            <Link to={`/kakao/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                카카오톡 스토어 웨이크업 바로가기
              </Button>
            </Link>
            <Link to={`/kakao/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                카카오톡 스토어 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>ESM+</Text>
            <Link to={`/esm/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                ESM+ 웨이크업 바로가기
              </Button>
            </Link>
            <Link to={`/esm/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                ESM+ 어드민 바로가기
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Text>TOSS </Text>
            <Link to={`/toss/member/login`}>
              <Button width={300} size="small" color="main" variant="outlined">
                TOSS  웨이크업 바로가기
              </Button>
            </Link>
            <Link to={`/toss/admin`}>
              <Button width={300} size="small" color="main" variant="outlined">
                TOSS  어드민 바로가기
              </Button>
            </Link>
          </Grid>
        </Box>
      </Wrap>
    </Container>
  );
}

export default Home;
