import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

*{
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans KR', sans-serif;
    box-sizing: border-box;
    color:#333;
}

a {
    text-decoration: none;
    color: inherit;
}

ul,
li {
    list-style: none;
}

input{
    font-size:inherit;
}

.star{
    position:relative;
    padding-left:15px;

    &::before{
        content:"*";
        position:absolute;
        left: 0;
        top:2.5px;
    }
}


// 모바일
@media ${({ theme }) => theme.device.mobile} {
    * {
        font-size:14px;
    }
}

// 태블릿
@media ${({ theme }) => theme.device.tablet} {
    * {
        font-size:16px;
    }
}

// 데스크탑
@media ${({ theme }) => theme.device.desktop} {
    * {
    /* *:not(.MuiDataGrid-root *) { */
        font-size:18px;
    }
}

.notices {
    padding-top: 20px;
    // text-align: center;
}
.notices > span {
    font-size: 14px;
    white-space: pre-line;
    position: relative;
    display: inline-block;
}

.notices > span:before {
    content: '※';
    position: absolute;
    top: 0;
    left: -20px;
}

// SweetAlert2 커스텀 스타일
.swal-custom-popup {    
    padding-top:unset;
    padding-left:unset;
    padding-right:unset;
    width: 900px;


    .swal-custom-title {
            font-size:1rem;
            margin:0;
            padding:20px 0;
            border-bottom: 1px solid #ddd;
        }
  

    .swal2-html-container {
        max-height: 800px; 
        overflow: auto;
        text-align:left;
        padding:40px 30px 50px;
        border-bottom: 1px solid #ddd;

        &.specificProduct {
            padding:0px 10px 0px;
        }

        .swal2-textarea {
            width: calc(100% - 50px) !important;
            margin-left: 25px !important;
            margin-right: 25px !important;
            font-size: 0.88rem;
        }

        .swal2-validation-message {
            margin:0;
            font-size: 0.88rem;
        }

        .textArea {
            font-size: 0.88rem;
            line-height: 1.8;

            span {
                color: #565656;
                font-size: 0.77rem;
            }

            .primaryText {
                line-height: normal;
                font-weight: 600;
                font-size: 1.38rem;
                margin-bottom: 30px;
            }
            .colorText {
                font-size: inherit;
                color:  ${({ theme }) => theme.pltoColor}
            }

            b {
                font-size: inherit;
            }

            .additionalArea {
                margin-top: 60px;
            }
        }

        .shipping {
            > div {
                padding-bottom:10px;
                overflow:hidden;
              
              > p {
                  width: 160px;
                  float:left;
              } 
               
              >div {
                float:left;
                width:calc(100% - 160px)
              }
            }
        }
    }

    // 모바일
    @media ${({ theme }) => theme.device.mobile} {
        .swal-custom-content {
            max-height: 350px; 
        }
    }

    .swal-custom-actions {
        justify-content:flex-end;

        .swal-custom-confirmButton,
        .swal2-deny  {
            font-size:0.8rem;
            padding:8px 30px;
            border-radius: 30px;
            min-width: 160px;
        }
       
        .swal-custom-cancleBtn{
            font-size:0.8rem;
            padding:8px 30px;
            border-radius: 30px;
            background-color: #ccc;
            width:160px;
        }
    
    }
    
}

// material ui grid 커스텀 스타일
.MuiDataGrid-main { 
    .MuiDataGrid-columnHeaderTitle{
        font-size: 0.88rem;
    }
    .MuiDataGrid-cell {
        font-size:0.77rem;
    }
}

// material ui 커스텀 스타일
/* .MuiDataGrid-viewport {
    max-width:none !important;
} */




`;

// $mainColor: #ef3d3d;
export default GlobalStyle;
