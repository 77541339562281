import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
import { TargetSolTypeRole, WAKEUP, checkUseSpringSolType } from 'common/common.constants';
import { WakeUp } from 'common/interface/props';

axios.defaults.withCredentials = true;

// Axios Custom - 즉시 주입 함수 표현식 (IIFE)
export const axiosCustom = (() => {
  // axios get
  async function axiosGet(url: string, config?: AxiosRequestConfig): Promise<any> {
    return axiosCommon(async () => await axios.get(url, config));
  }

  // axios post
  async function axiosPost(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    return axiosCommon(async () => await axios.post(url, data, config));
  }

  // axios delete
  async function axiosDelete(url: string, config?: AxiosRequestConfig): Promise<any> {
    return axiosCommon(async () => await axios.delete(url, config));
  }

  // axios 공통 처리 함수
  async function axiosCommon(axiosFunction: () => Promise<AxiosResponse>) {
    try {
      const { data: returnData } = await axiosFunction();

      return returnData;
    } catch (error: any) {
      let errorMessage = '';

      if (error.response?.data) {
        // 플토닷컴 response 형식
        if (error.response.data?.message) {
          errorMessage = error.response.data.message;
          //v3 response 형식 message X erorr O
        } else if (error.response.data?.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message;
      }

      // 토큰 만료시 로그 아웃 처리
      if (['JWT 토큰이 만료되었습니다.', '인증에 실패하였습니다.'].find((one) => errorMessage.includes(one))) {
        const solType = WAKEUP.find((one) => window.location.pathname.toLowerCase().includes(one.toLowerCase()));
        // if (solType && checkUseSpringSolType(solType)) {
        //   await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/logout`);
        // } else {
        await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/logout`);
        // }

        sessionStorage.removeItem('userInfo'); //로그인 정보 세션스토리지 초기화

        sessionStorage.removeItem('wakeupInfo');

        await Swal.fire('오류', errorMessage, 'error');

        if (solType) {
          window.location.href = `/${solType.toLowerCase()}/member/login?referUrl=${window.location.pathname}`;
        } else {
          window.location.reload();
        }

        // location.href= "/"
      } else {
        throw new Error(errorMessage);
      }
    }
  }

  return {
    get: axiosGet,
    post: axiosPost,
    delete: axiosDelete,
  };
})();

// 날짜포맷 yyyy-MM-dd 변환
export const getFormatDate = function (date: Date) {
  let year = date.getFullYear();
  let month: any = 1 + date.getMonth();
  month = month >= 10 ? month : '0' + month;
  let day: any = date.getDate();
  day = day >= 10 ? day : '0' + day;
  return year + '-' + month + '-' + day;
};

// 난수 생성 함수
export function random(digits: number) {
  // 0자리 난수 => 0
  if (digits <= 0) return 0;

  // 1자리 난수 => 1~9       :min=1, max=9
  // 2자리 난수 => 10~99     :min=10, max=99
  // 3자리 난수 => 100~999   :min=100, max=999
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;

  return Math.floor(Math.random() * (max - min) + min);
}

// 채널톡 관련
declare global {
  interface Window {
    ChannelIO: any;
    ChannelIOInitialized: any;
    attachEvent: any;
  }
}

// 채널톡 함수
export const channelIOSVC = (() => {
  const boot = (channelIo: any) => {
    if (window.ChannelIO) {
      window.ChannelIO('boot', channelIo);
    }
  };
  const show = () => {
    window.ChannelIO('show');
  };
  const load = async () => {
    const w = window;

    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})('ChannelIO script included twice.');
    }
    const d = window.document;
    const ch: any = function () {
      ch.c(arguments);
    };

    ch.q = [];
    ch.c = function (args: any) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement('script');

      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x: any = document.getElementsByTagName('script')[0];

      x.parentNode.insertBefore(s, x);

      // boot 완료시 읽지않은 메세지 개수 가져와서 세팅
      window.ChannelIO('onBoot', (guest: any) => {
        // window.ChannelIO('show');
      });
      window.ChannelIO('onChangeBadge', (unreadCount: any) => {});
      window.ChannelIO('onHide', () => {
        // window.ChannelIO('shutdown');
      });
      window.ChannelIO('onShow', () => {});
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  };
  const track = (eventName: any, obj: any) => {
    window.ChannelIO('track', eventName, obj);
  };
  return {
    boot,
    show,
    load,
    track,
  };
})();

/**
 * @alias 작업 결과 엑셀 컬럼 너비 반환
 */
export function getJobResultExcelStyle(solType: WakeUp) {
  switch (solType) {
    case TargetSolTypeRole.COUPANG:
    case TargetSolTypeRole.ALWAYZ:
    case TargetSolTypeRole.WEMAKEPRICE:
    case TargetSolTypeRole.KAKAO:
    case TargetSolTypeRole.ST11:
      return [
        {
          wch: 15,
        },
        {
          wch: 22,
        },
        {
          wch: 30,
        },
        {
          wch: 40,
        },
        {
          wch: 55,
        },
        {
          wch: 30,
        },
        {
          wch: 45,
        },
        {
          wch: 20,
        },
      ];
    case TargetSolTypeRole.ESM:
      return [
        {
          wch: 15,
        },
        {
          wch: 22,
        },
        {
          wch: 30,
        },
        {
          wch: 40,
        },
        {
          wch: 55,
        },
        {
          wch: 30,
        },
        {
          wch: 45,
        },
        {
          wch: 20,
        },
        {
          wch: 20,
        },
        {
          wch: 20,
        },
      ];

    default:
      return [
        {
          wch: 15,
        },
        {
          wch: 22,
        },
        {
          wch: 30,
        },
        {
          wch: 40,
        },
        {
          wch: 55,
        },
        {
          wch: 30,
        },
        {
          wch: 45,
        },
        {
          wch: 20,
        },
      ];
  }
}

/**
 * @alias 배열 쪼개기
 */
export function arrayChunk(array: any[], chunk: number) {
  const tempArray = [];

  let i: number, j: number;

  for (i = 0, j = array.length; i < j; i += chunk) {
    tempArray.push(array.slice(i, i + chunk));
  }
  return tempArray;
}
