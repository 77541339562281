import BoxWrap from '../../molecules/BoxWrap';
import TitleWrap from '../../molecules/TitleWrap';

import SupportMall from '../../atoms/SupportMall';
import StepBig from '../../atoms/StepBig';
import Grid from '../../atoms/Grid';
import Text from '../../atoms/Text';
import { WakeUp } from '../../../../common/interface/props';
import { WAKEUP_FORM_DATA } from '../../../../common/common.constants';

interface IProps {
  solType: WakeUp;
}

function CreateAccountGuide({ solType }: IProps) {
  return (
    <>
      {/* <TitleWrap title="상품등록 절차" subject={`통합솔루션(플레이오토) 활용한 ${text} 상품등록`} /> */}
      <TitleWrap title={`플레이오토를 활용한 ${WAKEUP_FORM_DATA[solType].name.type1} 상품 등록 서비스 이용`} />

      <BoxWrap>
        <BoxWrap.Top>
          <Grid display="flex" direction="column" alignItems="center">
            <Grid top={60} />
            <Text>
              다른 쇼핑몰에서 판매 중인 상품을 쉽고 빠르게, 그리고{' '}
              <Text fontWeight={600} inline>
                무료
              </Text>
              로 {WAKEUP_FORM_DATA[solType].name.type1} 에서도 판매를 시작하실 수 있습니다.
            </Text>
            <Text>
              1단계는 ‘플레이오토’ 회원 가입 단계이며, 1단계를 완료하신 분들은 즉시 {WAKEUP_FORM_DATA[solType].name.type1}에 상품을 등록할 수 있습니다.
            </Text>

            <Grid top={60} bottom={60}>
              <StepBig />
            </Grid>
          </Grid>
        </BoxWrap.Top>
        <BoxWrap.Bottom>
          <SupportMall solType={solType} />
          {/* <StepBig step3={data.stepBig3} /> */}
        </BoxWrap.Bottom>
      </BoxWrap>
    </>
  );
}

export default CreateAccountGuide;
