import React from 'react';
import styled from 'styled-components';

export default function GMPIntroduction() {
  return (
    <div>
      <div
        className="e_bg_contain"
        style={{
          background: `url('/images/c-1-bg.png') no-repeat center center`,
          // height: '1566px',
          paddingBottom: '50px',
          backgroundSize: 'cover',
        }}
      >
        <EContW1200>
          <img
            src="/images/pltoc-1.png"
            alt=""
            style={{
              height: 'auto',
              maxWidth: '920px',
              width: '100%',
              paddingTop: '165px',
            }}
          />
        </EContW1200>
      </div>
      <div
        className="e_bg_contain"
        style={{
          background: `url('/images/c-2-bg.png') no-repeat center center`,
          // height: '1566px',
          paddingBottom: '50px',
          backgroundSize: 'cover',
        }}
      >
        <EContW1200>
          <img
            src="/images/pltoc-2.png"
            alt=""
            style={{
              maxWidth: '1304px',
              // height: '100%',
              width: '100%',
              paddingTop: '130px',
            }}
          />
        </EContW1200>
      </div>
      <div
        className="e_bg_contain"
        style={{
          background: `url('/images/c-3-bg.png') no-repeat center center`,
          // height: '310px',
          padding: '50px 0',
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        <EContW1200 className=" buttonArea" style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '650px' }}>
          <a href="https://plto.com/introduction/Plto20/" target="_blank" rel="noreferrer">
            <img src="/images/pltobtn-1.png" alt="" style={{ width: '17.2rem' }} />
          </a>
          <a href="https://app.playauto.io" target="_blank" rel="noreferrer">
            <img src="/images/pltobtn-2.png" alt="" style={{ width: '17.2rem' }} />
          </a>

          {/* {parentIframe ? ( */}
          {/* <Link href="/scrap/loginAfterChoice"> */}
          {/* <a style={{ display: 'inline-block' }}>
            <img src="/images/btn-3.png" alt="" style={{ width: '17.2rem' }} />
          </a> */}
          {/* </Link> */}
          {/* ) : (
                        <a
                            href={`${pltoUrl}/etc/empCoupang`}
                            style={{ display: 'inline-block' }}
                            onClick={() => {
                                userBehaviorLog({
                                    action: behaviorActionType.CLICK,
                                    screen: router.pathname,
                                    param: { target: '처음으로 돌아가기' },
                                    playautoUserId: userBehaviorLogData.playautoUserId,
                                    coupangUserId: userBehaviorLogData.coupangUserId,
                                    coupangVendorId: userBehaviorLogData.coupangVendorId
                                });
                            }}
                        >
                            <img src={`/images/btn-3.png`} alt="" style={{ width: '310px' }} />
                        </a>
                    )} */}
          {/* 
                    // <Link href="/scrap/marketInfoInput">
                        <a style={{ display: 'inline-block' }}>
                            <img src={`/images/btn-3.png`} alt="" style={{ width: '310px' }} />
                        </a>
                    // </Link> */}
        </EContW1200>
      </div>
    </div>
  );
}

const EContW1200 = styled.div`
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;
