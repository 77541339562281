import styled from 'styled-components';
import Grid from '../../atoms/Grid';
import Text from '../../atoms/Text';

interface IProps {
  imgPath: string;
  text: string;
}

const BannerTag = styled(Grid)<{ imgPath: string }>`
  width: 100%;
  height: 250px;
  background: url(${({ imgPath }) => imgPath}) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const TextTag = styled(Text)`
  z-index: 10;
`;

function Banner({ imgPath, text }: IProps) {
  return (
    <BannerTag display="flex" justifyContent="center" alignItems="center" imgPath={imgPath}>
      <TextTag color="white" fontSize="2.7rem">
        {text}
      </TextTag>
    </BannerTag>
  );
}

export default Banner;
