import styled from 'styled-components';
import { WAKEUP_FORM_DATA } from '../../../../common/common.constants';
import { IMemberProps } from '../../../../common/interface/props';

// interface IProps {
//   step3: string;
// }

const Wrap = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  padding: 10px 10px 50px 10px;
  text-align: center;
  > ul {
    width: 100%;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: column;
    }

    > li {
      /* border-right: 1px solid #ddd; */
      height: 56px;

      &:nth-child(even) {
        width: 1px;
        background: #ddd;
        @media ${({ theme }) => theme.device.mobile} {
          display: none;
        }
      }

      &:nth-child(odd) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    > span {
      padding: 5px 20px;
      border-radius: 20px;
      background: #333;
      color: white;
      font-size: 0.88rem;
    }

    > p {
      padding-top: 20px;
      font-size: 0.88rem;
    }
  }

  > img {
    margin-top: 5px;
    height: 1.2rem;
  }
`;

// function StepBig({ step3 }: IProps) {
function StepBig({ solType }: IMemberProps) {
  return (
    <Wrap>
      상품 이전 지원 쇼핑몰
      <ul>
        {WAKEUP_FORM_DATA[solType].useMarket.map((one: any, index: number) => (
          <>
            <li key={index}>
              <img src={`/images/${one.logo}`} alt="" style={{ width: one.size }} />
              <span>{one.text}</span>
            </li>

            {WAKEUP_FORM_DATA[solType].useMarket.length - 1 > index && <li></li>}
          </>
        ))}

        {/* <li>
          <img src="/images/logo_gmarket.png" alt="" style={{ width: '82px' }} />
          <span>(2.0 포함)</span>
        </li>
        <li></li>
        <li>
          <img src="/images/logo_auction.png" alt="" style={{ width: '96px' }} />
          <span>(2.0 포함)</span>
        </li>
        <li></li>
        <li>
          <img src="/images/logo_11st.png" alt="" style={{ width: '56px' }} />
          <span>(단일 상품 포함)</span>
        </li>
        <li></li>
        <li>
          <img src="/images/logo_interpark.png" alt="" style={{ width: '90px' }} />
        </li> */}
      </ul>
    </Wrap>
  );
}

export default StepBig;
