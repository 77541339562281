import styled from 'styled-components';

interface IProps {
  noBody?: boolean;
}

const Container = styled.div<IProps>`
  max-width: ${({ theme }) => theme.device.maxWidth};
  min-width: ${({ theme }) => theme.device.minWidth};
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.noBody ? '10px' : '80px')} 10px ${(props) => (props.noBody ? '0' : '10px')} 10px;
  margin: 0 auto;
`;

//초석이 되는 컨테이너, const A = styled(BasicContainer) 이렇게 상속받아서사용
export default Container;
