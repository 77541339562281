import Grid from '../../atoms/Grid';
import Text from '../../atoms/Text';

function ResetPasswordGuide() {
  return (
    <Grid display="flex" direction="column" alignItems="center">
      <img src="/images/iconInfo.png" alt="" />
      <Grid top={30}>
        <Text textAlign="center">
          <Text color="black" fontSize={'2rem'} fontWeight={600} inline>
            새로운 비밀번호로 변경합니다.
          </Text>
        </Text>
      </Grid>
    </Grid>
  );
}

export default ResetPasswordGuide;
