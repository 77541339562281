import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

const BoxTop = styled.div`
  background-color: #f7f7f7;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 40px 0;
`;

const BoxBottom = styled.div`
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 40px 10px;
`;

function BoxWrap({ children }: IProps) {
  return <>{children}</>;
}

BoxWrap.Top = BoxTop;
BoxWrap.Bottom = BoxBottom;

export default BoxWrap;
