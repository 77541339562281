import { WAKEUP_FORM_DATA } from '../../../../common/common.constants';
import { WakeUp } from '../../../../common/interface/props';
import CreateAccountForm2 from 'components/form/CreateAccount';

import TitleWrap from '../../molecules/TitleWrap';

interface IProps {
  solType: WakeUp;
}

function CreateAccountForm({ solType }: IProps) {
  return (
    <>
      <TitleWrap
        title="회원 가입 정보 입력"
        subject={`플레이오토 간편 회원 가입을 통해 ${WAKEUP_FORM_DATA[solType].name.type1}에 상품을 빠르게 판매하실 수 있습니다.`}
        step={1}
      />
      <CreateAccountForm2 solType={solType} />
    </>
  );
}

export default CreateAccountForm;
