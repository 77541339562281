import styled from 'styled-components';
import Container from '../../atoms/Container';

const FooterWrap = styled.div`
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding-bottom: 30px;
  /* margin-top: 170px; */

  > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    @media ${({ theme }) => theme.device.mobile} {
      :not(:last-child) {
        flex-direction: column;
        align-items: unset;
        > div:last-child {
          padding-top: 30px;
        }
      }
    }
  }
`;

const Top = styled.div`
  > div {
    :first-child {
      > a {
        font-size: 0.8rem;

        :not(:first-child) {
          padding-left: 20px;
        }

        :last-child {
          color: ${({ theme }) => theme.pltoColor};
        }
      }
    }
  }
`;

const Middle = styled.div`
  > div {
    :first-child {
      > p {
        font-size: 0.8rem;
        > a {
          text-decoration: underline;
        }
      }
    }

    :last-child {
      > a {
        display: inline-block;
        width: 26px;
        height: 26px;
        overflow: hidden;
        margin-left: 15px;
        > img {
          width: 100%;
          margin-top: -100%;

          :hover {
            margin-top: 0;
          }
        }
      }
    }
  }
`;

const Bottom = styled.div`
  > div {
    :last-child {
      display: flex;
      align-items: center;
      > img:last-child {
        padding-left: 30px;
      }
    }
  }
`;

function Footer() {
  return (
    <FooterWrap>
      <Container noBody={true}>
        <Top>
          <div>
            <a href="http://www.playauto.co.kr/download/PLAYAUTO_20201Q.pdf" target="_blank" rel="noopener noreferrer">
              회사소개서
            </a>
            <a href="https://plto.com/policy/Policy/" title="이용약관">
              이용약관
            </a>
            <a href="https://plto.com/policy/Policy/?tabIndex=2" title="개인정보취급방침">
              개인정보취급방침
            </a>
          </div>
          {/* <div>FamilySite</div> */}
        </Top>
        <Middle>
          <div>
            <p>
              (주)커넥트웨이브 대표: 이건수<span> | </span>개인정보 보호책임자: 연충욱<span> | </span>
              주소: 서울특별시 금천구 벚꽃로 298, 17층 (가산동, 대륭포스트타워6차)
            </p>
            <p>
              사업자번호: 117-81-40065<span> | </span>통신판매 신고번호: 제2004-서울양천-00918호
              <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1178140065" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
                사업자정보확인
              </a>
            </p>
            <p>
              이메일주소 : <a href="mailto:wakeup@playauto.co.kr">wakeup@playauto.co.kr</a> <span> | </span> Fax : 02-6925-4604
            </p>
          </div>
          <div>
            <a href="https://blog.naver.com/playautocorp" title="블로그" target="_blank" rel="noopener noreferrer">
              <img src="/images/iconBlog.png" alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCHpFg1fiUe2Xq9rDvNGJcpQ" title="유튜브" target="_blank" rel="noopener noreferrer">
              <img src="/images/iconYoutube.png" alt="" />
            </a>
            <a href="https://www.facebook.com/playauto.co.kr" title="페이스북" target="_blank" rel="noopener noreferrer">
              <img src="/images/iconFaceBook.png" alt="" />
            </a>
          </div>
        </Middle>
        <Bottom>
          <div>
            <img src="/images/footerLogo.png" alt="" />
          </div>
          <div>
            <img src="/images/iconAws.png" alt="" />
            <img src="/images/iconNipa.png" alt="" />
          </div>
        </Bottom>
      </Container>
    </FooterWrap>
  );
}

export default Footer;
