// 채널톡 버튼
import { useEffect } from 'react';
import { IUserInfo, WakeUp } from 'common/interface/props';
import styled from 'styled-components';
import { CHANNELIO, checkIntergrationGMP, TargetSolTypeRole } from '../../../../common/common.constants';
import { channelIOSVC } from '../../../../common/common.function';
import { WAKEUP_FORM_DATA } from 'common/common.constants';

interface IProps {
  solType: WakeUp;
}

const ChannelIO = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #0072ce;
  text-align: center;
  border-radius: 25px;
  opacity: 0.8;
  transition: width 0.5s;
  font-size: 0;
  cursor: pointer;
  z-index: 999;

  img {
    width: 22px;
    vertical-align: middle;
  }

  p {
    display: inline-block;
    color: #fff;
    width: 0;
    height: 0;
    overflow: hidden;
    vertical-align: top;
    text-indent: 10px;
    font-size: 12px;
    transition: width 0.3s;
  }

  .link {
    margin-top: 110px;
    a {
      display: inline-block;
      line-height: 60px;
      padding: 0 75px;
      border: 1px solid #0072ce;
      border-radius: 30px;
      background-color: #0072ce;
      color: #fff;
      transition: all 0.3s;
      &:hover {
        background-color: #fff;
        color: #0072ce;
      }
    }
  }

  :hover {
    width: 160px;
    opacity: 1;
  }

  :hover > p {
    width: 100px;
    height: auto;
  }
`;

const ChannelIOButton = ({ solType }: IProps) => {
  // 채널 세팅
  useEffect(() => {
    // 로그인 정보 가져오기
    const rawUserInfo = sessionStorage.getItem('userInfo');

    if (rawUserInfo) {
      // 로그인 데이터 파싱
      const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);

      const channelSetting = CHANNELIO;

      channelSetting.profile = {
        name: userInfo.email,
        user_name: userInfo.email, //fill with user name
        mobileNumber: '', //fill with user phone number
        htel: '',
        tel: '',
        email: userInfo.email,
        isEmp: '',
        mem_ser: `WakeUp ${WAKEUP_FORM_DATA[solType].name.type1}`,
        id: '',
        c_no: '',
        biz_reg_no: '',
        userId: '',
      };

      channelIOSVC.boot(channelSetting);
    }
  }, []);

  return !checkIntergrationGMP(solType) ? (
    <>
      <ChannelIO
        className="chanelIo"
        onClick={() => {
          channelIOSVC.show();
        }}
      >
        <img src="/images/icon_chat.png" alt="" />
        <p>채팅 문의 하기</p>
      </ChannelIO>
    </>
  ) : (
    <></>
  );
};

export default ChannelIOButton;
