import { IMemberProps, SellerType } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import WakeUpLoginForm from '../../../../UI/organisms/WakeUpLoginForm';
import Container from '../../../../UI/atoms/Container';
import React from 'react';

export default function WakeUpLogin({ solType }: IMemberProps) {
  // 쿠팡 유입경로별 셀러 타입
  const [sellerType, setSellerType] = React.useState('3P' as SellerType);

  React.useEffect(() => {
    // 쿠팡 유입경로별 셀러 타입
    const sellerTypeSessionStorage = sessionStorage.getItem('sellerType') as SellerType;

    setSellerType(sellerTypeSessionStorage || '3P');
  }, []);

  return (
    <>
      {/* <Banner imgPath="/images/bg_title3.png" text="상품등록" /> */}

      <Container noBody={true}>
        <Grid>
          <WakeUpLoginForm solType={solType} coupangSellerType={sellerType} />
        </Grid>
      </Container>
    </>
  );
}
