import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { createAccountAgreeRequest } from '../../../../redux_modules/agree';
import { RootState } from '../../../../redux_modules';
import Policy from './policy';
const Wrap = styled.div`
  > div {
    display: flex;
    align-items: center;
    padding: 1.25rem;
    border-bottom: 1px solid #e0e5e9;
    cursor: pointer;

    :first-child {
      background-color: #f7f7f7;
      > p {
        font-weight: bold;
      }
    }

    > p {
      font-size: 1rem;
      padding-left: 1.25rem;
      > span {
        font-size: 0.77rem;
        padding-left: 1.25rem;
        text-decoration: underline;
      }
    }
  }

  .policyInline {
    padding: 0px;
    overflow: auto;
    background-color: #f7f7f7;
  }

  .infoArea {
    max-height: 200px;
    padding-left: 50px;
    padding-right: 20px;
  }
`;

const SpanImg = styled.span<{ selected: boolean }>`
  width: 28px;
  height: 28px;
  background: url('/images/btnRegister.png') left ${(props) => (props.selected ? 'bottom' : 'top')} no-repeat;
`;

function AgreeWrap() {
  const [allAgree, setAllAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);

  const dispatch = useDispatch();

  const { agreeInfo } = useSelector((state: RootState) => ({
    agreeInfo: state.agreeInfo,
  }));

  useEffect(() => {
    if (allAgree === true || (agree1 === true && agree2 === true && agree3 === true)) {
      if (agreeInfo.status !== 'SUCCESS') {
        dispatch(createAccountAgreeRequest(true));
      }
    } else {
      if (agreeInfo.status === 'SUCCESS') {
        dispatch(createAccountAgreeRequest(false));
      }
    }
  }, [allAgree, agree1, agree2, agree3]);

  return (
    <Wrap>
      <div
        onClick={() => {
          const targetBoolean = !allAgree;

          setAllAgree(targetBoolean);
          setAgree1(targetBoolean);
          setAgree2(targetBoolean);
          setAgree3(targetBoolean);
        }}
      >
        <SpanImg selected={allAgree} />
        <p>이용약관에 모두 동의합니다.</p>
      </div>

      {/* PLAYAUTO 통합회원 이용약관 */}
      <div
        onClick={(e) => {
          setAgree1(!agree1);
        }}
      >
        <SpanImg selected={agree1} />
        <p>PLAYAUTO 통합회원 이용약관(필수)</p>
      </div>
      <div className='policyInline'>
        <div className="infoArea">
          <Policy type="combine" />
        </div>
      </div>

      {/* 개인정보 수집 및 이용 */}
      <div
        onClick={(e) => {
          setAgree2(!agree2);
        }}
      >
        <SpanImg selected={agree2} />
        <p>개인정보 수집 및 이용(필수)</p>
      </div>
      <div className='policyInline'>
        <div className="infoArea">
          <Policy type="privateScrap" />
        </div>
      </div>

      {/* 개인정보처리 위수탁 계약 */}
      <div
        onClick={(e) => {
          setAgree3(!agree3);
        }}
      >
        <SpanImg selected={agree3} />
        <p>개인정보처리 위수탁 계약(필수)</p>
      </div>
      <div className='policyInline'>
        <div className="infoArea">
          <Policy type="trust" />
        </div>
      </div>
    </Wrap>
  );
}

export default AgreeWrap;
