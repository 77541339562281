import { useEffect, useState } from 'react';
// atomic component
import Container from 'components/UI/atoms/Container';
import Text from 'components/UI/atoms/Text';
import Grid from 'components/UI/atoms/Grid';
import Button from 'components/UI/atoms/Button';
import TextField from 'components/UI/atoms/TextField';

// common
import InputWrap from 'components/UI/molecules/InputWrap';
import RadioButton from 'components/UI/atoms/RadioButton';
import TextArea from 'components/UI/atoms/TextArea';

import Swal from 'sweetalert2';

import { axiosCustom } from '../../common/common.function';
import { ScrapType } from 'common/common.constants';

export default function ProdCompare() {
  const [str1, setStr1] = useState<string>('');
  const [str2, setStr2] = useState<string>('');
  const [stringSimilarity, setStringSimilarity] = useState<number>(0);

  const [imageUrl1, setImageUrl1] = useState<string>('');
  const [imageUrl2, setImageUrl2] = useState<string>('');
  const [imageSimilarity, setImageSimilarity] = useState<number>(0);

  const [jobId, setJobId] = useState<string>('');
  const [scrapShopScrapType, setScrapShopScrapType] = useState<ScrapType>(ScrapType.SPECIFIC);
  const [scrapProdCodes, setScrapProdCodes] = useState<string>('');
  const [wakeupScrapType, setWakeupScrapType] = useState<ScrapType>(ScrapType.SPECIFIC);
  const [wakeupProdCodes, setWakeupProdCodes] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  // IP 검사
  useEffect(() => {
    async function ip() {
      try {
        // 통합 어드민 페이지의 기본 solType 값은 coupang 임
        await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/coupang/ipCheck`, {
          headers: {
            'sol-type': 'ADMIN',
          },
        });
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: error.message,
          icon: 'error',
        });

        window.location.href = 'https://plto.com';
      }
    }
    ip();
  }, []);

  /**
   * 문자열 유사도 검사
   */
  async function checkStringSimilarity() {
    if (!str1 || !str2) {
      return Swal.fire({
        title: '실패',
        html: '문자열을 입력해주세요',
        icon: 'error',
      });
    }

    const { success, message, similarity } = await axiosCustom.post(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/string-similarity`,
      {
        str1,
        str2,
      },
      {
        headers: {
          'sol-type': 'ADMIN',
        },
      },
    );

    if (!success) {
      return Swal.fire({
        title: '실패',
        html: message || '',
        icon: 'error',
      });
    }

    setStringSimilarity(similarity);
  }

  /**
   * 이미지 유사도 검사
   */
  async function checkImageSimilarity() {
    if (!imageUrl1 || !imageUrl2) {
      return Swal.fire({
        title: '실패',
        html: '이미지 URL을 입력해주세요',
        icon: 'error',
      });
    }

    const { success, message, similarity } = await axiosCustom.post(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/image-similarity`,
      {
        str1: imageUrl1,
        str2: imageUrl2,
      },
      {
        headers: {
          'sol-type': 'ADMIN',
        },
      },
    );

    if (!success) {
      return Swal.fire({
        title: '실패',
        html: message || '',
        icon: 'error',
      });
    }

    setImageSimilarity(similarity);
  }

  /**
   * 상품 유사도 재확인 작업
   */
  async function checkReProdCompareJob() {
    const scrapProdCodeList = scrapProdCodes
      .split('\n')
      .map((v) => v.trim())
      .filter((v) => v);

    const wakeupProdCodeList = wakeupProdCodes
      .split('\n')
      .map((v) => v.trim())
      .filter((v) => v);

    try {
      if (!jobId) {
        Swal.fire({
          title: '실패',
          html: `작업번호를 입력해주세요`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return false;
      }

      if (!email) {
        Swal.fire({
          title: '실패',
          html: `결과 받을 이메일 주소를 입력해주세요`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return false;
      }

      if (scrapShopScrapType === ScrapType.SPECIFIC && !scrapProdCodeList?.length) {
        Swal.fire({
          title: '실패',
          html: `가져온 쇼핑몰 상품 - 지정 상품으로 비교시, 상품번호는 필수입니다`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return false;
      }

      if (wakeupScrapType === ScrapType.SPECIFIC && !wakeupProdCodeList?.length) {
        Swal.fire({
          title: '실패',
          html: `웨이크업 상품 - 지정 상품으로 비교시, 상품번호는 필수입니다`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return false;
      }

      Swal.fire({
        title: '잠시만 기다려 주세요.',
        html: '작업 재시도중 ...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const { success, message } = await axiosCustom.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/re-prod-compare-job`,
        {
          jobId: +jobId,
          email,
          scrapShopScrapType,
          scrapProdCodeList,
          wakeupScrapType,
          wakeupProdCodeList,
        },
        {
          headers: {
            'sol-type': 'ADMIN',
          },
        },
      );

      if (!success) {
        return Swal.fire({
          title: '실패',
          html: message || '',
          icon: 'error',
        });
      }

      return Swal.fire({
        title: '성공',
        html: '작업이 완료되면, 결과 엑셀을 이메일로 전송해드립니다.',
        icon: 'success',
      });
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    }
  }

  const handleChangeJobId = (event: any) => {
    // 숫자 이외의 문자 제거
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    setJobId(newValue);
  };

  return (
    <Container noBody={true}>
      <Grid display="flex" width={'100%'}>
        {/* 문자열 비교 */}
        <Grid top={20} display="flex" direction="column" width={'50%'} alignItems="flex-start">
          <Text fontWeight={700}>[문자열 비교]</Text>
          <Grid top={10}>
            <InputWrap
              label={'문자열1'}
              labelWidth={160}
              input={<TextField type={'text'} placeholder={`문자를 입력해 주세요`} value={str1} onChange={(event) => setStr1(event.target.value)} />}
            />
          </Grid>
          <Grid top={10}>
            <InputWrap
              label={'문자열2'}
              labelWidth={160}
              input={<TextField type={'text'} placeholder={`문자를 입력해 주세요`} value={str2} onChange={(event) => setStr2(event.target.value)} />}
            />
          </Grid>
          <Grid top={10}>
            <InputWrap label={'결과'} labelWidth={160} input={<>{stringSimilarity}%</>} />
          </Grid>
          <Grid top={20} display="flex" justifyContent="center">
            <Button size="small" color="main" type="button" onClick={checkStringSimilarity}>
              문자열 유사도 검사
            </Button>
          </Grid>
        </Grid>

        {/* 이미지URL 비교 */}
        <Grid top={20} left={30} display="flex" direction="column" width={'50%'}>
          <Text fontWeight={700}>[이미지URL 비교]</Text>
          <Grid top={10}>
            <InputWrap
              label={'이미지URL1'}
              labelWidth={160}
              input={
                <TextField type={'text'} placeholder={`이미지URL을 입력해 주세요`} value={imageUrl1} onChange={(event) => setImageUrl1(event.target.value)} />
              }
            />
          </Grid>
          <Grid top={10}>
            <InputWrap
              label={'이미지URL2'}
              labelWidth={160}
              input={
                <TextField type={'text'} placeholder={`이미지URL을 입력해 주세요`} value={imageUrl2} onChange={(event) => setImageUrl2(event.target.value)} />
              }
            />
          </Grid>
          <Grid top={10}>
            <InputWrap label={'결과'} labelWidth={160} input={<>{imageSimilarity}%</>} />
          </Grid>

          <Grid top={20} display="flex" justifyContent="center">
            <Button size="small" color="main" type="button" onClick={checkImageSimilarity}>
              이미지 유사도 검사
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* 상품 유사도 재측정 */}
      <Grid top={50}>
        <Text fontWeight={700}>[상품유사도 재확인]</Text>
        <Grid top={20}>
          <InputWrap
            label={'작업번호'}
            labelWidth={160}
            input={<TextField type={'text'} placeholder={`작업번호를 입력해 주세요`} value={jobId} onChange={handleChangeJobId} />}
          />
        </Grid>

        <Grid display="flex" width={'100%'}>
          {/* 가져온쇼핑몰 상품 */}
          <Grid top={20} display="flex" direction="column" width={'50%'} alignItems="flex-start">
            <InputWrap
              label={'가져온쇼핑몰 상품'}
              labelWidth={160}
              input={
                <>
                  <RadioButton
                    name={'scrapShopScrapType'}
                    id={`specific`}
                    label={'지정 상품'}
                    checked={scrapShopScrapType === ScrapType.SPECIFIC}
                    onChange={(e) => {
                      setScrapShopScrapType(ScrapType.SPECIFIC);
                    }}
                  />
                  <RadioButton
                    name={'scrapShopScrapType'}
                    id={`normal`}
                    label={'전체 상품'}
                    checked={scrapShopScrapType === ScrapType.NORMAL}
                    onChange={(e) => {
                      setScrapShopScrapType(ScrapType.NORMAL);
                    }}
                  />
                  {scrapShopScrapType === ScrapType.SPECIFIC && (
                    <>
                      <Grid top={20}>
                        <InputWrap
                          label={'상품번호'}
                          labelWidth={160}
                          input={
                            <TextArea
                              fontSize="inherit"
                              placeholder={`상품번호를 입력해 주세요`}
                              height={'150px'}
                              onChange={(event) => {
                                setScrapProdCodes(event.target.value);
                              }}
                            />
                          }
                        />
                      </Grid>
                    </>
                  )}
                </>
              }
            />
          </Grid>

          {/* 웨이크업 상품 */}
          <Grid top={20} left={30} display="flex" direction="column" width={'50%'}>
            <InputWrap
              label={'웨이크업 상품'}
              labelWidth={160}
              input={
                <>
                  <RadioButton
                    name={'wakeupScrapType'}
                    id={`specific`}
                    label={'지정 상품'}
                    checked={wakeupScrapType === ScrapType.SPECIFIC}
                    onChange={(e) => {
                      setWakeupScrapType(ScrapType.SPECIFIC);
                    }}
                  />
                  <RadioButton
                    name={'wakeupScrapType'}
                    id={`normal`}
                    label={'전체 상품'}
                    checked={wakeupScrapType === ScrapType.NORMAL}
                    onChange={(e) => {
                      setWakeupScrapType(ScrapType.NORMAL);
                    }}
                  />
                  {wakeupScrapType === ScrapType.SPECIFIC && (
                    <>
                      <Grid top={20}>
                        <InputWrap
                          label={'상품번호'}
                          labelWidth={160}
                          input={
                            <TextArea
                              fontSize="inherit"
                              placeholder={`상품번호를 입력해 주세요`}
                              height={'150px'}
                              onChange={(event) => {
                                setWakeupProdCodes(event.target.value);
                              }}
                            />
                          }
                        />
                      </Grid>
                    </>
                  )}
                </>
              }
            />
          </Grid>
        </Grid>

        <Grid top={20}>
          <InputWrap
            label={'결과받을 이메일주소'}
            labelWidth={160}
            input={
              <TextField
                type={'text'}
                placeholder={'이메일 주소를 입력해주세요'}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid top={20} display="flex" justifyContent="center">
        <Button size="small" color="main" type="button" onClick={checkReProdCompareJob}>
          상품 유사도 재확인 작업 시작
        </Button>
      </Grid>
    </Container>
  );
}
