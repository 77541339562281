import { ScrapProdForm } from 'components/form/ScrapProdForm';
import { useLocation } from 'react-router-dom';
import { IMemberProps, ISolTypeInState } from '../../../../../common/interface/props';
import Container from '../../../../UI/atoms/Container';

function ScrapProd({ solType }: IMemberProps) {
  const location = useLocation();

  const { targetSolType } = location.state as ISolTypeInState;

  return (
    <>
      <Container noBody={true}>
        <ScrapProdForm solType={solType} targetSolType={targetSolType} />
      </Container>
    </>
  );
}

export default ScrapProd;
