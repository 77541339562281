import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ADMIN, ALWAYZ, COUPANG, ESM, KAKAO, TOSS, checkUseSpringSolType } from '../../../../common/common.constants';

import Container from '../../atoms/Container';
import Swal from 'sweetalert2';
import { axiosCustom } from 'common/common.function';
import { useEffect, useState } from 'react';
import { IUserInfo, WakeUp } from 'common/interface/props';

const HeaderWrap = styled.div`
  background-color: #fff; //배경색상
  width: 100%; //가로 넓이
  height: 80px; // 세로 높이
  border-bottom: 1px solid #ddd; //헤더 밑줄긋기
  position: fixed; //스크롤 내려도 고정되있음(바디 장악하니 주의할것)
  z-index: 999; //position을 주면 z-index 높이가 생긴다.
  top: 0;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;

  > div:last-child {
    > a,
    span {
      font-weight: 500;
      color: ${({ theme }) => theme.pltoColor};
      padding: 5px 15px;

      &:last-child {
        border: 1px solid ${({ theme }) => theme.pltoColor};
        border-radius: 30px;
      }
    }
  }
`;

export default function Header() {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const location = useLocation();

  // solType
  const [solType, setSolType] = useState('');

  // 로그인 여부
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 로그인 여부 체크 (주소가 바뀔때마다)
  useEffect(() => {
    // WakeUp 서비스 확인
    setSolType(
      ['WEMAKEPRICE', `ADMIN`, `COUPANG`, 'ST11', 'KAKAO', 'ALWAYZ', 'ESM', 'TOSS', 'ALL'].includes(location.pathname.split('/')[1].toUpperCase())
        ? location.pathname.split('/')[1].toUpperCase()
        : '',
    );

    // 로그인 정보 가져오기
    const rawUserInfo = sessionStorage.getItem('userInfo');

    // 로그인 데이터 파싱
    const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);

    userInfo ? setIsLoggedIn(true) : setIsLoggedIn(false);
  }, [location]);

  // useEffect(() => {
  //   if (location.pathname !== '/coupang/admin' && solType === 'COUPANG') {
  //     window.location.href = 'https://engapi-coupang.playauto.co.kr';
  //   }
  // }, [solType]);

  // 로그아웃
  async function checkLogout() {
    const confirm = await Swal.fire({
      title: '확인',
      text: '로그아웃하시겠습니까?',
      showConfirmButton: true,
      showCancelButton: true,
    });

    if (confirm.isConfirmed) {
      //Cookie에 jwt Token 삭제 API 호출
      let logoutResult;
      // if (solType && checkUseSpringSolType(solType as WakeUp)) {
      //   logoutResult = await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/logout`);
      // } else {
      logoutResult = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/logout`);
      // }

      if (logoutResult.success) {
        setIsLoggedIn(false);

        sessionStorage.removeItem('userInfo'); //로그인 정보 세션스토리지 초기화
        sessionStorage.removeItem('wakeupInfo');
        sessionStorage.removeItem('addressSeq'); // 11번가 주소록 세션스토리지 초기화
        navigate(`/${solType.toLowerCase()}/member/create-account`); //router init
      }
    }
  }

  return (
    <>
      {solType !== '' && (
        <HeaderWrap>
          <HeaderContainer noBody={true}>
            <div>
              {
                <Link to={`/${solType.toLowerCase()}/member/create-account`}>
                  <img src="https://cloud.plto.com/design/logo/logo_main.png" alt="main" style={{ width: '8.25rem' }} />
                </Link>
              }
            </div>
            <div>
              {solType !== ADMIN && solType === COUPANG ? (
                !isLoggedIn ? (
                  <>
                    {pathname.includes('/member/create-account') ? (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_coupang`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>
                        <Link to={`/${solType.toLowerCase()}/member/login`}>로그인</Link>
                      </>
                    ) : (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_coupang`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>

                        <Link to={`/${solType.toLowerCase()}/member/create-account`}>회원가입</Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link to={`/admin/dashboard`}>연동 관리</Link> */}
                    <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_coupang`} target="_blank" rel="noreferrer">
                      사용 가이드 보러가기
                    </a>
                    <span onClick={checkLogout} style={{ paddingLeft: 20, cursor: 'pointer' }}>
                      로그아웃
                    </span>
                  </>
                )
              ) : (
                <></>
              )}
              {solType !== ADMIN && solType === ALWAYZ ? (
                !isLoggedIn ? (
                  <>
                    {pathname.includes('/member/create-account') ? (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_alwayz`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>
                        <Link to={`/${solType.toLowerCase()}/member/login`}>로그인</Link>
                      </>
                    ) : (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_alwayz`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>

                        <Link to={`/${solType.toLowerCase()}/member/create-account`}>회원가입</Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link to={`/admin/dashboard`}>연동 관리</Link> */}
                    <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_alwayz`} target="_blank" rel="noreferrer">
                      사용 가이드 보러가기
                    </a>
                    <span onClick={checkLogout} style={{ paddingLeft: 20, cursor: 'pointer' }}>
                      로그아웃
                    </span>
                  </>
                )
              ) : (
                <></>
              )}
              {solType !== ADMIN && solType === ESM ? (
                !isLoggedIn ? (
                  <>
                    {pathname.includes('/member/create-account') ? (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_esm`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>
                        <Link to={`/${solType.toLowerCase()}/member/login`}>로그인</Link>
                      </>
                    ) : (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_esm`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>

                        <Link to={`/${solType.toLowerCase()}/member/create-account`}>회원가입</Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link to={`/admin/dashboard`}>연동 관리</Link> */}
                    <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_esm`} target="_blank" rel="noreferrer">
                      사용 가이드 보러가기
                    </a>
                    <span onClick={checkLogout} style={{ paddingLeft: 20, cursor: 'pointer' }}>
                      로그아웃
                    </span>
                  </>
                )
              ) : (
                <></>
              )}

              {solType !== ADMIN && solType === KAKAO ? (
                !isLoggedIn ? (
                  <>
                    {pathname.includes('/member/create-account') ? (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_kakao`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>
                        <Link to={`/${solType.toLowerCase()}/member/login`}>로그인</Link>
                      </>
                    ) : (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_kakao`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>

                        <Link to={`/${solType.toLowerCase()}/member/create-account`}>회원가입</Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link to={`/admin/dashboard`}>연동 관리</Link> */}
                    <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_kakao`} target="_blank" rel="noreferrer">
                      사용 가이드 보러가기
                    </a>
                    <span onClick={checkLogout} style={{ paddingLeft: 20, cursor: 'pointer' }}>
                      로그아웃
                    </span>
                  </>
                )
              ) : (
                <></>
              )}

              {solType !== ADMIN && solType === TOSS ? (
                !isLoggedIn ? (
                  <>
                    {pathname.includes('/member/create-account') ? (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_toss`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>
                        <Link to={`/${solType.toLowerCase()}/member/login`}>로그인</Link>
                      </>
                    ) : (
                      <>
                        <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_toss`} target="_blank" rel="noreferrer">
                          사용 가이드 보러가기
                        </a>

                        <Link to={`/${solType.toLowerCase()}/member/create-account`}>회원가입</Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link to={`/admin/dashboard`}>연동 관리</Link> */}
                    <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_toss`} target="_blank" rel="noreferrer">
                      사용 가이드 보러가기
                    </a>
                    <span onClick={checkLogout} style={{ paddingLeft: 20, cursor: 'pointer' }}>
                      로그아웃
                    </span>
                  </>
                )
              ) : (
                <></>
              )}


              {solType !== ADMIN && solType !== COUPANG && solType !== ALWAYZ && solType !== ESM && solType !== KAKAO && solType !== TOSS ? (
                !isLoggedIn ? (
                  <>
                    <Link to={`/${solType.toLowerCase()}/member/login`}>로그인</Link>
                    <Link to={`/${solType.toLowerCase()}/member/create-account`}>회원가입</Link>
                  </>
                ) : (
                  <>
                    {/* <Link to={`/admin/dashboard`}>연동 관리</Link> */}
                    <span onClick={checkLogout} style={{ paddingLeft: 20, cursor: 'pointer' }}>
                      로그아웃
                    </span>
                  </>
                )
              ) : (
                <></>
              )}
            </div>
          </HeaderContainer>
        </HeaderWrap>
      )}
    </>
  );
}
