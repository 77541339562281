import { WAKEUP_FORM_DATA } from '../../../../common/common.constants';
import { WakeUp } from '../../../../common/interface/props';
import ShopLoginForm2 from '../../../form/ShopLogin';
import TitleWrap from '../../molecules/TitleWrap';

interface IProps {
  solType: WakeUp;
}

export default function ShopLoginForm({ solType }: IProps) {
  return (
    <>
      <TitleWrap title="사이트 선택" subject={`${WAKEUP_FORM_DATA[solType].name.type2} 전송할 상품의 쇼핑몰을 선택해주세요.`} step={2} />
      <ShopLoginForm2 solType={solType} />
    </>
  );
}
