import { IMemberProps } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import Container from '../../../../UI/atoms/Container';

import TitleWrap from 'components/UI/molecules/TitleWrap';
import { WAKEUP_FORM_DATA } from 'common/common.constants';
import WakeupLoginForm from 'components/form/WakeupLoginForm';

export default function WakeUpLogin({ solType }: IMemberProps) {
  return (
    <>
      <Container noBody={true}>
        <Grid>
          <TitleWrap
            title={`${WAKEUP_FORM_DATA[solType].name.type1} 상품비교 작업`}
            subject={`판매중인 상품을 ${WAKEUP_FORM_DATA[solType].name.type1} 상품과 비교합니다.`}
            step={3}
          />
          <WakeupLoginForm solType={solType} coupangSellerType={'3P'} isProdCompare={true} />
        </Grid>
      </Container>
    </>
  );
}
