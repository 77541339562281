// 총 2개의 router를 만든다. 로그인된 사람 Router, 로그아웃된 사람 Router

import { ReactRouter } from './reactrouter';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider, QueryClient } from 'react-query';

// Redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
// Redux 개발자 도구
import { composeWithDevTools } from 'redux-devtools-extension';

// Redux Middleware
import logger from 'redux-logger';
import thunk from 'redux-thunk';
// Reducer
import rootReducer from '../src/redux_modules/index';

import GlobalStyle from './styles/global';
import theme from './styles/theme';

// react datepicker
import 'react-datepicker/dist/react-datepicker.css';
// import './styles/main.scss';

// Redux Stroe
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
  // logger는 dev때만 사용 chrome console 창에 상태 표시됨
  //composeWithDevTools(applyMiddleware(thunk, logger)),
);

const queryClient = new QueryClient();

export default function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        {/* 실서버 배포 금지 - 하면 홈페이지에 리엑트 쿼리 아이콘 보임 ㅡ ㅡ */}
        {/* <ReactQueryDevtools /> */}
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ReactRouter />
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </>
  );
}
