import styled from 'styled-components';
// import iconStep from '../../../../images/login/icon_step.png';

interface IProps {
  step: number;
  direction?: 'row' | 'column';
}
interface INumber {
  selected: boolean;
}

const StepSmallWrap = styled.div<{ direction: IProps['direction'] }>`
  width: 18.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    position: absolute;
    right: 0;
    width: 150px;

    top: ${({ direction }) => (direction === 'row' ? -30 : 0)}px;
  }

  > img {
    height: 1.2rem;
  }
`;

const Number = styled.span<INumber>`
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 0.8rem;
  background-color: ${(props) => (props.selected ? '#333' : '#ccc')};
  border-radius: 50%;
`;

function StepSmall({ step, direction }: IProps) {
  return (
    <StepSmallWrap direction={direction}>
      <Number selected={step === 1 ? true : false}>01</Number>
      <img src="/images/iconStep.png" alt="step" />
      <Number selected={step === 2 ? true : false}>02</Number>
      <img src="/images/iconStep.png" alt="step" />
      <Number selected={step === 3 ? true : false}>03</Number>
    </StepSmallWrap>
  );
}

export default StepSmall;
