import { SellerType } from 'common/interface/props';
import { TokenLoginForm } from 'components/form/TokenLoginForm';
import { useSearchParams } from 'react-router-dom';
import Container from '../../../UI/atoms/Container';

/**
 * gmp에서 준 token으로 wakeup 로그인 시켜줌
 */
export default function TokenLogin() {
  const [searchParams] = useSearchParams(); // url param 가져오기

  const token = searchParams.get('token')!;
  const jobId = searchParams.get('jobId')!;
  const sendAddressSeq = searchParams.get('sendAddressSeq') || '';
  const returnAddressSeq = searchParams.get('returnAddressSeq') || '';
  const isFirstLogin = searchParams.get('isFirstLogin') === 'true';
  const coupangSellerType = (searchParams.get('sellerType') || '3P') as SellerType;

  return (
    <Container>
      <TokenLoginForm
        token={token}
        jobId={+jobId || 0}
        isFirstLogin={isFirstLogin}
        sendAddressSeq={sendAddressSeq}
        returnAddressSeq={returnAddressSeq}
        coupangSellerType={coupangSellerType}
      ></TokenLoginForm>
    </Container>
  );
}
