// npm
import React from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useMutation } from 'react-query';

// common
import { IMemberProps, SellerType } from 'common/interface/props';
import { IFindEmailOutput, IFindPasswordOutput, ILoginOutput } from 'components/form/interfaces/member.interface';
import { COUPANG, regexEmail, regexPassword, ADMIN_USER, PROD_COMPARE_USER, checkIntergrationGMP, TargetSolTypeRole } from 'common/common.constants';

// atomic component
import Text from 'components/UI/atoms/Text';
import Grid from 'components/UI/atoms/Grid';
import TextField from 'components/UI/atoms/TextField';
import Button from 'components/UI/atoms/Button';

// etc
import { FormError } from 'components/form/form-error';
import { findEmaildMutation, FindPasswordMutation, LoginMutation } from 'components/form/api/member.api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccessRequest } from 'redux_modules/login';

export default function Login({ solType }: IMemberProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate(); // 히스토리 구하기
  const [searchParams] = useSearchParams(); // url param 가져오기
  const location = useLocation();
  const dispatch = useDispatch();
  const isProdCompare = location.pathname.toLowerCase().includes('prod-compare');
  const [sendAddressSeq, setSendAddressSeq] = React.useState<String>('');
  const [returnAddressSeq, setReturnAddressSeq] = React.useState<String>('');

  /**
   * 2.0 연동 오픈 용 소스이며, 쿠팡만 선오픈 되므로 임시 주석처리
   * 11번가 유효성 검사 함수, 11번가는 셀러오피스를 통해 들어온 업체만 사용가능
   */
  async function st11Validation(email: string) {
    // // ===========================================================
    // // QA 용 테스트 코드

    // // 이메일이 st11_test@playauto.co.kr 면 11번가 페이지로 이동 시켜주는 케이스 보여줌
    // if (email === 'st11_test@playauto.co.kr') {
    //   await Swal.fire({
    //     title: '실패',
    //     html: '11번가 SellerOffice 통해 접속한 사용자만 사용이 가능합니다.SellerOffice 출고지, 반품/교환지 정보를 입력한 후, 접속해주시기 바랍니다.',
    //     icon: 'error',
    //     showCancelButton: true,
    //     cancelButtonText: '취소',
    //     confirmButtonText: '11번가 접속',

    //     preConfirm: () => {
    //       window.open('https://soffice.11st.co.kr/register/popupPlayauto.tmall?method=popupPlayauto');
    //     },
    //   });

    //   Swal.close();
    //   return false;
    // }

    // // 임시로 위 계정 하나 빼고 전부 11번가 주소록 검사 넘어감
    // sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq: '2', returnAddressSeq: '3' }));
    // return true;
    // ===========================================================

    if (location.pathname.includes('st11')) {
      const sendAddressSeq = searchParams.get('sendAddressSeq');
      const returnAddressSeq = searchParams.get('returnAddressSeq');
      const sessionAddressReq = sessionStorage.getItem('addressSeq');

      // 디버그 환경에서는 wakeup 테스트 계정의 주소록 설정 후 패스
      if (process.env.REACT_APP_DEV === 'true' || ['pawn-admin@playauto.co.kr', 'derntest119@yopmail.com'].includes(email)) {
        sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq: '2', returnAddressSeq: '3' }));
        setSendAddressSeq('2');
        setReturnAddressSeq('3');
        return true;
        // 세션 스토리지에 addressReq있을 경우 패스
      } else if (sendAddressSeq && returnAddressSeq) {
        setSendAddressSeq(sendAddressSeq);
        setReturnAddressSeq(returnAddressSeq);
        // 웨이크업 로그인 전에 다른 페이지로 이동하고올 수도 있으니까 세션스토리지에도 저장은 해두자
        sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq: sendAddressSeq, returnAddressSeq: returnAddressSeq }));
        return true;
      } else if (sessionAddressReq) {
        const addressSeq = JSON.parse(sessionAddressReq);

        setSendAddressSeq(addressSeq.sendAddressSeq);
        setReturnAddressSeq(addressSeq.returnAddressSeq);
        return true;
      } else {
        await Swal.fire({
          title: '실패',
          html: '11번가 SellerOffice 통해 접속한 사용자만 사용이 가능합니다.SellerOffice 출고지, 반품/교환지 정보를 입력한 후, 접속해주시기 바랍니다.',
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: '취소',
          confirmButtonText: '11번가 접속',

          preConfirm: () => {
            window.open('https://soffice.11st.co.kr/register/popupPlayauto.tmall?method=popupPlayauto');
          },
        });

        Swal.close();
        return false;
      }
    }
    return true;
  }

  const { mutate: login } = useMutation(LoginMutation, {
    // mutate 함수가 실행되기 전에 실행
    onMutate: (variables) => {
      // 무한 로딩 화면 생성
      Swal.fire({
        title: `로그인 중...`,
        html: '플레이오토 회원검사를 진행중입니다.', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    },
    onSuccess: async (data: ILoginOutput, variables) => {
      // //mutation 실행후 받은 data를 serverState에 넣는 예제
      // // queryKey가 'login'로 시작하는 모든 쿼리 무효화
      // await queryClient.invalidateQueries('login');
      // //login에서 응답받은 data를 react-query에 담음
      // queryClient.setQueryData('login', data);

      if (data && data.success) {
        const result = await Swal.fire({
          title: '성공',
          html: `로그인 성공`,
          icon: 'success',
          confirmButtonText: '확인',
        });

        if (result.isConfirmed) {
          const token = data.gmpToken;

          // 2.0 사용안함(REACT_APP_GMP_NOT_USED === 'true')이면, 2.0 모달 페이지로 이동 안하고 바로 shop-login으로 이동
          // + 어드민계정도 예외처리 포함 (ex. pawn_test@playauto.co.kr)
          // const isAdminAccount = ADMIN_USER.includes(variables.email);

          // 2.0 솔루션과 연동된 solType 만 이동 시킴
          // 다만 어드민 계정이면 무조건 2.0 솔루션 안거침
          const isAdminAccount = !checkIntergrationGMP(solType) || ADMIN_USER.includes(variables.email);

          if (process.env.REACT_APP_GMP_NOT_USED === 'true' || isAdminAccount || isProdCompare) {
            dispatch(
              loginSuccessRequest({
                isLogined: true,
                isAdmin: isAdminAccount,
                isSolExpired: false,
              }),
            );

            // dev2 에서만 여기서 인증값 세팅하고
            // 일반 환경에서는 token-login 폼에서 세팅 함
            sessionStorage.setItem('wakeupInfo', JSON.stringify({ gmpToken: token }));
            // 이메일이랑 솔타입 편하게 꺼내쓰기 위해 넣음
            sessionStorage.setItem('userInfo', JSON.stringify({ email: variables.email, solType: variables.solType }));

            navigate(`/${solType.toLowerCase()}${isProdCompare ? '-prod-compare' : ''}/job/shop-login`);

            return true;
          }

          // 쿠팡만 적용
          // 3P -> 일반
          // Retail -> 쿠팡 서플라이
          let sellerType: SellerType = '3P';
          if (location.pathname.toLowerCase().includes('coupang')) {
            const coupangSellerType = sessionStorage.getItem('sellerType');

            sellerType = coupangSellerType === 'Retail' ? coupangSellerType : '3P';
          }

          // 일반 환경에서는 2.0 솔루션에서 모달로 웨이크업 솔루션이 띄워짐
          window.location.href = `${process.env.REACT_APP_GMP_API_ENDPOINT}/login.html?sendAddressSeq=${sendAddressSeq}?returnAddressSeq=${returnAddressSeq}?utm_source=letter&utm_medium=email&utm_campaign=wakeup&utm_content=complete_mail_1&p=/startWakeUp&wakeup_user_token=${token}&sellerType=${sellerType}`;
          return true;
        }
      } else {
        Swal.fire({
          title: '실패',
          html: `로그인 실패`,
          icon: 'error',
        });
      }

      // const referUrl = new URL(window.location.href).searchParams.get('referUrl');

      // if (referUrl) {
      //   navigate(referUrl); //해당 주소로 이동
      // } else {
      //   if (data?.result?.useCount && data?.result?.useCount > 0) {
      //     navigate(`/${solType.toLowerCase()}/LoginAfterChoice`); //해당 주소로 이동
      //   } else {
      //     navigate(`/${solType.toLowerCase()}/job/shop-login`); //해당 주소로 이동
      //   }
      // }
    },
    onError: (error: any) => {
      Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
      // 에러 발생
    },
  });

  const { mutate: findPassword } = useMutation(FindPasswordMutation, {
    // mutate 함수가 실행되기 전에 실행
    onMutate: (variables) => {},
    onSuccess: async (data: IFindPasswordOutput, variables) => {
      await Swal.fire({
        title: '성공',
        html: `입력하신 ${variables.email}로 비밀번호 재설정 이메일이 전송되었습니다.<br/>이메일 인증하기 버튼을 눌러 비밀번호를 변경해주세요`,
        icon: 'success',
        confirmButtonText: '확인',
      });
    },
    onError: (error: any) => {
      Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    },
  });

  const { mutate: findEmail } = useMutation(findEmaildMutation, {
    // mutate 함수가 실행되기 전에 실행
    onMutate: (variables) => {},
    onSuccess: async (data: IFindEmailOutput, variables) => {
      if (data.success === false) {
        await Swal.fire({
          title: '경고',
          html: '일치하는 쿠팡 밴더아이디가 없습니다.<br/> 다시 확인하여 주시기 바랍니다.',
          icon: 'error',
        });
      } else
        await Swal.fire({
          title: '성공',
          html: `${data.maskingEmail?.join('<br/>')}`,
          icon: 'success',
          confirmButtonText: '확인',
        });
    },
    onError: async (error: any) => {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    },
  });

  // 쇼핑몰 로그인 정보 전송 함수
  async function onSubmit(data: any) {
    const { email, password } = data;
    //  * 2.0 연동 오픈 용 소스이며, 쿠팡만 선오픈 되므로 임시 주석처리
    // 11번가 유효성 검사
    const st11ValidationResult = await st11Validation(email);

    if (isProdCompare) {
      /* ******************************* 상품비교 테스트계정 login ******************************* */
      if (PROD_COMPARE_USER.find((v) => v === email)) {
        login({ email, password, solType });
      } else {
        Swal.fire({
          title: '경고',
          html: '상품 비교 작업을 할 수 있는 계정이 아닙니다',
          icon: 'error',
        });
      }
    } else {
      /* ******************************* 일반계정 login ******************************* */
      // useMutation 실행
      st11ValidationResult && login({ email, password, solType });
    }
  }

  // 비밀번호 찾기
  async function onfindPw(e: React.MouseEvent) {
    e.preventDefault();
    const { value: email } = await Swal.fire({
      title: `비밀번호 찾기`,
      input: `email`,
      inputPlaceholder: `이메일 주소를 입력해 주시기 바랍니다.`,
      confirmButtonText: `인증메일 발송`,
    });
    findPassword({ email, solType });
  }

  // 이메일 아이디 찾기
  async function onfindEmail(e: React.MouseEvent) {
    e.preventDefault();
    const { value: vendorId, dismiss } = await Swal.fire({
      title: `아이디 찾기`,
      input: `text`,
      inputPlaceholder: `쿠팡 업체코드를 입력해 주시기 바랍니다.`,
      confirmButtonText: `아이디 찾기`,
      showCancelButton: true,
      cancelButtonText: `취소`,
    });
    //입력했을 경우 백엔드로 전송 시작
    if (vendorId) {
      return findEmail({ vendorId, solType });
    }
    //입력하지 않았을 경우 에러 노출
    if (!vendorId && dismiss === undefined) {
      return await Swal.fire({
        title: '경고',
        html: '쿠팡 업체코드가 입력되지 않았습니다. <br/> 다시 확인하여 주시기 바랍니다.',
        icon: 'error',
      });
    }
    //취소버튼을 클릭한 경우 아무 처리 안함
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid display="flex" direction="column" alignItems="center" justifyContent="center" height={400}>
          <Text fontSize="28px" fontWeight={500}>
            로그인
          </Text>
          <Grid width={400} top={30} bottom={30}>
            <TextField type="email" placeholder="이메일 ID" {...register(`email`, { required: true, maxLength: 50, pattern: regexEmail })} />
            {errors?.email && errors.email && errors.email.type === 'required' && <FormError errorMessage={'이메일은 필수 입력 항목입니다.'} />}
            {errors?.email && errors.email.type === 'maxLength' && <FormError errorMessage={'이메일의 최대 길이를 초과하였습니다.(최대 50자)'} />}
            {errors?.email && errors.email.type === 'pattern' && <FormError errorMessage={'올바른 이메일 형식이 아닙니다.'} />}
            <Grid top={30} />
            <TextField type="password" placeholder="비밀번호" {...register(`password`, { required: true, minLength: 8, pattern: regexPassword })} />
            {errors?.password && errors.password.type === 'required' && <FormError errorMessage={'비밀번호는 필수 입력 항목입니다.'} />}
            {errors?.password && errors.password.type === 'minLength' && <FormError errorMessage={'비밀번호는 8자 이상 입력해 주세요.'} />}
            {errors?.password && errors.password.type === 'pattern' && <FormError errorMessage={'비밀번호는 영문+숫자+특문 8자 이상 입력해 주세요.'} />}
          </Grid>
          <Button type="submit" width={240} color="main">
            로그인
          </Button>

          <Grid display="flex" justifyContent="center" width={400} top={30}>
            <Text underline fontWeight={500} onClick={onfindPw}>
              <a href={`/${solType.toLowerCase()}/member/login`}>비밀번호를 잊으셨나요?</a>
            </Text>
          </Grid>

          {solType === COUPANG && (
            <Grid display="flex" justifyContent="center" width={400} top={10}>
              <Text underline fontWeight={500} onClick={onfindEmail}>
                <a href={`/${solType.toLowerCase()}/member/login`}>아이디를 잊으셨나요?</a>
              </Text>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
}
