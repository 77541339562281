import TitleWrap from 'components/UI/molecules/TitleWrap';
import { IMemberProps } from '../../../../../common/interface/props';
import Container from '../../../../UI/atoms/Container';
import ShopLoginForm2 from '../../../../form/ShopLogin';
import { WAKEUP_FORM_DATA } from 'common/common.constants';

export default function ShopLoginProdCompare({ solType }: IMemberProps) {
  return (
    <>
      <Container noBody={true}>
        <TitleWrap
          title="사이트 선택"
          subject={`${WAKEUP_FORM_DATA[solType].name.type1} 상품과 비교할 쇼핑몰을 선택해주세요.`}
          step={2}
        />

        <ShopLoginForm2 solType={solType} isProdCompare={true} />
      </Container>
    </>
  );
}
