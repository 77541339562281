import Grid from 'components/UI/atoms/Grid';
import styled from 'styled-components';

interface IProps {
  children?: string;
  subTitle?: string;
  direction?: 'row' | 'column';
}

const TitleTag = styled.h2`
  font-size: 1.77rem;
  padding-right: 10px;
  /* font-weight: ; */
`;

const SubTitleTag = styled.h4`
  font-weight: 500;
  padding-top: 10px;
  font-size: 18px;
`;

function Title({ children, subTitle, direction }: IProps) {
  return (
    <Grid display={direction === 'row' ? 'flex' : 'block'} alignItems="flex-end">
      <TitleTag>{children}</TitleTag>
      {subTitle && <SubTitleTag>{subTitle}</SubTitleTag>}
    </Grid>
  );
}

export default Title;
