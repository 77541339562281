import { IMemberProps } from '../../../../common/interface/props';
import ProgressForm from '../../../form/ProgressForm';
import Container from '../../../UI/atoms/Container';

export default function Progress({ solType, text }: IMemberProps) {
  return (
    <Container noBody={true}>
      <ProgressForm solType={solType} />
    </Container>
  );
}
