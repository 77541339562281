import styled from 'styled-components';

interface IProps {
  variant?: 'filled' | 'outlined';
  width?: number;
}

const TextField = styled.input<IProps>`
  ${({ variant, width }) =>
    variant === 'outlined'
      ? `
  //width: 100%;
  //padding: 5px 0;
  width: ${width ? width : '200'}px;
  padding: 5px 8px;
  border: 1px solid#e2e2e2;
  background: none;
  border-radius:3px;
  //border-bottom: 1px solid #ddd;
  `
      : `
  width: 100%;
  //padding: 5px 0;
  padding-bottom: 5px;
  border: none;
  background: none;
  border-bottom: 1px solid #ddd;
  `}
`;

export default TextField;
