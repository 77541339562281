import { checkUseSpringSolType } from 'common/common.constants';
import { axiosCustom } from 'common/common.function';
import {
  IBizRegNoInput,
  IBizRegNoOutput,
  ICreateAccountInput,
  ICreateAccountOutput,
  IEmailCheckInput,
  IEmailCheckOutput,
  IFindEmailInput,
  IFindEmailOutput,
  IFindPasswordInput,
  IFindPasswordOutput,
  ILoginInput,
  ILoginOutput,
} from 'components/form/interfaces/member.interface';

/**
 * @Post
 * @url ${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/send-auth-mail2
 * @returns - 200 { success: true, message: "인증 메일 발송 완료" }
 * @returns - 403 { success: false, message: '중복된 이메일이 존재합니다. 로그인 후 이용 부탁 드립니다."}
 * @description - 이메일 중복 체크 및 인증메일 발송 함수
 */
export async function emailCheckMutation({ email, authCode, solType }: IEmailCheckInput): Promise<IEmailCheckOutput> {
  // if (checkUseSpringSolType(solType)) {
  //   return await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/send-auth-mail`, {
  //     email: email,
  //     authCode: authCode,
  //   });
  // } else {
  return await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/send-auth-mail2`, {
    email: email,
    authCode: authCode,
  });
  //}
}

/**
 * @Post
 * @url ${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/send-auth-mail2
 * @returns - 200 { success: true, message: "사업자 조회 성공" }
 * @returns - 403 { success: false, message: '이미 등록된 사업자 입니다." }
 * @description - 사업자 번호 확인 함수
 */
export async function bizNoCheckMutation({ bizRegNo, solType }: IBizRegNoInput): Promise<IBizRegNoOutput> {
  // if (checkUseSpringSolType(solType)) {
  //   return await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/search-biz-no`, {
  //     bizRegNo,
  //   });
  // } else {
  return await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/search-bizno`, {
    bizRegNo,
  });
  //}
}

/**
 * @Post
 * @url ${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/${solType.toLowerCase()}/create-account
 * @returns - 200 { success: true, message: "[쿠팡] - 회원 가입 성공" }
 * @returns - 403 { success: false, message: '플토 통합회원 가입에 실패하였습니다."}
 * @description - 회원가입 함수
 */
export async function createAccountMutation({
  solType,
  email,
  password,
  bizRegNo,
  tel,
  smsAgree,
  mailAgree,
}: ICreateAccountInput): Promise<ICreateAccountOutput> {
  // if (solType && checkUseSpringSolType(solType)) {
  //   return await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/create-account`, {
  //     email,
  //     password,
  //     bizRegNo,
  //     tel,
  //     smsAgree,
  //     mailAgree,
  //     solType,
  //   });
  // } else {
  return await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/create-account`, {
    email,
    password,
    bizRegNo,
    tel,
    smsAgree,
    mailAgree,
    solType,
  });
  //}
}

/**
 * @Post
 * @url ${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/${solType.toLowerCase()}/login
 * @returns - 200 { success: true, message: "[쿠팡] - 로그인 성공", result: { useCount: 92 } }
 * @returns - 403 { success: false, message: '아이디 또는 비밀번호가 일치하지 않습니다."}
 * @description - 웨이크업 로그인 함수
 */
export async function LoginMutation({ email, password, solType }: ILoginInput): Promise<ILoginOutput> {
  // console.log(solType);
  // if (solType && checkUseSpringSolType(solType)) {
  //   return await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/login`, {
  //     email,
  //     password,
  //     solType,
  //   });
  // } else {
  return await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/login`, {
    email,
    password,
    solType,
  });
  //}
}

/**
 * @Post
 * @url ${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/${solType.toLowerCase()}/find-pw
 * @returns - 200 { success: true, message: "비밀번호 찾기 성공" }
 * @returns - 422 { success: false, message: '이메일을 확인해주세요'}
 * @description - 비밀번호 찾기 함수, plto.com 의 비밀번호 찾기 API를 사용함
 */
export async function FindPasswordMutation({ email, solType }: IFindPasswordInput): Promise<IFindPasswordOutput> {
  return await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/find-pw`, {
    email,
    solType,
  });
}

/**
 * @Post
 * @url ${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/${solType.toLowerCase()}/find-email
 * @returns - 200 { success: true, result : [0] : ""ans***@gmail.com"", [1] : "asd@naver.com" }
 * @returns - 200 { success: false, message: '이메일 찾기 실패, 쿠팡 업체코드를 확인해 주세요'}
 * @description - 이메일 찾기 함수, 웨이크업 DB에서 쿠팡 벤더코드와 일치하는 이메일을 찾음
 */
export async function findEmaildMutation({ vendorId, solType }: IFindEmailInput): Promise<IFindEmailOutput> {
  // if (checkUseSpringSolType(solType)) {
  //   return await axiosCustom.post(`${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/coupang/find-email`, {
  //     vendorId,
  //     solType,
  //   });
  // } else {
  return await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/coupang/find-email`, {
    vendorId,
    solType,
  });
  //}
}
