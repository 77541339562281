import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import styled from 'styled-components';
import Grid from '../../atoms/Grid';
import Text from '../../atoms/Text';

interface IProps {
  nowPage: number;
  max: number;
  size: number;
  nowPageEvent: any;
}

const PageBox = styled(Grid)`
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background: #e8e9eb;
  }
`;

export default function Pagination({ nowPage, max, size, nowPageEvent }: IProps) {
  // 페이지네이션 시작 페이지
  const startPage = Math.floor((nowPage - 1) / size) * size + 1;

  return (
    <Grid display="flex" justifyContent="center">
      <PageBox
        onClick={() => {
          const page = startPage - size;
          //   if (page > 0 && !disabled) nowPageEvent(page);
          if (page > 0) nowPageEvent(page);
        }}
      >
        <BiChevronLeft />
      </PageBox>
      {[...Array(size)].map((one, index) => {
        const page = startPage + index;

        // 마지막 페이지 검수
        return page > max || nowPage <= 0 ? (
          <></>
        ) : (
          <PageBox
            key={index}
            // className={`page-item ${page === nowPage ? 'active' : ''}`}
            color={page === nowPage ? 'main' : ''}
            onClick={() => {
              //   !disabled && nowPageEvent(page);
              nowPageEvent(page);
            }}
          >
            <Text color={page === nowPage ? 'white' : 'black'}>{page}</Text>
          </PageBox>
        );
      })}
      <PageBox
        className="page-item"
        onClick={() => {
          const page = startPage + size;
          //   if (page <= max && !disabled) nowPageEvent(page);
          if (page <= max) nowPageEvent(page);
        }}
      >
        <BiChevronRight />
      </PageBox>
    </Grid>
  );
}
