import { axiosCustom } from 'common/common.function';
import React from 'react';
import { useLocation } from 'react-router-dom';

export default function GMPAdminLogin() {
  const { search } = useLocation();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // GMP 토큰 발행 (로그인 쿠키로 백엔드에서 GMP 토큰 생성함)
        const { result } = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/get-token-gmp`);

        // 2.0 이동
        window.location.href = `https://app.playauto.io/login.html${search || '?'}&wakeup_user_token=${result.token}`;
      } catch (err) {
        // 오류시 자동로그인 없이 로그인 페이지로 이동
        window.location.href = `https://app.playauto.io/login.html`;
      }
    };

    fetchData();
  }, []);

  return <div>GMPAdminLogin</div>;
}
