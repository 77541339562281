import { IMemberProps } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import WakeUpLoginForm from '../../../../UI/organisms/WakeUpLoginForm';
import Container from '../../../../UI/atoms/Container';

export default function WakeUpLogin({ solType }: IMemberProps) {
  return (
    <Container noBody={true}>
      <WakeUpLoginForm solType={solType} />
    </Container>
  );
}
