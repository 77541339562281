import { IMemberProps } from 'common/interface/props';

//UI Components
import Grid from 'components/UI/atoms/Grid';
import CreateAccountAgree from 'components/UI/organisms/CreateAccountAgree';
import CreateAccountForm from 'components/UI/organisms/CreateAccountForm';
import CreateAccountGuide from 'components/UI/organisms/CreateAccountGuide';
import Container from 'components/UI/atoms/Container';

export default function CreactAccount({ solType }: IMemberProps) {
  return (
    <>
      {/* <Banner imgPath="/images/bg_main.png" text="플레이오토 회원가입" /> */}
      <Container noBody={true}>
        <Grid top={60}>
          <CreateAccountGuide solType={solType} />
        </Grid>
        <Grid top={60}>
          <CreateAccountAgree />
        </Grid>
        <Grid top={60} bottom={60}>
          <CreateAccountForm solType={solType} />
        </Grid>
      </Container>
    </>
  );
}
