import Grid from '../../atoms/Grid';
import Text from '../../atoms/Text';

function LoginGuide() {
  return (
    <Grid display="flex" direction="column" alignItems="center">
      <br />
      <img style={{ width: 39, height: 39 }} src="/images/iconInfo2.png" alt="" />
      <Grid top={30}>
        <Text textAlign="center">
          <Text color="main" fontWeight={600} inline>
            1단계 ‘무료 회원가입’
          </Text>
          &nbsp;을 완료하신 분들은 로그인을 통해&nbsp;
          <Text color="main" fontWeight={600} inline>
            2단계 ‘이전할 쇼핑몰 선택’
          </Text>
          으로 넘어가실 수 있습니다
        </Text>
      </Grid>
    </Grid>
  );
}

export default LoginGuide;
