import { useParams } from 'react-router-dom';
import Container from '../UI/atoms/Container';

export default function Guide() {
  const { target } = useParams<{ target: string }>();

  let images: any[] = [];

  switch (target) {
    case 'smartStore1':
      images = ['smartStore1_1.png', 'smartStore1_2.png'];
      break;
    case 'smartStore2':
      images = ['smartStore2_1.png', 'smartStore2_2.png'];
      break;
    case 'coupang':
      images = ['coupang.png'];
      break;
    case 'wakeup_wemakeprice':
      images = ['wakeup_wemakeprice_1.png', 'wakeup_wemakeprice_2.png'];
      break;
    case 'wakeup_st11':
      images = ['wakeup_st11_1.png', 'wakeup_st11_2.png'];
      break;
    case 'wakeup_coupang':
      images = [
        'wakeup_coupang_title.png',
        'wakeup_coupang01.png',
        'wakeup_coupang02.png',
        'wakeup_coupang03.png',
        'wakeup_coupang04.png',
        'wakeup_coupang05.png',
        'wakeup_coupang06.png',
        'wakeup_coupang07.png',
      ];
      break;

    case 'scrap_prod_coupang':
      images = ['scrap_prod_1.png', 'scrap_prod_2.png', 'scrap_prod_3.png', 'scrap_prod_4.png', 'scrap_prod_5.png', 'scrap_prod_6.png'];
      break;
    case 'wakeup_alwayz':
      images = ['alwayz_main_1100.png'];
      break;
    case 'scrap_prod_alwayz':
      images = ['alwayz_scrap_prod_1.png', 'alwayz_scrap_prod_2.png', 'alwayz_scrap_prod_3.png', 'alwayz_scrap_prod_4.png', 'alwayz_scrap_prod_5.png'];
      break;

    case 'wakeup_esm':
      images = ['wakeup_esm_2.png', 'wakeup_esm_3.png', 'wakeup_esm_4.png', 'wakeup_esm_5.png', 'wakeup_esm_6.png'];
      break;

    case 'esm_sellingtool_guide':
      images = ['esm_sellingtool_guide_1.png'];
      break;
  
    case 'wakeup_kakao':
      images = ['wakeup_kakao.jpg'];
      break;

    case 'scrap_prod_kakao':
      images = ['scrap_prod_kakao_1.jpg'];
      break;

    case 'wakeup_toss':
      images = ['wakeup_toss_1.png', 'wakeup_toss_2.png', 'wakeup_toss_3.png', 'wakeup_toss_4.png', 'wakeup_toss_5.png', 'wakeup_toss_6.png', 'wakeup_toss_7.png', 'wakeup_toss_8.png',];
      break;
      
    case 'scrap_prod_toss':
      images = ['scrap_prod_toss_1.png', 'scrap_prod_toss_2.png', 'scrap_prod_toss_3.png', 'scrap_prod_toss_4.png', 'scrap_prod_toss_5.png'];
      break;

  }

  return (
    <>
      {target === 'wakeup_esm' && (
        <Container noBody={true}>
          <div style={{ position: 'relative' }}>
            <img
              src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/wakeup_esm_1.png`}
              style={{
                width: '100%',
                display: 'block',
              }}
              alt=""
            />
            <a
              href="https://cloud.plto.com/sales/Wakeup_manual/ESMPLUS_guide.pdf"
              style={{ display: 'block', width: '27%', height: '10%', position: 'absolute', bottom: '23%', left: '10%' }}
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
        </Container>
      )}

      {target === 'smartStore1' ? (
        <>
          <img
            src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${images[0]}`}
            alt=""
            style={{
              width: '100%',
            }}
          />
          <br />
          <br />
          <br />
          <div style={{ width: '1280px', margin: '0 auto' }}>
            <iframe width={1280} height={850} src="https://app.arcade.software/share/rZ0XSVY1tXKuvOK4JSh2" />
          </div>
          <img
            src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${images[1]}`}
            alt=""
            style={{
              display: 'block',
              width: '1280px',
              margin: '0 auto',
            }}
          />
        </>
      ) : target === 'smartStore2' ? (
        <>
          <img
            src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${images[0]}`}
            alt=""
            style={{
              width: '100%',
            }}
          />
          <br />
          <br />
          <br />
          <div style={{ width: '1280px', margin: '0 auto' }}>
            <iframe width={1250} height={850} src="https://app.arcade.software/share/cuCxcF6maNvXX4PENrCn" />
          </div>
          <img
            src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${images[1]}`}
            alt=""
            style={{
              display: 'block',
              width: '1280px',
              margin: '0 auto',
            }}
          />
        </>
      ) : target === 'esm_sellingtool_guide' ? (
        <>
          <img
            src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${images[0]}`}
            alt=""
            style={{
              width: '100%',
            }}
          />
          <br />
          <br />
          <br />
          <div style={{ width: '1280px', margin: '0 auto' }}>
            <iframe width={1250} height={850} src="https://app.arcade.software/share/E2sNCpcExWBc9J7FhdGQ" />
          </div>
        </>
      ) : target === 'coupang' ? (
        <>
          <img
            src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${images[0]}`}
            alt=""
            style={{
              width: '100%',
            }}
          />
          <br />
          <br />
          <br />
          <div style={{ width: '1280px', margin: '0 auto' }}>
            <iframe width={1250} height={850} src="https://app.arcade.software/share/xHDZ6Kh0udHIvDO7t0Qh" />
          </div>
        </>
      ) : (
        images.map((one, index) => (
          <Container noBody={true}>
            <div key={index}>
              <img
                src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/${one}`}
                alt=""
                style={{
                  width: '100%',
                }}
              />
            </div>
          </Container>
        ))
      )}

      {target === 'wakeup_esm' && (
        <Container noBody={true}>
          <div style={{ position: 'relative', paddingTop: '27px' }}>
            <img
              src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/wakeup_esm_07.png`}
              style={{
                width: '100%',
                display: 'block',
              }}
              alt=""
            />
            <a
              href="https://cloud.plto.com/sales/Wakeup_manual/ESMPLUS_guide.pdf"
              style={{ display: 'block', width: '23%', height: '15%', position: 'absolute', bottom: '24.2%', left: '17.8%' }}
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div style={{ position: 'relative', paddingTop: '27px' }}>
            <img
              src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/wakeup_esm_08.png`}
              style={{
                width: '100%',
                display: 'block',
              }}
              alt=""
            />
            <img
              src={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/images/help/wakeup_esm_09.png`}
              style={{
                width: '100%',
                display: 'block',
              }}
              alt=""
            />
          </div>
        </Container>
      )}
    </>
  );
}
