import { IMemberProps } from 'common/interface/props';
import Container from 'components/UI/atoms/Container';
import Grid from 'components/UI/atoms/Grid';
import Text from 'components/UI/atoms/Text';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function LoginAfterChoice({ solType }: IMemberProps) {
  return (
    <Container>
      <Wrap>
        <Box>
          <Link to="/GMPIntroduction" target="_blank">
            <Grid height="100%" style={{ overflow: 'hidden' }}>
              <img src="/images/image_1.png" alt="step" />
            </Grid>

            <TextBox>
              <Text fontSize="1.33rem" fontWeight={700}>
                플레이오토 2.0 solution
                <br /> 간략소개
              </Text>
              <Grid top={15} />
              <Text fontSize="0.77rem">
                판매자분의 플레이오토 2.0 solution 준비가 완료되었습니다. <br />
                상품 관리 및 주문까지 한번에 관리해보세요.
              </Text>
              <BottomTextBox>
                <Text color="main" fontWeight={700}>
                  자세히 알아보기 &nbsp;
                  <img src="/images/btn_more.png" alt="step" />
                </Text>
              </BottomTextBox>
            </TextBox>
          </Link>
        </Box>

        <Box>
          <Link to={`/${solType.toLowerCase()}/job/shop-login`}>
            <Grid height="100%" style={{ overflow: 'hidden' }}>
              <img src="/images/image_2.png" alt="step" />
            </Grid>

            <TextBox>
              <Text fontSize="1.33rem" fontWeight={700}>
                온라인 상품 등록
                <br /> 서비스
              </Text>
              <Grid top={15} />
              <Text fontSize="0.77rem">타사에서 상품을 쿠팡으로 쉽게 옮기세요!</Text>
              <BottomTextBox>
                <Text color="main" fontWeight={700}>
                  사용하기 &nbsp;
                  <img src="/images/btn_more.png" alt="step" />
                </Text>
              </BottomTextBox>
            </TextBox>
          </Link>
        </Box>
      </Wrap>
    </Container>
  );
}

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
`;

const Box = styled.div`
  width: 100%;
  max-width: calc(50% - 25px);
  height: 420px;
  position: relative;
  margin-bottom: 120px;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 530px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 530px;
    height: 280px;
  }
`;

const TextBox = styled.div`
  width: calc(100% - 40px);
  height: 350px;
  background: white;

  position: absolute;
  top: 150px;
  left: 50%;
  margin-left: calc((100% - 40px) / 2 * -1);
  box-shadow: 0 3px 30px rgb(0 0 0 / 30%);
  padding: 60px;

  @media ${({ theme }) => theme.device.mobile} {
    top: 100px;
    height: 230px;
  }
`;
const BottomTextBox = styled.div`
  position: absolute;
  bottom: 60px;

  @media ${({ theme }) => theme.device.mobile} {
    bottom: 20px;
  }
`;
