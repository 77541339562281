import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  // 하위 엘리먼트
  children: ReactNode;

  // variant (default = contained)
  variant?: 'contained' | 'outlined' | 'disabled';

  // 가로
  width?: number;

  // 색상
  color?: 'wakeup' | 'main' | string;

  // 사이즈 (defeault = medium)
  size?: 'small' | 'medium';

  // 버튼 타입
  type?: 'submit' | 'button';

  // 모서리 각도
  borderRadius?: number;

  // 디스플레이 유형
  display?: 'inline-block' | 'flex' | 'inline-flex' | 'block' | 'none';

  // 버튼 비활성화 유무
  disabled?: boolean;

  // 클릭 이벤트
  onClick?: MouseEventHandler;
}

const ButtonTag = styled.button<IProps>`
  border-radius: ${({ borderRadius }) => (borderRadius! >= 0 ? `${borderRadius}px` : '50px')};
  display: ${({ display }) => (display ? `${display}` : '')};
  disabled: ${({ disabled }) => (disabled ? `${disabled}` : '')};
  // 가로
  width: 100%;
  ${({ width }) => (width ? `max-width: ${width}px;` : '')}

  // 사이즈
  ${({ size }) =>
    size === 'small'
      ? `
  padding: 7px 15px;
  font-size: 0.8rem;
  `
      : `
  padding: 15px 20px;
  font-weight: 500;
  font-size: 0.88rem;
  `}


  // 색상
  ${({ variant, theme, color }) => {
    const useColor = color === 'main' ? theme.pltoColor : color === 'wakeup' ? theme.wakeUpColor : color;

    return variant === 'outlined'
      ? `
      color: ${useColor};
      background: none;
      border: 1px solid ${useColor};
      :hover {
        color: white;
        background: ${useColor};
      }
    `
      : variant === 'disabled'
      ? `
      color: #000;
      background: none;
      border: 1px solid #AAAA;

      :hover {
        color: ${useColor};
        background: none;
        border: 1px solid ${useColor};
      }
    `
      : `
    color:white;
      background: ${useColor};
      border: 1px solid ${useColor};

      :hover {
        color: ${useColor};
        background: none;
      }
    `;
  }}

  cursor: pointer;
`;

function Button(props: IProps) {
  return <ButtonTag {...props} />;
}

export default Button;
