import TitleWrap from '../../molecules/TitleWrap';
import AgreeWrap from '../../molecules/AgreeWrap';

function CreateAccountAgree() {
  return (
    <>
      <TitleWrap title="약관동의" />
      <AgreeWrap />
    </>
  );
}

export default CreateAccountAgree;
