import { useEffect } from 'react';
import { IUserInfo, WakeUp } from '../../../../common/interface/props';
import Swal from 'sweetalert2';
import { axiosCustom } from 'common/common.function';
import { useSelector } from 'react-redux';
import { RootState } from 'redux_modules';

interface IProps {
  solType: WakeUp;
  requiredLogin: boolean;
}

function LoginCheck({ solType, requiredLogin }: IProps) {
  // login 여부 값을 redux에서 가져옴
  // 2.0 솔루션에 귀속 되어있기 때문에 토큰으로 관리할 필요가 없음
  const { loginInfo } = useSelector((state: RootState) => ({
    loginInfo: state.login,
  }));
  const rawUserInfo = sessionStorage.getItem('userInfo');


  // 로그인 데이터 파싱
  const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);
  useEffect(() => {

    // 쿠팡 이외는 session storage 로 검사함
    if (requiredLogin && ['WEMAKEPRICE', 'ST11', 'ALWAYZ'].includes(solType)) {
      if (!userInfo) {
        moveLogin();
      }
    }
    // 쿠팡만 2.0 솔루션 연동 선 오픈
    // 쿠팡은 redux 로 검사
    else if (solType !== 'ADMIN') {
      // 로그인 필수 페이지
      if (requiredLogin && !loginInfo.data?.isLogined) {
        moveLogin();
      }
    }

    async function moveLogin() {
      await Swal.fire({
        title: '확인',
        text: '로그인이 필요한 서비스입니다.',
        showConfirmButton: true,
      });

      sessionStorage.removeItem('wakeupInfo');
      sessionStorage.removeItem('userInfo');

      const pathSolType = window.location.pathname.split('/')[1].toLowerCase();
      window.location.href = `/${pathSolType}/member/login?referUrl=${window.location.pathname}`;
    }


  }, [loginInfo, requiredLogin, solType]);

  return <></>;
}
export default LoginCheck;
