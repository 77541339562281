import customClass from 'common/swal.custom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Theme from 'styles/theme';
import Swal from 'sweetalert2';
import { checkIntergrationGMP, ScrapType, TargetSolTypeRole, WAKEUP_FORM_DATA } from '../../common/common.constants';

import { axiosCustom } from '../../common/common.function';
import { IScrapProdProps, IUserInfo, WakeUp } from '../../common/interface/props';
import { RootState } from '../../redux_modules';

export function ScrapProdForm({ solType, targetSolType }: IScrapProdProps) {
  const token = sessionStorage.getItem('wakeupInfo');
  const jsonToken = JSON.parse(token!);
  const navigate = useNavigate();
  const { shopInfo, wakeupInfo, loginInfo } = useSelector((state: RootState) => ({
    shopInfo: state.shopLogin,
    wakeupInfo: state.wakeupLogin,
    loginInfo: state.login,
  }));

  const shopNames1 = targetSolType.map((shop) => WAKEUP_FORM_DATA[shop].name.type1);
  const shopNames4 = targetSolType.map((shop) => WAKEUP_FORM_DATA[shop].name.type4);

  // 유저 정보
  const rawUserInfo = sessionStorage.getItem('userInfo');
  const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);
  const email = userInfo?.email;

  const html =
    solType === 'ALL' || solType === 'ESM'
      ? `<div class="textArea">
        <div class="primaryText">
        [${shopNames1.join(',&nbsp&nbsp')}]<br/> <p class="colorText">상품 등록 작업이 시작되었습니다.</p>
        </div>
        ※ 안내<br/>
        작업은 상품 등록 개수에 따라 최대 약 1일이 소요되며,<br/>
        <b>작업이 완료 되면 상품별 성공/실패 여부를 ${email} 이메일로 첨부드리니 엑셀 파일을 통해 확인 부탁드립니다.</b><br/>
        ${
          loginInfo.data?.isSolExpired
            ? `<div class="additionalArea">
            현재 회원님의 플토2.0 서비스 무료 체험 기간이 만료되었습니다.<br/>
            <b>서비스 무료 체험 기간이 만료되더라도 [상품 이관] 서비스는 계속 이용하실 수 있으며,</b><br/>
            더욱 다양한 쇼핑몰 통합 관리 기능은 서비스 결제 후 이용해주시길 바랍니다.
          </div>`
            : ''
        }
        </div>
        `
      : `<div class="textArea">
          <div class="primaryText">
            ${shopNames4[0]}<br/>
            <p class="colorText">상품 등록 작업이 시작되었습니다.</p>
          </div>
          <br/>
          ※ 안내<br/>
          작업은 상품 등록 개수에 따라 최대 약 1일이 소요되며,<br/>
          <b>작업이 완료 되면 상품별 성공/실패 여부를 ${email} 이메일로 첨부드리니 엑셀 파일을 통해 확인 부탁드립니다.</b><br/>
          ${
            loginInfo.data?.isSolExpired
              ? `<div class="additionalArea">
              현재 회원님의 플토2.0 서비스 무료 체험 기간이 만료되었습니다.<br/>
              <b>서비스 무료 체험 기간이 만료되더라도 [상품 이관] 서비스는 계속 이용하실 수 있으며,</b><br/>
              더욱 다양한 쇼핑몰 통합 관리 기능은 서비스 결제 후 이용해주시길 바랍니다.
            </div>`
              : ''
          }
        </div>
        `;

  /**
   * 상품 이전 작업 시작 안내 문구 노출
   */
  const showGuide = async () => {
    const { value } = await Swal.fire(
      loginInfo.data?.isSolExpired
        ? {
            customClass: customClass({ specificProduct: false }),
            title: `${shopNames1.join(',')} 상품등록`,
            html,
            confirmButtonText: '솔루션 문의하기',
            confirmButtonColor: Theme.pltoColor,
            width: 800,
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: true,
            showDenyButton: true,
            denyButtonColor: Theme.pltoColor,
            cancelButtonText: '닫기',
            denyButtonText: '솔루션 이용 연장',
          }
        : {
            customClass: customClass({ specificProduct: false }),
            title: `${shopNames1.join(',')} 상품등록`,
            html,
            confirmButtonText: '확인',
            confirmButtonColor: Theme.pltoColor,
            width: 800,
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
          },
    );

    // 유효기간 만료 되었을 경우만
    if (loginInfo.data?.isSolExpired) {
      // 닫기 undefined
      if (value === undefined) {
        window.parent.postMessage({ action: 'close' }, process.env.REACT_APP_GMP_API_ENDPOINT!.toString());
        // 솔루션 문의 하기 true
      } else if (value) {
        window.parent.postMessage({ action: 'question' }, process.env.REACT_APP_GMP_API_ENDPOINT!.toString());
        // 솔루션 이용 연장 false
      } else if (!value) {
        window.parent.postMessage({ action: 'pay' }, process.env.REACT_APP_GMP_API_ENDPOINT!.toString());
      }
    }
  };

  /**
   * 2.0 솔루션 -> 모달에 후킹 페이지 띄우기
   */
  const callHookingPage = async () => {
    // ALL 타입에서 작업 완료 시 2.0 후킹 페이지 이동
    window.parent.postMessage(
      {
        shops: [
          { shop_cd: shopInfo.data.shopInfo.scrapShopCode, shop_id: shopInfo.data.shopInfo.scrapShopId },
          ...targetSolType.map((one) => {
            return { shop_cd: WAKEUP_FORM_DATA[one].code, shop_id: wakeupInfo.data[one].wakeupInfo.scrapShopId };
          }),
        ],
      },
      process.env.REACT_APP_GMP_API_ENDPOINT!.toString(),
    );

    return true;
  };

  useEffect(() => {
    async function fetchData() {
      // 쇼핑몰, WakeUp 데이터 유효성 검사
      if (shopInfo.status === 'SUCCESS' && wakeupInfo.status === 'SUCCESS') {
        Swal.fire({
          title: `잠시만 기다려 주세요...`,
          html: '작업 생성 중...',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        // 2.0연동 여부
        const isIntergrationGMP = checkIntergrationGMP(solType);

        // 작업번호
        let jobId;

        // 동기처리 위해서 for 씀
        for (const shop of targetSolType) {
          // 수집 할 쇼핑몰 / WakeUp 모델 데이터 합치기
          let jobInputData = {
            ...shopInfo.data.shopInfo,
            prodCodes:
              wakeupInfo.data[[TargetSolTypeRole.GMARKET, TargetSolTypeRole.AUCTION].includes(shop) ? TargetSolTypeRole.ESM : shop].wakeupInfo.prodCodes,
            scrapType:
              wakeupInfo.data[[TargetSolTypeRole.GMARKET, TargetSolTypeRole.AUCTION].includes(shop) ? TargetSolTypeRole.ESM : shop].wakeupInfo.scrapType,
            wakeupId: wakeupInfo.data[shop].wakeupInfo.scrapShopId,
            wakeupPw: wakeupInfo.data[shop].wakeupInfo.scrapShopPw,
            wakeupEtc1:
              // esm 이면서 옥션 단독 사용일 시 wakeupEtc1 에 id 기록
              !wakeupInfo.data.ESM?.wakeupInfo?.useESM && shop === 'AUCTION'
                ? wakeupInfo.data.AUCTION.wakeupInfo.scrapShopId
                : wakeupInfo.data[shop].wakeupInfo.scrapShopEtc1,
            wakeupEtc2:
              // esm 이면서 G마켓 단독 사용일 시 wakeupEtc2 에 id 기록
              !wakeupInfo.data.ESM?.wakeupInfo?.useESM && shop === 'GMARKET'
                ? wakeupInfo.data.GMARKET.wakeupInfo.scrapShopId
                : wakeupInfo.data[shop].wakeupInfo.scrapShopEtc2,
            wakeupEtc3:
              // esm 이면서 옥션 단독 사용일 시 wakeupEtc3 에 pw 기록
              !wakeupInfo.data.ESM?.wakeupInfo?.useESM && shop === 'AUCTION'
                ? wakeupInfo.data.AUCTION.wakeupInfo.scrapShopPw
                : wakeupInfo.data[shop].wakeupInfo.scrapShopEtc3,
            wakeupEtc4:
              // esm 이면서 G마켓 단독 사용일 시 wakeupEtc2 에 id 기록
              !wakeupInfo.data.ESM?.wakeupInfo?.useESM && shop === 'GMARKET'
                ? wakeupInfo.data.GMARKET.wakeupInfo.scrapShopPw
                : wakeupInfo.data[shop].wakeupInfo.scrapShopEtc4,
            wakeupEtc5: wakeupInfo.data[shop].wakeupInfo.scrapShopEtc5,
            wakeupEtc6: wakeupInfo.data[shop].wakeupInfo.scrapShopEtc6,
            wakeupDummy3: wakeupInfo.data[shop].wakeupInfo.shopDummy3,
            coupangSellerType: wakeupInfo.data[shop].wakeupInfo.coupangSellerType,
            targetSolType: wakeupInfo.data[shop].wakeupInfo.targetSolType,
            token: jsonToken.gmpToken,
            subscriptionType: wakeupInfo.data[shop].wakeupInfo.subscriptionType,
            prodCompareType: wakeupInfo.data[shop].wakeupInfo.prodCompareType,
          };

          // ESM 계정이면,
          if (wakeupInfo.data.ESM?.wakeupInfo?.useESM && (shop === 'GMARKET' || shop === 'AUCTION')) {
            if (shop === 'AUCTION') {
              // ESM 마스터 상품 하나에 옥션, 지마켓 존재함. ESM 으로 작업(jobId) 만들어서 백앤드 전송
              continue;
            }

            jobInputData = {
              ...shopInfo.data.shopInfo,
              prodCodes: wakeupInfo.data[TargetSolTypeRole.ESM].wakeupInfo.prodCodes,
              scrapType: wakeupInfo.data[TargetSolTypeRole.ESM].wakeupInfo.scrapType,
              wakeupId: wakeupInfo.data.ESM.wakeupInfo.wakeupId,
              wakeupPw: null,
              wakeupEtc1: wakeupInfo.data.AUCTION.wakeupInfo.scrapShopId,
              wakeupEtc2: wakeupInfo.data.GMARKET.wakeupInfo.scrapShopId,
              wakeupEtc3: wakeupInfo.data.AUCTION.wakeupInfo.scrapShopPw,
              wakeupEtc4: wakeupInfo.data.GMARKET.wakeupInfo.scrapShopPw,
              wakeupEtc5: wakeupInfo.data.GMARKET.wakeupInfo.scrapShopEtc5,
              wakeupEtc6: wakeupInfo.data.GMARKET.wakeupInfo.scrapShopEtc6,
              wakeupDummy3: {
                shopAuctionId: wakeupInfo.data.AUCTION.wakeupInfo.scrapShopId,
                shopAuctionPw: wakeupInfo.data.AUCTION.wakeupInfo.scrapShopPw,
                shopGmarketId: wakeupInfo.data.GMARKET.wakeupInfo.scrapShopId,
                shopGmarketPw: wakeupInfo.data.GMARKET.wakeupInfo.scrapShopPw,
              },
              coupangSellerType: wakeupInfo.data.AUCTION.wakeupInfo.coupangSellerType,
              targetSolType: TargetSolTypeRole.ESM,
              token: jsonToken.gmpToken,
              subscriptionType: wakeupInfo.data.AUCTION.wakeupInfo.subscriptionType,
              prodCompareType: wakeupInfo.data.AUCTION.wakeupInfo.prodCompareType,
            };
          }

          // 상품 유사도 비교 작업인 경우
          if (wakeupInfo.data[shop].wakeupInfo.isProdCompare) {
            await prodCompareSend(shop?.toLowerCase(), jobInputData);

            await Swal.fire({
              title: '작업 등록 성공',
              text: '상품 유사도 비교 작업 완료 후 결과는 메일로 발송됩니다.',
              showConfirmButton: true,
            });

            navigate(`/${shop.toLowerCase()}-prod-compare/job/shop-login`);
            return true;
          }

          // 상품 수집 실행
          const jobId = await scrapProdSend(jobInputData);

          // jobid가 없으면서 all 이아니면 보내버림
          if (!jobId && solType !== 'ALL') {
            navigate(`/${shop.toLowerCase()}/job/shop-login`);
            return true;
          }

          // 2.0연동 여부
          const isIntergrationGMP = checkIntergrationGMP(shop as WakeUp);

          // 2.0 사용안하거나(REACT_APP_GMP_NOT_USED === 'true'), 어드민 계정에서는 작업조회 프로그래스바 페이지로 이동
          if (jobId && solType !== 'ALL' && !isIntergrationGMP && (process.env.REACT_APP_GMP_NOT_USED === 'true' || loginInfo.data?.isAdmin)) {
            Swal.close();
            navigate(`/${solType.toLowerCase()}/job/${jobId}`);
            return true;
          }

          // all 이 아니면서 여기까지 왔으면 성공한거라 프로그레스바 페이지로 이동 하고 끝냄
          // 루프안에 있는 이유는 jobId가 필요하기 때문
          if (solType !== 'ALL') {
            // 위에서 jobId 가 생성 된 후 상품 이전 작업 시작 swal 노출
            await showGuide();

            // 작업 완료 시 2.0 후킹 페이지 이동
            // 다만 2.0 솔루션 만료일이 지난 업체는 보내지 않음.
            if (!loginInfo.data?.isSolExpired) {
              await callHookingPage();
            }
            return true;
          }
        }

        if (solType === 'ALL') {
          // 위에서 jobId 가 생성 된 후 상품 이전 작업 시작 swal 노출
          await showGuide();

          // 작업 완료 시 2.0 후킹 페이지 이동
          // 다만 2.0 솔루션 만료일이 지난 업체는 보내지 않음.
          if (!loginInfo.data?.isSolExpired) {
            await callHookingPage();
          }
          return true;
        }

        // jobid가 없으면서 all 이아니면 보내버림
        if (!jobId) {
          navigate(`/${solType.toLowerCase()}/job/shop-login`);
          return true;
        }

        // 2.0 사용안하거나(REACT_APP_GMP_NOT_USED === 'true'), 어드민 계정에서는 작업조회 프로그래스바 페이지로 이동
        if (jobId && !isIntergrationGMP && (process.env.REACT_APP_GMP_NOT_USED === 'true' || loginInfo.data?.isAdmin)) {
          Swal.close();
          navigate(`/${solType.toLowerCase()}/job/${jobId}`);
          return true;
        }

        // 위에서 jobId 가 생성 된 후 상품 이전 작업 시작 swal 노출
        await showGuide();

        // 작업 완료 시 2.0 후킹 페이지 이동
        // 다만 2.0 솔루션 만료일이 지난 업체는 보내지 않음.
        if (!loginInfo.data?.isSolExpired) {
          await callHookingPage();
        }

        return true;
      } else {
        // 유효성 검사 실패 또는 작업 모두 걸고 나서 shop-login으로 이동
        navigate(`/${solType.toLowerCase()}/job/shop-login`);
        return true;
      }

      // 상품수집 작업 시작
      async function scrapProdSend(jobInputData: any) {
        try {
          //상품수집 작업 시작
          const temp = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/scrap-prod`, jobInputData);

          return temp.jobId;
        } catch (error: any) {
          await Swal.fire({
            title: '실패',
            html: error.message,
            icon: 'error',
            confirmButtonText: '확인',
          });

          return false;
        }
      }

      // 상품유사도비교 작업 시작
      function prodCompareSend(shopName: any, jobInputData: any) {
        axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/prod-compare-job`, jobInputData);
      }
    }

    fetchData();
  }, [navigate, solType, shopInfo, wakeupInfo, targetSolType]);

  return <></>;
}
