import Grid from 'components/UI/atoms/Grid';
import { axiosCustom } from 'common/common.function';
import { IMemberProps } from 'common/interface/props';
import Button from 'components/UI/atoms/Button';
import Text from 'components/UI/atoms/Text';
import TextField from 'components/UI/atoms/TextField';
import { FormError } from 'components/form/form-error';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ResetPassword({ solType }: IMemberProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate(); // 히스토리 구하기
  const [searchParams] = useSearchParams(); // url param 가져오기

  const password: any = useRef(); //회원가입 전용, 비밀번호와 비밀번호 체크 일치여부 확인시 필요
  password.current = watch('password'); //비밀번호에 입력한 값

  const regexPassword = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=\-,\\.<>/\\?~\\(\\)\\_\\[\]\\{\\}\\|;:])(?=.*[0-9]).{8,}$/; // 비밀번호 체크 정규식 (영문+숫자+특문조합 8자 이상)

  // 쇼핑몰 로그인 정보 전송 함수
  async function onSubmit(data: any) {
    const { confirmPassword } = data;
    const userToken = searchParams.get('userToken');

    try {
      // 무한 로딩 화면 생성
      Swal.fire({
        title: `비밀번호 변경중...`,
        html: '플레이오토 비밀번호를 변경하는 중입니다.', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const resetPasswordResult = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/reset-password`, {
        password: confirmPassword,
        userToken: userToken,
      });

      await Swal.fire({
        title: '성공',
        html: `${resetPasswordResult.message}`,
        icon: 'success',
        confirmButtonText: '확인',
      });

      navigate(`/${solType.toLowerCase()}/member/login`); //해당 주소로 이동
    } catch (e: any) {
      Swal.fire({
        title: '실패',
        html: `${e.message}`,
        icon: 'error',
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid display="flex" direction="column" alignItems="center" justifyContent="center" height={400} left={30} right={30}>
        <Text fontSize="1.3rem" fontWeight={500}>
          새로운 비밀번호 입력
        </Text>
        <Grid top={60} bottom={30}>
          <TextField
            type="password"
            placeholder="영문 + 숫자 + 특문조합 8자 이상"
            {...register(`password`, { required: true, minLength: 8, pattern: regexPassword })}
          />
          {errors?.password && errors.password.type === 'required' && <FormError errorMessage={'비밀번호는 필수 입력 항목입니다.'} />}
          {errors?.password && errors.password.type === 'minLength' && <FormError errorMessage={'비밀번호는 8자 이상 입력해 주세요.'} />}
          {errors?.password && errors.password.type === 'pattern' && <FormError errorMessage={'비밀번호는 영문+숫자+특문 8자 이상 입력해 주세요.'} />}
        </Grid>
        <Grid bottom={60}>
          <TextField
            type="password"
            placeholder="비밀번호를 한번 더 입력해 주세요"
            {...register(`confirmPassword`, { required: true, minLength: 8, pattern: regexPassword, validate: (value) => value === password.current })}
          />
          {errors?.confirmPassword && errors.confirmPassword.type === 'required' && <FormError errorMessage={'비밀번호체크는 필수 입력 항목입니다.'} />}
          {errors?.confirmPassword && errors.confirmPassword.type === 'minLength' && <FormError errorMessage={'비밀번호는 8자 이상 입력해 주세요.'} />}
          {errors?.confirmPassword && errors.confirmPassword.type === 'pattern' && (
            <FormError errorMessage={'비밀번호는 영문+숫자+특문 8자 이상 입력해 주세요.'} />
          )}
          {errors?.confirmPassword && errors.confirmPassword.type === 'validate' && <FormError errorMessage={'비밀번호와 일치하지 않습니다.'} />}
        </Grid>
        <Button type="submit" color="main">
          비밀번호 변경
        </Button>
      </Grid>
    </form>
  );
}
