import { IUserInfo, IWakeupLoginForm, ShopCode } from 'common/interface/props';
import React from 'react';
import { useForm } from 'react-hook-form';
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  WAKEUP_FORM_DATA as WAKEUP_FORM_DATA_ORIGIN,
  ScrapType,
  SubscriptionType,
  TargetSolTypeRole,
  convertShopName,
  PROD_SIMILARITY_USER,
  ACCOUNT_AUTO_SETTING_ADMIN_USER,
} from '../../common/common.constants';
import { arrayChunk, axiosCustom } from '../../common/common.function';
import { wakeupLoginRequest } from '../../redux_modules/wakeup-login';
import { RootState } from '../../redux_modules';
import withReactContent from 'sweetalert2-react-content';

// atomic components
import Grid from 'components/UI/atoms/Grid';
import InputWrap from 'components/UI/molecules/InputWrap';
import TextField from 'components/UI/atoms/TextField';
import FormWrap from 'components/UI/molecules/FormWrap';
import Button from 'components/UI/atoms/Button';
import ChannelIOButton from 'components/UI/organisms/ChannelIOButton';

// etc
import { FormError } from 'components/form/form-error';
import { SHOP_FORM_DATA } from 'common/common.constants';
import Text from 'components/UI/atoms/Text';
import CheckBox from 'components/UI/atoms/CheckBox';
import Select from 'components/UI/atoms/Select';
import FixedGrid from 'components/UI/atoms/FixedGrid';
import { useNavigate } from 'react-router-dom';
import customClass from 'common/swal.custom';
import Theme from 'styles/theme';
import SpecificWrap from 'components/UI/molecules/SpecificWrap';

/**
 * 등록하려는 쇼핑몰 유효성 체크
 */
export interface ISelectShops {
  COUPANG?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  WEMAKEPRICE?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  ST11?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  ALWAYZ?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  KAKAO?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  GMARKET?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  AUCTION?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  ESM?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
  TOSS?: {
    selected: boolean;
    isLogined: boolean;
    scrapType: ScrapType;
    prodCodes: null;
  };
}

interface setSpecificOutput {
  success: boolean;
  scrapType?: ScrapType;
  prodCodes?: any;
}

export default function WakeupLoginForm({ solType, coupangSellerType, isProdCompare = false }: IWakeupLoginForm) {
  const WAKEUP_FORM_DATA = isProdCompare
    ? Object.fromEntries(Object.entries(WAKEUP_FORM_DATA_ORIGIN).filter(([shop, key]) => key.prodCompareUse === true))
    : WAKEUP_FORM_DATA_ORIGIN;

  // 유저 정보
  const rawUserInfo = sessionStorage.getItem('userInfo');
  const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);
  const email = userInfo.email;
  const isProdSimilarityUser = PROD_SIMILARITY_USER.find((v) => v === email);
  const isAccountAutoSettingAdmin = ACCOUNT_AUTO_SETTING_ADMIN_USER.find((v) => v === email);

  //#region useForm
  // loop를 통해 같은 키값으로 컴포넌트를 생성하기 때문에 쇼핑몰 별로 useForm을 다르게 줘야함
  const {
    register: coupangRegister,
    handleSubmit: coupangHandleSubmit,
    getValues: coupangGetValues,
    setValue: coupangSetValue,
    watch: coupangWatch,
    formState: { errors: coupangErrors },
  } = useForm();

  const {
    register: st11Register,
    handleSubmit: st11HandleSubmit,
    getValues: st11GetValues,
    setValue: st11SetValue,
    watch: st11Watch,
    formState: { errors: st11Errors },
  } = useForm();

  const {
    register: wemakepriceRegister,
    handleSubmit: wemakepriceHandleSubmit,
    getValues: wemakepriceGetValues,
    setValue: wemakepriceSetValue,
    watch: wemakepriceWatch,
    formState: { errors: wemakepriceErrors },
  } = useForm();

  const {
    register: alwayzRegister,
    handleSubmit: alwayzHandleSubmit,
    getValues: alwayzGetValues,
    setValue: alwayzSetValue,
    watch: alwayzWatch,
    formState: { errors: alwayzErrors },
  } = useForm();

  const {
    register: kakaoRegister,
    handleSubmit: kakaoHandleSubmit,
    getValues: kakaoGetValues,
    setValue: kakaoSetValue,
    watch: kakaoWatch,
    formState: { errors: kakaoErrors },
  } = useForm();

  const {
    register: gmarketRegister,
    handleSubmit: gmarketHandleSubmit,
    getValues: gmarketGetValues,
    setValue: gmarketSetValue,
    watch: gmarketWatch,
    formState: { errors: gmarketErrors },
  } = useForm();

  const {
    register: acutionRegister,
    handleSubmit: acutionHandleSubmit,
    getValues: acutionGetValues,
    setValue: acutionSetValue,
    watch: acutionWatch,
    formState: { errors: acutionErrors },
  } = useForm();

  const {
    register: tossRegister,
    handleSubmit: tossHandleSubmit,
    getValues: tossGetValues,
    setValue: tossSetValue,
    watch: tossWatch,
    formState: { errors: tossErrors },
  } = useForm();
  //#endregion

  const [subscriptionType, setSubscriptionType] = React.useState<SubscriptionType>(SubscriptionType.NONE);
  const [prodCompareType, setProdCompareType] = React.useState<boolean>(false);
  const [wakeupFormData, setWakeupFormData] = React.useState<any>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const token = sessionStorage.getItem('wakeupInfo');
  const jsonToken = JSON.parse(token!);

  const { shopInfo, wakeupInfo } = useSelector((state: RootState) => ({
    shopInfo: state.shopLogin,
    wakeupInfo: state.wakeupLogin,
  }));

  const MySwal = withReactContent(Swal);

  const coupangRef = React.useRef<HTMLDivElement>(null);
  const wemakepriceRef = React.useRef<HTMLDivElement>(null);
  const st11Ref = React.useRef<HTMLDivElement>(null);
  const alwayzRef = React.useRef<HTMLDivElement>(null);
  const gmarketRef = React.useRef<HTMLDivElement>(null);
  const auctionRef = React.useRef<HTMLDivElement>(null);
  const kakaoRef = React.useRef<HTMLDivElement>(null);
  const tossRef = React.useRef<HTMLDivElement>(null);
  const submitDivPosition = React.useRef<HTMLDivElement>(null);

  //#region useState
  const [coupangVendorIdLimit, setCoupangVendorIdLimit] = React.useState(false);

  const [coupangApiKey, setCoupangApiKey] = React.useState<{
    openApiAccessKey: string;
    openApiSecretKey: string;
  }>({
    openApiAccessKey: '',
    openApiSecretKey: '',
  });

  /**
   * 선택한 쇼핑몰 리스트
   */
  const [selectShops, setSelectShops] = React.useState<ISelectShops>(
    solType === 'ESM'
      ? {
          GMARKET: {
            selected: true,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          AUCTION: {
            selected: true,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          ESM: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
        }
      : solType === 'ALL'
      ? {
          COUPANG: {
            selected: true,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          WEMAKEPRICE: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          ST11: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          ALWAYZ: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          KAKAO: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          GMARKET: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
          AUCTION: {
            selected: false,
            isLogined: false,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
        }
      : {
          [solType]: {
            selected: true,
            isLogined: true,
            scrapType: ScrapType.NORMAL,
            prodCodes: null,
          },
        },
  );

  //#endregion
  React.useEffect(() => {
    async function fetchData() {
      // 쇼핑몰 데이터 유효성 검사
      shopInfo.status !== 'SUCCESS' && navigate(`/${solType.toLowerCase()}/job/shop-login`);

      // 쿠팡일 경우 저장된 데이터 노출
      if (selectShops.COUPANG?.selected === true && !isProdCompare) {
        let result;
        // if (checkUseSpringSolType(solType)) {
        //   result = await axiosCustom.post(
        //     `${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/coupang/user-info`,
        //     {},
        //     {
        //       headers: {
        //         Authentication: `Bearer ${jsonToken.gmpToken}`,
        //       },
        //     },
        //   );
        // } else {
        result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/coupang/user-info`, {
          token: jsonToken.gmpToken,
        });
        // }

        coupangSetValue('shopId', result?.coupangInfo?.id);
        coupangSetValue('shopEtc5', result?.coupangInfo?.vendorId);
        coupangSetValue('shopEtc3', result?.coupangInfo?.accessKey);
        coupangSetValue('shopEtc4', result?.coupangInfo?.secretKey);

        setCoupangVendorIdLimit(result.coupangInfo.vendorIdLimit);
      }
    }

    fetchData();
  }, [solType, shopInfo, navigate]);

  //#region 스크롤 이벤트 ALL 타입에서만 동작할 거임
  const [submitPosition, setSubmitPosition] = React.useState<boolean>(solType === 'ALL' || solType === 'ESM'); // window 의 pageYOffset값을 저장

  function onScroll() {
    // 상품 등록 버튼 고정 위치
    const position1 = submitDivPosition.current?.getBoundingClientRect().bottom!;

    // html 최상단 위치 부터 상품 등록 버튼 고정 위치 까지의 거리 + 고정div 세로크기 (40px고정) 값이
    // 현재 화면의 세로 크기를 넘었다면 상품등록 하단 고정 풀음
    setSubmitPosition(position1 + 40 > window.innerHeight);
  }
  // 스크롤 이벤트 리스너 추가
  React.useEffect(() => {
    (solType === 'ALL' || solType === 'ESM') && window.addEventListener('scroll', onScroll);
    return () => {
      (solType === 'ALL' || solType === 'ESM') && window.removeEventListener('scroll', onScroll);
    };
  }, []);
  ////#endregion

  /**
   * @alais 사이트 리스트 배열 정리
   * @description ALL타입일 경우 사이트 선택영역의 UI를 정리하기위해 3개씩 나눔
   */
  React.useEffect(() => {
    if (solType === 'ALL' || (solType === 'ESM' && WAKEUP_FORM_DATA)) {
      setWakeupFormData(
        arrayChunk(
          Object.entries(WAKEUP_FORM_DATA).filter((one) => (solType === 'ESM' ? one[1].isESM : one[1].isShop)),
          3,
        ),
      );
    }
  }, [WAKEUP_FORM_DATA]);

  /**
   * 쇼핑몰에 맞는 useForm을 가져와보자..
   */
  function getUseForm(targetSolType: TargetSolTypeRole, name: string, value: any, key: string = '') {
    switch (targetSolType) {
      case TargetSolTypeRole.COUPANG:
        switch (name) {
          case 'register':
            return coupangRegister(key, value);
          case 'handleSubmit':
            return coupangHandleSubmit(value);
          case 'getValues':
            return coupangGetValues();
          case 'setValue':
            return coupangSetValue(key, value);
          case 'watch':
            return coupangWatch(key);

          case 'errors':
            return coupangErrors;
          default:
            break;
        }
        break;

      case TargetSolTypeRole.ST11:
        switch (name) {
          case 'register':
            return st11Register(key, value);
          case 'handleSubmit':
            return st11HandleSubmit(value);
          case 'getValues':
            return st11GetValues();
          case 'setValue':
            return st11SetValue(key, value);
          case 'watch':
            return st11Watch(key);

          case 'errors':
            return st11Errors;
          default:
            break;
        }
        break;
      case TargetSolTypeRole.WEMAKEPRICE:
        switch (name) {
          case 'register':
            return wemakepriceRegister(key, value);
          case 'handleSubmit':
            return wemakepriceHandleSubmit(value);
          case 'getValues':
            return wemakepriceGetValues();
          case 'setValue':
            return wemakepriceSetValue(key, value);
          case 'watch':
            return wemakepriceWatch(key);

          case 'errors':
            return wemakepriceErrors;
          default:
            break;
        }
        break;
      case TargetSolTypeRole.ALWAYZ:
        switch (name) {
          case 'register':
            return alwayzRegister(key, value);
          case 'handleSubmit':
            return alwayzHandleSubmit(value);
          case 'getValues':
            return alwayzGetValues();
          case 'setValue':
            return alwayzSetValue(key, value);
          case 'watch':
            return alwayzWatch(key);

          case 'errors':
            return alwayzErrors;
          default:
            break;
        }
        break;
      case TargetSolTypeRole.KAKAO:
        switch (name) {
          case 'register':
            return kakaoRegister(key, value);
          case 'handleSubmit':
            return kakaoHandleSubmit(value);
          case 'getValues':
            return kakaoGetValues();
          case 'setValue':
            return kakaoSetValue(key, value);
          case 'watch':
            return kakaoWatch(key);

          case 'errors':
            return kakaoErrors;
          default:
            break;
        }
        break;

      case TargetSolTypeRole.GMARKET:
        switch (name) {
          case 'register':
            return gmarketRegister(key, value);
          case 'handleSubmit':
            return gmarketHandleSubmit(value);
          case 'getValues':
            return gmarketGetValues();
          case 'setValue':
            return gmarketSetValue(key, value);
          case 'watch':
            return gmarketWatch(key);
          case 'errors':
            return gmarketErrors;
          default:
            break;
        }
        break;

      case TargetSolTypeRole.AUCTION:
        switch (name) {
          case 'register':
            return acutionRegister(key, value);
          case 'handleSubmit':
            return acutionHandleSubmit(value);
          case 'getValues':
            return acutionGetValues();
          case 'setValue':
            return acutionSetValue(key, value);
          case 'watch':
            return acutionWatch(key);
          case 'errors':
            return acutionErrors;
          default:
            break;
        }
        break;

      case TargetSolTypeRole.TOSS:
        switch (name) {
          case 'register':
            return tossRegister(key, value);
          case 'handleSubmit':
            return tossHandleSubmit(value);
          case 'getValues':
            return tossGetValues();
          case 'setValue':
            return tossSetValue(key, value);
          case 'watch':
            return tossWatch(key);
          case 'errors':
            return tossErrors;
          default:
            break;
        }
        break;

      default:
        switch (name) {
          case 'register':
            return alwayzRegister(key, value);
          case 'handleSubmit':
            return alwayzHandleSubmit(value);
          case 'getValues':
            return alwayzGetValues(value);
          case 'setValue':
            return alwayzSetValue(key, value);

          case 'errors':
            return alwayzErrors;
          default:
            break;
        }
    }
  }

  /**
   * 쇼핑몰에 맞는 useRef를 가져와보자..
   */
  function getUseRef(targetSolType: TargetSolTypeRole) {
    switch (targetSolType) {
      case TargetSolTypeRole.COUPANG:
        return coupangRef;
      case TargetSolTypeRole.WEMAKEPRICE:
        return wemakepriceRef;
      case TargetSolTypeRole.ST11:
        return st11Ref;
      case TargetSolTypeRole.ALWAYZ:
        return alwayzRef;
      case TargetSolTypeRole.GMARKET:
        return gmarketRef;
      case TargetSolTypeRole.AUCTION:
        return auctionRef;
      case TargetSolTypeRole.KAKAO:
        return kakaoRef;
      case TargetSolTypeRole.TOSS:
        return tossRef;

      default:
        break;
    }
  }

  /**
   * 지정 상품 가져오기 데이터 세팅
   */
  async function setSpecificData(specificProduct = false, targetSolType: TargetSolTypeRole): Promise<setSpecificOutput> {
    if (specificProduct === true) {
      // 특정 상품 등록하기
      let html;
      if (targetSolType === 'ALWAYZ') {
        html = `
        <div style="background: #f7f7f7; padding: 10px 20px; margin: 10px;">
            <p style="font-size: 1.11rem; font-weight:bold;padding-bottom:25px;">※ 주의 사항</p>
            <div style="font-size: .88rem; text-align:left">
                플레이오토 상품 이전 기능을 이용 시 '상품등록' 작업만 가능하며, '상품수정' 작업은 진행되지 않습니다. <br/>
                이 기능을 사용하여 상품등록시 상품이 중복 등록 될 수 있습니다.<br/>
                <br/>

                <div style="color: #FF3636; font-size: .88rem;">
                ※ 입력하신 쇼핑몰 상품 코드는 ${WAKEUP_FORM_DATA[targetSolType].name.type3}에 새로운 상품으로 등록됩니다. <br/>
                ※ 올웨이즈는 상품 삭제를 지원하지 않습니다. 기존에 존재하는 상품을 이전할 경우, 중복 등록이 발생 할 수 있으니, 각별히 주의해주시기 바랍니다.
                </div>
            </div>
        </div>
        `;
      } else {
        html = `
        <div style="background: #f7f7f7; padding: 10px 20px; margin: 10px;">
            <p style="font-size: 1.11rem; font-weight:bold;padding-bottom:25px;">※ 주의 사항</p>
            <div style="font-size: .88rem; text-align:left">
                플레이오토 상품 이전 기능을 이용 시 '상품등록' 작업만 가능하며, '상품수정' 작업은 진행되지 않습니다. <br/>
                한번 이전한 상품의 재등록 작업을 진행하기 위해서는 먼저 ‘${WAKEUP_FORM_DATA[targetSolType].name.type3}’에 접속하여 등록된 상품을 직접 삭제해주시기 바랍니다.<br/>
                <br/>

                <div style="color: #FF3636; font-size: .88rem;">
                ※ 입력하신 쇼핑몰 상품 코드는 ${WAKEUP_FORM_DATA[targetSolType].name.type3}에 새로운 상품으로 등록됩니다. <br/>
                ※ ${WAKEUP_FORM_DATA[targetSolType].name.type3}에서 기존 상품을 삭제하지 않고 상품 이전 시 중복 등록이 발생할 수 있으므로 각별히 주의 부탁 드립니다.
                </div>
            </div>
        </div>
        `;
      }

      const html2 = `
        <div style="font-size: .88rem; padding: 10px 25px; line-height: 1.7;font-weight: 500">
        - 등록할 상품의 쇼핑몰 상품 코드(상품번호)를 엔터(Enter) 기준으로 입력해주세요. <br/>
        - 상품 코드는 한 줄에 1개씩 입력해주세요.
        </div>
      `;

      const { value: policy } = await Swal.fire({
        customClass: customClass({ specificProduct: true }),
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
        confirmButtonColor: Theme.pltoColor,
        title: '지정 상품 등록',
        html: html,
      });

      // 취소
      if (!policy) {
        // 취소 + 수정모드 아니면 전체상품 모드로 돌아감
        if ((solType === 'ALL' || solType === 'ESM') && selectShops[targetSolType]?.prodCodes === null) {
          setSelectShops((one) => {
            return {
              ...one,
              [targetSolType]: {
                ...one[targetSolType],
                scrapType: ScrapType.NORMAL,
                prodCodes: null,
              },
            };
          });
        }
        return { success: false };
      }

      const inputValue: string[] = ((solType === 'ALL' || solType === 'ESM') && selectShops[targetSolType]?.prodCodes) || [];

      // 지정 상품 등록하기
      const { value: prodCodes } = await Swal.fire({
        customClass: customClass({ specificProduct: true }),
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonColor: Theme.pltoColor,
        confirmButtonText: solType === 'ALL' || solType === 'ESM' ? `저장` : `${WAKEUP_FORM_DATA[targetSolType].name.type2} 상품 등록`,
        input: 'textarea',
        inputValue: (solType === 'ALL' || solType === 'ESM') && inputValue.length > 0 ? inputValue.join('\n') : '',
        inputPlaceholder: 'B1234566790\nB1234567890',
        title: '지정 상품 등록',
        html: html + html2,
        preConfirm: (input) => {
          if (!input) {
            Swal.showValidationMessage('가져올 쇼핑몰 상품코드를 입력하세요.');
          }
          return input;
        },
      });

      // 지정상품 상품 코드 리스트
      if (!prodCodes) {
        // 상품 지정도 안했는데 수정 모드도 아니면 전체 상품으로 돌아감
        if ((solType === 'ALL' || solType === 'ESM') && selectShops[targetSolType]?.prodCodes === null) {
          setSelectShops((one) => {
            return {
              ...one,
              [targetSolType]: {
                ...one[targetSolType],
                scrapType: ScrapType.NORMAL,
                prodCodes: null,
              },
            };
          });
        }
        return { success: false };
      }

      // 지정 상품 설정 및 상품 코드 리스트 넣어줌
      setSelectShops((shops) => {
        return { ...shops, [targetSolType]: { ...shops[targetSolType], scrapType: ScrapType.SPECIFIC, prodCodes: prodCodes.split('\n').filter(Boolean) } };
      });
      return { success: true, scrapType: ScrapType.SPECIFIC, prodCodes: prodCodes.split('\n').filter(Boolean) };
    } else {
      // 전체 상품 설정 및 상품 코드 리스트 비워줌
      setSelectShops((shops) => {
        return { ...shops, [targetSolType]: { ...shops[targetSolType], scrapType: ScrapType.NORMAL, prodCodes: null } };
      });

      return { success: true, scrapType: ScrapType.NORMAL, prodCodes: null };
    }
  }

  /**
   * 쇼핑몰 로그인 함수
   * @param targetSolType 쇼핑몰 solType
   * @param data useForm 데이터
   * @param reqData wakeup 작업 데이터
   * @returns
   */
  async function doLogin(targetSolType: TargetSolTypeRole, data: any, reqData: any) {
    // 쇼핑몰 로그인 실행
    const jobId = await shopLoginSend();

    // 쇼핑몰 로그인 소켓
    jobId && shopLoginSocket();

    // 쇼핑몰 로그인 실행
    async function shopLoginSend() {
      try {
        //쇼핑몰 로그인 통신
        const { jobId } = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/shop-login`, reqData);

        return jobId;
      } catch (error: any) {
        Swal.fire('오류', error.message, 'error');
        return false;
      }
    }

    // 쇼핑몰 로그인 소켓
    function shopLoginSocket() {
      //  소켓 연결
      const sockets = io(`${process.env.REACT_APP_BACKEND_ENDPOINT}`, {
        query: { jobId: jobId },
        transports: ['websocket'],
      });
      //  완료
      sockets.on('shopLogin', async (payload: any) => {
        interface IShopDummy3 {
          /* 쿠팡 */
          openApiAccessKey?: string;
          openApiSecretKey?: string;

          /* 11번가 */
          returnAddressSeq?: string;
          sendAddressSeq?: string;

          /* ESM */
          shopGmarketId?: string;
          shopGmarketPw?: string;
          shopAuctionId?: string;
          shopAuctionPw?: string;
        }

        let shopDummy3: IShopDummy3 = {};

        // 결과 응답일 경우
        if (payload.result) {
          if (targetSolType === 'COUPANG') {
            if (prodCompareType) {
              shopDummy3.openApiAccessKey = coupangApiKey.openApiAccessKey;
              shopDummy3.openApiSecretKey = coupangApiKey.openApiSecretKey;
            }
          } else if (targetSolType === 'ST11') {
            if (solType === 'ALL') {
              //11번가 어드민을 통해서 seq(출고지,반품/교환지)를 정상적으로 등록했을때만 작업 시작
              const { success, message, returnAddressData, sendAddressData } = await axiosCustom.post(
                `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/${targetSolType.toLowerCase()}/scrap-default-address`,
                {
                  apiKey: data.shopEtc1,
                },
              );

              if (!success) {
                await Swal.fire({
                  title: '실패',
                  html: message,
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: '확인',
                });

                return false;
              }

              const swalResult = await MySwal.fire({
                customClass: customClass({ specificProduct: false }),
                title: '11번가 출고지, 반품/교환지 확인',

                html: (
                  <div className="shipping">
                    <Grid display="flex" direction="column" justifyContent="flex-end" bottom={200}>
                      <Text fontSize="18px" fontWeight={400}>
                        11번가 <b>출고지</b>, <b>반품/교환지</b>의 경우 11번가 어드민에 설정되어있는 기본주소로 자동 설정 됩니다.
                        <br />
                        <br />
                        아래 주소 정보로 11번가에 상품 이전을 원하지 않으신다면 취소 후 11번가 어드민에서 기본주소 재 설정 부탁드립니다.
                        <br />
                        <br />
                      </Text>
                    </Grid>

                    <div>
                      <p>출고지 주소</p>
                      <div>
                        <TextField variant="outlined" disabled style={{ width: '100%' }} value={sendAddressData.shippingPlaceAddress} />
                      </div>
                    </div>

                    <div>
                      <p>반품/교환지 주소</p>
                      <div>
                        {' '}
                        <TextField variant="outlined" disabled style={{ width: '100%' }} value={sendAddressData.shippingPlaceAddress} />
                      </div>
                    </div>
                  </div>
                ),

                cancelButtonText: '취소',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: '확인',
                width: 800,
                allowOutsideClick: false,
              });

              if (!swalResult.isConfirmed) {
                return false;
              }

              shopDummy3.returnAddressSeq = returnAddressData.returnAddressSeq;
              shopDummy3.sendAddressSeq = sendAddressData.sendAddressSeq;
            }
            if (solType === 'ST11') {
              const sessionAddressSeq = sessionStorage.getItem('addressSeq');
              let AddressSeq;

              if (sessionAddressSeq) {
                AddressSeq = JSON.parse(sessionAddressSeq);
              }

              if (!AddressSeq || !AddressSeq?.sendAddressSeq || !AddressSeq?.returnAddressSeq) {
                await Swal.fire({
                  title: '실패',
                  html: '11번가 SellerOffice 통해 접속한 사용자만 사용이 가능합니다.SellerOffice 출고지, 반품/교환지 정보를 입력한 후, 접속해주시기 바랍니다.',
                  icon: 'error',
                  showCancelButton: true,
                  cancelButtonText: '취소',
                  confirmButtonText: '11번가 접속',

                  preConfirm: () => {
                    window.open('https://soffice.11st.co.kr/register/popupPlayauto.tmall?method=popupPlayauto');
                  },
                });

                Swal.close();
                return false;
              }

              shopDummy3.returnAddressSeq = '';
              shopDummy3.sendAddressSeq = '';

              const shippingInfoExist = false;

              const { sendAddressData, returnAddressData } = await axiosCustom.post(
                `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/${targetSolType.toLowerCase()}/scrap-address`,
                {
                  apiKey: data.shopEtc1,
                  sendAddressSeq: AddressSeq.sendAddressSeq,
                  returnAddressSeq: AddressSeq.returnAddressSeq,
                },
              );

              const swalResult = await MySwal.fire({
                customClass: customClass({ specificProduct: false }),
                title: shippingInfoExist ? '11번가 출고지, 반품/교환지 확인' : '11번가 출고지, 반품/교환지 선택',

                html: (
                  <div className="shipping">
                    <div>
                      <p>출고지 주소</p>
                      <div>
                        {/* <TextField variant="outlined" disabled style={{ width: '100%' }} /> */}
                        <Select variant="outlined" style={{ width: '100%' }} id="st11-send-address-seq">
                          {typeof sendAddressData.sendAddressAddr === 'string' ? (
                            <option value={sendAddressData.sendAddressAddrSeq}>{sendAddressData.sendAddressAddr}</option>
                          ) : (
                            sendAddressData.sendAddressAddr.map((one: any, index: any) => (
                              <option key={index} value={sendAddressData.sendAddressAddrSeq[index]}>
                                {one}
                              </option>
                            ))
                          )}
                        </Select>
                      </div>
                    </div>

                    <div>
                      <p>반품/교환지 주소</p>
                      <div>
                        {/* <TextField variant="outlined" disabled style={{ width: '100%' }} /> */}
                        <Select variant="outlined" style={{ width: '100%' }} id="st11-return-address-seq">
                          {typeof returnAddressData.returnAddressAddr === 'string' ? (
                            <option value={returnAddressData.returnAddressAddrSeq}>{returnAddressData.returnAddressAddr}</option>
                          ) : (
                            returnAddressData.returnAddressAddr.map((one: any, index: any) => (
                              <option key={index} value={returnAddressData.returnAddressAddrSeq[index]}>
                                {one}
                              </option>
                            ))
                          )}
                        </Select>
                      </div>
                    </div>
                  </div>
                ),

                preConfirm: () => {
                  return {
                    st11SendAddressSeq: (document.getElementById('st11-send-address-seq') as HTMLInputElement).value,
                    st11ReturnAddressSeq: (document.getElementById('st11-return-address-seq') as HTMLInputElement).value,
                  };
                },

                cancelButtonText: shippingInfoExist ? '배송정보 수정' : '배송정보 등록',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: '상품등록 시작',
                confirmButtonColor: Theme.pltoColor,
                width: 800,
                allowOutsideClick: false,
              });

              if (swalResult.dismiss === MySwal.DismissReason.cancel) {
                window.open('https://soffice.11st.co.kr/register/popupPlayauto.tmall?method=popupPlayauto');

                return false;
              } else if (swalResult.value?.st11ReturnAddressSeq === 'none' || swalResult.value?.st11SendAddressSeq === 'none') {
                await Swal.fire({
                  title: '실패',
                  html: '출고지, 반품/교환지는 필수입니다.',
                  icon: 'error',
                });

                return false;
              } else if (!swalResult.isConfirmed) {
                return false;
              } else {
                shopDummy3.returnAddressSeq = swalResult.value?.st11ReturnAddressSeq;
                shopDummy3.sendAddressSeq = swalResult.value?.st11SendAddressSeq;
              }
            }
          } else if (targetSolType === `ALWAYZ`) {
            //올웨이즈는 판매가 10% 세일가를 팀 구매가로 사용한다 동의 팝업, 취소시 로그인 실패
            const alwayzAgree = await MySwal.fire({
              title: '올웨이즈 약관 동의',
              html: (
                <div>
                  <span>1. 상품 등록이 시작되면 상품의 팀 구매가를 판매가의 10% 할인된 가격으로 판매됩니다.</span>
                  <br />
                  <br />
                  <span>예시: 판매가 10,000원이면 팀 구매가는 9,000원입니다.</span>
                  <br />
                  <br />
                  <span>2. 올웨이즈는 전 상품 무료배송입니다.(배송비 부과 불가)</span>
                  <br />
                  <br />
                  <span>동의하시겠습니까? </span>
                </div>
              ),
              showCancelButton: true,
              cancelButtonText: '취소',
              confirmButtonText: '확인',
            });
            // 동의하지 않았을 때
            if (!alwayzAgree.isConfirmed) {
              //총 3가지 조건을 통과했을때만 상품등록 시작
              //1.A125 DLL C# 로그인 아이디,비밀번호 체크 2.C# mailOrderBusinessNumber 체크 3. 판매가 10% 팀구매가 적용 동의
              Swal.close();
              return false;
            }
          } else if (targetSolType === `KAKAO`) {
            // 카카오측에서 정해준 정책사항이며 동의를 받을 수 있도록 요청해줌
            const kakaoAgree = await MySwal.fire({
              title: '카카오톡 스토어 상품 등록 약관 동의',
              html: (
                <div>
                  <span>1. 쇼핑하기 전시여부 항목이 전시함으로 등록됩니다.</span>
                  <br />
                  <br />
                  <span>2. 쇼핑하우 전시여부 항목이 전시함으로 등록됩니다.</span>
                  <br />
                  <br />
                  <span>3. 추천 리워드 설정 → '추천 구매시 리워드 지급' 항목이 자동으로 선택됩니다.</span>
                  <br />
                  <br />
                  <span>4. 제조사/브랜드 글자수 15자 초과시 제조사/브랜드가 공란으로 등록됩니다.</span>
                  <br />
                  <br />
                  <span>위 내용에 동의 후 상품 등록을 진행하시겠습니까? </span>
                </div>
              ),
              showCancelButton: true,
              cancelButtonText: '취소',
              confirmButtonText: '확인',
            });
            // 동의하지 않았을 때
            if (!kakaoAgree.isConfirmed) {
              //총 3가지 조건을 통과했을때만 상품등록 시작
              //1.A125 DLL C# 로그인 아이디,비밀번호 체크 2.C# mailOrderBusinessNumber 체크 3. 판매가 10% 팀구매가 적용 동의
              Swal.close();
              return false;
            }
          } else if (solType === 'ESM') {
            if (targetSolType === 'GMARKET') {
              shopDummy3.shopGmarketId = reqData.scrapShopId;
              shopDummy3.shopGmarketPw = reqData.scrapShopPw;
            } else if (targetSolType === 'AUCTION') {
              shopDummy3.shopAuctionId = reqData.scrapShopId;
              shopDummy3.shopAuctionPw = reqData.scrapShopPw;
            }
          } else if (solType === 'TOSS') {
            const re = await checkTossAccount();
            if (!re) {
              return false;
            }

            // 토스에서 정해준 정책사항이며 동의를 받을 수 있도록 요청해줌
            const tossAgree = await MySwal.fire({
              title: '토스 약관 동의',
              html: (
                <>
                  <Text fontSize="10px" fontWeight={400} textAlign="left">
                    <div>
                      <span>토스는 전 상품 무료배송입니다.</span>
                      <br />
                      <span>상품에 배송비가 있으면 상품 판매가에 배송비를 더한 값으로 상품 등록됩니다</span>
                      <br />
                      <br />
                      <span>위 내용에 동의 후 상품 등록을 진행하시겠습니까? </span>
                    </div>
                  </Text>
                </>
              ),
              width: 700,
              showCancelButton: true,
              cancelButtonText: '취소',
              confirmButtonText: '확인',
            });
            // 동의하지 않았을 때
            if (!tossAgree.isConfirmed) {
              Swal.close();
              return false;
            }
          }

          dispatch(
            wakeupLoginRequest({
              ...wakeupInfo.data,
              [targetSolType]: {
                wakeupInfo: {
                  ...reqData,
                  subscriptionType: subscriptionType,
                  prodCompareType: prodCompareType,
                  shopDummy3,
                },
              },
            }),
          );

          // 쇼핑몰 별 유효성 검사 업데이트 (선택여부, 로그인 여부)
          setSelectShops((shops) => {
            return { ...shops, [targetSolType]: { ...shops[targetSolType], selected: true, isLogined: true } };
          });

          // 로그인 error input 제거
          getUseForm(targetSolType, 'setValue', false, 'loginError');

          //Step3. 작업 시작
          //ALL 타입은 아래 함수를 직접 호출해서 따로 사용함
          if (solType !== 'ALL' && solType !== 'ESM') {
            // 아래 doWork에서도 dispatch를 사용하여 이전 내용이 불러와지므로 강제 렌더링

            await doWork();

            return true;
          }

          Swal.close();
        } else {
          await Swal.fire({
            title: '실패',
            html: payload.message,
            icon: 'error',
            confirmButtonText: '확인',
          });

          return false;
        }
      });
    }
    return false;
  }

  async function showSchedulerPopup(targetSolType: TargetSolTypeRole) {
    // 가져온 쇼핑몰 정보
    const scrapShopInfo = shopInfo.data.shopInfo;
    const scrapShopCode: ShopCode = scrapShopInfo.scrapShopCode;

    // 등록하는 쇼핑몰 정보
    const wakeupShopInfo = getUseForm(targetSolType, 'getValues', '');

    // TODO_SUE 임시처리
    // 현재 form validation 처리가 안되고 있어, 임의로 유효성 검사 해줌
    if (!wakeupShopInfo.shopId || !wakeupShopInfo.shopEtc1) {
      return MySwal.fire('실패', `계정 정보를 모두 입력해주세요`, 'error');
    }

    // 유저 정보
    const rawUserInfo = sessionStorage.getItem('userInfo');
    const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);

    // 스케줄러 설정시작 및 종료일
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment().add(30, 'days').format('YYYY-MM-DD');

    // 팝업 출력
    const schedulerNotice = await MySwal.fire({
      customClass: customClass({ specificProduct: false }),
      title: (
        <>
          <h2> 상품 자동 이전</h2>
        </>
      ),
      html: `
        <div>
          <span style="font-size: 1.11rem; font-weight:bold;">상품 자동 이전이란? </span>
          <br />
          <br />
          <span>매일 정해진 시간에 전체상품을 자동으로 이전해주는 서비스 입니다. </span>
          <br /> <br />      
          <span>
            ${SHOP_FORM_DATA[`${scrapShopCode}`].name}(${scrapShopInfo.scrapShopId}) 전체 상품을 ${convertShopName(targetSolType)}(${
        wakeupShopInfo.shopId
      }) 로 등록합니다.
          </span>
          <br />
          <br />
          <span>특이사항</span>
          <br />
          <span style="font-size: 16px;">- 최초 설정일(${startDate}) 부터 30일동안(${endDate}) 매일 오전 3:30에 상품등록을 시도합니다. </span>
          <br />
          <span style="font-size: 16px;">- 등록 성공 및 실패에 대한 내용은 메일(${userInfo.email})로 발송됩니다. </span>
          <br />
          <span style="font-size: 16px;">- 신규 상품코드를 기준으로 자동 이전됩니다. </span>
          <br />
          <span style="font-size: 16px;">- 기존에 이전한 상품코드는 다시 이전되지 않습니다. </span>
          <br />
          <br />
          <span>위 내용에 동의하시겠습니까?</span>
          <br />
          <br />
        </div>
      `,

      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: '동의하고 시작하기',
      confirmButtonColor: Theme.pltoColor,
      cancelButtonText: '취소',
      width: 800,
    });

    if (schedulerNotice.isConfirmed) {
      setSubscriptionType(SubscriptionType.SUBSCRIBED);
    }
  }

  /**
   * ESM 유효성 검사
   */
  async function checkESMAccount() {
    const useGmarket = wakeupInfo.data.GMARKET.wakeupInfo !== null;
    const useAuction = wakeupInfo.data.AUCTION.wakeupInfo !== null;

    try {
      const inputData = {
        gmarketId: useGmarket ? wakeupInfo.data.GMARKET.wakeupInfo.scrapShopId : '',
        useGmarket,
        auctionId: useAuction ? wakeupInfo.data.AUCTION.wakeupInfo.scrapShopId : '',
        useAuction,
      };

      // 아래에서 ESM 계정 유효성 검사를 진행함

      // Step1. G마켓 대행사 검사 && 반품지 교환지 기본 설정 검사
      // Step2. 옥션 대행사 검사 && 반품지 교환지 기본 설정 검사
      // Step3. G마켓 출하지 기본 설정 검사
      // Step4. 옥션 출하지 기본 설정 검사
      // Step5. G마켓 옥션 둘 다 사용할 시 마스터 계정이 같은지 검사
      const { success, message, sameEsmMasterAccount } = await axiosCustom.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/esm-validation-check`,
        inputData,
      );

      // ESM 대행사 (셀링툴 업체)에 플레이오토 추가 안되어 있을경우 안내 창
      if (!success) {
        if (message.includes('셀링툴 업체에 플레이오토가 선택되어있지 않습니다.')) {
          await Swal.fire({
            title: '실패',
            html: message,
            icon: 'error',
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: '셀링툴 추가 가이드 확인하기',

            preConfirm: () => {
              window.open(`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/esm_sellingtool_guide`);
            },
          });
        } else {
          await Swal.fire({
            title: '실패',
            html: message,
            icon: 'error',
            confirmButtonText: '확인',
          });
        }

        return false;
      }

      if (useGmarket && useAuction) {
        // 동일 마스터계정이면, targetSolType 은 ESM 이 되고, 작업은 ESM 1개로 관리됨
        wakeupInfo.data.ESM.wakeupInfo = {
          useESM: success && sameEsmMasterAccount,
        };
      }

      return true;
    } catch (error: any) {
      if (error.message.includes('셀링툴 업체에 플레이오토가 선택되어있지 않습니다.')) {
        await Swal.fire({
          title: '실패',
          html: error.message,
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: '취소',
          confirmButtonText: '셀링툴 추가 가이드 확인하기',

          preConfirm: () => {
            window.open(`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/esm_sellingtool_guide`);
          },
        });
      } else {
        await Swal.fire({
          title: '실패',
          html: error.message,
          icon: 'error',
          confirmButtonText: '확인',
        });
      }

      return false;
    }
  }

  /**
   * TOSS 계정 유효성 검사
   * 웨이크업 전용 템플릿의 값이 정상적으로 들어가있는지 확인
   */
  async function checkTossAccount() {
    try {
      // 쇼핑몰에 맞는 useForm 데이터 가져오기
      const data = getUseForm(TargetSolTypeRole.TOSS, 'getValues', '');

      const inputData = {
        wakeupId: data.shopId,
        accessKey: data.shopEtc1,
        secretKey: data.shopEtc2,
      };

      // 아래에서 토스 계정 유효성 검사를 진행함
      const { success, message } = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/toss-validation-check`, inputData);

      if (!success) {
        await Swal.fire({
          title: '실패',
          html: `토스 셀러어드민 > 통합솔루션 상품 등록 페이지에서<br /> 배송, 교환 및 반품 정보를 입력해주세요 ${message}`,
          icon: 'error',
          confirmButtonText: '확인',
        });

        return false;
      }
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `토스 셀러어드민 > 통합솔루션 상품 등록 페이지에서<br /> 배송, 교환 및 반품 정보를 입력해주세요 ${error.message}`,
        icon: 'error',
        confirmButtonText: '확인',
      });

      return false;
    }

    return true;
  }

  async function showProdComparePopup(targetSolType: TargetSolTypeRole) {
    // 가져온 쇼핑몰 정보
    const scrapShopInfo = shopInfo.data.shopInfo;
    const scrapShopCode: ShopCode = scrapShopInfo.scrapShopCode;

    // 등록하는 쇼핑몰 정보
    const wakeupShopInfo = getUseForm(targetSolType, 'getValues', '');

    // 쿠팡 API 를 사용하려면, OPEN API KEY 가 필요함
    if (selectShops.COUPANG?.selected) {
      if (!wakeupShopInfo.shopEtc3 || !wakeupShopInfo.shopEtc4) {
        return MySwal.fire('계정 정보를 모두 입력해주세요', 'error');
      }

      // 쿠팡 웨이크업 API KEY 를 통해 OPEN API KEY 를 가져옴
      const result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/coupang-open-api-key`, {
        accessKey: wakeupShopInfo.shopEtc3,
        secretKey: wakeupShopInfo.shopEtc4,
      });

      if (result) {
        setCoupangApiKey({
          openApiAccessKey: result.openApiAccessKey,
          openApiSecretKey: result.openApiSecretKey,
        });
      } else {
        return MySwal.fire('실패', `쿠팡 계정 정보가 유효하지 않습니다.`, 'error');
      }
    }

    // 팝업 출력
    const prodCompareNotice = await MySwal.fire({
      customClass: customClass({ specificProduct: false }),
      title: (
        <>
          <h2>중복상품 제외 등록</h2>
        </>
      ),
      html: `
        <div>
          <span style="font-size: 1.11rem; font-weight:bold;">중복상품 제외 등록이란? </span>
          <br />      
          <br />
          <span>
          ${SHOP_FORM_DATA[`${scrapShopCode}`].name}에 판매중인 상품 중 이미 ${convertShopName(
        targetSolType,
      )}에 등록된 유사한 상품이 있을 경우 자동으로 해당 상품 제외 후 ${convertShopName(targetSolType)}으로 이전해줍니다.

          </span>
          <br />
          <br />
          <span>- 중복상품 판단 기준 -</span>
          <br />
          <span>※ 각 상품의 데이터를 아래 단계로 확인해 한 단계라도 충족시 동일 상품이라고 판단해  ${convertShopName(
            targetSolType,
          )}에 상품을 등록하지 않습니다</span>
          <br />
          <span style="font-size: 16px;">1단계: 판매자상품코드 100% 일치 </span>
          <br />
          <span style="font-size: 16px;">2단계: 상품명 100% 일치 </span>
          <br />
          <span style="font-size: 16px;">3단계: 상품명 유사율 70% 이면서, 이미지 유사율 98% </span>
          <br />
          <span style="font-size: 16px;">4단계: 상품명 유사율 90% 이면서, 브랜드 100% 일치</span>
          <br />
          <br />
          <span>위 내용에 동의하시겠습니까?</span>
          <br />
          <br />
        </div>
      `,

      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: '동의',
      confirmButtonColor: Theme.pltoColor,
      cancelButtonText: '취소',
      width: 800,
    });

    if (prodCompareNotice.isConfirmed) {
      setProdCompareType(true);
    }
  }

  /**
   * 쇼핑몰 로그인 완료 후 상품 이전 실행 함수
   * 일반 타입은 doLogin 함수에서 실행되며
   * ALL 타입은 별도로 아래 함수를 호출 한다
   * @returns
   */
  async function doWork() {
    const targetSolType: TargetSolTypeRole[] = [];
    for (const one of Object.entries(selectShops)) {
      const key = one[0] as TargetSolTypeRole;
      const value = one[1];

      // 선택된 쇼핑몰
      if (value.selected) {
        // 로그인 안되었으면
        if (value.isLogined === false) {
          // await MySwal.fire('오류', `${WAKEUP_FORM_DATA[key].name.type1} 쇼핑몰 로그인 인증이 완료되지 않았습니다.`, 'error');
          // 해당 버튼으로 스크롤링
          getUseRef(key)?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

          // 로그인 에러 메세지 노출
          getUseForm(key, 'setValue', true, 'loginError');
          return false;
        }
        // 로그인 인증이 되었으면 추가
        // 작업 쇼핑몰 추가
        targetSolType.push(key);

        // 쇼핑몰의 지정 상품 등록하기 여부 적용
        // ALL 타입은 지정상품을 계속 수정할 수 있으므로 작업 직전 별도로 담아 준다.
        if (solType === 'ALL' || solType === 'ESM') {
          const specitificsShop = solType === 'ESM' ? TargetSolTypeRole.ESM : key;
          dispatch(
            wakeupLoginRequest({
              ...wakeupInfo.data,
              [specitificsShop]: {
                wakeupInfo: {
                  ...wakeupInfo.data[specitificsShop].wakeupInfo,
                  scrapType: selectShops[specitificsShop]?.scrapType,
                  prodCodes: selectShops[specitificsShop]?.prodCodes,
                },
              },
            }),
          );
        }
      }
    }
    // 인증 된 로그인 있으면 이동
    // 어차피 위에서 하나라도 인증 안되면 return으로 함수 끝나긴 함
    if (targetSolType.length > 0) {
      navigate(`/${solType.toLowerCase()}/job/scrap-prod`, { state: { targetSolType } });

      return true;
    }

    return false;
  }

  /**
   * on submit
   * all 타입은 step 1, 2, 3을 쪼개서 해야 해서 작업 나눴음
   */
  async function onSubmit(specificData = false, targetSolType: TargetSolTypeRole) {
    //Step0. 데이터 세팅

    // 쇼핑몰에 맞는 useForm 데이터 가져오기
    const data = getUseForm(targetSolType, 'getValues', '');

    // form validation 처리가 안되고 있어, 유효성 검사 해줌
    const form: any = WAKEUP_FORM_DATA[targetSolType]?.form;
    for (const key in data) {
      const one = form.find((one: any) => one?.key === key);
      if (!data[key] && one?.validation?.required) {
        return MySwal.fire('실패', `계정 정보를 모두 입력해주세요`, 'error');
      }
    }

    data.shopCode = WAKEUP_FORM_DATA[targetSolType].code;
    data.shopName = WAKEUP_FORM_DATA[targetSolType].name.type1;

    // 공백제거
    Object.keys(data).map((key: string) => {
      // 올웨이즈는 ID앞에 공백을 허용함
      if (key === `shopId` && targetSolType === 'ALWAYZ') {
        return false;
      }

      if (key !== 'loginError') {
        data[key] = data[key].trim();
      }

      return true;
    });

    let reqData: any = {
      scrapShopCode: data.shopCode,
      scrapShopId: data.shopId,
      scrapShopPw: data.shopPw,
      scrapShopEtc1: data.shopEtc1,
      scrapShopEtc2: data.shopEtc2,
      scrapShopEtc3: data.shopEtc3,
      scrapShopEtc4: data.shopEtc4,
      scrapShopEtc5: data.shopEtc5,
      scrapShopEtc6: data.shopEtc6,
      solType,
      targetSolType,
      scrapType: selectShops[targetSolType]?.scrapType,
      prodCodes: selectShops[targetSolType]?.prodCodes,
      coupangSellerType: targetSolType === 'COUPANG' ? coupangSellerType : '3P',
      token: jsonToken.gmpToken,
      subscriptionType: data.subscriptionType || SubscriptionType.NONE,
      prodCompareType: data.prodCompareType || false,
      isProdCompare: !!isProdCompare,
      wakeupEtc1: targetSolType === 'AUCTION' ? data.shopId : null,
      wakeupEtc2: targetSolType === 'GMARKET' ? data.shopId : null,
      wakeupEtc3: targetSolType === 'AUCTION' ? data.shopPw : null,
      wakeupEtc4: targetSolType === 'GMARKET' ? data.shopPw : null,
    };

    // 상품유사도 쿠팡 로그인은 OPEN API 키로 로그인 진행해, reqData 추가해줌
    if (isProdCompare && targetSolType === 'COUPANG') {
      reqData.scrapShopEtc1 = data.shopEtc3;
      reqData.scrapShopEtc2 = data.shopEtc4;
    }

    //Step1. 지정 상품이면 지정상품 세팅
    //ALL 타입은 아래 함수를 직접 호출해서 따로 사용함
    if (solType !== 'ALL' && solType !== 'ESM') {
      const result = await setSpecificData(specificData, targetSolType);

      // 지정상품 취소 하면 아래 작업까지 안감
      if (!result.success) {
        return false;
      }

      reqData.scrapType = result.scrapType;
      reqData.prodCodes = result.prodCodes;
    }

    // 무한 로딩 화면 생성
    Swal.fire({
      title: `${WAKEUP_FORM_DATA[targetSolType].name.type1} 정보 유효성 검사중...`,
      html: '유효성 검사중 ...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    //Step2. 로그인 실행
    //Step3. 작업 시작도 이 함수 안에 있음 doWork()
    // 작업 시작 함수_doWork() 가 doLogin 안에 있는 이유는
    // doLogin에서 하는 소켓 연결이 기다릴 수 없는 비동기 처리라
    // 함수를 나눠 버리면 로그인 되지도 않았는데 작업 시작 함수를 실행시켜 버린다 ㅠㅠ
    await doLogin(targetSolType, data, reqData);
  }

  // 이전 페이지 이동
  function goBack(e: any) {
    e.preventDefault();
    navigate(-1);
  }

  // 쿠팡전용 필드 이름 수정 함수
  const coupangLabelChange = (label: string) => {
    if (coupangSellerType === 'Retail') {
      if (label === '쿠팡 Wing ID') {
        label = '쿠팡 Supplierhub ID';
      }
    }

    return label;
  };

  return (
    <>
      <FormWrap>
        {solType === 'ALL' || solType === 'ESM' ? (
          <>
            <Grid display="flex" bottom={20} direction="column" justifyContent="flex-end" height={100}>
              <Text fontSize="18px" fontWeight={600}>
                사이트 선택
              </Text>
            </Grid>

            <FormWrap.Top>
              {solType && (solType === 'ALL' || solType === 'ESM') && (
                <Grid top={30} bottom={30}>
                  <InputWrap
                    label={'선택'}
                    labelWidth={160}
                    input={
                      <>
                        {wakeupFormData &&
                          wakeupFormData.map((one: any, index: any) => {
                            return (
                              <Grid right={40} display={'flex'}>
                                {one.map((shop: any) => {
                                  const key = shop[0] as TargetSolTypeRole;
                                  const value = shop[1];

                                  const defaultChecked =
                                    solType === 'ALL'
                                      ? key === TargetSolTypeRole.COUPANG
                                      : key === TargetSolTypeRole.GMARKET || key === TargetSolTypeRole.AUCTION;

                                  // 이전 단계에서 11번가 / 11번가 단일을 선택 하였을 경우 11번가로 이전 불가능
                                  if (shopInfo.data && key === TargetSolTypeRole.ST11 && ['A112', 'A113'].includes(shopInfo.data.shopInfo.scrapShopCode)) {
                                    return <></>;
                                  }
                                  return (
                                    <Grid width={250} right={20}>
                                      <CheckBox
                                        label={value.name.type1}
                                        defaultChecked={defaultChecked}
                                        name={key}
                                        onClick={(e) => {
                                          setSelectShops((shops) => {
                                            if ((e.target as HTMLInputElement).checked) {
                                              return { ...shops, [key]: { ...shops[key], selected: true, isLogined: false } };
                                            }
                                            return { ...shops, [key]: { ...shops[key], selected: false, isLogined: false } };
                                          });
                                        }}
                                        value={key}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            );
                          })}
                      </>
                    }
                  />
                </Grid>
              )}
            </FormWrap.Top>
          </>
        ) : (
          <></>
        )}

        {selectShops &&
          Object.entries(selectShops).map((one: any, index: number) => {
            const shop = one[0] as TargetSolTypeRole;
            const value = one[1];

            return value.selected ? (
              <>
                <form onSubmit={getUseForm(shop, 'handleSubmit', () => onSubmit(false, shop))} key={index}>
                  {(solType === 'ALL' || solType === 'ESM') && (
                    <Grid display="flex" bottom={20} direction="column" justifyContent="flex-end" height={100}>
                      <Text fontSize="18px" fontWeight={600}>
                        {WAKEUP_FORM_DATA[shop].name.type1}
                      </Text>
                    </Grid>
                  )}
                  <FormWrap.Box>
                    {WAKEUP_FORM_DATA[shop].form.map((form: any, index: number) => {
                      return (
                        <>
                          <Grid bottom={50} key={index} hidden={form.hidden}>
                            <InputWrap
                              label={shop === 'COUPANG' ? coupangLabelChange(form.name) : form.name}
                              labelWidth={160}
                              input={
                                form.name === '지정 상품 등록하기' ? (
                                  <>
                                    <SpecificWrap
                                      solType={solType}
                                      shop={shop}
                                      selectShops={selectShops}
                                      setDataEvent={solType === 'ALL' || solType === 'ESM' ? setSpecificData : onSubmit}
                                    ></SpecificWrap>
                                    {/* {solType === 'ALL' || solType === 'ESM' ? (
                                      <Grid display="flex">
                                        <RadioButton
                                          name={`${shop}_scrapType`}
                                          id={`${shop}_scrapType_normal`}
                                          label={'전체 상품'}
                                          value={'normal'}
                                          checked={selectShops[shop]?.scrapType === ScrapType.NORMAL}
                                          onChange={async (e) => {
                                            await setSpecificData(false, shop);
                                          }}
                                        />
                                        <RadioButton
                                          name={`${shop}_scrapType`}
                                          id={`${shop}_scrapType_specific`}
                                          label={'지정 상품'}
                                          value={ScrapType.SPECIFIC}
                                          checked={selectShops[shop]?.scrapType === ScrapType.SPECIFIC}
                                          onChange={async (e) => {
                                            await setSpecificData(true, shop);
                                          }}
                                        />

                                        <Button
                                          display={selectShops[shop]?.scrapType === ScrapType.NORMAL ? 'none' : 'block'}
                                          size="small"
                                          width={70}
                                          color="main"
                                          type="button"
                                          onClick={async () => {
                                            await setSpecificData(true, shop);
                                          }}
                                        >
                                          수정
                                        </Button>
                                      </Grid>
                                    ) : (
                                      <>
                                        {
                                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                          <a
                                            href=""
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                              textDecoration: 'underline',
                                              color: 'rgb(51,51,51)',
                                              fontSize: '0.88rem',
                                              fontWeight: 500,
                                              height: 'auto',
                                            }}
                                            onClick={async (_) => {
                                              _.preventDefault();
                                              onSubmit(true, shop);
                                            }}
                                          >
                                            지정 상품 등록하기
                                          </a>
                                        }
                                      </>
                                    )} */}
                                  </>
                                ) : (
                                  <TextField
                                    type={form.type || 'text'}
                                    defaultValue={form.defaultValue || (isAccountAutoSettingAdmin ? form.adminDefaultValue : '')}
                                    placeholder={`${form.name} 를 입력해 주세요`}
                                    {...getUseForm(shop, 'register', form.validation, form.key)}
                                    readOnly={
                                      form.key.toString().indexOf('shopEtc5') > -1 && shop === TargetSolTypeRole.COUPANG && !isAccountAutoSettingAdmin
                                        ? coupangVendorIdLimit
                                        : false
                                    }
                                  />
                                )
                              }
                              error={
                                getUseForm(shop, 'errors', null) &&
                                getUseForm(shop, 'errors', null)[form.key]?.type === 'required' && <FormError errorMessage={'필수 입력 항목입니다.'} />
                              }
                              adornment={form.adornment || <></>}
                            />
                          </Grid>
                        </>
                      );
                    })}

                    {/* 상품 자동 이전은 ALL 타입 노출 안함 */}
                    {solType !== 'ALL' && shop === 'ST11' && (
                      <>
                        <Grid top={30}>
                          <InputWrap
                            label="상품 자동 이전"
                            labelWidth={160}
                            input={
                              <>
                                {subscriptionType === SubscriptionType.SUBSCRIBED ? (
                                  <Text fontWeight={600} color="#0072CE">
                                    상품 자동 이전에 동의하셨습니다
                                  </Text>
                                ) : (
                                  <Button
                                    size="small"
                                    width={200}
                                    color="main"
                                    type="button"
                                    onClick={() => {
                                      showSchedulerPopup(shop);
                                    }}
                                  >
                                    상품 자동 이전하기
                                  </Button>
                                )}
                              </>
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/* 쿠팡 웨이크업에서, 상품비교 사용업체만 설정여부 받기 */}
                    {solType !== 'ALL' && shop === 'COUPANG' && isProdSimilarityUser && (
                      <>
                        <Grid bottom={50}>
                          <InputWrap
                            label="중복상품 제외 등록"
                            labelWidth={160}
                            input={
                              <>
                                {prodCompareType ? (
                                  <Text fontWeight={600} color="#0072CE" fontSize="0.88rem">
                                    중복상품 제외 등록 기능에 동의하셨습니다.
                                  </Text>
                                ) : (
                                  <Button
                                    size="small"
                                    width={200}
                                    color="main"
                                    type="button"
                                    onClick={() => {
                                      showProdComparePopup(shop);
                                    }}
                                  >
                                    중복상품 제외 등록하기
                                  </Button>
                                )}
                              </>
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {shop === 'COUPANG' && !isProdCompare && (
                      <li
                        style={{
                          fontSize: '14px',
                          whiteSpace: 'pre-line',
                          position: 'relative',
                          paddingLeft: '15px',
                        }}
                        className="notices"
                      >
                        <span>1회 작업 성공 시 업체코드 변경은 불가하며, 타 쿠팡아이디 진행 시 새롭게 플레이오토 아이디를 생성 부탁 드립니다.</span>
                        {solType !== 'ALL' && (
                          <>
                            <br />
                            <span
                              style={{
                                fontSize: '14px',
                                whiteSpace: 'pre-line',
                                position: 'relative',
                                color: '#0072CE',
                                marginTop: '5px',
                              }}
                            >
                              상품 재등록은 ‘지정 상품 등록하기’ 기능을 이용해주세요.
                            </span>
                          </>
                        )}
                      </li>
                    )}

                    {WAKEUP_FORM_DATA[shop].restrictionType && (
                      <>
                        <Grid top={40}>
                          <Text fontWeight={600} fontSize="0.88rem">
                            이전 불가한 상품 유형
                          </Text>
                          <Grid top={20} />
                          <Text fontWeight={500} color="#8a8a8a" fontSize="0.83rem">
                            {WAKEUP_FORM_DATA[shop].restrictionType}
                          </Text>
                        </Grid>
                      </>
                    )}

                    {solType === 'ALL' || solType === 'ESM' ? (
                      <Grid>
                        <Grid display="block" textAlign="center" ref={getUseRef(shop)}>
                          {selectShops[shop]?.isLogined ? (
                            <Text fontWeight={600} fontSize="1rem" color="#0072CE" textAlign="center" lineHeight={50}>
                              계정 인증이 완료되었습니다.
                            </Text>
                          ) : (
                            <>
                              <Grid top={50}>
                                <Button size="medium" width={200} color="main" type="submit">
                                  계정 확인하기
                                </Button>
                              </Grid>

                              {getUseForm(shop, 'watch', null, 'loginError') && (
                                <Grid top={10}>
                                  <FormError
                                    errorMessage={`
                              ${WAKEUP_FORM_DATA[shop].name.type1} 쇼핑몰 로그인 인증이 완료되지 않았습니다.`}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </FormWrap.Box>
                </form>
                {/* TODO_SUE ChannelIOButton 출력 위치 변경되는지 확인하기 */}
                <ChannelIOButton solType={solType} />
              </>
            ) : (
              <></>
            );
          })}

        {/* ESM 일경우 옥션2.0, G마켓2.0 공통 정책 정의 */}
        {solType === 'ESM' && (selectShops.GMARKET?.selected || selectShops.AUCTION?.selected) && WAKEUP_FORM_DATA['ESM'].restrictionType && (
          <>
            <Grid display="flex" bottom={20} direction="column" justifyContent="flex-end" height={100}>
              <Text fontSize="18px" fontWeight={600}>
                지정 상품 등록하기
              </Text>
            </Grid>
            <FormWrap.Box>
              <InputWrap
                labelWidth={200}
                label={'지정 상품 등록하기'}
                input={<SpecificWrap solType={solType} shop={TargetSolTypeRole.ESM} selectShops={selectShops} setDataEvent={setSpecificData}></SpecificWrap>}
              ></InputWrap>
            </FormWrap.Box>
            <Grid top={30}>
              <Text fontWeight={600} fontSize="0.88rem">
                상품 이전 간 주의사항
              </Text>
              <Grid top={20} />
              <Text fontWeight={500} color="#8a8a8a" fontSize="0.83rem">
                {WAKEUP_FORM_DATA['ESM'].restrictionType}
              </Text>
            </Grid>
          </>
        )}

        {/* 상품등록 고정 위치를 알기 위해 */}
        <Grid id={'submitDivPosition'} ref={submitDivPosition}></Grid>
        <FixedGrid fixed={submitPosition} position="bottom" height="135px">
          <FormWrap.Bottom id={'submitDivFixed'}>
            <Grid display="flex" justifyContent="center">
              <Button size="medium" width={200} variant="outlined" color="main" onClick={goBack}>
                이전
              </Button>
              {Object.entries(selectShops).find((data) => {
                return data[1].selected === true;
              }) ? (
                <>
                  <Grid display="inline-block" right={20} />
                  <Button
                    size="medium"
                    width={200}
                    color="main"
                    type="button"
                    onClick={async (e) => {
                      e.preventDefault();
                      switch (solType) {
                        case 'ALL':
                          if (selectShops.GMARKET?.selected || selectShops.AUCTION?.selected) {
                            await checkESMAccount();
                          }
                          await doWork();
                          break;
                        case 'ESM':
                          await checkESMAccount();
                          await doWork();
                          break;
                        default:
                          await onSubmit(false, solType as TargetSolTypeRole);
                          break;
                      }
                    }}
                  >
                    {isProdCompare ? '상품 비교' : '상품 등록'}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </FormWrap.Bottom>
        </FixedGrid>
      </FormWrap>
    </>
  );
}
