import Title from '../../atoms/Title';
import StepSmall from '../../atoms/StepSmall';
import styled from 'styled-components';

interface IProps {
  title?: string;
  subject?: string;
  step?: number;
  direction?: 'row' | 'column';
}

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #333;
  padding-bottom: 15px;
  position: relative;
  align-items: flex-end;
`;

function TitleWrap({ title, subject, step, direction }: IProps) {
  return (
    <Wrap>
      <Title subTitle={subject} direction={direction}>
        {title}
      </Title>
      {step && <StepSmall step={step} direction={direction} />}
    </Wrap>
  );
}

export default TitleWrap;
