import { forwardRef, MouseEventHandler } from 'react';
import styled from 'styled-components';
import Grid from '../Grid';

interface IProps {
  label?: string;
  value?: any;
  name?: string;
  defaultChecked?: any;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

const CheckBoxTag = styled.input`
  cursor: pointer;
`;

const LabelTag = styled.label`
  cursor: pointer;
  font-size: 0.88rem;
`;

const CheckBox = forwardRef((props: IProps, ref?: React.Ref<HTMLInputElement>) => {
  return (
    <Grid display="inline-flex" alignItems="center">
      <CheckBoxTag type="checkbox" id={props.name} {...props} ref={ref} />
      <Grid left={10} right={10}>
        <LabelTag htmlFor={props.name}>{props.label}</LabelTag>
      </Grid>
    </Grid>
  );
});
export default CheckBox;
