import { IMemberProps } from '../../../../../common/interface/props';
import Template from './Template';
import Container from '../../../../UI/atoms/Container';

function ResetPassword({ solType }: IMemberProps) {
  return (
    <Container>
      <Template solType={solType} />
    </Container>
  );
}

export default ResetPassword;
