import { useEffect, useState } from 'react';
// atomic component
import Container from 'components/UI/atoms/Container';
import Text from 'components/UI/atoms/Text';
import Grid from 'components/UI/atoms/Grid';
import Button from 'components/UI/atoms/Button';
import TextField from 'components/UI/atoms/TextField';

// common
import InputWrap from 'components/UI/molecules/InputWrap';
import RadioButton from 'components/UI/atoms/RadioButton';
import TextArea from 'components/UI/atoms/TextArea';

import Swal from 'sweetalert2';

import { axiosCustom } from '../../common/common.function';
import { ISearchData } from 'components/admin/interfaces/admin.interface';
import { ScrapType } from 'common/common.constants';

interface IProps {
  onComplete: (searchData: ISearchData) => void;
}

export default function JobReSend({ onComplete }: IProps) {
  // jobId
  const [jobId, setJobId] = useState<string>('');
  // scrapType
  const [scrapType, setScrapType] = useState<ScrapType>(ScrapType.SPECIFIC);
  // prodCodes
  const [prodCodes, setProdCodes] = useState<string>('');

  // IP 검사
  useEffect(() => {
    async function ip() {
      try {
        // 통합 어드민 페이지의 기본 solType 값은 coupang 임
        await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/coupang/ipCheck`, {
          headers: {
            'sol-type': 'ADMIN',
          },
        });
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: error.message,
          icon: 'error',
        });

        window.location.href = 'https://plto.com';
      }
    }
    ip();
  }, []);

  // 검색 이벤트
  async function onSubmit() {
    const newProdCodes = prodCodes
      .split('\n')
      .map((v) => v.trim())
      .filter((v) => v);

    try {
      if (scrapType === ScrapType.SPECIFIC && !newProdCodes?.length) {
        Swal.fire({
          title: '실패',
          html: `지정 상품 등록시, 상품번호는 필수입니다`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return false;
      }

      Swal.fire({
        title: '잠시만 기다려 주세요.',
        html: '작업 재시도중 ...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const {
        success,
        message,
        jobId: newJobId,
        targetSolType,
      } = await axiosCustom.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/job-re-send`,
        {
          jobId: +jobId,
          scrapType,
          prodCodes: newProdCodes,
        },
        {
          headers: {
            'sol-type': 'ADMIN',
          },
        },
      );

      if (!success) {
        return Swal.fire({
          title: '실패',
          html: message || '',
          icon: 'error',
        });
      }

      // 여기서 버튼 누르면,
      const re = await Swal.fire({
        title: '작업 재시도 성공',
        html: `작업번호: ${newJobId} <br />
                작업 조회 버튼을 누르면 상세조회 탭에서 작업을 조회합니다. <br />`,
        showCancelButton: true,
        confirmButtonText: '작업 조회',
        cancelButtonText: '닫기',
      });

      if (re.value === true) {
        onComplete({ solType: targetSolType, jobId: newJobId });
      }
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    }
  }

  const handleChangeJobId = (event: any) => {
    // 숫자 이외의 문자 제거
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    setJobId(newValue);
  };

  return (
    <Container noBody={true}>
      <Grid top={20}>
        <Text fontSize="0.88rem">
          ※ 웨이크업 회원이 등록한 작업 정보로 플레이오토 웨이크업 계정에 작업을 재시도하게 됩니다.
          <br />
          [플레이오토 웨이크업 계정]
          <Text fontSize="0.70rem">
            쿠팡: okfactory1 / 11번가: pltowakeup / 올웨이즈: playauto / 위메프: wmpp53070 / 옥션: myshopy3 / 지마켓: myshopy1 / 카카오: talkstore@playauto.co.kr / 토스: sueunPlto
            <br />
          </Text>
          <br />
          웨이크업 회원이 등록한 작업 유형과 무관하게 작업 재시도 가능하니, 지정 상품 등록 작업으로 재시도 권장드립니다.
          <br />
        </Text>
      </Grid>

      <Grid top={50}>
        <InputWrap
          label={'작업번호'}
          labelWidth={160}
          input={<TextField type={'text'} placeholder={`작업번호를 입력해 주세요`} value={jobId} onChange={handleChangeJobId} />}
        />
      </Grid>
      <Grid top={50} bottom={50}>
        <InputWrap
          label={'작업유형'}
          labelWidth={160}
          input={
            <>
              <RadioButton
                name={'scrapType'}
                id={`specific`}
                label={'지정 상품'}
                checked={scrapType === ScrapType.SPECIFIC}
                onChange={(e) => {
                  setScrapType(ScrapType.SPECIFIC);
                }}
              />
              <RadioButton
                name={'scrapType'}
                id={`normal`}
                label={'전체 상품'}
                checked={scrapType === ScrapType.NORMAL}
                onChange={(e) => {
                  setScrapType(ScrapType.NORMAL);
                }}
              />
              {scrapType === ScrapType.SPECIFIC && (
                <>
                  <Grid top={20}>
                    <InputWrap
                      label={'상품번호'}
                      labelWidth={160}
                      input={
                        <TextArea
                          fontSize="inherit"
                          placeholder={`상품번호를 입력해 주세요`}
                          height={'150px'}
                          onChange={(event) => {
                            setProdCodes(event.target.value);
                          }}
                        />
                      }
                    />
                  </Grid>
                </>
              )}
            </>
          }
        />
      </Grid>

      <Grid display="flex" justifyContent="center" bottom={50}>
        <Button size="medium" width={200} color="main" type="button" onClick={onSubmit}>
          작업 재시도
        </Button>
      </Grid>
    </Container>
  );
}
