import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  fixed: boolean;
  position: 'top' | 'bottom' | 'right' | 'left';
  children: ReactNode;
  height: string;
}

const FixedGrid = styled.div<IProps>`
  position: ${({ fixed }) => (fixed ? 'fixed' : '')};
  ${({ fixed, position }) => (fixed && position === 'top' ? 'top: 0; left: 0; right: 0;' : '')};
  ${({ fixed, position }) => (fixed && position === 'bottom' ? 'bottom: 0; left: 0; right: 0;' : '')};
  ${({ fixed, position }) => (fixed && position === 'left' ? 'left: 0; top: 0; bottom: 0;' : '')};
  ${({ fixed, position }) => (fixed && position === 'right' ? 'right: 0; top: 0; bottom: 0;' : '')};
  background-color: ${({ fixed }) => (fixed ? '#fff' : '')};
  border-top: ${({ fixed, position }) => (fixed && position === 'bottom' ? '1px solid #ddd' : '')};
  border-bottom: ${({ fixed, position }) => (fixed && position === 'top' ? '1px solid #ddd' : '')};
  border-left: ${({ fixed, position }) => (fixed && position === 'right' ? '1px solid #ddd' : '')};
  border-right: ${({ fixed, position }) => (fixed && position === 'left' ? '1px solid #ddd' : '')};
  height: ${({ height }) => height};
`;

export default FixedGrid;
