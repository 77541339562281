import { IMemberProps } from '../../../../../common/interface/props';

import Grid from '../../../../UI/atoms/Grid';
import Banner from '../../../../UI/molecules/Banner';
import LoginForm from '../../../../UI/organisms/LoginForm';
import LoginGuide from '../../../../UI/organisms/LoginGuide';
import Container from '../../../../UI/atoms/Container';

export default function Login({ solType }: IMemberProps) {
  return (
    <>
      <Banner imgPath="/images/bg_login.png" text="플레이오토 로그인" />
      <Container noBody={true}>
        <Grid top={30} bottom={30}>
          <LoginGuide />
        </Grid>
        <Grid bottom={50}>
          <LoginForm solType={solType} />
        </Grid>
      </Container>
    </>
  );
}
