import styled from 'styled-components';

// interface IProps {
//   step3: string;
// }

const StepWrapBig = styled.div`
  max-width: 35rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      padding: 5px 20px;
      border-radius: 20px;
      background: #333;
      color: white;
      font-size: 0.88rem;
    }

    > p {
      padding-top: 20px;
      font-size: 0.88rem;
    }
  }

  > img {
    margin-top: 5px;
    height: 1.2rem;
  }
`;

// function StepBig({ step3 }: IProps) {
function StepBig() {
  return (
    <StepWrapBig>
      <div>
        <span>1단계</span>
        <p>무료 회원가입</p>
      </div>
      <img src="/images/iconStep.png" alt="step" />
      <div>
        <span>2단계</span>
        <p>이전할 쇼핑몰 선택</p>
      </div>
      <img src="/images/iconStep.png" alt="step" />
      <div>
        <span>3단계</span>
        {/* <p>{step3}</p> */}
        <p>상품등록</p>
      </div>
    </StepWrapBig>
  );
}

export default StepBig;
