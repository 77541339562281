import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import styled from 'styled-components';
import Grid from '../Grid';

interface IProps {
  headerItems: Omit<GridColDef, 'field'>[];
  bodyItems?: any[][];
}

const GridTag = styled(Grid)`
  height: 600px;
  overflow-y: scroll;
  border: 1px solid #e2e2e2;
  border-radius: 5px;

  .MuiDataGrid-root * {
    font-size: 0.77rem;
  }
`;

export default function TableComponent(props: IProps) {
  const hideableIndexs: number[] = [];
  const columns = useMemo(
    () =>
      props.headerItems
        .filter((item, index) => {
          // 숨길 컬럼의 index를 기록후
          // 노출할 컬럼만 filter에서 반환함
          if (item.hideable) {
            hideableIndexs.push(index);
          }
          return !item.hideable;
        })
        .map((one, index) => ({
          sortable: false,
          disableColumnMenu: true,
          ...one,
          field: `col${index}`,
        })),
    [props.headerItems],
  );

  const rows = useMemo(
    () =>
      props.bodyItems
        ? props.bodyItems.map((row, index) => {
            let rowIndex = 0;

            return row.reduce((all, current, index2) => {
              all.id = index;
              // 숨길 컬럼은 bodyItem 배열 요소에서 포함시키지 않음
              if (!hideableIndexs.includes(index2)) {
                all[`col${rowIndex}`] = current;
                rowIndex++;
              }
              return all;
            }, {});
          })
        : [],
    [props.bodyItems],
  );

  return (
    <>
      <GridTag>
        <DataGrid columns={columns} rows={rows} hideFooter />
      </GridTag>
    </>
  );
}
