import { WAKEUP_FORM_DATA } from 'common/common.constants';
import { axiosCustom, getFormatDate, getJobResultExcelStyle } from 'common/common.function';
import { WakeUp } from 'common/interface/props';
import { ISearchData } from 'components/admin/interfaces/admin.interface';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import XLSX from 'xlsx';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import styled from 'styled-components';

// atomic component
import Container from 'components/UI/atoms/Container';
import Text from 'components/UI/atoms/Text';
import Grid from 'components/UI/atoms/Grid';
import Button from 'components/UI/atoms/Button';
import TextField from 'components/UI/atoms/TextField';
import Table from 'components/UI/atoms/Table';
import Select from 'components/UI/atoms/Select';
import Pagination from 'components/UI/molecules/Pagination';
import { BiSearch } from 'react-icons/bi';
import { RiFileExcel2Line, RiAlarmWarningFill } from 'react-icons/ri';
import Tooltip from 'components/UI/atoms/Tooltip';
const MySwal = withReactContent(Swal);
registerLocale('ko', ko);

interface IDashboard {
  solType: WakeUp;
  isIntegrated?: boolean;
  searchData: ISearchData;
  setSearchData: React.Dispatch<React.SetStateAction<ISearchData>>;
}

interface IJobData {
  startDate: string;
  allEndDate: string;
  scrapShopCode: string;
  scrapShopName: string;
  targetShopName: string;
  scrapShopId: string;
  state: string;
  scrapType: string;
  msg: string;
  jobId: number;
  email: string;
  wakeupId: string;
  wakeupEtc1: string;
  wakeupEtc2: string;
  wakeupEtc5: string;
  successCount?: number;
  failureCount?: number;
}
const ButtonTag = styled(Button)`
  border: 1px solid #e2e2e2;
  padding: 5px 5px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: #f5f5f5;
  }
`;
export default function Dashboard({ solType, isIntegrated, searchData, setSearchData }: IDashboard) {
  // 시작일 (기본값 : 현재일 - 7일) -> 2024-06-27 시작은 7일이였으나 알수없는 사유로 60일로 변경되있음, 14일로 바꿈
  const [startDate, setStartDate] = useState(() => {
    const d = new Date();
    d.setDate(d.getDate() - 14);
    return d;
  });

  // 종료일 (기본값 : 현재일)
  const [endDate, setEndDate] = useState(new Date());

  // 페이지당 노출 수
  const [viewCount, setViewCount] = useState(30);

  // 조회 솔루션 타입
  const [searchSolType, setSearchSolType] = useState(solType);
  // 조회 키
  const [searchType, setSearchType] = useState('playautoId');
  // 조회 값
  const [searchValue, setSearchValue] = useState('');

  // 현재 페이지
  const [nowPage, setNowPage] = useState(1);

  // 작업 총 수
  const [max, setMax] = useState(0);

  // 작업 데이터
  const [jobData, setJobData] = useState<IJobData[]>([]);

  // 페이지변경됐는지 확인 훅
  const [pageChanged, setPageChanged] = useState<boolean>(true);

  // 상위 컴포넌트에서 serarchData 변경시, 검색 실행
  useEffect(() => {
    if (searchData.jobId) {
      setPageChanged(true); // 페이지 변경여부

      setSearchType('jobId');
      setSearchValue(searchData.jobId);
      setSearchSolType(searchData.solType);
      setNowPage(1);

      getJobData({
        searchType: 'jobId',
        searchValue: searchData.jobId,
        searchSolType: searchData.solType,
        nowPage: 1,
      });
    }

    return () => {
      // 해당 처리 안해주면, 탭 이동되고 다시 돌아올때마다 이전 조회 정보를 계속 가지고 있어서 초기화 해줌
      setSearchData({
        jobId: '',
        solType: 'COUPANG',
      });
    };
  }, [searchData]);

  // 페이지 변경 이벤트
  useEffect(() => {
    if (pageChanged) {
      setPageChanged(false);
    } else {
      getJobData();
    }
  }, [nowPage]);

  // IP 검사
  useEffect(() => {
    async function ip() {
      try {
        await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/${solType.toLowerCase()}/ipCheck`, {
          headers: {
            'sol-type': isIntegrated ? 'ADMIN' : solType.toUpperCase(),
          },
        });
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: error.message,
          icon: 'error',
        });

        window.location.href = 'https://plto.com';
      }
    }
    ip();
  }, []);

  function onSubmit() {
    getJobData();
  }

  // 검색 이벤트
  async function getJobData(search?: any) {
    Swal.fire({
      title: '잠시만 기다려 주세요.',
      html: '작업 조회중 ...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { result } = await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/detail-search`, {
        params: {
          startDate: getFormatDate(startDate),
          endDate: getFormatDate(endDate) + ' 23:59:59',
          solType: search?.searchSolType || searchSolType,
          searchType: search?.searchType || searchType,
          searchValue: search?.searchValue || searchValue,
          viewCount: viewCount,
          nowPage: search?.nowPage || nowPage,
        },
        headers: {
          'sol-type': isIntegrated ? 'ADMIN' : solType.toUpperCase(),
        },
      });
      if (result.jobData.length <= 0 || result.totalCount === 0) {
        Swal.fire({
          title: '실패',
          html: `정보가 조회되지 않습니다.`,
          icon: 'error',
        });
      } else {
        // 작업 데이터 설정
        setJobData(result.jobData);

        // 최대 페이지 설정
        setMax(Math.ceil(result.totalCount / viewCount));

        Swal.close();
      }
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    }
  }

  // 작업 상세 보기/엑셀 다운로드
  async function jobDetailEvent(type: 'EXCEL' | 'VIEW', jobId: number, count: number) {
    if (type === 'VIEW' && count > 100) {
      Swal.fire({
        title: '경고',
        html: '100 개 이상 조회는 엑셀 다운로드를 이용 부탁 드립니다',
        icon: 'error',
      });
      return false;
    }

    Swal.fire({
      title: '잠시만 기다려 주세요.',
      html: '작업 상세 다운로드 ...', // add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { result: jobDetailData } = await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/detail-job/${jobId}`, {
        timeout: 300000,
        headers: {
          'sol-type': isIntegrated ? 'ADMIN' : solType.toUpperCase(),
        },
      });

      if (type === 'VIEW') {
        MySwal.fire({
          width: 1200,
          title: '작업 상세 정보',
          html: (
            <>
              <Table
                headerItems={Object.keys(jobDetailData[0]).map((one, index) => ({
                  width:
                    index === 0
                      ? 110
                      : index === 1
                      ? 130
                      : index === 2
                      ? 160
                      : index === 3
                      ? 160
                      : index === 4
                      ? 220
                      : index === 5
                      ? 200
                      : index === 6
                      ? 220
                      : index === 7
                      ? 180
                      : index === 8
                      ? 230
                      : index === 9
                      ? 160
                      : index === 10
                      ? 220
                      : 100,
                  align: 'center',
                  headerAlign: 'center',
                  headerName: one,
                  editable: true,
                }))}
                bodyItems={jobDetailData.map((prodOne: any) => Object.keys(prodOne).map((one) => prodOne[one]))}
              />
            </>
          ),
          confirmButtonText: '확인',
        });
      } else if (type === 'EXCEL') {
        const dataWS = XLSX.utils.json_to_sheet(jobDetailData);
        // 엑셀 컬럼 너비 정하기
        dataWS['!cols'] = getJobResultExcelStyle(searchSolType);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS, 'jobDetailData');
        XLSX.writeFile(wb, `${jobId}.xlsx`);
        Swal.close();
      }
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });

      window.location.href = 'https://plto.com';
    }
  }

  /**
   * @alias 어드민 > 작업 강제 실패처리
   * @description 작업 시작이 24시간 지났고 state가 inactive이거나 active인 작업 실패처리 하도록 함
   * 24시간에 대한 유효성 검사는 프론트에서 하고 state에 관한 유효성 검사는 백엔드에서 처리
   */

  async function jobForceFailure(jobId: number, createdAt: string) {
    /* 작업 시작 시간 */
    const jobStartDate = new Date(createdAt);

    /* 현재 시간 */
    const currentDate = new Date();
    /* 24시간을 밀리초로 계산 */
    const hours24 = 24 * 60 * 60 * 1000;
    /* 작업 시작 후 24시간이 지난 경우만 실패처리 가능 */
    if (jobStartDate !== null && currentDate.getTime() - jobStartDate.getTime() >= hours24) {
      const agreeSwal = await Swal.fire({
        title: '작업 실패',
        html: '해당 작업을 강제로 실패처리 하시겠습니까?',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
      });

      // 동의하지 않았을 때
      if (!agreeSwal.isConfirmed) {
        return false;
      }
      const userInfoString = localStorage.getItem('userInfo');
      let userInfo;

      try {
        userInfo = JSON.parse(userInfoString!); // 문자열을 객체로 변환
        const result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/failure-job/${jobId}`, userInfo, {
          timeout: 300000,
          headers: {
            'sol-type': isIntegrated ? 'ADMIN' : solType.toUpperCase(),
          },
        });

        if (result.success == true) {
          await Swal.fire({
            title: '성공',
            html: `작업 실패 처리 되었습니다.`,
            icon: 'success',
          });
          getJobData();
        } else {
          await Swal.fire({
            title: '실패',
            html: `${result.message}`,
            icon: 'error',
          });
        }
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: `${error.message}`,
          icon: 'error',
        });
      }
    } else {
      await Swal.fire({
        title: '실패',
        html: '해당 작업은 24시간 이내에 시작된 작업입니다. 작업 실패 처리는 개발팀에게 문의해주세요.',
        icon: 'error',
      });
      return false;
    }
  }

  return (
    <Container noBody={true}>
      {(solType === 'COUPANG' || isIntegrated) && (
        <Grid top={40}>
          ※ 쿠팡의 성공/실패 집계의 경우 쿠팡에 데이터를 전달하기까지의 집계이며, <br />
          실제 쿠팡 Wing 상품등록 성공 / 실패 여부 및 사유 는 돋보기/엑셀 다운로드를 통해 자세히 확인하실 수 있습니다.
        </Grid>
      )}
      <Grid display="flex" alignItems="center" top={40} bottom={20}>
        <Grid display="inline-flex" justifyContent="flex-start" alignItems="center">
          <DatePicker
            locale="ko"
            selected={startDate}
            popperPlacement="top-start"
            onChange={(targetDate: Date) => {
              setStartDate(targetDate);
            }}
            dateFormat="yyyy-MM-dd"
            customInput={<TextField variant="outlined" width={100} style={{ fontSize: '0.88rem' }} />}
          />
          &nbsp;~&nbsp;
          <DatePicker
            locale="ko"
            selected={endDate}
            popperPlacement="top-start"
            onChange={(targetDate: Date) => {
              setEndDate(targetDate);
            }}
            dateFormat="yyyy-MM-dd"
            customInput={<TextField variant="outlined" width={100} style={{ fontSize: '0.88rem' }} />}
          />
        </Grid>

        {/* <Select variant="outlined" {...useForm.register(`wakeUpType`)}>
            {WAKEUP.map((one, index) => {
              if (one === 'WEMAKEPRICE')
                return (
                  <option value={one} key={index}>
                    {one}
                  </option>
                );
            })}
          </Select> */}
        <Grid display="flex" justifyContent="flex-end">
          {/* 솔루션 타입 */}
          {isIntegrated ? (
            <Select
              value={searchSolType}
              onChange={(event) => setSearchSolType(event.target.value as WakeUp)}
              variant="outlined"
              style={{ fontSize: '0.88rem' }}
            >
              <option value="COUPANG">쿠팡</option>
              <option value="WEMAKEPRICE">위메프</option>
              <option value="ST11">11번가</option>
              <option value="ALWAYZ">올웨이즈</option>
              <option value="KAKAO">카카오</option>
              <option value="ESM">ESM+</option>
              <option value="TOSS">TOSS</option>
              {/* <option value="ALL">ALL</option> */}
            </Select>
          ) : (
            <></>
          )}
          {/* 조회 키 */}
          <Select value={searchType} onChange={(event) => setSearchType(event.target.value)} variant="outlined" style={{ fontSize: '0.88rem' }}>
            <option value="playautoId">플레이오토 ID</option>
            {/* <option value="marketId">가져오는 쇼핑몰 ID</option> */}
            <option value="jobId">작업번호</option>
            {searchSolType === 'COUPANG' ? (
              <option value="coupangVendorId">쿠팡 업체코드</option>
            ) : searchSolType === 'ESM' ? (
              <>
                <option value="gmarketId">{WAKEUP_FORM_DATA.GMARKET.name.type1} 아이디</option>
                <option value="auctionId">{WAKEUP_FORM_DATA.AUCTION.name.type1} 아이디</option>
              </>
            ) : (
              <option value="wakeupId">{WAKEUP_FORM_DATA[searchSolType].name.type1} 아이디</option>
            )}
          </Select>
          &nbsp;
          {/* 조회 값 */}
          <TextField
            type="text"
            placeholder="조회 할 내용"
            variant="outlined"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            style={{ fontSize: '0.88rem' }}
          />
          &nbsp;
          <Button color="main" width={80} size="small" borderRadius={5} onClick={onSubmit}>
            검색
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Table
          headerItems={[
            {
              width: 170,
              headerName: '작업날짜',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 150,
              headerName: '플레이오토ID',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 100,
              headerName: `${WAKEUP_FORM_DATA.GMARKET.name.type1}아이디`,
              align: 'center',
              headerAlign: 'center',
              editable: true,
              hideable: solType === 'ESM' ? false : true,
            },
            {
              width: 100,
              headerName: `${WAKEUP_FORM_DATA.AUCTION.name.type1}아이디`,
              align: 'center',
              headerAlign: 'center',
              editable: true,
              hideable: solType === 'ESM' ? false : true,
            },
            {
              width: 100,
              headerName: `${WAKEUP_FORM_DATA[searchSolType].name.type1}아이디`,
              align: 'center',
              headerAlign: 'center',
              editable: true,
              hideable: solType === 'WEMAKEPRICE' ? false : true,
            },
            {
              width: 100,
              headerName: '쿠팡업체코드',
              align: 'center',
              headerAlign: 'center',
              editable: true,
              hideable: solType === 'COUPANG' ? false : true,
            },
            {
              width: 120,
              headerName: '가져오는 쇼핑몰',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 120,
              headerName: '이전한 쇼핑몰',
              align: 'center',
              headerAlign: 'center',
              editable: true,
              hideable: ['ALL', 'ESM'].includes(solType) ? false : true,
            },
            {
              width: 120,
              headerName: '작업상태',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 140,
              headerName: '작업메세지',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: '성공 / 실패',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: '작업번호',
              align: 'center',
              headerAlign: 'center',
              renderCell: (params: any) => {
                return (
                  <Grid>
                    <Text fontSize="0.77rem" fontWeight={500} lineHeight={24}>
                      {params.value.jobId}
                    </Text>
                    <Grid display="flex" justifyContent="center">
                      <ButtonTag
                        color="white"
                        size="small"
                        variant="outlined"
                        width={40}
                        borderRadius={5}
                        onClick={() => {
                          jobDetailEvent('VIEW', params.value.jobId, (params.value.successCount || 0) + (params.value.failureCount || 0));
                        }}
                      >
                        <BiSearch size="0.77rem" />
                      </ButtonTag>
                      <ButtonTag
                        color="white"
                        size="small"
                        variant="outlined"
                        width={40}
                        borderRadius={5}
                        onClick={() => {
                          jobDetailEvent('EXCEL', params.value.jobId, (params.value.successCount || 0) + (params.value.failureCount || 0));
                        }}
                      >
                        <RiFileExcel2Line size="0.77rem" />
                      </ButtonTag>

                      {/* {isIntegrated && (
                        <ButtonTag
                          color="white"
                          size="small"
                          variant="outlined"
                          width={40}
                          borderRadius={5}
                          onClick={() => {
                            jobForceFailure(params.value.jobId);
                          }}
                        >
                          <Tooltip title="작업을 강제 실패처리합니다.">
                            <RiAlarmWarningFill size="0.77rem" />
                          </Tooltip>
                        </ButtonTag>
                      )} */}
                    </Grid>
                  </Grid>
                );
              },
            },
            {
              width: 100,
              headerName: '지정상품',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 100,
              headerName: '작업실패처리',
              align: 'center',
              headerAlign: 'center',
              editable: true,
              renderCell: (params: any) => {
                const jobId = params.row.col7.jobId;
                const createdAt = params.row.col0;
                return (
                  <ButtonTag
                    color="white"
                    size="small"
                    variant="outlined"
                    width={40}
                    borderRadius={5}
                    onClick={() => {
                      jobForceFailure(jobId, createdAt);
                    }}
                  >
                    <RiAlarmWarningFill size="0.77rem" />
                  </ButtonTag>
                );
              },
            },
          ]}
          bodyItems={jobData.map((one) => [
            one.startDate,
            one.email,
            one.wakeupEtc2,
            one.wakeupEtc1,
            one.wakeupId,
            one.wakeupEtc5,
            one.scrapShopName,
            one.targetShopName,
            one.state,
            one.msg,
            `${one.successCount || 0} / ${one.failureCount || 0}`,
            {
              jobId: one.jobId,
              successCount: one.successCount,
              failureCount: one.failureCount,
            },
            one.scrapType,
            // <>
            //   <Text fontSize="0.9rem" fontWeight={500}>
            //     {one.job_id}
            //   </Text>
            //   <Grid display="flex" justifyContent="center">
            //     <ButtonTag
            //       color="white"
            //       size="small"
            //       variant="outlined"
            //       width={40}
            //       borderRadius={5}
            //       onClick={() => {
            //         jobDetailEvent('VIEW', one.job_id, (one.successCount || 0) + (one.failureCount || 0));
            //       }}
            //     >
            //       <BiSearch />
            //     </ButtonTag>
            //     <ButtonTag
            //       color="white"
            //       size="small"
            //       variant="outlined"
            //       width={40}
            //       borderRadius={5}
            //       onClick={() => {
            //         jobDetailEvent('EXCEL', one.job_id, (one.successCount || 0) + (one.failureCount || 0));
            //       }}
            //     >
            //       <RiFileExcel2Line />
            //     </ButtonTag>
            //   </Grid>
            // </>,
          ])}
        />
        <Grid top={30} />
        <Pagination nowPage={nowPage} max={max} size={5} nowPageEvent={setNowPage} />
      </Grid>
    </Container>
  );
}
