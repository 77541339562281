import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children?: ReactNode;

  // 텍스트 정렬
  textAlign?: 'center' | 'left';

  // 텍스트 크기
  fontSize?: string;

  // 텍스트 굵기
  fontWeight?: number;

  // 텍스트 굵기
  lineHeight?: number;

  // 텍스트 색상
  color?: 'wakeup' | 'main' | string;

  // 언더라인 여부
  underline?: boolean;

  // 인라인 여부
  inline?: boolean;

  // 클릭 이벤트
  onClick?: MouseEventHandler;

  // 줄바꿈 속성
  whiteSpace?: 'pre' | 'pre-wrap';

  // 커서 속성
  cursor?: 'pointer';

  //
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

const Text = styled.span<IProps>`
  // 텍스트 정렬
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}

  // 텍스트 크기
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : '')}

  // 텍스트 굵기
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : '')}

  // 텍스트 색상
  ${({ color, theme }) => (color ? `color: ${color === 'wakeup' ? theme.wakeUpColor : color === 'main' ? theme.pltoColor : color};` : '')}

  // 언더라인 여부
  ${({ underline }) => (underline ? 'text-decoration: underline;' : '')}

  // 인라인 여부
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};

  // 높이
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight}px;` : '')}

  // 줄바꿈 속성
  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace};` : '')}

  // 커서 속성
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}
`;

export default Text;
