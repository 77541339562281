import { IMemberProps } from 'common/interface/props';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function SellerType({ solType }: IMemberProps) {
  const { sellerType } = useParams<{ sellerType: string }>();

  const navigate = useNavigate(); // 히스토리 구하기

  useEffect(() => {
    // 쿠팡 유입경로별 상품등록 시 사용 할 데이터 저장
    sessionStorage.setItem('sellerType', sellerType === 'Retail' ? 'Retail' : '3P');

    // 로그인 페이지로 이동
    navigate('/coupang/member/login');
  }, []);
  return <></>;
}
