import * as React from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { ADMIN, ALWAYZ, COUPANG, KAKAO, ST11, ESM, ALL, WEMAKEPRICE, TOSS } from './common/common.constants';

//쿠팡
import CoupangCreateAccount from 'components/pages/coupang/member/create-account';
import CoupangLogin from 'components/pages/coupang/member/login';
import CoupangShopLogin from 'components/pages/coupang/job/shop-login';
import CoupangShopLoginProdCompare from 'components/pages/coupang/job/shop-login-prod-compare';
import CoupangWakeUpLogin from 'components/pages/coupang/job/wakeup-login';
import CoupangWakeUpLoginProdCompare from 'components/pages/coupang/job/wakeup-login-prod-compare';
import CoupangScrapProd from 'components/pages/coupang/job/scrap-prod';
import CoupangProgress from 'components/pages/coupang/job/progress';

//위메프
import WeMakePriceCreateAccount from 'components/pages/wemakeprice/member/CreateAccount';
import WeMakePriceLogin from 'components/pages/wemakeprice/member/Login';
import WeMakePriceShopLogin from 'components/pages/wemakeprice/job/ShopLogin';
import WeMakePriceWakeUpLogin from 'components/pages/wemakeprice/job/WakeUpLogin';
import WeMakePriceScrapProd from 'components/pages/wemakeprice/job/ScrapProd';
import WeMakePriceProgress from 'components/pages/wemakeprice/job/Progress';

//11번가
import St11CreateAccount from 'components/pages/st11/member/CreateAccount';
import St11Login from 'components/pages/st11/member/Login';
import St11ShopLogin from 'components/pages/st11/job/ShopLogin';
import St11WakeUpLogin from 'components/pages/st11/job/WakeUpLogin';
import St11ScrapProd from 'components/pages/st11/job/ScrapProd';
import St11Progress from 'components/pages/st11/job/Progress';

//카카오
import KakaoCreactAccount from 'components/pages/kakao/member/CreateAccount';
import KakaoLogin from 'components/pages/kakao/member/Login';
import KakaoShopLogin from 'components/pages/kakao/job/ShopLogin';
import KakaoWakeUpLogin from 'components/pages/kakao/job/WakeUpLogin';
import KakaoScrapProd from 'components/pages/kakao/job/ScrapProd';
import KakaoProgress from 'components/pages/kakao/job/Progress';

//올웨이즈
import AlwayzCreateAccount from 'components/pages/alwayz/member/CreateAccount';
import AlwayzLogin from 'components/pages/alwayz/member/Login';
import AlwayzShopLogin from 'components/pages/alwayz/job/ShopLogin';
import AlwayzWakeUpLogin from 'components/pages/alwayz/job/WakeUpLogin';
import AlwayzScrapProd from 'components/pages/alwayz/job/ScrapProd';
import AlwayzProgress from 'components/pages/alwayz/job/Progress';

//ESM
import ESMCreateAccount from 'components/pages/esm/member/CreateAccount';
import ESMLogin from 'components/pages/esm/member/Login';
import ESMShopLogin from 'components/pages/esm/job/ShopLogin';
import ESMWakeUpLogin from 'components/pages/esm/job/WakeUpLogin';
import ESMScrapProd from 'components/pages/esm/job/ScrapProd';
import ESMProgress from 'components/pages/esm/job/Progress';

//TOSS
import TossCreateAccount from 'components/pages/toss/member/CreateAccount';
import TossLogin from 'components/pages/toss/member/Login';
import TossShopLogin from 'components/pages/toss/job/ShopLogin';
import TossWakeUpLogin from 'components/pages/toss/job/WakeUpLogin';
import TossScrapProd from 'components/pages/toss/job/ScrapProd';
import TossProgress from 'components/pages/toss/job/Progress';

//웨이크업 통합(2023 프로젝트)
import AllGuide from 'components/pages/all/job/guide';
import AllCreateAccount from 'components/pages/coupang/member/create-account';
import AllLogin from 'components/pages/all/member/login';
import AllShopLoign from 'components/pages/all/job/shop-login';
import AllWakeUpLogin from 'components/pages/all/job/wakeup-login';
import AllScrapProd from 'components/pages/all/job/scrap-prod';
import AllProgress from 'components/pages/all/job/progress';

//공통
import ResetPassword from 'components/pages/wemakeprice/member/ResetPassword';
import NotFound from 'components/pages/notfound';
import Guide from 'components/pages/Guide';

import Footer from 'components/UI/organisms/Footer';
import Header from 'components/UI/organisms/Header';
import LoginCheck from 'components/UI/atoms/LoginCheck';
import Admin from 'components/admin/index';

import LoginAfterChoice from 'components/pages/LoginAfterChoice';
import GMPIntroduction from 'components/pages/GMPIntroduction';
import GMPAdminLogin from 'components/pages/GMPAdminLogin';
import SellerType from 'components/pages/coupang/member/seller-type';
import Grid from 'components/UI/atoms/Grid';
import Home from 'components/pages/Home';
import TokenLogin from 'components/pages/alwayz/member/TokenLogin';
import MainLayout from 'components/UI/template/MainLayout';
import JokerTest from 'components/pages/JokerTest';

//hash router google.com/#hello
//switch -> 오직 한 route만 import 할 수 있게 해주는 장치

//페이지 전환시 scroll 항상 탑으로 이동시키기 위해 필요함
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function ReactRouter() {
  return true ? (
    <Router>
      <ScrollToTop />
      <Grid>
        <Routes>
          {/* 점검페이지 */}
          {/* <Route path="*">
            <Notice />
          </Route> */}

          {/* 명시 된 경로 외 페이지 */}
          <Route path="/*" element={<NotFound />} />

          {/* 2.0 솔루션에서 로그인 하는 페이지 */}
          <Route path="/token-login" element={<TokenLogin />}></Route>

          {/* 조커 테스트용 페이지  */}
          <Route path="/joker-test" element={<JokerTest />}></Route>

          {/* 통합 어드민 페이지 */}
          <Route
            path="admin"
            element={
              <>
                <LoginCheck solType={ADMIN} requiredLogin={false} />
                <Admin isIntegrated={true} />
              </>
            }
          />

          <Route element={<MainLayout />}>
            {/* dev용 홈 페이지 */}
            {process.env.REACT_APP_DEV === 'true' && <Route path="/" element={<Home />} />}

            {/* 공용 */}
            <Route path="/guide/:target" element={<Guide />} />

            <Route path="/GMPIntroduction" element={<GMPIntroduction />} />

            <Route path="/GMPAdminLogin" element={<GMPAdminLogin />} />

            {/* 어드민 페이지 */}
            <Route path="/:solType">
              <Route
                path="admin"
                element={
                  <>
                    <LoginCheck solType={ADMIN} requiredLogin={false} />
                    <Admin isIntegrated={false} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 웨이크업 통합(2023 프로젝트) */}
          {/* 웨이크업 ALL Type은 2.0 솔루션을 통해서만 들어오므로, 로그인 및 회원가입 URL 주석 */}
          <Route path="/all">
            <Route path="member">
              <Route element={<MainLayout />}>
                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={false} />
                      <AllCreateAccount solType={ALL} />
                    </>
                  }
                />
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={false} />
                      <AllLogin solType={ALL} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="guide"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={false} />
                      <AllGuide solType={ALL} />
                    </>
                  }
                />
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={true} />
                      <AllShopLoign solType={ALL} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={true} />
                      <AllWakeUpLogin solType={ALL} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={true} />
                      <AllScrapProd solType={ALL} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={ALL} requiredLogin={true} />
                      <AllProgress solType={ALL} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* 쿠팡 웨이크업 */}
          <Route path="/coupang">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* 쿠팡 유입경로별 데이터 세팅 */}
                <Route path="sellerType/:sellerType" element={<SellerType solType={COUPANG} />} />

                {/* 쿠팡 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={COUPANG} />} />

                {/* 쿠팡 회원가입 */}
                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={false} />
                      <CoupangCreateAccount solType={COUPANG} />
                    </>
                  }
                />
                {/* 쿠팡 로그인 */}
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={false} />
                      <CoupangLogin solType={COUPANG} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangShopLogin solType={COUPANG} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangWakeUpLogin solType={COUPANG} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangScrapProd solType={COUPANG} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangProgress solType={COUPANG} />
                    </>
                  }
                />
              </Route>
            </Route>

            {/* 쿠팡 로그인 후 선택 페이지 */}
            <Route element={<MainLayout />}>
              <Route path="LoginAfterChoice" element={<LoginAfterChoice solType={COUPANG} />} />
            </Route>
          </Route>

          {/* 위메프 웨이크업 */}
          <Route path="/wemakeprice">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* 위메프 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={WEMAKEPRICE} />} />

                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={WEMAKEPRICE} requiredLogin={false} />
                      <WeMakePriceCreateAccount solType={WEMAKEPRICE} />
                    </>
                  }
                />
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={WEMAKEPRICE} requiredLogin={false} />
                      <WeMakePriceLogin solType={WEMAKEPRICE} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={WEMAKEPRICE} requiredLogin={true} />
                      <WeMakePriceShopLogin solType={WEMAKEPRICE} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={WEMAKEPRICE} requiredLogin={true} />
                      <WeMakePriceWakeUpLogin solType={WEMAKEPRICE} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={WEMAKEPRICE} requiredLogin={true} />
                      <WeMakePriceScrapProd solType={WEMAKEPRICE} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={WEMAKEPRICE} requiredLogin={true} />
                      <WeMakePriceProgress solType={WEMAKEPRICE} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* 11번가 웨이크업 */}
          <Route path="/st11">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* 11번가 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={ST11} />} />

                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={ST11} requiredLogin={false} />
                      <St11CreateAccount solType={ST11} />
                    </>
                  }
                />
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={ST11} requiredLogin={false} />
                      <St11Login solType={ST11} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={ST11} requiredLogin={true} />
                      <St11ShopLogin solType={ST11} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={ST11} requiredLogin={true} />
                      <St11WakeUpLogin solType={ST11} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={ST11} requiredLogin={true} />
                      <St11ScrapProd solType={ST11} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={ST11} requiredLogin={true} />
                      <St11Progress solType={ST11} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* 카카오 웨이크업 */}
          <Route path="/kakao">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* 카카오 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={KAKAO} />} />

                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={KAKAO} requiredLogin={false} />
                      <KakaoCreactAccount solType={KAKAO} />
                    </>
                  }
                />
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={KAKAO} requiredLogin={false} />
                      <KakaoLogin solType={KAKAO} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={KAKAO} requiredLogin={true} />
                      <KakaoShopLogin solType={KAKAO} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={KAKAO} requiredLogin={true} />
                      <KakaoWakeUpLogin solType={KAKAO} />
                    </>
                  }
                />

                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={KAKAO} requiredLogin={true} />
                      <KakaoScrapProd solType={KAKAO} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={KAKAO} requiredLogin={true} />
                      <KakaoProgress solType={KAKAO} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* 올웨이즈 웨이크업 */}
          <Route path="/alwayz">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* 올웨이즈 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={ALWAYZ} />} />

                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={ALWAYZ} requiredLogin={false} />
                      <AlwayzCreateAccount solType={ALWAYZ} />
                    </>
                  }
                />
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={ALWAYZ} requiredLogin={false} />
                      <AlwayzLogin solType={ALWAYZ} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={ALWAYZ} requiredLogin={true} />
                      <AlwayzShopLogin solType={ALWAYZ} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={ALWAYZ} requiredLogin={true} />
                      <AlwayzWakeUpLogin solType={ALWAYZ} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={ALWAYZ} requiredLogin={true} />
                      <AlwayzScrapProd solType={ALWAYZ} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={ALWAYZ} requiredLogin={true} />
                      <AlwayzProgress solType={ALWAYZ} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* ESM+ 웨이크업 */}
          <Route path="/esm">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* ESM+ 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={ESM} />} />

                {/* ESM+ 회원가입 */}
                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={ESM} requiredLogin={false} />
                      <ESMCreateAccount solType={ESM} />
                    </>
                  }
                />

                {/* ESM+ 로그인 */}
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={ESM} requiredLogin={false} />
                      <ESMLogin solType={ESM} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={ESM} requiredLogin={true} />
                      <ESMShopLogin solType={ESM} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={ESM} requiredLogin={true} />
                      <ESMWakeUpLogin solType={ESM} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={ESM} requiredLogin={true} />
                      <ESMScrapProd solType={ESM} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={ESM} requiredLogin={true} />
                      <ESMProgress solType={ESM} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* TOSS 토스 웨이크업 */}
          <Route path="/toss">
            <Route path="member">
              <Route element={<MainLayout />}>
                <Route path="ResetPassword" element={<ResetPassword solType={TOSS} />} />

                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={TOSS} requiredLogin={false} />
                      <TossCreateAccount solType={TOSS} />
                    </>
                  }
                />

                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={TOSS} requiredLogin={false} />
                      <TossLogin solType={TOSS} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={TOSS} requiredLogin={true} />
                      <TossShopLogin solType={TOSS} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={TOSS} requiredLogin={true} />
                      <TossWakeUpLogin solType={TOSS} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={TOSS} requiredLogin={true} />
                      <TossScrapProd solType={TOSS} />
                    </>
                  }
                />
                <Route
                  path=":jobId"
                  element={
                    <>
                      <LoginCheck solType={TOSS} requiredLogin={true} />
                      <TossProgress solType={TOSS} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* 쿠팡 상품비교 */}
          <Route path="/coupang-prod-compare">
            <Route path="member">
              <Route element={<MainLayout />}>
                {/* 쿠팡 유입경로별 데이터 세팅 */}
                <Route path="sellerType/:sellerType" element={<SellerType solType={COUPANG} />} />

                {/* 쿠팡 비밀번호 초기화 */}
                <Route path="ResetPassword" element={<ResetPassword solType={COUPANG} />} />

                {/* 쿠팡 회원가입 */}
                <Route
                  path="create-account"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={false} />
                      <CoupangCreateAccount solType={COUPANG} />
                    </>
                  }
                />

                {/* 쿠팡 로그인 */}
                <Route
                  path="login"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={false} />
                      <CoupangLogin solType={COUPANG} />
                    </>
                  }
                />
              </Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route path="job">
                <Route
                  path="shop-login"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangShopLoginProdCompare solType={COUPANG} />
                    </>
                  }
                />
                <Route
                  path="wakeup-login"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangWakeUpLoginProdCompare solType={COUPANG} />
                    </>
                  }
                />
                <Route
                  path="scrap-prod"
                  element={
                    <>
                      <LoginCheck solType={COUPANG} requiredLogin={true} />
                      <CoupangScrapProd solType={COUPANG} />
                    </>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Grid>
    </Router>
  ) : (
    // 헤더,푸터 포함
    //리엑트는 원래 URL 입력시 에러난다.(LINK로 라우터 연결이 아니고 직접입력시), 따라서 AWS S3 웹이미지 호스팅 오류문서에 index.html 똑같이 적어줘야함
    <Router>
      <ScrollToTop />

      <Header />

      <Grid top={80}>
        <Routes>
          {/* 점검페이지 */}
          {/* <Route path="*">
            <Notice />
          </Route> */}

          {/* 명시 된 경로 외 페이지 */}
          <Route path="/*" element={<NotFound />} />

          {/* dev용 홈 페이지 */}
          {process.env.REACT_APP_DEV === 'true' && <Route path="/" element={<Home />} />}

          {/* 어드민 페이지 */}
          <Route path="/:solType">
            <Route
              path="admin"
              element={
                <>
                  <LoginCheck solType={ADMIN} requiredLogin={false} />
                  <Admin isIntegrated={false} />
                </>
              }
            />
          </Route>

          {/* 2.0 솔루션에서 로그인 하는 페이지 */}
          <Route
            path="/token-login"
            element={
              <>
                <TokenLogin />
              </>
            }
          ></Route>

          {/* 웨이크업 통합(2023 프로젝트) */}
          <Route path="/all">
            <Route path="member">
              <Route
                path="create-account"
                element={
                  <>
                    <LoginCheck solType={ALL} requiredLogin={false} />
                    <AllCreateAccount solType={ALL} />
                  </>
                }
              />
              <Route
                path="login"
                element={
                  <>
                    <LoginCheck solType={ALL} requiredLogin={false} />
                    <AllLogin solType={ALL} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 쿠팡 웨이크업 */}
          <Route path="/coupang">
            {/* 쿠팡 로그인 후 선택 페이지 */}
            <Route path="LoginAfterChoice" element={<LoginAfterChoice solType={COUPANG} />} />

            <Route path="member">
              {/* 쿠팡 유입경로별 데이터 세팅 */}
              <Route path="sellerType/:sellerType" element={<SellerType solType={COUPANG} />} />

              {/* 쿠팡 비밀번호 초기화 */}
              <Route path="ResetPassword" element={<ResetPassword solType={COUPANG} />} />

              {/* 쿠팡 회원가입 */}
              <Route
                path="create-account"
                element={
                  <>
                    <LoginCheck solType={COUPANG} requiredLogin={false} />
                    <CoupangCreateAccount solType={COUPANG} />
                  </>
                }
              />
              {/* 쿠팡 로그인 */}
              <Route
                path="login"
                element={
                  <>
                    <LoginCheck solType={COUPANG} requiredLogin={false} />
                    <CoupangLogin solType={COUPANG} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 위메프 웨이크업 */}
          <Route path="/wemakeprice">
            <Route path="member">
              {/* 위메프 비밀번호 초기화 */}
              <Route path="ResetPassword" element={<ResetPassword solType={WEMAKEPRICE} />} />

              <Route
                path="create-account"
                element={
                  <>
                    <LoginCheck solType={WEMAKEPRICE} requiredLogin={false} />
                    <WeMakePriceCreateAccount solType={WEMAKEPRICE} />
                  </>
                }
              />
              <Route
                path="login"
                element={
                  <>
                    <LoginCheck solType={WEMAKEPRICE} requiredLogin={false} />
                    <WeMakePriceLogin solType={WEMAKEPRICE} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 11번가 웨이크업 */}
          <Route path="/st11">
            <Route path="member">
              {/* 11번가 비밀번호 초기화 */}
              <Route path="ResetPassword" element={<ResetPassword solType={ST11} />} />

              <Route
                path="create-account"
                element={
                  <>
                    <LoginCheck solType={ST11} requiredLogin={false} />
                    <St11CreateAccount solType={ST11} />
                  </>
                }
              />
              <Route
                path="login"
                element={
                  <>
                    <LoginCheck solType={ST11} requiredLogin={false} />
                    <St11Login solType={ST11} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 카카오 웨이크업 */}
          <Route path="/kakao">
            <Route path="member">
              {/* 카카오 비밀번호 초기화 */}
              <Route path="ResetPassword" element={<ResetPassword solType={KAKAO} />} />

              <Route
                path="create-account"
                element={
                  <>
                    <LoginCheck solType={ST11} requiredLogin={false} />
                    <KakaoCreactAccount solType={ST11} />
                  </>
                }
              />
              <Route
                path="login"
                element={
                  <>
                    <LoginCheck solType={ST11} requiredLogin={false} />
                    <KakaoLogin solType={ST11} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 올웨이즈 웨이크업 */}
          <Route path="/alwayz">
            <Route path="member">
              {/* 올웨이즈 비밀번호 초기화 */}
              <Route path="ResetPassword" element={<ResetPassword solType={ALWAYZ} />} />

              <Route
                path="create-account"
                element={
                  <>
                    <LoginCheck solType={ALWAYZ} requiredLogin={false} />
                    <AlwayzCreateAccount solType={ALWAYZ} />
                  </>
                }
              />
              <Route
                path="login"
                element={
                  <>
                    <LoginCheck solType={ALWAYZ} requiredLogin={false} />
                    <AlwayzLogin solType={ALWAYZ} />
                  </>
                }
              />
            </Route>
          </Route>

          {/* 공용 */}
          <Route path="/guide/:target" element={<Guide />} />

          <Route path="/GMPIntroduction" element={<GMPIntroduction />} />

          <Route path="/GMPAdminLogin" element={<GMPAdminLogin />} />
        </Routes>
      </Grid>

      <Footer />
    </Router>
  );
}
