// npm
import React from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';

// atomic components

import FormWrap from 'components/UI/molecules/FormWrap';
import InputWrap from 'components/UI/molecules/InputWrap';
import Grid from 'components/UI/atoms/Grid';
import Button from 'components/UI/atoms/Button';
import TextField from 'components/UI/atoms/TextField';
import Select from 'components/UI/atoms/Select';
import Text from 'components/UI/atoms/Text';
import Tooltip from 'components/UI/atoms/Tooltip';
import ChannelIOButton from 'components/UI/organisms/ChannelIOButton';

// common
import { IUserInfo, ShopCode, WakeUp } from 'common/interface/props';
import { axiosCustom } from 'common/common.function';

// etc
import { shopLoginRequest } from 'redux_modules/shop-login';
import { ACCOUNT_AUTO_SETTING_ADMIN_USER, SHOP_FORM_DATA as SHOP_FORM_DATA_ORIGIN, TargetSolTypeRole } from 'common/common.constants';
import { FormError } from 'components/form/form-error';
import { useNavigate } from 'react-router-dom';
import { wakeupLoginRequest } from 'redux_modules/wakeup-login';
interface IProps {
  solType: WakeUp;
  isProdCompare?: boolean;
}

function Container({ solType, isProdCompare = false }: IProps) {
  const {
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm();

  const SHOP_FORM_DATA = isProdCompare
    ? Object.fromEntries(Object.entries(SHOP_FORM_DATA_ORIGIN).filter(([shop, key]) => key.prodCompareUse === true))
    : SHOP_FORM_DATA_ORIGIN;

  // 쇼핑몰 코드 변경 감지
  const shopCode: ShopCode = watch('shopCode', 'A077');

  // 쇼핑몰 코드 정보
  const shopCodeList = Object.entries(SHOP_FORM_DATA)
    .filter(([key, value]) => (value.use as WakeUp[]).includes(solType))
    .map(([key, value]) => [key, value.name]);

  // 쇼핑몰 정보
  const shopFormList = SHOP_FORM_DATA[shopCode];

  const navigate = useNavigate(); // 히스토리 구하기

  const dispatch = useDispatch();

  const token = sessionStorage.getItem('wakeupInfo');
  const jsonToken = JSON.parse(token!);

  // 유저 정보
  const rawUserInfo = sessionStorage.getItem('userInfo');
  const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);
  const isAccountAutoSettingAdmin = ACCOUNT_AUTO_SETTING_ADMIN_USER.find((v) => v === userInfo?.email);

  /**
   * 작업 끝난 후 navigate로 shop-login으로 이동 시켜 주면
   * redux 정보가 초기화가 안되니 초기화 한 번 시켜줌
   */
  React.useEffect(() => {
    dispatch(shopLoginRequest(null));
    dispatch(
      wakeupLoginRequest({
        COUPANG: {
          wakeupInfo: null,
        },
        WEMAKEPRICE: {
          wakeupInfo: null,
        },
        ALWAYZ: {
          wakeupInfo: null,
        },
        ST11: {
          wakeupInfo: null,
        },
        KAKAO: {
          wakeupInfo: null,
        },
        ALL: {
          wakeupInfo: null,
        },
        ADMIN: {
          wakeupInfo: null,
        },
        ESM: {
          wakeupInfo: null,
        },
        GMARKET: {
          wakeupInfo: null,
        },
        AUCTION: {
          wakeupInfo: null,
        },
        TOSS: {
          wakeupInfo: null,
        },
      }),
    );
  }, []);

  // 쇼핑몰 로그인 정보 전송 함수
  async function onSubmit(data: any) {
    // 무한 로딩 화면 생성
    Swal.fire({
      title: `쇼핑몰 정보 유효성 검사중...`,
      html: '유효성 검사중 ...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    // 공백제거
    Object.keys(data).map((key: string) => {
      data[key] = data[key].trim();
    });

    const reqData = {
      scrapShopCode: data.shopCode,
      scrapShopId: data.shopId,
      scrapShopPw: data.shopPw,
      scrapShopEtc1: data.shopEtc1,
      scrapShopEtc2: data.shopEtc2,
      scrapShopEtc3: data.shopEtc3,
      scrapShopEtc4: data.shopEtc4,
      scrapShopEtc5: data.shopEtc5,
      scrapShopEtc6: data.shopEtc6,
      solType,
      targetSolType: 'LOGIN',
      token: jsonToken.gmpToken,
      isProdCompare: !!isProdCompare,
      wakeupDummy3: solType === TargetSolTypeRole.KAKAO && shopCode === 'A027' ? 'KAKAO' : '',
    };

    // 쇼핑몰 로그인 실행
    const jobId = await shopLoginSend();
    // 쇼핑몰 로그인 소켓
    jobId && shopLoginSocket();

    // 쇼핑몰 로그인 실행
    async function shopLoginSend() {
      try {
        //쇼핑몰 로그인 통신
        const { jobId } = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/shop-login`, reqData, {});
        return jobId;
      } catch (error: any) {
        Swal.fire('오류', error.message, 'error');
        return false;
      }
    }

    // 쇼핑몰 로그인 소켓
    function shopLoginSocket() {
      //  소켓 연결
      const sockets = io(`${process.env.REACT_APP_BACKEND_ENDPOINT}`, {
        query: { jobId: jobId },
        transports: ['websocket'],
      });
      //  완료
      sockets.on('shopLogin', async (payload: any) => {
        // 결과 응답일 경우
        if (payload.result) {
          dispatch(
            shopLoginRequest({
              shopInfo: {
                ...reqData,
                cookie: payload?.cookie,
              },
            }),
          );

          Swal.close();

          navigate(`/${solType.toLowerCase()}${isProdCompare ? '-prod-compare' : ''}/job/wakeup-login`);
        } else {
          await Swal.fire({
            title: '실패',
            html: payload.message,
            icon: 'error',
            confirmButtonText: '확인',
          });
        }
      });
    }
  }
  return (
    <>
      {shopCode && (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormWrap>
              <FormWrap.Top>
                <Grid top={10}>
                  <InputWrap
                    label={
                      shopFormList.toolTip ? (
                        <>
                          사이트 선택{' '}
                          <Tooltip title={shopFormList.toolTip}>
                            <Grid color="#e9ecef" left={5} right={5}>
                              ?
                            </Grid>
                          </Tooltip>
                        </>
                      ) : (
                        '사이트 선택'
                      )
                    }
                    labelWidth={160}
                    input={
                      <Select
                        {...register('shopCode', {
                          setValueAs: (data) => {
                            unregister &&
                              data !== shopCode &&
                              unregister(['shopId', 'shopPw', 'shopEtc1', 'shopEtc2', 'shopEtc3', 'shopEtc4', 'shopEtc5', 'shopEtc6']);

                            return data;
                          },
                        })}
                      >
                        {shopCodeList.map((one: any, index: any) => (
                          <option value={one[0]} key={index}>
                            {one[1]}
                          </option>
                        ))}
                      </Select>
                    }
                  />
                </Grid>
                {shopFormList.form.map((one: any, index: any) => (
                  <Grid top={50} key={index} hidden={one.hidden}>
                    {shopCode === 'A077' ? (
                      <InputWrap
                        key={index}
                        label={`${one.name}`}
                        inputNumber={index}
                        labelWidth={160}
                        smartStore={true}
                        input={
                          <TextField
                            type="text"
                            placeholder={`${one.name}를 입력해 주세요`}
                            defaultValue={one.defaultValue || (isAccountAutoSettingAdmin ? one.adminDefaultValue : '')}
                            {...register(one.key, one.validation)}
                          />
                        }
                        error={errors && errors[one.key]?.type === 'required' && <FormError errorMessage={'필수 입력 항목입니다.'} />}
                        adornment={one.adornment || <></>}
                      />
                    ) : (
                      <InputWrap
                        key={index}
                        label={`${one.name}`}
                        labelWidth={160}
                        input={
                          <TextField
                            type={one.type || 'text'}
                            placeholder={`${one.name} 를 입력해 주세요`}
                            defaultValue={one.defaultValue || (isAccountAutoSettingAdmin ? one.adminDefaultValue : '')}
                            {...register(one.key, one.validation)}
                          />
                        }
                        error={errors && errors[one.key]?.type === 'required' && <FormError errorMessage={'필수 입력 항목입니다.'} />}
                        adornment={one.adornment || <></>}
                      />
                    )}
                  </Grid>
                ))}
              </FormWrap.Top>
              <Grid top={20} bottom={20}>
                {solType === TargetSolTypeRole.KAKAO && shopCode === 'A027' ? (
                  // 카카오에서만 인터파크-오픈마켓 상품 정보를 구 어드민에서만이 아닌 신 어드민에서도 조회 하도록 추가했기때문에 로그인에 필요한 정보가 추가 됨
                  <Text fontWeight={500} fontSize="0.88rem" dangerouslySetInnerHTML={{ __html: `※ ${shopFormList.notices[1]}` }} key={0}></Text>
                ) : shopCode === 'A027' ? (
                  // 카카오에서만 인터파크-오픈마켓 상품 정보를 구 어드민에서만이 아닌 신 어드민에서도 조회 하도록 추가했기때문에 로그인에 필요한 정보가 추가 됨
                  <Text fontWeight={500} fontSize="0.88rem" dangerouslySetInnerHTML={{ __html: `※ ${shopFormList.notices[0]}` }} key={0}></Text>
                ) : (
                  shopFormList.notices?.map((one: any, index: any) => (
                    <Text fontWeight={500} fontSize="0.88rem" dangerouslySetInnerHTML={{ __html: `※ ${one}` }} key={index}></Text>
                  ))
                )}
              </Grid>
              <Grid display="flex" justifyContent="center">
                <Button size="medium" width={200} color="main">
                  다음
                </Button>
              </Grid>
            </FormWrap>
          </form>
          <ChannelIOButton solType={solType} />
        </>
      )}
    </>
  );
}

export default Container;
