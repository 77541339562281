import Button from '../components/UI/atoms/Button';
import Grid from '../components/UI/atoms/Grid';
import Text from '../components/UI/atoms/Text';
import { IUserInfo, WakeUp } from './interface/props';

/**
 * 등록할 쇼핑몰 솔루션 타입 규칙
 */
export enum TargetSolTypeRole {
  COUPANG = 'COUPANG',
  WEMAKEPRICE = 'WEMAKEPRICE',
  ST11 = 'ST11',
  KAKAO = 'KAKAO',
  ALWAYZ = `ALWAYZ`,
  ESM = `ESM`,
  GMARKET = `GMARKET`,
  AUCTION = `AUCTION`,
  TOSS = `TOSS`,
}

/**
 * 등록할 쇼핑몰 솔루션 타입 규칙
 */
export enum ScrapType {
  NORMAL = 'NORMAL',
  SPECIFIC = 'SPECIFIC',
}

// 2.0 솔루션과 연동 된 solType 리스트
export const intergrationGMPSolTypeList: WakeUp[] = ['COUPANG', 'WEMAKEPRICE', 'ST11', 'ALWAYZ', 'KAKAO', 'ESM', 'TOSS', 'ALL'];

/**
 * 구독 종류
 */
export enum SubscriptionType {
  SUBSCRIBED = 'SUBSCRIBED', // 구독함
  UNSUBSCRIBED = 'UNSUBSCRIBED', // 구독해지함
  NONE = 'NONE', // 아무것도안함
}

/**
 * 이메일 형식 체크 정규식
 */
export const regexEmail = /^([0-9a-zA-Z_\\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
/**
 * 비밀번호 체크 정규식 (영문+숫자+특문조합 8자 이상)
 */
export const regexPassword = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=\-,\\.<>/\\?~\\(\\)\\_\\[\]\\{\\}\\|;:])(?=.*[0-9]).{8,}$/;
/**
 * 전화번호 체크 정규식
 */
export const regexTel = /^[0-9]+$/;

// 어드민 계정 정보
export const ADMIN_USER: string[] = ['pawn_admin@playauto.co.kr', 'sue.playauto@gmail.com', 'xlvllvlx@naver.com'];

// 계정정보 자동입력 어드민 계정 정보
export const ACCOUNT_AUTO_SETTING_ADMIN_USER: string[] = ['pawn_admin@playauto.co.kr', 'sue.playauto@gmail.com', 'xlvllvlx@naver.com'];

// 상품비교 테스트 계정 정보
export const PROD_COMPARE_USER: string[] = ['pawn_admin@playauto.co.kr', 'sue.playauto@gmail.com', 'xlvllvlx@naver.com'];

// 상품유사도설정 테스트 계정 정보
export const PROD_SIMILARITY_USER: string[] = ['pawn_admin@playauto.co.kr', 'sue.playauto@gmail.com', 'xlvllvlx@naver.com'];

/**
 * 현재 solType이 2.0 솔루션과 연동된 solType인지 체크하는 함수
 */
export const checkIntergrationGMP = (solType: WakeUp): Boolean => {
  try {
    return intergrationGMPSolTypeList.includes(solType);
  } catch {
    return false;
  }
};

// wakeup.backend3(spring) 과 연동된 solType 리스트
export const springSolTypeList: WakeUp[] = ['COUPANG', 'WEMAKEPRICE', 'ST11', 'ALWAYZ', 'KAKAO', 'ESM'];

/**
 * 현재 solType이 wakeup.backend3(spring) 과 연동된 solType 인지 체크하는 함수
 */
export const checkUseSpringSolType = (solType: WakeUp): Boolean => {
  try {
    return springSolTypeList.includes(solType);
  } catch {
    return false;
  }
};

/**
 * 사업자 번호 체크 함수
 */
export const checkingBizNo = (bizNo: any) => {
  const checkID: number[] = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
  let i,
    chkSum = 0,
    c2: any;
  bizNo = bizNo.replace(/-/gi, '');
  for (i = 0; i <= 7; i++) {
    chkSum += checkID[i] * bizNo.charAt(i);
  }
  c2 = `0${checkID[8] * bizNo.charAt(8)}`;
  c2 = c2.substring(c2.length - 2, c2.length);

  chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
  const remander = (10 - (chkSum % 10)) % 10;
  // Math.floor(bizNo.charAt(9)) 와 remander 가 둘다 0 인경우 유효하지 않은 사업자번호로 처리 2018-04-18 rony
  let totalChkVal = Math.floor(bizNo.charAt(9)) === remander && !(Math.floor(bizNo.charAt(9)) === 0 && remander === 0);
  if (bizNo.length === 10 && bizNo !== '0000000000') {
    totalChkVal = Math.floor(bizNo.charAt(9)) === remander;
  }
  if (totalChkVal) {
    return true; // OK!
  } else {
    return false;
  }
};

export const WEMAKEPRICE: WakeUp = 'WEMAKEPRICE';
export const COUPANG: WakeUp = 'COUPANG';
export const ST11: WakeUp = 'ST11';
export const ADMIN: WakeUp = `ADMIN`;
export const KAKAO: WakeUp = `KAKAO`;
export const ALWAYZ: WakeUp = 'ALWAYZ';
export const ESM: WakeUp = 'ESM';
export const TOSS: WakeUp = 'TOSS';
export const ALL: WakeUp = 'ALL';

export const WAKEUP: WakeUp[] = ['WEMAKEPRICE', 'COUPANG', 'ADMIN', 'ST11', 'KAKAO', 'ALWAYZ', 'ESM', 'TOSS', 'ALL'];

// 쇼핑몰 이름 변환
export function convertShopName(name: TargetSolTypeRole | WakeUp) {
  switch (name) {
    case 'ALL':
      return '웨이크업 통합';
    case 'COUPANG':
      return '쿠팡';
    case 'ST11':
      return '11번가';
    case 'WEMAKEPRICE':
      return '위메프';
    case 'ALWAYZ':
      return '올웨이즈';
    case 'KAKAO':
      return '카카오톡 스토어';
    case 'ESM':
      return '옥션&G마켓';
    case 'ESM_ALL':
      return 'ESM 전체';
    case 'GMARKET':
      return '지마켓';
    case 'AUCTION':
      return '옥션';
    case 'TOSS':
      return '토스';
  }
}

// 쇼핑몰 폼 데이터
// 순서는 중요도순
export const SHOP_FORM_DATA = {
  A077: {
    use: [WEMAKEPRICE, COUPANG, ST11, KAKAO, ALWAYZ, ESM, TOSS, ALL],
    prodCompareUse: true,
    toolTip: '',
    name: '스마트스토어',
    notices: ['대행사 변경 이후 API 사용까지 시스템 상황에 따라 약 1시간 정도의 반영시간이 소요될 수 있습니다. '],
    form: [
      {
        key: 'shopId',
        name: '스마트스토어 ID',
        validation: { required: true },
        adornment: <></>,
        //defaultValue: 'dkim211@gmail.com',
        adminDefaultValue: 'okfactory1025@gmail.com',
      },
      {
        key: 'shopEtc4',
        name: 'API 연동용 판매자 ID',
        validation: { required: true },
        adornment: (
          <Grid display="flex" direction="column" width={150} alignItems="center">
            <a href="https://sell.smartstore.naver.com/#/sellers/store/detail/api" target="_blank" rel="noreferrer">
              <Button width={150} size="small" color="main" variant="outlined" type="button">
                <Text color="inherit" fontSize="0.77rem">
                  API 발급 바로가기
                </Text>
              </Button>
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/smartStore1`} target="_blank" rel="noreferrer">
              <Text fontSize="0.75rem" color="main" fontWeight={600}>
                API 발급방법 알아보기
              </Text>
            </a>
          </Grid>
        ),
        //defaultValue: 'ncp_1nxqed_01',
        adminDefaultValue: 'ncp_1o46vv_01',
      },
      {
        key: 'shopEtc2',
        name: '스토어 URL',
        validation: { required: true },
        adornment: (
          <Grid display="flex" direction="column" width={200} alignItems="center">
            <a href="https://sell.smartstore.naver.com/#/sellers/store/detail/smartstore" target="_blank" rel="noreferrer">
              <Button width={200} size="small" variant="outlined" color="main" type="button">
                <Text color="inherit" fontSize="0.77rem">
                  스토어 URL 확인 하러가기
                </Text>
              </Button>
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/smartStore2`} target="_blank" rel="noreferrer">
              <Text fontSize="0.75rem" color="main" fontWeight={600}>
                스토어 URL 입력방법 알아보기
              </Text>
            </a>
          </Grid>
        ),
        //defaultValue: 'dkim211',
        adminDefaultValue: 'okfactory1',
      },
    ],
  },
  B378: {
    use: [TOSS],
    prodCompareUse: false,
    name: '쿠팡',
    toolTip: '',
    notices: [],
    form: [
      {
        key: 'shopId',
        name: '쿠팡 Wing ID',
        validation: { required: true },
        type: 'text',
        //defaultValue: '',
        adminDefaultValue: 'okfactory1',
      },
      {
        key: 'shopEtc5',
        name: '쿠팡 업체코드',
        validation: { required: true },
        adornment: (
          <Grid display="flex" direction="column" width={210} alignItems="center">
            <a href="https://wing.coupang.com/tenants/wing-account/vendor/salesinfo?currentPlatform=DESKTOP&currentLocale=ko" target="_blank" rel="noreferrer">
              <Button width={200} size="small" color="main" variant="outlined" type="button">
                <Text color="inherit" fontSize="0.77rem">
                  OPEN API 발급 바로가기
                </Text>
              </Button>
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/coupang`} target="_blank" rel="noreferrer">
              <Text fontSize="0.75rem" color="main" fontWeight={600}>
                쿠팡 계정 정보 등록 가이드 바로가기
              </Text>
            </a>
          </Grid>
        ),
        //defaultValue: '',
        adminDefaultValue: 'A00331599',
      },
      {
        key: 'shopEtc1',
        name: '쿠팡 AccessKey',
        validation: { required: true },
        //defaultValue: '',
        adminDefaultValue: '70932eff-9e5e-4dff-9c1b-fafe9b2a7312',
      },
      {
        key: 'shopEtc2',
        name: '쿠팡 SecretKey',
        validation: { required: true },
        //defaultValue: '',
        adminDefaultValue: '0abdb15f26f297c8db568a79a3da6de7721eba03',
      },
    ],
  },

  // A006: {
  //   use: [WEMAKEPRICE, COUPANG, ST11, KAKAO, ALWAYZ, ALL],
  //   prodCompareUse: false,
  //   name: 'G마켓',
  //   toolTip: `'G마켓' 와 'G마켓2.0' 이란?

  //   ESM+ 사이트 자체적으로 모든 상품은 [일단상품]과 [2.0 상품]으로 구분 됩니다.
  //   상품을 구분하는 기준은 해당 사이트 자체적인 정책으로, 당사에서 정확한 차이점 안내는 불가하며
  //   사이트측 정책을 확인 부탁 드립니다.
  //   `,
  //   notices: [],
  //   form: [
  //     {
  //       key: 'shopId',
  //       name: 'G마켓 ID',
  //       validation: { required: true },
  //       adornment: <></>,
  //       // defaultValue: '',
  //     },
  //     {
  //       key: 'shopPw',
  //       name: 'G마켓 PW',
  //       type: 'password',
  //       validation: { required: true },
  //       adornment: <></>,
  //       // defaultValue: '',
  //     },
  //   ],
  // },
  A523: {
    use: [WEMAKEPRICE, COUPANG, ST11, KAKAO, ALWAYZ, TOSS, ALL],
    prodCompareUse: false,
    name: 'G마켓2.0',
    toolTip: `'G마켓' 와 'G마켓2.0' 이란?
    
    ESM+ 사이트 자체적으로 모든 상품은 [일단상품]과 [2.0 상품]으로 구분 됩니다.
    2024년 03월부로 G마켓은 2.0만 지원 가능합니다.
    상품을 구분하는 기준은 해당 사이트 자체적인 정책으로, 당사에서 정확한 차이점 안내는 불가하며
    사이트측 정책을 확인 부탁 드립니다.
    `,
    notices: [],
    form: [
      {
        key: 'shopId',
        name: 'G마켓 ID',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopPw',
        name: 'G마켓 PW',
        type: 'password',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
    ],
  },
  // A001: {
  //   use: [WEMAKEPRICE, COUPANG, ST11, KAKAO, ALWAYZ, ALL],
  //   prodCompareUse: false,
  //   name: '옥션',
  //   toolTip: `'옥션' 와 '옥션2.0' 이란?

  //   ESM+ 사이트 자체적으로 모든 상품은 [일단상품]과 [2.0 상품]으로 구분 됩니다.
  //   상품을 구분하는 기준은 해당 사이트 자체적인 정책으로, 당사에서 정확한 차이점 안내는 불가하며
  //   사이트측 정책을 확인 부탁 드립니다.
  //   `,
  //   notices: [],
  //   form: [
  //     {
  //       key: 'shopId',
  //       name: '옥션 ID',
  //       validation: { required: true },
  //       adornment: <></>,
  //       // defaultValue: '',
  //     },
  //     {
  //       key: 'shopPw',
  //       name: '옥션 PW',
  //       type: 'password',
  //       validation: { required: true },
  //       adornment: <></>,
  //       // defaultValue: '',
  //     },
  //   ],
  // },
  A522: {
    use: [WEMAKEPRICE, COUPANG, ST11, KAKAO, ALWAYZ, TOSS, ALL],
    prodCompareUse: false,
    name: '옥션2.0',
    toolTip: `'옥션' 와 '옥션2.0' 이란?
                                    
    ESM+ 사이트 자체적으로 모든 상품은 [일단상품]과 [2.0 상품]으로 구분 됩니다.
    2024년 03월부로 옥션은 2.0만 지원 가능합니다.
    상품을 구분하는 기준은 해당 사이트 자체적인 정책으로, 당사에서 정확한 차이점 안내는 불가하며
    사이트측 정책을 확인 부탁 드립니다.
    `,
    notices: [],
    form: [
      {
        key: 'shopId',
        name: '옥션 ID',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopPw',
        name: '옥션 PW',
        type: 'password',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
    ],
  },
  A112: {
    use: [WEMAKEPRICE, COUPANG, KAKAO, ALWAYZ, TOSS, ALL],
    prodCompareUse: false,
    name: '11번가',
    toolTip: `'11번가' 와 '11번가(단일)' 이란?

    11번가 사이트 자체적으로 모든 상품은 [일단상품]과 [단일상품]으로 구분 됩니다.
    상품을 구분하는 기준은 해당 사이트 자체적인 정책으로,
    당사에서 정확한 차이점 안내는 불가하며 사이트측 정책을 확인 부탁 드립니다.
    `,
    notices: [],
    form: [
      {
        key: 'shopId',
        name: '11번가 ID',
        validation: { required: true },
        defaultValue: '',
        adornment: <></>,
      },
      {
        key: 'shopEtc1',
        name: '11번가 API 인증키',
        type: 'password',
        validation: { required: true },
        defaultValue: '',
        adornment: <></>,
      },
    ],
  },
  A113: {
    use: [WEMAKEPRICE, COUPANG, KAKAO, ALWAYZ, TOSS, ALL],
    prodCompareUse: false,
    name: '11번가(단일)',
    toolTip: `'11번가' 와 '11번가(단일)' 이란?

    11번가 사이트 자체적으로 모든 상품은 [일단상품]과 [단일상품]으로 구분 됩니다.
    상품을 구분하는 기준은 해당 사이트 자체적인 정책으로,
    당사에서 정확한 차이점 안내는 불가하며 사이트측 정책을 확인 부탁 드립니다.
    `,
    notices: [],
    form: [
      {
        key: 'shopId',
        name: '11번가 ID',
        validation: { required: true },
        defaultValue: '',
        adornment: <></>,
      },
      {
        key: 'shopEtc1',
        name: '11번가 API 인증키',
        type: 'password',
        validation: { required: true },
        defaultValue: '',
        adornment: <></>,
      },
    ],
  },
  A027: {
    use: [WEMAKEPRICE, COUPANG, ST11, KAKAO, ALWAYZ, TOSS, ALL],
    prodCompareUse: false,
    name: '인터파크-오픈마켓',
    toolTip: '',
    notices: [
      `인터파크는 로그인 후 사업자를 선택하여 관리가 가능하도록 되어 있습니다</br>
    따라서 '서브 아이디 정보' 에 ^^^ 기준으로 세가지 정보가 필요합니다</br>
    회사명^^^사업자구분^^^리스트순서</br></br>
    
    예)주식회사 플레이오토^^^OM사업자^^^1</br></br>
    
    단 서브아이디가 없는 경우 빈내용으로 넣어주셔도 됩니다`,
      `인터파크는 로그인 후 사업자를 선택하여 관리가 가능하도록 되어 있습니다</br>
    따라서 '서브 아이디 정보' 에 ^^^ 기준으로 세가지 정보가 필요합니다</br>
    회사명^^^사업자구분^^^리스트순서^^^업체번호</br></br>
    
    예)주식회사 플레이오토^^^OM사업자^^^1^^^1234567890</br></br>
    
    단 서브아이디가 없는 경우 빈내용으로 넣어주셔도 됩니다`,
    ],

    form: [
      {
        key: 'shopId',
        name: '인터파크-오픈마켓 ID',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopPw',
        name: '인터파크-오픈마켓 PW',
        type: 'password',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopEtc2',
        name: '서브 아이디 정보',
        validation: { required: false },
        adornment: <></>,
        // defaultValue: '',
      },
    ],
  },
  B666: {
    use: [ST11, COUPANG, ESM, TOSS, ALL],
    prodCompareUse: false,
    name: '무신사',
    toolTip: '',
    notices: [],
    form: [
      {
        key: 'shopId',
        name: '무신사 ID',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopPw',
        name: '무신사 PW',
        type: 'password',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopEtc1',
        name: '무신사 OTP 인증키',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
    ],
  },
  B959: {
    use: [ST11, ESM, TOSS, ALL],
    prodCompareUse: false,
    name: '오늘의집',
    toolTip: '',
    notices: ["판매진행전 '원산지'를 꼭 확인해 주시기 바랍니다."],
    form: [
      {
        key: 'shopId',
        name: '오늘의집 ID',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
      {
        key: 'shopEtc1',
        name: 'API Access Key',
        type: 'password',
        validation: { required: true },
        adornment: <></>,
        // defaultValue: '',
      },
    ],
  },
};

// 쇼핑몰 이미지 데이터
export const SHOP_IMAGE_DATA = {
  smartstore: {
    logo: 'logo_smartstore.png',
    size: 118,
    text: '',
  },
  gmarket: {
    logo: 'logo_gmarket.png',
    size: 82,
    text: '2.0',
  },
  auction: {
    logo: 'logo_auction.png',
    size: 96,
    text: '2.0',
  },
  '11st': {
    logo: 'logo_11st.png',
    size: 56,
    text: '(단일 상품 포함)',
  },
  interpark: {
    logo: 'logo_interpark.png',
    size: 90,
    text: '',
  },
  ohou: {
    logo: 'logo_ohou.jpg',
    size: 110,
    text: '',
  },
  musinsa: {
    logo: 'logo_musinsa.png',
    size: 110,
    text: '',
  },
  coupang: {
    logo: 'logo_coupang.png',
    size: 110,
    text: '',
  },
};

const rawUserInfo = sessionStorage.getItem('userInfo');
const userInfo: IUserInfo = rawUserInfo && JSON.parse(rawUserInfo);
let email = userInfo?.email ? userInfo?.email : '';

// WakeUp 폼 데이터
export const WAKEUP_FORM_DATA = {
  COUPANG: {
    code: 'B378',
    name: {
      type1: '쿠팡',
      type2: '쿠팡으로',
      type3: '쿠팡 Wing',
      type4: '쿠팡 Wing 으로',
      type5: '쿠팡 상품 확인하기',
    },
    isShop: true,
    isESM: false,
    prodCompareUse: true,
    form: [
      {
        key: 'shopId',
        name: '쿠팡 Wing ID',
        validation: { required: true },
        type: 'text',
        //defaultValue: 'playauto10',
        //defaultValue: 'wmpp53070',
        adminDefaultValue: 'okfactory1',
      },
      {
        key: 'shopEtc5',
        name: '쿠팡 업체코드',
        type: 'text',
        validation: { required: true },
        //defaultValue: 'A00068466',
        adminDefaultValue: 'A00331599',
      },
      {
        key: 'shopEtc3',
        name: '쿠팡 AccessKey',
        type: 'password',
        validation: { required: true },
        //defaultValue: 'ad832bfd-e8aa-4e42-867f-e9d26ac338cb',
        adminDefaultValue: '70932eff-9e5e-4dff-9c1b-fafe9b2a7312',
        adornment: (
          <>
            <Grid display="flex" direction="column" width={200} alignItems="center">
              <a
                href="/"
                onClick={(e) => {
                  window.open(
                    'https://wing.coupang.com/tenants/vendor-join/vendor/agreement-popup?popup=playautoPolicyAgreement',
                    '_blank',
                    'width=480,height=680',
                  );

                  e.preventDefault();
                }}
              >
                <Button width={200} size="small" variant="outlined" color="main" type="button">
                  <Text color="inherit" fontSize="0.77rem">
                    API 발급 바로가기
                  </Text>
                </Button>
              </a>
            </Grid>
          </>
        ),
      },
      {
        key: 'shopEtc4',
        name: '쿠팡 SecretKey',
        type: 'password',
        validation: { required: true },
        //defaultValue: '478a91a8ebae6b402822aecf0a94a2a32b860421',
        adminDefaultValue: '4b39d193e9cc6c100cfdfd402f6292fc04d09931',
      },
      {
        key: 'shopEtc1',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
        adornment: (
          <>
            <Grid display="flex" direction="column" width={200} alignItems="center">
              <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/scrap_prod_coupang`} target="_blank" rel="noreferrer">
                <Button width={200} size="small" variant="outlined" color="main" type="button">
                  <Text color="inherit" fontSize="0.77rem">
                    지정 상품 등록하기란?
                  </Text>
                </Button>
              </a>
            </Grid>
          </>
        ),
      },
    ],
    restrictionType: (
      <>
        <Text color="inherit" fontSize="inherit">
          1. 옵션 개수가 200개를 초과한 상품
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 상품정보 내 출고지 or 반품지 정보가 없는 상품
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 일부 상품정보(옵션 개수 * 상세설명 텍스트)가 비정상적으로 큰 경우
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          4. 상품이미지 중 gif 가 포함된 상품
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          5. 쿠팡에 등록가능한 최대 배송비 및 반품비를 초과한 상품 [쿠팡 배송비 기준 안내]
        </Text>
        <Text color="inherit" fontSize="inherit">
          링크:&nbsp;
          <a href="https://wing.coupang.com/tenants/wing-account/partner/notices/54932" target="_blank" rel="noreferrer">
            https://wing.coupang.com/tenants/wing-account/partner/notices/54932
          </a>
        </Text>
      </>
    ),
    specificProduct: false,
    adminUrl: 'https://wing.coupang.com',
    useMarket: [
      SHOP_IMAGE_DATA.smartstore,
      SHOP_IMAGE_DATA.gmarket,
      SHOP_IMAGE_DATA.auction,
      SHOP_IMAGE_DATA['11st'],
      SHOP_IMAGE_DATA.interpark,
      SHOP_IMAGE_DATA.musinsa,
    ],
  },
  WEMAKEPRICE: {
    code: 'B719',
    name: {
      type1: '위메프',
      type2: '위메프로',
      type3: '위메프 파트너 2.0',
      type4: '위메프 파트너 2.0 로',
      type5: '위메프 파트너 2.0 접속',
    },
    isShop: true,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: 'shopId',
        name: '위메프 ID',
        validation: { required: true },
        type: 'text',
        //defaultValue: 'wmpp53070',
      },
      {
        key: 'shopEtc1',
        name: 'API 인증키',
        validation: { required: true },
        type: 'password',
        adornment: (
          <Grid display="flex" direction="column" width={200} alignItems="center">
            <a href="https://wpartner.wemakeprice.com" target="_blank" rel="noreferrer">
              <Button width={200} size="small" variant="outlined" color="main" type="button">
                <Text color="inherit" fontSize="0.77rem">
                  API 인증키 확인 하러가기
                </Text>
              </Button>
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_wemakeprice`} target="_blank" rel="noreferrer">
              <Text fontSize="0.75rem" color="main" fontWeight={600}>
                API 인증키 입력방법 알아보기
              </Text>
            </a>
          </Grid>
        ),
        //defaultValue: '553fa371d4ec54ab0e6498e5c31e008a2bf7201654ee50fc4d7bba4c37a8259de03d0ca82fca5ce763ee56796a48f42ec9426f9e6ef20533c7461558de85a957',
      },
      {
        key: 'shopEtc2',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
      },
    ],
    restrictionType: (
      <>
        <Text color="inherit" fontSize="inherit">
          1. 이미 등록된 상품과 상품명, 판매 가격이 모두 동일한 상품
        </Text>
        <Grid display="flex" alignItems="flex-start" top={10} style={{ color: 'inherit', fontSize: 'inherit' }}>
          <Text color="inherit" fontSize="inherit" lineHeight={18.5}>
            -&nbsp;&nbsp;
          </Text>
          <Text color="inherit" fontSize="inherit">
            <Text color="inherit" fontSize="inherit">
              플레이오토로 한번 이전하신 상품은 상품명, 판매가격을 변경하거나
            </Text>
            <Text color="inherit" fontSize="inherit">
              판매종료, 판매중지 처리하여도 다시 이전하실 수 없습니다.
            </Text>
          </Text>
        </Grid>
        <Grid display="flex" alignItems="flex-start" top={10} style={{ color: 'inherit', fontSize: 'inherit' }}>
          <Text color="inherit" fontSize="inherit" lineHeight={18.5}>
            -&nbsp;&nbsp;
          </Text>
          <Text color="inherit" fontSize="inherit">
            <Text color="inherit" fontSize="inherit">
              플레이오토를 통해 등록한 이력이 없는 상품이나, 해당 상품과 동일한 상품명,
            </Text>
            <Text color="inherit" fontSize="inherit">
              판매가격을 보유한 상품이 이미 위메프에 존재하는 경우 해당 상품은 이전이 불가합니다.
            </Text>
          </Text>
        </Grid>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 위메프 카테고리와 맞지 않는 카테고리에 등록된 상품
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 상품 정보가 비정상적으로 큰 경우(개선 예정)
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit" className="star">
          옵션 수 200개가 넘는 상품인 경우, 일부 옵션은 등록되지 않을 수 있습니다
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit" className="star">
          추가구매옵션은 지원하지 않습니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit" className="star">
          배송정보는 위메프에 등록된 기본 배송정보로 등록됩니다.
        </Text>
      </>
    ),

    specificProduct: false,
    adminUrl: 'https://wpartner.wemakeprice.com',
    useMarket: [SHOP_IMAGE_DATA.smartstore, SHOP_IMAGE_DATA.gmarket, SHOP_IMAGE_DATA.auction, SHOP_IMAGE_DATA['11st'], SHOP_IMAGE_DATA.interpark],
  },
  ADMIN: {
    code: '',
    name: {
      type1: '',
      type2: '',
      type3: '',
      type4: '',
      type5: '',
    },
    isShop: false,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: '',
        name: '',
        validation: { required: false },
        type: 'text',
        defaultValue: '',
      },
    ],
    restrictionType: `이전 불가 유형`,
    specificProduct: false,
    adminUrl: '',
    useMarket: [],
  },
  ALL: {
    code: '',
    name: {
      type1: '전체',
      type2: '',
      type3: '',
      type4: '',
      type5: '',
    },
    isShop: false,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: '',
        name: '',
        validation: { required: false },
        type: 'text',
        defaultValue: '',
      },
    ],
    restrictionType: `이전 불가 유형`,
    specificProduct: false,
    adminUrl: '',
    useMarket: [
      SHOP_IMAGE_DATA.smartstore,
      SHOP_IMAGE_DATA['11st'],
      SHOP_IMAGE_DATA.auction,
      SHOP_IMAGE_DATA.gmarket,
      SHOP_IMAGE_DATA.interpark,
      SHOP_IMAGE_DATA.musinsa,
      SHOP_IMAGE_DATA.ohou,
    ],
  },
  ST11: {
    code: 'A112',
    name: {
      type1: '11번가',
      type2: '11번가로',
      type3: '11번가 셀러오피스',
      type4: '11번가 셀러오피스 로',
      type5: '11번가 셀러오피스 접속',
    },
    isShop: true,
    isESM: false,
    prodCompareUse: false,
    // 스마트스토어 > 스마트에디터 변환 QA로 인한 지정 상품 가져오기 특별처리 추가
    // 2024-03-12
    form: [
      {
        key: 'shopId',
        name: '11번가 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
      },
      {
        key: 'shopEtc1',
        name: 'API 인증키',
        validation: { required: true },
        type: 'password',
        adornment: (
          <Grid display="flex" direction="column" width={200} alignItems="center">
            <a href="https://soffice.11st.co.kr" target="_blank" rel="noreferrer">
              <Button width={200} size="small" variant="outlined" color="main" type="button">
                <Text color="inherit" fontSize="0.77rem">
                  API 인증키 확인 하러가기
                </Text>
              </Button>
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_st11`} target="_blank" rel="noreferrer">
              <Text fontSize="0.75rem" color="main" fontWeight={600}>
                API 인증키 입력방법 알아보기
              </Text>
            </a>
          </Grid>
        ),
      },
      {
        key: 'shopEtc2',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
      },
    ],
    restrictionType: (
      <>
        <Text color="inherit" fontSize="inherit">
          1. 해외직구, 렌털/가입 상품, e쿠폰/상품권, 여행/숙박/항공 카테고리는 사용이 불가합니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 해외배송, 주문제작, 배송되지 않는 무형의 상품은 지원하지 않습니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 상품상태 '새상품'만 지원 합니다. (중고상품은 지원하지 않습니다.)
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          4. 상품이미지 항목은 .gif 파일 형식을 사용하실 수 없습니다.
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          5. '판매중'이 아닌 상품은 상품 이전이 불가합니다.
        </Text>
      </>
    ),
    specificProduct: true,
    adminUrl: 'https://soffice.11st.co.kr/view/main',
    useMarket: [
      SHOP_IMAGE_DATA.smartstore,
      SHOP_IMAGE_DATA.gmarket,
      SHOP_IMAGE_DATA.auction,
      SHOP_IMAGE_DATA.interpark,
      SHOP_IMAGE_DATA.ohou,
      SHOP_IMAGE_DATA.musinsa,
    ],
  },
  ALWAYZ: {
    code: 'A125',
    name: {
      type1: '올웨이즈',
      type2: '올웨이즈로',
      type3: '올웨이즈 어드민',
      type4: '올웨이즈 어드민으로',
      type5: '올웨이즈 어드민 접속',
    },
    isShop: true,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: 'shopId',
        name: '올웨이즈 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
      },
      {
        key: 'shopPw',
        name: '올웨이즈 비밀번호',
        validation: { required: true },
        type: 'password',
        defaultValue: '',
      },
      {
        key: 'shopEtc1',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
        adornment: (
          <>
            <Grid display="flex" direction="column" width={200} alignItems="center">
              <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/scrap_prod_alwayz`} target="_blank" rel="noreferrer">
                <Button width={200} size="small" variant="outlined" color="main" type="button">
                  <Text color="inherit" fontSize="0.77rem">
                    지정 상품 등록하기란?
                  </Text>
                </Button>
              </a>
            </Grid>
          </>
        ),
      },
    ],
    restrictionType: (
      <>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          1. 택배배송 외 다른 배송방법은 지원하지 않습니다.
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 올웨이즈 카테고리와 맞지 않는 카테고리에 등록된 상품
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 상품 정보가 비정상적으로 큰 경우(개선 예정)
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          4. 상품 대표 이미지는 gif 형식의 이미지를 지원하지 않으며, 대표 이미지에 gif 파일이 포함되어 있을 시 jpeg 파일로 자동 변환되어 등록됩니다.
        </Text>
      </>
    ),
    specificProduct: true,
    adminUrl: 'https://alwayzseller.ilevit.com',
    useMarket: [SHOP_IMAGE_DATA.smartstore, SHOP_IMAGE_DATA.gmarket, SHOP_IMAGE_DATA.auction, SHOP_IMAGE_DATA['11st'], SHOP_IMAGE_DATA.interpark],
  },

  ESM: {
    code: '',
    name: {
      type1: 'ESM+',
      type2: '',
      type3: '',
      type4: '',
      type5: '',
    },
    isShop: false,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: 'shopEtc1',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
      },
    ],
    restrictionType: (
      <>
        <Text color="blue" fontSize="inherit">
          ESM+ 마스터 계정으로는 계정 확인하기가 불가합니다. G마켓, 옥션 각각의 쇼핑몰 계정으로 계정 확인을 부탁드립니다.
        </Text>

        <Grid top={20} />
        <Text color="inherit" fontSize="inherit">
          1. G마켓, 옥션 계정 모두 입력 시 마스터 계정이 같다면 마스터 상품으로 묶여 등록되며, 마스터 계정이 다르다면 각각의 쇼핑몰에 나뉘어 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 상품명은 최대 100byte까지 절삭되어 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 판매 기간은 90일로 고정되어 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          4. 상품 리스트 이미지에서 중복되는 이미지는 제거된 후 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          5. 출고지/반품지는 ESM+ 판매자센터에 설정된 기본 출고지/반품지로 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          6. G마켓, 옥션 각각 등록 시 발송정책은 ESM+ 판매자센터에 설정된 기본 발송정책으로 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          7. G마켓, 옥션을 마스터 상품으로 묶어 등록 시 발송정책은 아래와 같이 등록됩니다.
        </Text>
        <Text color="inherit" fontSize="inherit" className="star">
          G마켓 : 기본 발송정책
        </Text>
        <Text color="inherit" fontSize="inherit" className="star">
          옥션 : G마켓 기본 발송정책과 동일한 내용의 정책 (동일한 내용의 정책이 없다면 자동 생성 후 등록됩니다.)
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          8. 묶음 배송비 템플릿은 가져오는 쇼핑몰의 묶음 배송 정보와 동일한 템플릿으로 등록됩니다. (동일한 내용의 템플릿이 없다면 자동 생성 후 등록됩니다.)
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          9. 배송 방법이 해외배송인 상품은 등록이 불가합니다.
        </Text>
      </>
    ),
    specificProduct: false,
    adminUrl: '',
    useMarket: [SHOP_IMAGE_DATA.smartstore, SHOP_IMAGE_DATA.musinsa, SHOP_IMAGE_DATA.ohou],
  },
  // wakeup.plto.com/admin 의 ESM+ 전체 통계를 보기위한 타입
  ESM_ALL: {
    code: '',
    name: {
      type1: 'ESM+ 전체',
      type2: '',
      type3: '',
      type4: '',
      type5: '',
    },
    isShop: false,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: '',
        name: '',
        validation: { required: false },
        type: 'text',
        defaultValue: '',
      },
    ],
    restrictionType: (
      <>
        <Text color="inherit" fontSize="inherit">
          1. G마켓, 옥션 계정 모두 입력 시 마스터 계정이 같다면 마스터 상품으로 묶여 등록되며, 마스터 계정이 다르다면 각각의 쇼핑몰에 나뉘어 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 상품명은 최대 100byte까지 절삭되어 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 판매 기간은 90일로 고정되어 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          4. 상품 리스트 이미지에서 중복되는 이미지는 제거된 후 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          5. 출고지/반품지는 ESM+ 판매자센터에 설정된 기본 출고지/반품지로 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          6. G마켓, 옥션 각각 등록 시 발송정책은 ESM+ 판매자센터에 설정된 기본 발송정책으로 등록됩니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          7. G마켓, 옥션을 마스터 상품으로 묶어 등록 시 발송정책은 아래와 같이 등록됩니다.
        </Text>
        <Text color="inherit" fontSize="inherit" className="star">
          G마켓 : 기본 발송정책
        </Text>
        <Text color="inherit" fontSize="inherit" className="star">
          옥션 : G마켓 기본 발송정책과 동일한 내용의 정책 (동일한 내용의 정책이 없다면 자동 생성 후 등록됩니다.)
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          8. 묶음 배송비 템플릿은 가져오는 쇼핑몰의 묶음 배송 정보와 동일한 템플릿으로 등록됩니다. (동일한 내용의 템플릿이 없다면 자동 생성 후 등록됩니다.)
        </Text>
      </>
    ),
    specificProduct: false,
    adminUrl: '',
    useMarket: [SHOP_IMAGE_DATA.smartstore, SHOP_IMAGE_DATA.musinsa, SHOP_IMAGE_DATA.ohou],
  },
  GMARKET: {
    code: 'A523',
    name: {
      type1: 'G마켓',
      type2: 'G마켓으로',
      type3: 'G마켓3',
      type4: 'G마켓4',
      type5: 'G마켓5',
    },
    isShop: true,
    isESM: true,
    prodCompareUse: false,
    form: [
      {
        key: 'shopId',
        name: 'G마켓 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
      },
      {
        key: 'shopPw',
        name: 'G마켓 PW',
        validation: { required: true },
        type: 'password',
        defaultValue: '',
      },
    ],
    restrictionType: undefined,
    specificProduct: false,
    adminUrl: 'https://www.esmplus.com/Home/Home',
    useMarket: [SHOP_IMAGE_DATA.smartstore],
  },
  AUCTION: {
    code: 'A522',
    name: {
      type1: '옥션',
      type2: '옥션으로',
      type3: '옥션3',
      type4: '옥션4',
      type5: '옥션5',
    },
    isShop: true,
    isESM: true,
    prodCompareUse: false,
    form: [
      {
        key: 'shopId',
        name: '옥션 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
      },
      {
        key: 'shopPw',
        name: '옥션 PW',
        validation: { required: true },
        type: 'password',
        defaultValue: '',
      },
    ],
    restrictionType: undefined,
    specificProduct: false,
    adminUrl: 'https://www.esmplus.com/Home/Home',
    useMarket: [SHOP_IMAGE_DATA.smartstore],
  },
  KAKAO: {
    code: 'B688',
    name: {
      type1: '카카오톡 스토어',
      type2: '카카오톡 스토어로',
      type3: '카카오톡 스토어 판매자센터',
      type4: '카카오톡 스토어 판매자센터로',
      type5: '카카오톡 스토어 판매자센터 접속',
    },
    isShop: true,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: 'shopId',
        name: '카카오톡 스토어 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
      },
      {
        key: 'shopEtc1',
        name: 'API 인증키',
        validation: { required: true },
        type: 'password',
        adornment: (
          <Grid display="flex" direction="column" width={200} alignItems="center">
            <a href="https://shopping-seller.kakao.com/display/store-seller/dashboard" target="_blank" rel="noreferrer">
              <Button width={200} size="small" variant="outlined" color="main" type="button">
                <Text color="inherit" fontSize="0.77rem">
                  API 인증키 확인 하러가기
                </Text>
              </Button>
            </a>
            {/* <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/wakeup_st11`} target="_blank" rel="noreferrer">
              <Text fontSize="0.75rem" color="main" fontWeight={600}>
                API 인증키 입력방법 알아보기
              </Text>
            </a> */}
          </Grid>
        ),
      },
      {
        key: 'shopEtc2',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
        adornment: (
          <>
            <Grid display="flex" direction="column" width={200} alignItems="center">
              <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/scrap_prod_kakao`} target="_blank" rel="noreferrer">
                <Button width={200} size="small" variant="outlined" color="main" type="button">
                  <Text color="inherit" fontSize="0.77rem">
                    지정 상품 등록하기란?
                  </Text>
                </Button>
              </a>
            </Grid>
          </>
        ),
      },
    ],
    restrictionType: (
      <>
        <Text color="inherit" fontSize="inherit">
          1. 배송 방법이 해외배송 및 상담예약인 상품은 등록이 불가합니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 배송비 타입은 [무료, 유료, 조건부무료(구매가기준), 수량별부과] 만 사용이 가능합니다.
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 기본 출고지/반품지 주소가 설정되어 있지 않으면 상품 등록이 불가합니다.
        </Text>
        <Grid top={20} />
        <Text color="inherit" fontSize="inherit" className="star">
          출고지는 카카오톡 스토어 계정에 설정되어있는 기본 출고지로 등록 됩니다.
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit" className="star">
          반품지는 카카오톡 스토어 계정에 설정되어있는 기본 반품지로 등록 됩니다.
        </Text>
      </>
    ),
    specificProduct: true,
    adminUrl: 'https://shopping-seller.kakao.com/display/store-seller/dashboard',
    useMarket: [SHOP_IMAGE_DATA.smartstore, SHOP_IMAGE_DATA.gmarket, SHOP_IMAGE_DATA.auction, SHOP_IMAGE_DATA['11st'], SHOP_IMAGE_DATA.interpark],
  },
  TOSS: {
    code: 'A538',
    name: {
      type1: '토스',
      type2: '토스로',
      type3: '토스 어드민',
      type4: '토스 어드민으로',
      type5: '토스 어드민 접속',
    },
    isShop: true,
    isESM: false,
    prodCompareUse: false,
    form: [
      {
        key: 'shopId',
        name: '토스 로그인 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
        adminDefaultValue: 'sueunPlto',
      },
      {
        key: 'shopEtc3',
        name: '셀러 ID',
        validation: { required: true },
        type: 'text',
        defaultValue: '',
        adminDefaultValue: '36661',
      },
      {
        key: 'shopEtc1',
        name: 'Access Key',
        type: 'text',
        validation: { required: true },
        adminDefaultValue: 'tyms74l99v8axwc3uycvph83ae0aq4zr',
      },
      {
        key: 'shopEtc2',
        name: 'Secret Key',
        type: 'text',
        validation: { required: true },
        adminDefaultValue: 'pO0zGmrceP4J1yFnMzRXYRIPDtkZyIrkNehdcpnrIATNs6AO',
      },
      {
        key: 'shopEtc5',
        name: '지정 상품 등록하기',
        validation: { required: false },
        defaultValue: '',
        adornment: (
          <>
            <Grid display="flex" direction="column" width={200} alignItems="center">
              <a href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/guide/scrap_prod_toss`} target="_blank" rel="noreferrer">
                <Button width={200} size="small" variant="outlined" color="main" type="button">
                  <Text color="inherit" fontSize="0.77rem">
                    지정 상품 등록하기란?
                  </Text>
                </Button>
              </a>
            </Grid>
          </>
        ),
      },
    ],
    restrictionType: (
      <>
        <Text color="inherit" fontSize="inherit">
          1. 이미 등록된 상품과 상품명이 동일한 상품은 등록이 불가합니다
        </Text>
        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          2. 특정 상품군(가공식품, 수입식품, 건강기능식품, 원물(비과세), 축산, 생수)은 판매 권한을 먼저 신청해야 하며 판매 권한을 보유하지 않은 경우
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;상품 이전이 불가합니다.
          <a href="https://shopping-seller.toss.im/product-sales-permits" target="blank">
            <span style={{ fontSize: '0.83rem', color: '#0072ce' }}>(판매 권한 신청하기)</span>
          </a>
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          3. 배송 방법이 해외배송인 상품은 등록이 불가합니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          4. 해외직구 카테고리는 상품 등록이 불가합니다.
        </Text>

        <Grid top={10} />
        <Text color="inherit" fontSize="inherit">
          5. 중고상품은 등록이 불가합니다.
        </Text>
      </>
    ),
    specificProduct: true,
    adminUrl: 'https://shopping-seller.toss.im',
    useMarket: [
      SHOP_IMAGE_DATA.smartstore,
      SHOP_IMAGE_DATA.coupang,
      SHOP_IMAGE_DATA.gmarket,
      SHOP_IMAGE_DATA.auction,
      SHOP_IMAGE_DATA['11st'],
      SHOP_IMAGE_DATA.interpark,
      SHOP_IMAGE_DATA.musinsa,
      SHOP_IMAGE_DATA.ohou,
    ],
  },
};

// 채널톡 설정값
export const CHANNELIO = {
  pluginKey: '39d12506-aa0f-4990-b380-89597f9df25a',
  hideDefaultLauncher: true,
  profile: {},
};
