import WakeupLoginForm from 'components/form/WakeupLoginForm';
import { WAKEUP_FORM_DATA } from '../../../../common/common.constants';
import { SellerType, WakeUp } from '../../../../common/interface/props';
import TitleWrap from '../../molecules/TitleWrap';

interface IProps {
  solType: WakeUp;
  coupangSellerType?: SellerType;
}

export default function WakeUpLoginForm({ solType, coupangSellerType }: IProps) {
  return (
    <>
      <TitleWrap
        title={`${WAKEUP_FORM_DATA[solType].name.type1} 상품등록`}
        subject={`판매중인 상품을 ${WAKEUP_FORM_DATA[solType].name.type2} 전송합니다.`}
        step={3}
      />
      <WakeupLoginForm solType={solType} coupangSellerType={coupangSellerType} />
    </>
  );
}
