import { axiosCustom } from 'common/common.function';
import { StatisticType, WakeUp } from 'common/interface/props';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import ko from 'date-fns/locale/ko';
import { registerLocale } from 'react-datepicker';
import Container from 'components/UI/atoms/Container';
import Grid from 'components/UI/atoms/Grid';
import Tab from 'components/UI/atoms/Tab';
import Text from 'components/UI/atoms/Text';
import Line from 'components/UI/atoms/Line';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import StatisticsTable from 'components/admin/StatisticsTable';
import Tooltip from 'components/UI/atoms/Tooltip';
import { convertShopName } from 'common/common.constants';

interface IStatistics {
  solType: WakeUp;
  isIntegrated?: boolean;
}

registerLocale('ko', ko);

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid #ddd;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export default function Statistics({ solType, isIntegrated }: IStatistics) {
  const [allWeekData, setAllWeekData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      visitor: 0,
      joinSeller: 0,
      migrationProd: 0,
      migrationSellerFirst: 0,
      migrationSeller: 0,
      prodAvgSeller: 0,
      successPercent: 0,
    },
  ]); // 주간 전체 데이터

  const [allMonthData, setAllMonthData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      visitor: 0,
      joinSeller: 0,
      migrationProd: 0,
      migrationSellerFirst: 0,
      migrationSeller: 0,
      prodAvgSeller: 0,
      successPercent: 0,
    },
  ]); // 월간 전체 데이터
  const [allDataSum, setAllDataSum] = useState([
    {
      visitor: 0,
      joinSeller: 0,
      migrationProd: 0,
      migrationSellerFirst: 0,
      migrationSeller: 0,
      prodAvgSeller: 0,
      successPercent: 0,
    },
  ]); // 전체합산 통계 데이터

  // ALL 타입 어드민일 시 선택한 쇼핑몰 타입
  const [selectWeekSolType, setSelectWeekSolType] = useState<WakeUp>(solType);
  // ALL 타입 어드민일 시 선택한 쇼핑몰 타입
  const [selectMonthSolType, setSelectMonthSolType] = useState<WakeUp>(solType);
  // 통합 통계 페이지용 쇼핑몰 구분
  const [interSolType, setInterSolType] = useState<WakeUp>(solType);
  // 통계 전체 데이터
  const [statistics, setStatistics] = useState<any>();

  const selectSolType = {
    selectWeekSolType,
    selectMonthSolType,
  };
  //백엔드 wakeup commob DB statistics_week Table에 저장된 주간 통계 자료 가져오기
  useEffect(() => {
    async function fetchData() {
      try {
        const { result } = await axiosCustom.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/statistics`,
          {
            solType: solType,
            isIntegrated: isIntegrated,
          },
          {
            headers: {
              'sol-type': isIntegrated ? 'ADMIN' : solType.toUpperCase(),
            },
          },
        );
        setStatistics(result); // 통계 전체 데이터
        setAllMonthData(result[interSolType].monthData[selectMonthSolType]); // 월간 전체 데이터
        setAllWeekData(result[interSolType].weekData[selectWeekSolType]); // 주간 전체 데이터
        setAllDataSum(result[interSolType].allData); // 전체 통계 데이터
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: `${error.message}`,
          icon: 'error',
        });

        window.location.href = 'https://plto.com';
      }
    }
    fetchData();
  }, []);

  // ALL 타입 선택한 쇼핑몰 달라질 경우 보이는 타입 변경 - 주간
  useEffect(() => {
    statistics && setAllWeekData(statistics[interSolType].weekData[selectWeekSolType]);
  }, [selectWeekSolType]);

  // ALL 타입 선택한 쇼핑몰 달라질 경우 보이는 타입 변경 - 월간
  useEffect(() => {
    statistics && setAllMonthData(statistics[interSolType].monthData[selectMonthSolType]);
  }, [selectMonthSolType]);

  // ALL 타입 선택한 쇼핑몰 달라질 경우 보이는 타입 변경 - 월간
  useEffect(() => {
    if (statistics && statistics[interSolType]) {
      const selectWeekType = interSolType === 'ALL' || interSolType === 'ESM' ? selectWeekSolType : interSolType;
      const selectMonthType = interSolType === 'ALL' || interSolType === 'ESM' ? selectMonthSolType : interSolType;

      setAllMonthData(statistics[interSolType].monthData[selectMonthType]); // 월간 전체 데이터
      setAllWeekData(statistics[interSolType].weekData[selectWeekType]); // 주간 전체 데이터
      setAllDataSum(statistics[interSolType].allData); // 전체 통계 데이터
    }
  }, [interSolType]);

  const columns = {
    날짜: '',
    '가입 셀러': '플레이오토 신규 회원가입 수',
    '이전 성공 상품 수': '주당 상품 이전 성공한 상품 수 (실패건수 미포함)',
    '첫 이전 성공 셀러': '주당 상품 이전 최초 성공한 셀러 수',
    '이전 성공 셀러': '주당 상품 이전 성공한 셀러 수',
    '셀러당 평균 이전 성공 상품 수': '주당 상품 이전 이용한 셀러별 평균 성공 상품 수 (이전 성공 상품 수 / 이전 성공 셀러)',
    '상품 이전 성공 비율': '주당 상품 이전 성공 비율 ((이전 성공 상품 수 / 이전 전체 상품 수) * 100)',
  };

  //const columns = ['날짜', '가입 셀러', '이전 성공 상품 수', '첫 이전 성공 셀러', '이전 성공 셀러', '셀러당 평균 이전 성공 상품 수', '상품 이전 성공 비율'];

  // 주간 통계 데이터
  const weekRows = [
    allWeekData.map((one, index): any => ({
      id: index,
      day: `${one.startDate} ~ ${one.endDate}`,
      // visitorCount: one.visitor.toLocaleString(), //방문자수
      joinSellerCount: one.joinSeller.toString(), //서비스가입 셀러
      prodCount: one.migrationProd.toString(), //총 상품 수
      migrationFirstSellerCount: one.migrationSellerFirst.toString(), //상품 첫 이전 셀러 수
      migrationSellerCount: one.migrationSeller.toString(), //상품 이전 셀러 수
      sellerAverageProdCount: one.prodAvgSeller.toFixed().toString(), //셀러당 평균 상품 수
      successRate: `${one.successPercent.toString()}%`, //상품 별 성공비율
    })),
  ];

  // 월간 통계 데이터
  const monthRows = [
    allMonthData.map((one, index): any => ({
      id: index,
      day: `${one.startDate} ~ ${one.endDate}`,
      // visitorCount: one.visitor.toLocaleString(), //방문자수
      joinSellerCount: one.joinSeller.toString(), //서비스가입 셀러
      prodCount: one.migrationProd.toString(), //총 상품 수
      migrationFirstSellerCount: one.migrationSellerFirst.toString(), //상품 첫 이전 셀러 수
      migrationSellerCount: one.migrationSeller.toString(), //상품 이전 셀러 수
      sellerAverageProdCount: one.prodAvgSeller.toFixed().toString(), //셀러당 평균 상품 수
      successRate: `${one.successPercent.toString()}%`, //상품 별 성공비율
    })),
  ];

  const allDataSumColumns = {
    '가입 셀러': '플레이오토 신규 회원가입 수',
    '이전 성공 상품 수': '전체 상품 이전 성공한 상품 수 (실패건수 미포함)',
    '첫 이전 성공 셀러': '전체 상품 이전 최초 성공한 셀러 수',
    '이전 성공 셀러': '전체 상품 이전 성공한 셀러 수',
    '셀러당 평균 이전 성공 상품 수': '전체 상품 이전 이용한 셀러별 평균 성공 상품 수 (이전 성공 상품 수 / 이전 성공 셀러)',
    '상품 이전 성공 비율': '전체 상품 이전 성공 비율 ((이전 성공 상품 수 / 이전 전체 상품 수) * 100)',
  };
  //const allDataSumColumns = ['가입 셀러', '이전 성공 상품 수', '첫 이전 성공 셀러', '이전 성공 셀러', '셀러당 평균 이전 성공 상품 수', '상품 이전 성공 비율'];

  const allDataSumData = {
    // visitorCountAll: allDataSum[0].visitor.toLocaleString(), //방문자수
    joinSellerCountAll: allDataSum[0].joinSeller.toLocaleString(), //서비스가입 셀러
    prodCountAll: allDataSum[0].migrationProd.toLocaleString(), //총 상품 수
    migrationFirstSellerCountAll: allDataSum[0].migrationSellerFirst.toLocaleString(), //상품 첫 이전 셀러 수
    migrationSellerCountAll: allDataSum[0].migrationSeller.toLocaleString(), //상품 이전 셀러 수
    sellerAverageProdCountAll: allDataSum[0].prodAvgSeller.toFixed().toLocaleString(), //셀러당 평균 상품 수
    successRateAll: `${allDataSum[0].successPercent.toLocaleString()}%`, //상품 별 성공비율
  };

  // 엑셀 다운로드
  async function excelDownloadEvent(type: StatisticType) {
    let typeName;
    let rows;
    let selectShop;

    switch (type) {
      case 'MONTH':
        typeName = '월간';
        rows = monthRows;
        selectShop = selectSolType.selectMonthSolType;
        break;

      case 'WEEK':
        typeName = '주간';
        rows = weekRows;
        selectShop = selectSolType.selectWeekSolType;
        break;

      default:
        selectShop = solType;
        break;
    }
    Swal.fire({
      title: '잠시만 기다려 주세요.',
      html: `${typeName} 통계 상세 다운로드 ...`, // add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const excelData =
      (rows &&
        rows[0].map((one): any => {
          return {
            day: one.day,
            joinSellerCount: parseInt(one.joinSellerCount.replace(',', '')),
            prodCount: parseInt(one.prodCount.replace(',', '')),
            migrationFirstSellerCount: parseInt(one.migrationFirstSellerCount.replace(',', '')),
            migrationSellerCount: parseInt(one.migrationSellerCount.replace(',', '')),
            sellerAverageProdCount: parseInt(one.sellerAverageProdCount.replace(',', '')),
            successRate: one.successRate,
          };
        })) ||
      [];

    let titleName = '';
    switch (interSolType) {
      case 'ALL':
        titleName = `${convertShopName(interSolType)}${selectShop !== 'ALL' ? ` - ${convertShopName(selectShop)}` : ''} ${typeName} 통계`;
        break;
      case 'ESM':
        titleName = `${convertShopName(selectShop)} ${typeName} 통계`;
        break;
      default:
        titleName = `${convertShopName(interSolType)} ${typeName} 통계`;
        break;
    }
    // const titleName = `${convertShopName(interSolType)}${
    //   interSolType === 'ALL' && selectShop !== 'ALL'
    //     ? `-${convertShopName(selectShop)}`
    //     : interSolType === 'ESM' && selectShop !== 'ESM_ALL'
    //     ? `-${convertShopName(selectShop)}`
    //     : ''
    // } ${typeName} 통계`;

    const dataWS = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, dataWS, titleName);

    // 헤더 설정
    XLSX.utils.sheet_add_aoa(
      dataWS,
      [['날짜', '가입 셀러', '이전 성공 상품 수', '첫 이전 성공 셀러', '이전 성공 셀러', '셀러당 평균 이전 성공 상품 수 ', '상품 이전 성공 비율']],
      { origin: 'A1' },
    );

    // 너비 지정
    dataWS['!cols'] = [24, 10, 17, 17, 17, 27, 18].map((one) => {
      return { wch: one };
    });

    // 숫자형식으로 셀 서식 변경
    // fmt를 #,##0 으로 하면 통화 형식으로 변경 됨
    const fmt = '0';
    const range = XLSX.utils.decode_range(dataWS['!ref'] || '');
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = dataWS[XLSX.utils.encode_cell({ r: R, c: C })];
        if (!cell || cell.t !== 'n') continue;
        cell.z = fmt;
      }
    }

    XLSX.writeFile(wb, `${titleName}.xlsx`);
    Swal.close();
  }

  /**
   * ALL 타입에서 선택한 쇼핑몰 통계 변경 이벤트
   */
  const changeSolType = (type: StatisticType, solType: WakeUp) => {
    switch (type) {
      case 'MONTH':
        setSelectMonthSolType(solType);
        break;
      case 'WEEK':
        setSelectWeekSolType(solType);
        break;
      default:
        break;
    }
  };

  /**
   * 전체 통계 페이지에서 선택한 쇼핑몰 통계 변경 이벤트
   */
  const changeInterSolType = (solType: WakeUp) => {
    setInterSolType(solType);

    // ALL 타입으로 변경 시 ALL 타입 하위 구분을 전체로 초기화
    if (solType === 'ALL') {
      setSelectMonthSolType('ALL');
      setSelectWeekSolType('ALL');
    } else if (solType === 'ESM') {
      setSelectMonthSolType('ESM_ALL');
      setSelectWeekSolType('ESM_ALL');
    }
  };

  return (
    <Container noBody={true}>
      <Grid top={50}>
        {isIntegrated ? (
          <Grid left={60} bottom={35}>
            <Tab
              tabNames={['쿠팡', '위메프', '11번가', '올웨이즈', '카카오', 'ESM', '토스', 'ALL']}
              tabNodes={[<></>]}
              size={'small'}
              tabSwitchEvent={changeInterSolType}
              tabSwitchValues={['COUPANG', 'WEMAKEPRICE', 'ST11', 'ALWAYZ', 'KAKAO', 'ESM', 'TOSS', 'ALL']}
            />
          </Grid>
        ) : (
          <></>
        )}
        <TableContainer style={{ overflowX: 'visible' }}>
          <Styles>
            <Grid bottom={20}>
              <Text fontSize="30px" fontWeight={600} color="#444">
                전체 통계
              </Text>
            </Grid>
            <Line height={2} color="#000" />
            <Table>
              <TableHead>
                <TableRow>
                  {Object.entries(allDataSumColumns).map(([key, value]: any) => (
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {key}
                      <Tooltip title={value}>
                        <Grid color="#f2f4f7" left={5} right={5}>
                          ?
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{allDataSumData.joinSellerCountAll}</TableCell>
                  <TableCell align="center">{allDataSumData.prodCountAll}</TableCell>
                  <TableCell align="center">{allDataSumData.migrationFirstSellerCountAll}</TableCell>
                  <TableCell align="center">{allDataSumData.migrationSellerCountAll}</TableCell>
                  <TableCell align="center">{allDataSumData.sellerAverageProdCountAll}</TableCell>
                  <TableCell align="center">{allDataSumData.successRateAll}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Styles>
          <Styles>
            {/* 주간 통계 */}
            <StatisticsTable
              type="WEEK"
              excelDownloadEvent={excelDownloadEvent}
              columns={columns}
              rows={weekRows[0]}
              solType={interSolType}
              selectSolType={selectSolType}
              changeSolType={changeSolType}
              interSolType={interSolType}
            ></StatisticsTable>
          </Styles>
          <Styles>
            {/* 월간 통계 */}
            <StatisticsTable
              type="MONTH"
              excelDownloadEvent={excelDownloadEvent}
              columns={columns}
              rows={monthRows[0]}
              solType={interSolType}
              selectSolType={selectSolType}
              changeSolType={changeSolType}
              interSolType={interSolType}
            ></StatisticsTable>
          </Styles>
        </TableContainer>
      </Grid>
    </Container>
  );
}
