import Grid from 'components/UI/atoms/Grid';
import { IMemberProps } from 'common/interface/props';
import BoxWrap from 'components/UI/molecules/BoxWrap';
import TitleWrap from 'components/UI/molecules/TitleWrap';
import ResetPassword from 'components/form/resetPassword';

function ResetPasswordForm({ solType }: IMemberProps) {
  return (
    <Grid display="flex" justifyContent="center">
      <Grid width={500}>
        <TitleWrap />
        <BoxWrap>
          <BoxWrap.Bottom>
            <ResetPassword solType={solType} />
          </BoxWrap.Bottom>
        </BoxWrap>
      </Grid>
    </Grid>
  );
}

export default ResetPasswordForm;
