import { axiosCustom } from 'common/common.function';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';

// atomic component
import Container from 'components/UI/atoms/Container';
import Text from 'components/UI/atoms/Text';
import Grid from 'components/UI/atoms/Grid';
import Button from 'components/UI/atoms/Button';
import TextField from 'components/UI/atoms/TextField';
import Table from 'components/UI/atoms/Table';
import Select from 'components/UI/atoms/Select';
import Pagination from 'components/UI/molecules/Pagination';
import { BiSearch } from 'react-icons/bi';
import { RiEditCircleLine } from 'react-icons/ri';
import ToggleSwitch from 'components/UI/atoms/ToggleSwitch';

interface IUserData {
  userId: string;
  email: string;
  solType: string;
  gmpSolNo: string;
  etc1: string;
  etc2: string;
  etc3: string;
  etc4: string;
  etc5: string;
  schedulerUseYn: boolean;
}

const ButtonTag = styled(Button)`
  border: 1px solid #e2e2e2;
  padding: 5px 5px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: #f5f5f5;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default !important;
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export default function MemberBoard() {
  // 페이지당 노출 수
  const [viewCount, setViewCount] = useState(30);

  // 조회 솔루션 타입
  const [searchSolType, setSearchSolType] = useState('');
  // 조회 키
  const [searchType, setSearchType] = useState('email');
  // 조회 값
  const [searchValue, setSearchValue] = useState('');

  // 현재 페이지
  const [nowPage, setNowPage] = useState(1);

  // 유저 데이터
  const [userData, setUserData] = useState<IUserData[]>([]);

  // 유저 데이터 총 수
  const [userCount, setUserCount] = useState(0);

  // 페이지변경됐는지 확인 훅
  const [pageChanged, setPageChanged] = useState<boolean>(true);

  // 쿠팡 벤더아이디 비활성화 버튼의 상태를 관리
  const [disabledButtons, setDisabledButtons] = useState<{ [key: string]: boolean }>({});

  // 페이지 변경 이벤트
  useEffect(() => {
    if (pageChanged) {
      setPageChanged(false);
    } else {
      getUserData();
    }
  }, [nowPage]);

  // IP 검사
  useEffect(() => {
    async function ip() {
      try {
        await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/admin/ipCheck`, {
          headers: {
            'sol-type': 'ADMIN',
          },
        });
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: error.message,
          icon: 'error',
        });

        window.location.href = 'https://plto.com';
      }
    }
    ip();
  }, []);

  function onSubmit() {
    getUserData();
  }

  // 검색 이벤트
  async function getUserData() {
    Swal.fire({
      title: '잠시만 기다려 주세요.',
      html: '작업 조회중 ...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { result } = await axiosCustom.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/user`, {
        params: {
          solType: searchSolType,
          searchType: searchType,
          searchValue: searchValue,
          viewCount: viewCount,
          nowPage: nowPage,
        },
        headers: {
          'sol-type': 'ADMIN',
        },
      });

      if (result.userData.length <= 0 || result.totalCount === 0) {
        Swal.fire({
          title: '실패',
          html: `정보가 조회되지 않습니다.`,
          icon: 'error',
        });
      } else {
        // 유저 데이터 설정
        setUserData(result.userData);

        // 최대 페이지 설정
        setUserCount(Math.ceil(result.totalCount / viewCount));

        Swal.close();
      }
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    }
  }

  // 유저 강제 삭제 처리
  async function userDelete(userId: number) {
    const agreeSwal = await Swal.fire({
      title: '웨이크업 계정 삭제',
      html: '웨이크업 계정을 강제로 삭제처리 하시겠습니까?',
      showCancelButton: true,
      cancelButtonText: '취소',
      confirmButtonText: '확인',
    });

    // 동의하지 않았을 때
    if (!agreeSwal.isConfirmed) {
      return false;
    }

    try {
      const result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/user/delete/${userId}`, {
        timeout: 300000,
        headers: {
          'sol-type': 'ADMIN',
        },
      });

      if (result.success) {
        await Swal.fire({
          title: '성공',
          html: `계정이 삭제되었습니다`,
          icon: 'success',
        });
      } else {
        await Swal.fire({
          title: '실패',
          html: `${result.message}`,
          icon: 'error',
        });
      }
    } catch (error: any) {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    }
  }

  /**
   * @alias 11번가 스케줄러 비활성화
   * @description 스케줄러 :11번가로 매일 정해진 시간에 전체상품을 자동으로 이전해주는 서비스
   * 해당 스케줄러를 비활성화할 수 있는 기능
   * 활성화는 셀러만 직접 가능해야 하기 때문에 비활성화에서 활성화는 불가처리
   */
  async function schedulerDeactivate(userId: number, schedulerUse: boolean) {
    /* 비활성화에서 활성화는 불가처리 */
    if (!schedulerUse) {
      await Swal.fire({
        title: '실패',
        html: '상품 자동 이전 활성화는 셀러만 직접 가능합니다.',
        icon: 'error',
      });
      return false;
    } else {
      const agreeSwal = await Swal.fire({
        title: '스케줄러 비활성화',
        html: '스케줄러를 비활성화 하시겠습니까?',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
      });

      if (!agreeSwal.isConfirmed) {
        return false;
      }
      const userInfoString = localStorage.getItem('userInfo');
      let userInfo;
      /* 비활성화 처리 시작 */
      try {
        userInfo = JSON.parse(userInfoString!); // 문자열을 객체로 변환

        const result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/user/scheduler/deactivate/${userId}`, userInfo, {
          timeout: 300000,
          headers: {
            'sol-type': 'ADMIN',
          },
        });

        if (result.success) {
          await Swal.fire({
            title: '성공',
            html: `스케줄러 비활성화 처리되었습니다`,
            icon: 'success',
          });

          /*스케줄러를 비활성화 했을때 스위치 off처리*/
          setUserData(
            userData.map((one) => {
              if (one.userId.toString() === userId.toString()) {
                return {
                  ...one,
                  schedulerUseYn: !schedulerUse,
                };
              }
              return one;
            }),
          );
        } else {
          await Swal.fire({
            title: '실패',
            html: `${result.message}`,
            icon: 'error',
          });
        }
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: `${error.message}`,
          icon: 'error',
        });
      }
    }
  }
  /**
   * @alias 어드민 -> 쿠팡 밴더아이디 초기화
   * @description 어드민 > 멤버 조회 > 쿠팡 밴더아이디(etc5) 초기화(1->0)
   * etc5 밴더아이디 고정여부 0-미고정, 1-고정
   * 쿠팡의 요청으로 벤더 아이디를 수정 못하게 해놨는데, 셀러 요청시 초기화 수 있도록 처리
   */

  async function vendorIdReset(userId: number, etc5Value: number) {
    //
    if (etc5Value === 1) {
      const agreeSwal = await Swal.fire({
        title: '쿠팡 벤더 아이디 초기화',
        html: '해당 아이디를 초기화 하시겠습니까?',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
      });

      // 동의하지 않았을 때
      if (!agreeSwal.isConfirmed) {
        return false;
      }

      const userInfoString = localStorage.getItem('userInfo');
      let userInfo;
      /* 벤더아이디 초기화 처리 시작 */
      try {
        userInfo = JSON.parse(userInfoString!); // 문자열을 객체로 변환

        const result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/job/admin/user/coupang/reset-id/${userId}`, userInfo, {
          timeout: 300000,
          headers: {
            'sol-type': 'ADMIN',
          },
        });

        if (result.success) {
          await Swal.fire({
            title: '성공',
            html: '쿠팡 벤더 아이디가 초기화되었습니다.',
            icon: 'success',
          });
          // 벤더 아이디 초기화되면 etc5 value 변경
          setUserData(
            userData.map((one) => {
              if (one.userId.toString() === userId.toString()) {
                return {
                  ...one,
                  etc5: '0',
                };
              }
              return one;
            }),
          );
          // 벤더 아이디 초기화되면 아이콘 버튼 비활성화처리
          setDisabledButtons((prevState) => ({
            ...prevState,
            [userId]: true,
          }));
        }
      } catch (error: any) {
        await Swal.fire({
          title: '실패',
          html: `${error.message}`,
          icon: 'error',
        });
      }
    }

    return;
  }

  return (
    <Container noBody={true}>
      <Grid display="flex" alignItems="center" top={40} bottom={20}>
        <Grid display="flex" justifyContent="flex-end">
          {/* 솔루션 조회 키 */}
          <Select value={searchSolType} onChange={(event) => setSearchSolType(event.target.value)} variant="outlined" style={{ fontSize: '0.88rem' }}>
            <option value="">전체</option>
            <option value="COUPANG">쿠팡</option>
            <option value="WEMAKEPRICE">위메프</option>
            <option value="ST11">11번가</option>
            <option value="ALWAYZ">올웨이즈</option>
            <option value="KAKAO">카카오</option>
            <option value="ESM">ESM</option>
            <option value="TOSS">토스</option>
          </Select>
          &nbsp;
          {/* 조회 키 */}
          <Select value={searchType} onChange={(event) => setSearchType(event.target.value)} variant="outlined" style={{ fontSize: '0.88rem' }}>
            <option value="email">플레이오토 ID</option>
            <option value="gmpSolNo">2.0 솔루션번호</option>
          </Select>
          &nbsp;
          {/* 조회 값 */}
          <TextField
            type="text"
            placeholder="조회 할 내용"
            variant="outlined"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            style={{ fontSize: '0.88rem' }}
          />
          &nbsp;
          <Button color="main" width={80} size="small" borderRadius={5} onClick={onSubmit}>
            검색
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Table
          headerItems={[
            {
              width: 150,
              headerName: 'userId',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 250,
              headerName: '플레이오토ID',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 120,
              headerName: '웨이크업',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 140,
              headerName: '2.0 솔루션번호',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: 'etc1',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: 'etc2',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: 'etc3',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: 'etc4',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: 'etc5',
              align: 'center',
              headerAlign: 'center',
              editable: true,
            },
            {
              width: 110,
              headerName: 'schedulerUseYn',
              align: 'center',
              headerAlign: 'center',
              editable: true,
              renderCell: (params: any) => {
                const userId = params.row.col10;
                const schedulerUse = params.value;
                return <ToggleSwitch isChecked={params.value} onChange={() => schedulerDeactivate(userId, schedulerUse)} />;
              },
            },
            {
              width: 110,
              headerName: '쿠팡 벤더아이디 초기화',
              align: 'center',
              headerAlign: 'center',
              editable: true,
              renderCell: (params: any) => {
                const etc5Value = params.row.col8;
                const userId = params.row.col10;
                const wakeupValue = params.row.col2;
                //웨이크업정보가 쿠팡이 아니거나, 0이면 버튼 비활성화
                const isDisabled = disabledButtons[userId] || wakeupValue !== 'COUPANG' || etc5Value === 0;

                return (
                  <ButtonTag
                    color="white"
                    size="small"
                    variant="outlined"
                    width={40}
                    borderRadius={5}
                    disabled={isDisabled}
                    onClick={() => {
                      vendorIdReset(userId, etc5Value);
                    }}
                  >
                    <RiEditCircleLine size="0.77rem" />
                  </ButtonTag>
                );
              },
            },

            // {
            //   width: 110,
            //   headerName: '⚙',
            //   align: 'center',
            //   headerAlign: 'center',
            //   renderCell: (params: any) => {
            //     return (
            //       <Grid>
            //         <Text fontSize="0.77rem" fontWeight={500} lineHeight={24}>
            //           {params.value.userId}
            //         </Text>
            //         <Grid display="flex" justifyContent="center">
            //           {/* 스케줄러 비활성화 처리 */}
            //           <ButtonTag
            //             color="white"
            //             size="small"
            //             variant="outlined"
            //             width={40}
            //             borderRadius={5}
            //             onClick={() => {
            //               schedulerDeactivate(params.value);
            //             }}
            //           >
            //             <RiStopCircleLine size="0.77rem" />
            //           </ButtonTag>

            //           {/* 계정 삭제 */}
            //           <ButtonTag
            //             color="white"
            //             size="small"
            //             variant="outlined"
            //             width={40}
            //             borderRadius={5}
            //             onClick={() => {
            //               userDelete(params.value);
            //             }}
            //           >
            //             <RiDeleteBinLine size="0.77rem" />
            //           </ButtonTag>

            //         </Grid>
            //       </Grid>
            //     );
            //   },
            // },
          ]}
          bodyItems={userData.map((one) => [
            one.userId,
            one.email,
            one.solType,
            one.gmpSolNo,
            one.etc1,
            one.etc2,
            one.etc3,
            one.etc4,
            one.etc5,
            one.schedulerUseYn,
            one.userId,
          ])}
        />
        <Grid top={30} />
        <Pagination nowPage={nowPage} max={userCount} size={5} nowPageEvent={setNowPage} />
      </Grid>
    </Container>
  );
}
