import styled from 'styled-components';
import TitleWrap from '../../molecules/TitleWrap';
import BoxWrap from '../../molecules/BoxWrap';
import Grid from '../../atoms/Grid';
import Button from '../../atoms/Button';
import { IMemberProps } from '../../../../common/interface/props';

import LoginForm2 from '../../../form/Login';
import Text from '../../atoms/Text';
import { useNavigate } from 'react-router-dom';

const LeftGrid = styled(Grid)`
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const RightGrid = styled(Grid)`
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`;

function LoginForm({ solType }: IMemberProps) {
  const navigate = useNavigate(); // 히스토리 구하기
  async function onclick() {
    navigate(`/${solType.toLowerCase()}/member/create-account`); //해당 주소로 이동
  }
  return (
    <Grid display="flex" justifyContent="space-between">
      <LeftGrid right={50}>
        <TitleWrap />
        <BoxWrap>
          <BoxWrap.Bottom>
            <Grid display="flex" direction="column" alignItems="center" justifyContent="center" height={400}>
              <Grid bottom={30}>
                <Text fontSize="28px" fontWeight={500} textAlign="center">
                  아직
                </Text>
                <Text fontSize="28px" fontWeight={500} textAlign="center">
                  회원이 아니신가요?
                </Text>
              </Grid>
              <Button onClick={onclick} width={240} color="main">
                간편 회원가입
              </Button>
            </Grid>
          </BoxWrap.Bottom>
        </BoxWrap>
      </LeftGrid>

      <RightGrid>
        <TitleWrap />
        <BoxWrap>
          <BoxWrap.Bottom>
            <LoginForm2 solType={solType} />
          </BoxWrap.Bottom>
        </BoxWrap>
      </RightGrid>
    </Grid>
  );
}

export default LoginForm;
