import { IMemberProps } from '../../../../../common/interface/props';
import ProgressForm from '../../../../form/ProgressForm';
import Container from '../../../../UI/atoms/Container';
import Grid from '../../../../UI/atoms/Grid';

function Progress({ solType, text }: IMemberProps) {
  return (
    <Container noBody={true}>
      <ProgressForm solType={solType} />
    </Container>
  );
}

export default Progress;
