import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  // 하위 엘리먼트
  children?: ReactNode;

  // 디스플레이 유형
  display?: 'inline-block' | 'flex' | 'inline-flex' | 'block';

  // flex 방향
  direction?: 'row' | 'column';

  // flex 가로 정렬 기준
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';

  // flex 세로 정렬 기준
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline';

  // 패딩
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;

  // 가로
  width?: number | string;

  // 세로
  height?: number | string;

  // 마우스 커서 모양
  cursor?: 'pointer';

  // position
  position?: 'relative' | 'absolute';

  // color
  color?: 'main' | 'wakeup' | string;

  // 자식 텍스트 정렬
  textAlign?: 'center';
}

const Grid = styled.div<IProps>`
  // 디스플레이 유형
  ${({ display }) => (display ? `display: ${display};` : '')}

  // flex 방향
  ${({ direction }) => (direction ? `flex-direction: ${direction};` : '')}

  // flex 가로 정렬 기준
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}

  // flex 세로 정렬 기준
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  
  // 패딩
  ${({ top }) => (top ? `padding-top: ${top}px;` : '')}
  ${({ bottom }) => (bottom ? `padding-bottom: ${bottom}px;` : '')}
  ${({ left }) => (left ? `padding-left: ${left}px;` : '')}
  ${({ right }) => (right ? `padding-right: ${right}px;` : '')}

  // 가로
  width: ${({ display }) => (display && (display === 'inline-block' || display === 'inline-flex') ? 'auto' : '100%')};
  ${({ width }) => (width ? `max-width: ${typeof width === 'number' ? `${width}px` : width};` : '')}

  // 세로
  ${({ height }) => (height ? `height: ${typeof height === 'number' ? `${height}px` : height};` : '')}

  // 마우스 커서 모양
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}

  // position
  ${({ position }) => (position ? `position: ${position};` : '')}

  // color
  ${({ color, theme }) => (color ? `background-color: ${color === 'wakeup' ? theme.wakeUpColor : color === 'main' ? theme.pltoColor : color};` : '')}

  // 자식 텍스트 정렬
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}

  // 숨김처리
  ${({ hidden }) => (hidden ? `visibility: hidden;` : '')}
`;

// function Grid(props: IProps) {
//   return <GridTag {...props} />;
// }
export default Grid;
