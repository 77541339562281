// npm
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useMutation } from 'react-query';
// common
import { IMemberProps } from 'common/interface/props';
import { random } from 'common/common.function';
import { IBizRegNoOutput, ICreateAccountOutput, IEmailCheckOutput } from 'components/form/interfaces/member.interface';
import { checkingBizNo, checkIntergrationGMP, COUPANG, regexEmail, regexPassword, regexTel } from 'common/common.constants';

// atomic component
import Grid from 'components/UI/atoms/Grid';
import TextField from 'components/UI/atoms/TextField';
import Button from 'components/UI/atoms/Button';
import FormWrap from 'components/UI/molecules/FormWrap';
import InputWrap from 'components/UI/molecules/InputWrap';

// etc
import { FormError } from 'components/form/form-error';
import CheckBox from 'components/UI/atoms/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux_modules';
import { bizNoCheckMutation, emailCheckMutation, createAccountMutation } from 'components/form/api/member.api';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { loginSuccessRequest } from 'redux_modules/login';

export default function CreateAccount({ solType }: IMemberProps) {
  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { agreeInfo } = useSelector((state: RootState) => ({
    agreeInfo: state.agreeInfo,
  }));

  // 진행 스텝 상태
  const [step, setStep] = React.useState({
    email: false,
    authCode: false,
    bizRegNo: false,
    verifiedBizRegNo: '',
  });

  const navigate = useNavigate(); // 히스토리 구하기
  const [searchParams] = useSearchParams(); // url param 가져오기
  const location = useLocation();
  const dispatch = useDispatch();
  const password: any = React.useRef(); //회원가입 전용, 비밀번호와 비밀번호 체크 일치여부 확인시 필요
  password.current = watch('password'); //비밀번호에 입력한 값
  const [loading, setLoading] = useState(false); // 회원가입 진행중인경우, 버튼 이벤트 막음
  const [isSt11Valid, setIsSt11Valid] = useState<Boolean>(false); // 11번가 주소록 저장 여부

  /** 사업자번호가 변경되면, 사업자번호조회 다시 하게 함 */
  if (step.bizRegNo && step.verifiedBizRegNo && step.verifiedBizRegNo !== watch('bizRegNo')) {
    setStep({
      ...step,
      verifiedBizRegNo: '',
      bizRegNo: false,
    });

    Swal.fire({
      title: '실패',
      html: `사업자 번호가 변경되었습니다. <br> 사업자 번호 조회를 진행해주시기 바랍니다.`,
      icon: 'warning',
      confirmButtonText: '확인',
    });
  }

  React.useEffect(() => {
    const sendAddressSeq = searchParams.get('sendAddressSeq');
    const returnAddressSeq = searchParams.get('returnAddressSeq');

    if (sendAddressSeq && returnAddressSeq) {
      sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq: sendAddressSeq, returnAddressSeq: returnAddressSeq }));
    }
  }, []);

  /**
   * 2.0 연동 오픈 용 소스이며, 쿠팡만 선오픈 되므로 임시 주석처리
   * 11번가 유효성 검사 함수, 11번가는 셀러오피스를 통해 들어온 업체만 사용가능
   */
  async function st11Validation() {
    if (location.pathname.includes('st11')) {
      const sendAddressSeq = searchParams.get('sendAddressSeq');
      const returnAddressSeq = searchParams.get('returnAddressSeq');
      const sessionAddressReq = sessionStorage.getItem('addressSeq');
      if (process.env.REACT_APP_DEV === 'true') {
        sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq: '2', returnAddressSeq: '3' }));
        setIsSt11Valid(true);
      } else if (sessionAddressReq) {
        setIsSt11Valid(true);
      } else if (sendAddressSeq && returnAddressSeq) {
        sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq: sendAddressSeq, returnAddressSeq: returnAddressSeq }));
        setIsSt11Valid(true);
      } else {
        // await Swal.fire({
        //   title: '실패',
        //   html: '11번가 SellerOffice 통해 접속한 사용자만 사용이 가능합니다.SellerOffice 출고지, 반품/교환지 정보를 입력한 후, 접속해주시기 바랍니다.',
        //   icon: 'error',
        //   showCancelButton: true,
        //   cancelButtonText: '취소',
        //   confirmButtonText: '11번가 접속',
        //   preConfirm: () => {
        //     window.open('https://soffice.11st.co.kr/register/popupPlayauto.tmall?method=popupPlayauto');
        //   },
        // });
        // Swal.close();
        // return false;
        setIsSt11Valid(false);
      }
    }
    return true;
  }

  React.useEffect(() => {
    st11Validation();
  }, []);

  const { mutate: emailCheck } = useMutation(emailCheckMutation, {
    // mutate 함수가 실행되기 전에 실행
    onMutate: (variables) => {
      // 무한 로딩 화면 생성
      Swal.fire({
        title: `회원가입 중...`,
        html: '인증메일 발송중...', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
    },
    onSuccess: async (data: IEmailCheckOutput, variables) => {
      await Swal.fire({
        title: '성공',
        html: `사용 가능한 이메일 주소 입니다. 인증을 위한 메일이 발송되었으니 메일을 확인해 주세요.`,
        icon: 'success',
        confirmButtonText: '확인',
      });

      setStep({
        ...step,
        email: true,
        authCode: false,
        bizRegNo: false,
      });
    },
    onError: async (error: any) => {
      await Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    },
  });

  const { mutate: bizNoCheck } = useMutation(bizNoCheckMutation, {
    onMutate: (variable) => {},
    onSuccess: async (data: IBizRegNoOutput, variable) => {
      await Swal.fire({
        title: '성공',
        html: `사업자번호가 확인되었습니다. 회원가입을 진행해주세요`,
        icon: 'success',
        confirmButtonText: '확인',
      });
      setStep({
        ...step,
        email: true,
        authCode: true,
        bizRegNo: true,
        verifiedBizRegNo: String(variable.bizRegNo),
      });
    },
    onError: (error: any) => {
      Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    },
  });

  const { mutate: createAccount } = useMutation(createAccountMutation, {
    onMutate: (variables) => {},
    onSuccess: async (data: ICreateAccountOutput, variables) => {
      setLoading(false);
      if (data && data.success) {
        const result = await Swal.fire({
          title: '성공',
          html: `회원가입 성공`,
          icon: 'success',
          confirmButtonText: '확인',
        });

        if (result.isConfirmed) {
          const token = data.gmpToken;
          const isIntergrationGMP = checkIntergrationGMP(solType);

          // 11번가는 셀러오피스에서 주소록 선택 후 웨이크업으로 와야 작업이 가능한데
          // 그렇지 않을 경우에는 비로그인 상태로 로그인페이지로 이동시켜준다.
          if (!isSt11Valid && location.pathname.includes('st11')) {
            navigate(`/${solType.toLowerCase()}/member/login`);
            return true;
          }

          // 2.0 사용안함(REACT_APP_GMP_NOT_USED === 'true')이면, 2.0 모달 페이지로 이동 안하고 바로 shop-login으로 이동
          if (process.env.REACT_APP_GMP_NOT_USED === 'true' || !isIntergrationGMP) {
            dispatch(
              loginSuccessRequest({
                isLogined: true,
                isAdmin: true,
                isSolExpired: false,
              }),
            );

            // dev2 에서만 여기서 인증값 세팅하고
            // 일반 환경에서는 token-login 폼에서 세팅 함
            sessionStorage.setItem('wakeupInfo', JSON.stringify({ gmpToken: token }));
            // 이메일이랑 솔타입 편하게 꺼내쓰기 위해 넣음
            sessionStorage.setItem('userInfo', JSON.stringify({ email: variables.email, solType: variables.solType }));

            navigate(`/${solType.toLowerCase()}/job/shop-login`);

            return true;
          }

          // 일반 환경에서는 2.0 솔루션에서 모달로 웨이크업 솔루션이 띄워짐
          window.location.href = `${process.env.REACT_APP_GMP_API_ENDPOINT}/login.html?utm_source=letter&utm_medium=email&utm_campaign=wakeup&utm_content=complete_mail_1&p=/startWakeUp&wakeup_user_token=${token}`;

          return true;
        }
      }
    },
    onError: (error: any) => {
      setLoading(false);
      Swal.fire({
        title: '실패',
        html: `${error.message}`,
        icon: 'error',
      });
    },
  });

  //step1 - 이메일 중복조회 및 인증
  async function onEmailCheck() {
    const { email } = getValues();
    const authCode = random(8);
    sessionStorage.setItem('emailAuthCode', authCode.toString());
    //  * 2.0 연동 오픈 용 소스이며, 쿠팡만 선오픈 되므로 임시 주석처리
    // //11번가 유효성 검사
    // const st11ValidationResult = await st11Validation();
    // // useMutation 실행
    // st11ValidationResult && emailCheck({ email, authCode });

    emailCheck({ email, authCode, solType });
  }

  //step2 - 인증번호 확인
  async function onConfirmCodeCheck() {
    const { confirmCode } = getValues();
    try {
      const authCode = sessionStorage.getItem('emailAuthCode');
      if (confirmCode.toString() !== authCode) {
        await Swal.fire({
          title: '실패',
          html: `인증번호가 일치하지 않습니다.`,
          icon: 'error',
          confirmButtonText: '확인',
        });
      } else {
        await Swal.fire({
          title: '성공',
          html: `인증번호가 확인되었습니다. 회원가입을 진행해주세요`,
          icon: 'success',
          confirmButtonText: '확인',
        });
        solType !== COUPANG
          ? setStep({
              ...step,
              email: true,
              authCode: true,
              bizRegNo: false,
            })
          : setStep({
              ...step,
              email: true,
              authCode: true,
              bizRegNo: true,
            });
      }
    } catch (e: any) {
      Swal.fire({
        title: '실패',
        html: `${e.message}`,
        icon: 'error',
      });
    }
  }

  // Step3- 사업자번호 확인
  async function onBizNoCheck() {
    let { bizRegNo } = getValues();
    bizRegNo = parseInt(bizRegNo);
    //쿠팡 제외하고는 모두 사업제 체크
    if (solType !== COUPANG) {
      if (!step.authCode) {
        await Swal.fire({
          title: '실패',
          html: `인증번호를 먼저 확인해 주시기 바랍니다.`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return false;
      }
      bizNoCheck({ bizRegNo, solType });
    }
  }

  //step3 회원가입
  //() -> react에서는 e(Event)를 받지만 react-hook-form에서는 data를 받음
  async function onSubmit() {
    // 진행중인 경우 중복 호출되지 않도록 함
    if (loading) {
      return;
    }

    try {
      if (step.email && step.authCode && step.bizRegNo && agreeInfo.status === 'SUCCESS') {
        let { email, bizRegNo, password, tel, smsAgree, mailAgree } = getValues();
        bizRegNo = parseInt(bizRegNo);

        // sms, 이메일 수신 동의 하나라도 미체크 시
        if (smsAgree === false || mailAgree === false) {
          const result = await Swal.fire({
            title: 'SMS 및 이메일 알림 동의',
            html: `SMS 및 이메일 알림을 동의하지 않으셨습니다. <br />
                      동의하지 않을 경우 <b>상품등록 완료 알림</b>을 받으실 수 없습니다.
                      <strong>SMS 및 이메일 알림에 동의 하시겠습니까?</strong>`,
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: '동의',
          });

          if (result.value === true) {
            smsAgree = true;
            mailAgree = true;
          }
        }

        setLoading(true); // 진행중
        createAccount({ solType, email, bizRegNo, password, tel, smsAgree, mailAgree });
      } else {
        if (!step.email) {
          await Swal.fire({
            title: '실패',
            html: `이메일 중복 조회를 먼저 진행해 주시기 바랍니다.`,
            icon: 'warning',
            confirmButtonText: '확인',
          });
        }

        if (step.email && !step.authCode) {
          await Swal.fire({
            title: '실패',
            html: `이메일 인증번호를 입력해 주시기 바랍니다.`,
            icon: 'error',
            confirmButtonText: '확인',
          });
        }
        if (solType !== COUPANG && step.email && step.authCode && !step.bizRegNo) {
          await Swal.fire({
            title: '실패',
            html: `사업자 번호를 조회해 주시기 바랍니다.`,
            icon: 'error',
            confirmButtonText: '확인',
          });
        }
        if (agreeInfo.status !== 'SUCCESS') {
          await Swal.fire({
            title: '실패',
            html: `이용약관에 동의해주시기 바랍니다.`,
            icon: 'error',
            confirmButtonText: '확인',
          });
        }
      }
    } catch (e: any) {
      setLoading(false);
      Swal.fire({
        title: '실패',
        html: `${e.message}`,
        icon: 'error',
      });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrap>
          <FormWrap.Top>
            <Grid top={50}>
              <InputWrap
                label="이메일 ID"
                labelWidth={140}
                input={
                  <TextField
                    type="email"
                    placeholder="이메일 형식으로 입력해 주세요."
                    disabled={step.authCode}
                    {...{ ...register(`email`, { required: true, maxLength: 50, pattern: regexEmail }) }}
                  />
                }
                adornment={
                  <Button width={140} size="small" color="main" variant="outlined" onClick={handleSubmit(onEmailCheck)} disabled={step.authCode}>
                    {step.authCode ? '인증완료' : '중복조회 및 인증'}
                  </Button>
                }
                error={
                  errors?.email && errors.email.type === 'required' ? (
                    <FormError errorMessage={'이메일은 필수 입력 항목입니다.'} />
                  ) : errors?.email && errors.email.type === 'maxLength' ? (
                    <FormError errorMessage={'이메일의 최대 길이를 초과하였습니다.(최대 50자)'} />
                  ) : errors?.email && errors.email.type === 'pattern' ? (
                    <FormError errorMessage={'올바른 이메일 형식이 아닙니다.'} />
                  ) : (
                    <></>
                  )
                }
              />
            </Grid>

            {step.email && !step.authCode && (
              <Grid top={50}>
                <InputWrap
                  label="인증번호"
                  labelWidth={140}
                  input={
                    <TextField
                      type="text"
                      placeholder="인증번호를 입력해 주세요."
                      {...register(`confirmCode`, {
                        required: step.email,
                        pattern: regexTel,
                        minLength: 8,
                        maxLength: 8,
                      })}
                    />
                  }
                  error={
                    errors?.confirmCode && errors.confirmCode.type === 'required' ? (
                      <FormError errorMessage={'인증번호는 필수 입력 항목입니다.'} />
                    ) : errors?.confirmCode && errors.confirmCode.type === 'minLength' ? (
                      <FormError errorMessage={'인증번호는 발급받은 8자리로만 입력해 주세요'} />
                    ) : errors?.confirmCode && errors.confirmCode.type === 'maxLength' ? (
                      <FormError errorMessage={'인증번호는 발급받은 8자리로만 입력해 주세요'} />
                    ) : errors?.confirmCode && errors.confirmCode.type === 'pattern' ? (
                      <FormError errorMessage={'인증번호는 숫자만 입력해 주세요'} />
                    ) : (
                      <></>
                    )
                  }
                  adornment={
                    <Button width={140} size="small" variant="outlined" color="main" onClick={handleSubmit(onConfirmCodeCheck)}>
                      인증번호 확인
                    </Button>
                  }
                />
              </Grid>
            )}
            {solType !== COUPANG && (
              <Grid top={50}>
                <InputWrap
                  label="사업자 번호"
                  labelWidth={140}
                  input={
                    <TextField
                      type="text"
                      placeholder="숫자만 입력해 주세요."
                      {...{
                        ...(step.email &&
                          step.authCode &&
                          register(`bizRegNo`, { required: true, pattern: regexTel, validate: (value) => checkingBizNo(value) })),
                      }}
                    />
                  }
                  adornment={
                    <Button width={140} size="small" color="main" variant="outlined" onClick={handleSubmit(onBizNoCheck)}>
                      사업자 번호 조회
                    </Button>
                  }
                  error={
                    errors?.bizRegNo && errors.bizRegNo.type === 'required' ? (
                      <FormError errorMessage={'사업자 번호는 필수 입력 항목입니다.'} />
                    ) : errors?.bizRegNo && errors.bizRegNo.type === 'pattern' ? (
                      <FormError errorMessage={'사업자 번호는 숫자만 입력해 주세요.'} />
                    ) : errors?.bizRegNo && errors.bizRegNo.type === 'validate' ? (
                      <FormError errorMessage={'유효하지 않은 사업자번호 입니다.'} />
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>
            )}

            <Grid top={50}>
              <InputWrap
                label="비밀번호"
                labelWidth={140}
                input={
                  <TextField
                    type="password"
                    placeholder="영문 + 숫자 + 특수 문자 조합으로 8자 이상 입력해주세요."
                    {...{
                      ...(step.email &&
                        step.authCode &&
                        step.bizRegNo &&
                        register(`password`, { required: step.authCode, minLength: 8, pattern: regexPassword })),
                    }}
                  />
                }
                error={
                  errors?.password && errors.password.type === 'required' ? (
                    <FormError errorMessage={'비밀번호는 필수 입력 항목입니다.'} />
                  ) : errors?.password && errors.password.type === 'minLength' ? (
                    <FormError errorMessage={'비밀번호는 8자 이상 입력해 주세요.'} />
                  ) : errors?.password && errors.password.type === 'pattern' ? (
                    <FormError errorMessage={'비밀번호는 영문+숫자+특문 8자 이상 입력해 주세요.'} />
                  ) : (
                    <></>
                  )
                }
              />
            </Grid>

            <Grid top={50}>
              <InputWrap
                label="비밀번호확인"
                labelWidth={140}
                input={
                  <TextField
                    type="password"
                    placeholder="비밀번호를 한번 더 입력해 주세요."
                    {...(step.email &&
                      step.authCode &&
                      step.bizRegNo && {
                        ...register(`confirmPassword`, {
                          required: step.authCode,
                          minLength: 8,
                          pattern: regexPassword,
                          validate: (value) => value === password.current,
                        }),
                      })}
                  />
                }
                error={
                  errors?.confirmPassword && errors.confirmPassword.type === 'required' ? (
                    <FormError errorMessage={'비밀번호체크는 필수 입력 항목입니다.'} />
                  ) : errors?.confirmPassword && errors.confirmPassword.type === 'minLength' ? (
                    <FormError errorMessage={'비밀번호는 8자 이상 입력해 주세요.'} />
                  ) : errors?.confirmPassword && errors.confirmPassword.type === 'pattern' ? (
                    <FormError errorMessage={'비밀번호는 영문+숫자+특문 8자 이상 입력해 주세요.'} />
                  ) : errors?.confirmPassword && errors.confirmPassword.type === 'validate' && step.authCode ? (
                    <FormError errorMessage={'비밀번호와 일치하지 않습니다.'} />
                  ) : (
                    <></>
                  )
                }
              />
            </Grid>

            <Grid top={50}>
              <InputWrap
                label="휴대폰번호"
                labelWidth={140}
                input={
                  <TextField
                    type="text"
                    placeholder="(-)을 제외한 숫자만 입력해 주세요."
                    {...{
                      ...(step.email &&
                        step.authCode &&
                        step.bizRegNo &&
                        register(`tel`, {
                          required: step.authCode,
                          pattern: regexTel,
                          // valueAsNumber: true,
                        })),
                    }}
                  />
                }
                error={
                  errors?.tel && errors.tel.type === 'required' ? (
                    <FormError errorMessage={'휴대폰 번호는 필수 입력 항목입니다.'} />
                  ) : errors?.tel && errors.tel.type === 'pattern' ? (
                    <FormError errorMessage={'휴대폰 번호는 숫자만 입력해 주세요.'} />
                  ) : (
                    <></>
                  )
                }
              />
            </Grid>

            <Grid top={50}>
              <InputWrap
                label="알림수신동의"
                labelWidth={140}
                input={
                  <>
                    <Grid>
                      <CheckBox label="SMS 동의" {...register('smsAgree')} defaultChecked={true} />
                      <CheckBox label="이메일 동의" {...register('mailAgree')} defaultChecked={true} />
                    </Grid>
                    <p style={{ fontSize: '0.77rem', letterSpacing: '-1px', color: '#565656', marginTop: '7px' }}>
                      * 상품 이전 완료 시 알림 및 프로모션 등 플레이오토에서 제공하는 알림 수신에 동의합니다.
                    </p>
                  </>
                }
              />
            </Grid>
          </FormWrap.Top>
          <FormWrap.Bottom>
            <Grid display="flex" justifyContent="center">
              <Button color="main" variant="contained" size="medium" width={240}>
                다음
              </Button>
            </Grid>
          </FormWrap.Bottom>
        </FormWrap>
      </form>
    </>
  );
}
