import { IMemberProps } from '../../../../../common/interface/props';
import Grid from '../../../../UI/atoms/Grid';
import WakeUpLoginForm from '../../../../UI/organisms/WakeUpLoginForm';
import Container from '../../../../UI/atoms/Container';
import Banner from '../../../../UI/molecules/Banner';

export default function WakeUpLogin({ solType }: IMemberProps) {
  return (
    <>
      {/* <Banner imgPath="/images/bg_title3.png" text="상품등록" /> */}

      <Container noBody={true}>
        <WakeUpLoginForm solType={solType} />
      </Container>
    </>
  );
}
