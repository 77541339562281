import Grid from 'components/UI/atoms/Grid';
import { forwardRef, MouseEventHandler, ChangeEventHandler } from 'react';
import styled from 'styled-components';

interface IProps {
  id?: string;
  name: string;
  checked?: boolean;
  label?: string;
  value?: any;
  defaultChecked?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const RadioInput = styled.input.attrs({ type: 'radio' })`
  cursor: pointer;
`;

const RadioLabel = styled.label`
  position: relative;
  padding-left: 6px;
  padding-bottom: 3.5px;
  margin-right: 30px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
`;

const RadioButton = ({ id, name, value, checked, onChange, label }: IProps) => {
  return (
    <>
      <Grid display="inline-flex" alignItems="center" justifyContent="center">
        <RadioInput id={id} name={name} value={value} checked={checked} onChange={onChange} />
        <RadioLabel htmlFor={id}>{label}</RadioLabel>
      </Grid>
    </>
  );
};

export default RadioButton;
