import { ScrapType, TargetSolTypeRole } from 'common/common.constants';
import { WakeUp } from 'common/interface/props';
import Button from 'components/UI/atoms/Button';
import Grid from 'components/UI/atoms/Grid';
import RadioButton from 'components/UI/atoms/RadioButton';
import { ISelectShops } from 'components/form/WakeupLoginForm';

interface IProps {
  solType: WakeUp;
  shop: TargetSolTypeRole;
  selectShops: ISelectShops;
  setDataEvent: (arg0: boolean, arg1: TargetSolTypeRole) => Promise<any>;
}

function SpecificWrap({ solType, shop, selectShops, setDataEvent }: IProps) {
  return (
    <>
      {solType === 'ALL' || solType === 'ESM' ? (
        <Grid display="flex">
          <RadioButton
            name={`${shop}_scrapType`}
            id={`${shop}_scrapType_normal`}
            label={'전체 상품'}
            value={'normal'}
            checked={selectShops[shop]?.scrapType === ScrapType.NORMAL}
            onChange={async (e) => {
              await setDataEvent(false, shop);
            }}
          />
          <RadioButton
            name={`${shop}_scrapType`}
            id={`${shop}_scrapType_specific`}
            label={'지정 상품'}
            value={ScrapType.SPECIFIC}
            checked={selectShops[shop]?.scrapType === ScrapType.SPECIFIC}
            onChange={async (e) => {
              await setDataEvent(true, shop);
            }}
          />

          <Button
            display={selectShops[shop]?.scrapType === ScrapType.NORMAL ? 'none' : 'block'}
            size="small"
            width={70}
            color="main"
            type="button"
            onClick={async () => {
              await setDataEvent(true, shop);
            }}
          >
            수정
          </Button>
        </Grid>
      ) : (
        <>
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href=""
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'underline',
                color: 'rgb(51,51,51)',
                fontSize: '0.88rem',
                fontWeight: 500,
                height: 'auto',
              }}
              onClick={async (_) => {
                _.preventDefault();
                setDataEvent(true, shop);
              }}
            >
              지정 상품 등록하기
            </a>
          }
        </>
      )}
    </>
  );
}

export default SpecificWrap;
