import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { WAKEUP_FORM_DATA } from 'common/common.constants';
import { WakeUp } from 'common/interface/props';
import Button from 'components/UI/atoms/Button';
import Grid from 'components/UI/atoms/Grid';
import Line from 'components/UI/atoms/Line';
import Text from 'components/UI/atoms/Text';
import Tooltip from 'components/UI/atoms/Tooltip';
import React from 'react';

/**
 * 통계 테이블 컴포넌트
 */
export default function StatisticsTable({ type, excelDownloadEvent, columns, rows, interSolType, selectSolType, changeSolType }: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [selectShop, setSelectShop] = React.useState<WakeUp>();

  React.useEffect(() => {
    switch (type) {
      case 'MONTH':
        setSelectShop(selectSolType.selectMonthSolType);
        break;

      case 'WEEK':
        setSelectShop(selectSolType.selectWeekSolType);
        break;
    }
  }, [type, selectSolType]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Grid display="flex" bottom={interSolType === 'ALL' ? 10 : 0}>
          <Text fontSize="30px" fontWeight={600} color="#444">
            {type === 'MONTH' ? '월간' : type === 'WEEK' ? '주간' : ''} 통계
          </Text>
        </Grid>
        {['ALL', 'ESM'].includes(interSolType) ? (
          <></>
        ) : (
          <Button type="button" color="main" variant="outlined" size="small" width={120} onClick={() => excelDownloadEvent(type)}>
            엑셀 다운로드
          </Button>
        )}
      </Grid>

      <Grid display={'flex'} justifyContent={'space-between'} alignItems="center" top={0} bottom={10}>
        {interSolType === 'ALL' ? (
          <>
            <Grid display={'flex'} width={600} top={0}>
              {['ALL', 'COUPANG', 'WEMAKEPRICE', 'ST11', 'ALWAYZ', 'KAKAO'].map((one) => (
                <Grid right={5}>
                  <Button
                    borderRadius={0}
                    type="button"
                    color="main"
                    variant={`${selectShop === one ? 'contained' : 'disabled'}`}
                    size="small"
                    width={120}
                    onClick={() => changeSolType(type, one)}
                  >
                    {WAKEUP_FORM_DATA[one as WakeUp].name.type1}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Button type="button" color="main" variant="outlined" size="small" width={120} onClick={() => excelDownloadEvent(type)}>
              엑셀 다운로드
            </Button>
          </>
        ) : interSolType === 'ESM' ? (
          <>
            <Grid display={'flex'} width={600} top={0}>
              {['ESM_ALL', 'ESM', 'AUCTION', 'GMARKET'].map((one) => (
                <Grid right={5}>
                  <Button
                    borderRadius={0}
                    type="button"
                    color="main"
                    variant={`${selectShop === one ? 'contained' : 'disabled'}`}
                    size="small"
                    width={120}
                    onClick={() => changeSolType(type, one)}
                  >
                    {one === 'ESM_ALL' ? '전체' : one === 'ESM' ? '옥션&G마켓' : WAKEUP_FORM_DATA[one as WakeUp].name.type1}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Button type="button" color="main" variant="outlined" size="small" width={120} onClick={() => excelDownloadEvent(type)}>
              엑셀 다운로드
            </Button>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Line height={2} color="#000" />
      <Table>
        <TableHead>
          <TableRow>
            {Object.entries(columns).map(([key, value]: any) => (
              <TableCell style={{ fontWeight: 'bold' }} align="center" size="small">
                {key}
                {key !== '날짜' ? (
                  <Tooltip title={value}>
                    <Grid color="#f2f4f7" left={2} right={2}>
                      ?
                    </Grid>
                  </Tooltip>
                ) : (
                  ''
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((one: any) => (
            <TableRow key={one.index}>
              <TableCell align="center">{one.day}</TableCell>
              <TableCell align="center">{one.joinSellerCount}</TableCell>
              <TableCell align="center">{one.prodCount}</TableCell>
              <TableCell align="center">{one.migrationFirstSellerCount}</TableCell>
              <TableCell align="center">{one.migrationSellerCount}</TableCell>
              <TableCell align="center">{one.sellerAverageProdCount}</TableCell>
              <TableCell align="center">{one.successRate}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
