import styled from 'styled-components';

interface IProps {
  progress: number;
  color: 'main' | 'wakeup';
}

const ProgressWrap = styled.div`
  height: 20px;
  background-color: #eee;
  border-radius: 20px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ width: number; color: IProps['color'] }>`
  width: ${({ width }) => width}%;
  height: 100%;
  background-color: ${({ theme, color }) => (color === 'wakeup' ? theme.wakeUpColor : theme.pltoColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: 0.3s;
`;

function Progress({ progress, color }: IProps) {
  return (
    <ProgressWrap>
      <ProgressBar width={progress} color={color} />
    </ProgressWrap>
  );
}

export default Progress;
