import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
}

const FormWrapTag = styled.div`
  margin-top: 40px;
  border: 1px solid #dddddd;
  padding: 40px 111px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 50px 10px 10px 10px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 50px 10px 10px 10px;
  }
`;

const FormTop = styled.div`
  background-color: #f7f7f7;
  padding: 10px 80px 10px 80px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 10px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 10px;
  }
`;

const FormBottom = styled.div`
  padding: 40px 0;
`;

const FormBox = styled.div`
  background-color: #f7f7f7;
  padding: 70px 80px;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 10px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 10px;
  }
`

function FormWrap({ children }: IProps) {
  return <FormWrapTag>{children}</FormWrapTag>;
}

FormWrap.Top = FormTop;
FormWrap.Bottom = FormBottom;
FormWrap.Box = FormBox;

export default FormWrap;
