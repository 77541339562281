import { useEffect, useState } from 'react';

import Dashboard from './DashBoard';
import MemberBoard from './MemberBoard';
import JobReSend from './JobReSend';
import ProdCompare from './ProdCompare';
import Container from '../UI/atoms/Container';
import Text from '../UI/atoms/Text';
import Line from '../UI/atoms/Line';
import Grid from '../UI/atoms/Grid';
import { useParams } from 'react-router-dom';
import { WakeUp } from 'common/interface/props';
import Tab from 'components/UI/atoms/Tab';
import { TargetSolTypeRole } from 'common/common.constants';
import { ISearchData } from 'components/admin/interfaces/admin.interface';
import Statistics from 'components/admin/Statistics';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { axiosCustom } from 'common/common.function';

interface IProps {
  isIntegrated: boolean;
}

export default function Admin({ isIntegrated }: IProps) {
  //url을 통해 폰어드민에서 토큰을 전달받음
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>();
  const params = useParams();

  // 통합 어드민 페이지의 기본  값은 coupang
  const solType = isIntegrated ? 'COUPANG' : (params.solType!.toUpperCase() as WakeUp);

  // 연동관리 타입 (통계, 상세조회)
  const [adminType, setAdminType] = useState<string>('통계관리');
  const [searchData, setSearchData] = useState<ISearchData>({
    jobId: '',
    solType: TargetSolTypeRole.COUPANG,
  });

  // 연동관리 타입 변경 이벤트
  const changeAdmintype = (adminType: string) => {
    setAdminType(adminType);
  };

  // 작업 재시도 완료 이벤트
  const handleJobReSendComplete = (searchData: ISearchData) => {
    setAdminType('상세조회');
    setSearchData({
      jobId: searchData.jobId,
      solType: searchData.solType,
    });
  };

  /**
   * @description 폰 어드민에서 전달받은 토큰이 없으면 통합어드민은 접속 불가 그외의 사이트 어드민은 상관없음
   */
  useEffect(() => {
    const fetchData = async () => {
      const token = searchParams.get('token');
      if (token) {
        setToken(token);
        return true;
      }
      //만약 개발환경에서 접속하면 접속 허가
      if (!token && process.env.REACT_APP_DEV === 'true') {
        return true;
      }

      await Swal.fire({
        title: '실패',
        html: `정상적인 경로가 아닙니다. 로그인 후 이용해주세요.`,
        icon: 'error',
      });
      //폰어드민으로 redirect
      window.location.href = `https://pawn-admin.plto.com/`;
    };
    //통합 어드민에만 적용
    isIntegrated && fetchData();
  }, []);

  /**
   * @description 통합 어드민 단독 접속시 폰어드민 로그인하라는 메시지와 함께 redirect 시키고, 폰어드민 로그인 후 웨이크업 어드민 사용할 수 있도록 함
   * 폰 어드민 토큰 웨이크업으로 전달 -> 통합 어드민 접속시에 폰 어드민에 토큰을 기반으로 유저 유효성 검사 및 정보 받아옴
   * -> 받아온 정보로 로그 기록 및 접근권한 부여
   */
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorage.setItem('jwt', token);

        //폰어드민에 유저 유효성 검사 로직
        try {
          const sendUrlResponse = await axiosCustom.get(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/auth/get-user-info`, {
            headers: {
              jwt: token,
            },
          });
          // 어드민 권한이 없다면
          if (!sendUrlResponse || (sendUrlResponse && sendUrlResponse.userInfo.Type !== 'ADMIN')) {
            await Swal.fire({
              title: '실패',
              html: `어드민 권한이 필요한 서비스입니다. 폰팀에 문의해주세요.`,
              icon: 'error',
            });

            return;
          } else {
            //어드민 권한이 있다면 로컬스토리지에 유저정보 저장 (로그 기록에 사용)
            localStorage.setItem('userInfo', JSON.stringify(sendUrlResponse.userInfo));
          }
          //그외 통신 에러 메시지
        } catch (error: any) {
          console.log(error);
          await Swal.fire({
            title: 'Error',
            text: error.message,
            showConfirmButton: true,
          });
        }
      }
    };
    isIntegrated && fetchData();
  }, [token]);

  return (
    <Container>
      <Grid bottom={10}>
        <Grid bottom={40}>
          <Text fontSize="2rem" fontWeight={600} color="#444">
            WAKEUP {adminType}
          </Text>
        </Grid>
        <Tab
          currentTab={adminType}
          tabNames={isIntegrated ? ['통계관리', '상세조회', '멤버조회', '작업 재시도', '상품 유사도'] : ['통계관리', '상세조회']}
          tabNodes={[
            <Statistics solType={solType} isIntegrated={isIntegrated} />,
            <Dashboard solType={solType} isIntegrated={isIntegrated} searchData={searchData} setSearchData={setSearchData} />,
            isIntegrated && <MemberBoard />,
            isIntegrated && <JobReSend onComplete={handleJobReSendComplete} />,
            isIntegrated && <ProdCompare />,
          ]}
          tabSwitchEvent={changeAdmintype}
          tabSwitchValues={isIntegrated ? ['통계관리', '상세조회', '멤버조회', '작업 재시도', '상품 유사도'] : ['통계관리', '상세조회']}
        />
      </Grid>
      <Line color="#e8e8e8" />
    </Container>
  );
}
