import { IMemberProps } from 'common/interface/props';
import WakeUpLoginForm from 'components/UI/organisms/WakeUpLoginForm';
import Container from 'components/UI/atoms/Container';

export default function WakeUpLogin({ solType }: IMemberProps) {
  return (
    <>
      {/* <Banner imgPath="/images/bg_title3.png" text="상품등록" /> */}

      <Container noBody={true}>
        <WakeUpLoginForm solType={solType} />
      </Container>
    </>
  );
}
