import { IMemberProps } from '../../../../../common/interface/props';

import Grid from '../../../../UI/atoms/Grid';
import Banner from '../../../../UI/molecules/Banner';
import LoginForm from '../../../../UI/organisms/LoginForm';
import LoginGuide from '../../../../UI/organisms/LoginGuide';
import Container from '../../../../UI/atoms/Container';
import CreateAccountGuide from 'components/UI/organisms/CreateAccountGuide';

export default function Login({ solType }: IMemberProps) {
  return (
    <>
      <Container noBody={true}>
        <CreateAccountGuide solType={'COUPANG'} />
        <Grid top={30} bottom={30}>
          <LoginGuide />
        </Grid>
        <Grid bottom={50}>
          <LoginForm solType={solType} />
        </Grid>
      </Container>
    </>
  );
}
