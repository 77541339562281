/* 액션 타입 만들기 */
// Ducks 패턴을 따를땐 액션의 이름에 접두사를 넣어주세요.

// 이렇게 하면 다른 모듈과 액션 이름이 중복되는 것을 방지 할 수 있습니다.
export const WAKEUP_LOGIN_SUCCESS = 'wakeup_login/WAKEUP_LOGIN_SUCCESS';

/**
 * ALL 타입은 한번에 여러 쇼핑몰에 등록하기에 쇼핑몰별 공간 만들어 둠
 */
export interface IWakeupLogin {
  COUPANG: {
    wakeupInfo: any;
  };
  WEMAKEPRICE: {
    wakeupInfo: any;
  };
  ALWAYZ: {
    wakeupInfo: any;
  };
  ST11: {
    wakeupInfo: any;
  };
  KAKAO: {
    wakeupInfo: any;
  };
  ESM: {
    wakeupInfo: any;
  };
  GMARKET: {
    wakeupInfo: any;
  };
  AUCTION: {
    wakeupInfo: any;
  };
  TOSS: {
    wakeupInfo: any;
  };
  ALL: {
    wakeupInfo: any;
  };
  ADMIN: {
    wakeupInfo: any;
  };
}

/* 액션 생성함수 만들기 */
// 액션 생성함수를 만들고 export 키워드를 사용해서 내보내주세요.

//Step2. Request 함수에서 받은 값을 redux에 집어넣음
export const wakeupLoginSuccess = (data: IWakeupLogin) => {
  return {
    type: WAKEUP_LOGIN_SUCCESS,
    data: {
      ...data,
    },
  };
};

interface IWakeupLoginState {
  status: string;
  data: IWakeupLogin;
}
/* 초기 상태 선언 */
const initialState: IWakeupLoginState = {
  status: 'INIT',
  data: {
    COUPANG: {
      wakeupInfo: null,
    },
    WEMAKEPRICE: {
      wakeupInfo: null,
    },
    ALWAYZ: {
      wakeupInfo: null,
    },
    ST11: {
      wakeupInfo: null,
    },
    KAKAO: {
      wakeupInfo: null,
    },
    ESM: {
      wakeupInfo: null,
    },
    GMARKET: {
      wakeupInfo: null,
    },
    AUCTION: {
      wakeupInfo: null,
    },
    TOSS: {
      wakeupInfo: null,
    },
    ALL: {
      wakeupInfo: null,
    },
    ADMIN: {
      wakeupInfo: null,
    },
  },
};

// 리듀서선언 (export default 로 내보내주세요.)
// Step3. 액션 생성함수에서 return받은 상태를 reducer를 통해 redux상태에 집어넣음
export default function wakeupLoginReducer(state = initialState, action: any): IWakeupLoginState {
  switch (action.type) {
    case WAKEUP_LOGIN_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        data: action.data,
      };
    default:
      return state;
  }
}

//Step1. 비지니스로직 작성하는곳
export const wakeupLoginRequest = (data: IWakeupLogin) => {
  return async (dispatch: any) => {
    dispatch(wakeupLoginSuccess(data));
  };
};
