import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Grid from '../Grid';
import Text from '../Text';

interface IProps {
  title: string;
  children: ReactNode;
}

const TooltipWrap = styled(Grid)`
  top: calc(100% + 5px);
  left: 890%;
  transform: translateX(-50%);
  width: 590px;
  white-space: pre-line;

  ::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: 100%;
    left: 21%;
    transform: translateX(-50%);

    border-top: 5px solid transparent;
    border-bottom: 5px solid ${({ color }) => color};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
`;

function Tooltip({ title, children }: IProps) {
  const [active, setActive] = useState(false);

  return (
    <>
      <Grid
        display="inline-block"
        position="relative"
        cursor="pointer"
        onMouseOver={() => {
          setActive(true);
        }}
        onMouseOut={() => {
          setActive(false);
        }}
      >
        {children}
        {active && (
          <TooltipWrap display="inline-block" position="absolute" color="#616161" left={20} right={20} top={10} bottom={10}>
            <Text color="white" fontSize="0.9rem">
              {title}
            </Text>
          </TooltipWrap>
        )}
      </Grid>
    </>
  );
}

export default Tooltip;
