import styled from 'styled-components';

/**
 * PLAYAUTO 통합회원 이용약관
 * @returns
 */
function Combine() {
  return (
    <>
      <div className="policyBox">
        <h3>제1조 [목적]</h3>
        <div className="">
          본 약관은 ㈜커넥트웨이브(이하 “회사”)이 제공하는 쇼핑몰 통합솔루션 플레이오토 서비스(이하 "서비스")의 이용과 관련하여 회사와 이용자의 권리ㆍ의무 및
          책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        </div>
        <h3>제2조 [용어의정의]</h3>
        <div>
          본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <div className="pl20">
            <span>①</span>서비스: 쇼핑몰에서의 판매활동을 통합적으로 관리·처리할 수 있도록 상품등록, 주문관리, 클레임처리 등을 할 수 있는 서비스를 말합니다.
            <br />
            <span>②</span>“이용자”라 함은 본 약관에 따라 회사와 서비스 이용계약을 체결하고 “회사”가 제공하는 “서비스”를 이용하는 개인 또는 기업을 의미합니다.
            <br />
            <span>③</span>아이디(ID): 이용자 식별과 이용자의 서비스 이용을 위하여 이용자가 선정하고 회사가 승인하는 이메일 주소나 문자와 숫자의 조합을
            의미합니다.
            <br />
            <span>④</span>비밀번호: 아이디(ID)와 일치된 이용자임을 확인하고, 이용자의 비밀을 보호하기 위해 이용자가 설정한 문자와 숫자의 조합을 의미합니다.
            <br />
            <span>⑤</span>스팸: 수신자가 원하지 않는데도 불구하고 정보통신망을 통해 일방적으로 전송 또는 게시되는 영리목적의 광고성 정보를 말합니다.
            <br />
            <span>⑥</span>피싱 메시지: 메시지 내용 중 인터넷 주소를 클릭하면 악성코드가 설치되어 수신자가 모르는 사이에 금전적 피해 또는 개인·금융정보 탈취
            피해를 야기하는 메시지를 말합니다.
            <br />
          </div>
        </div>
        <h3>제3조 [약관의 게시와 개정]</h3>
        <div className="pl20">
          <span>①</span>“회사”는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 홈페이지 초기 화면(plto.com)에 게시합니다.
          <br />
          <span>②</span>“회사”는 전기통신사업법, 약관 규제에 관한 법률, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”),
          전자상거래 등에서의 소비자 보호에 관한 법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
          <br />
          <span>③</span>“회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터
          적용일자 전까지 홈페이지에 공지합니다. 다만, 이용자의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일 전에 공지하고 이용자가 사전에 등록한
          이메일, 전화번호로 메일, 문자메시지 등의 전자적 수단을 통해 별도로 명확히 통지하도록 합니다.
          <br />
          <span>④</span>“회사” 본 조 제3항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용 일까지 거부의사를 표시하지 않으면 동의한 것으로
          본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 이용자가 개정약관에 동의한 것으로 봅니다.
          <br />
          <span>⑤</span>이용자가 개정약관의 적용에 동의하지 않을 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 이용자는 이용계약을 해지할 수 있습니다.
          다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
          <br />
          <span>⑥</span>본 약관은 “이용자”가 약관의 내용에 동의함으로써 효력이 발생하며 이용계약 종료 일까지 적용됩니다. 단, 채권 또는 채무관계가 있을 경우에는
          채권, 채무의 완료 일까지로 규정합니다.
          <br />
        </div>
        <h3>제4조 [약관 외 준칙]</h3>
        <div className="pl20">
          <span>①</span>본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 전기통신사업법, 개인정보보호법, 정보통신망법, 전자상거래 등에서의 소비자보호에
          관한 법률, 약관의 규제에 관한 법률 및 기타 관계법령 또는 상관례에 따릅니다.
          <br />
          <span>②</span>회사는 유료서비스 및 개별 서비스에 대해서는 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 별도의
          이용약관 및 정책이 우선하여 적용됩니다.
          <br />
        </div>
        <h3>제5조 [이용계약의 체결]</h3>
        <div className="pl20">
          <span>①</span>이용계약은 “이용자”가 되고자 하는 자(이하 &quot;가입신청자&quot;)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가
          이러한 신청에 대하여 승낙함으로써 체결됩니다.
          <br />
          <span>②</span>제 1항에 따른 회사 승낙에도 불구하고 이용자는 회사와 서비스 이용에 대한 상담 또는 회사가 정한 기간 동안 무료서비스를 이용할 수 있을 뿐
          이용요금을 선납하기 전까지는 유료서비스의 이용을 개시할 수 없습니다.
          <br />
          <span>③</span>“회사”는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을
          하지 않거나 사후에 이용계약을 해지할 수 있습니다.
          <br />
          1) 가입신청자가 본 약관에 의하여 이전에 이용자자격을 상실한 적이 있는 경우, 단 회사의 이용자 재가입 승낙을 얻을 경우에는 예외로 함<br />
          2) 실명이 아니거나 타인의 명의를 이용한 경우
          <br />
          3) 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
          <br />
          4) 만 14세 미만의 가입자인 경우
          <br />
          5) 이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우
          <br />
          6) 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
          <br />
          7) 제20조 제3항에 의하여 회사로부터 계약해지를 당한 이후 1년이 경과하지 않은 경우
          <br />
          8) 기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있음을 인정하는 경우
          <br />
          <span>④</span>본 조 제 1항에 따른 신청에 있어 회사는 이용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청하거나 증빙자료를 요청할 수
          있습니다.
          <br />
          <span>⑤</span>회사는 본 조 제 3항 각호에 따라 이용신청이 이루어 지지 않는지 관리·감독할 수 있습니다.
          <br />
          <span>⑥</span>회사는 다음 각호에 해당하는 신청에 대해서는 승낙을 지연할 수 있습니다.
          <br />
          1) 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우
          <br />
          2) 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우
          <br />
          3) 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우
          <br />
          <span>⑦</span>회사와 이용자가 서비스 이용에 관하여 별도의 계약을 체결한 경우, 당해 별도의 계약이 본 약관에 우선하여 적용됩니다.
          <br />
        </div>
        <h3>제6조 [개인정보 수집]</h3>
        <div className="pl20">
          <span>①</span>회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.
          <br />
          <span>②</span>회사는 개인정보의 수집 시 관련법규에 따라 개인정보처리방침에 그 수집범위 및 목적을 사전 고지합니다.
          <br />
          <span>③</span>회사는 서비스 화면에서 회사가 수집한 개인정보의 수집, 이용 또는 제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취해야 합니다.
          <br />
        </div>
        <h3>제7조 [개인정보보호 의무]</h3>
        <div className="pl20">
          <span>①</span>회사는 개인정보보호법, 정보통신망법 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다.
          <br />
          <span>②</span>개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는
          회사의 개인정보처리방침이 적용되지 않습니다.
          <br />
        </div>
        <h3>제8조 [이용자의 아이디 및 비밀번호의 관리에 대한 의무]</h3>
        <div className="pl20">
          <span>①</span>이용자는 서비스 이용을 위한 아이디 및 비밀번호의 관리에 대한 책임, 본인 아이디의 제3자에 의한 부정사용 등 이용자의 고의 또는 과실로 인해
          발생하는 모든 불이익에 대한 책임을 부담합니다.
          <br />
          <span>②</span>이용자에게 통보된 서비스 ID 또는 비밀번호에 의하여 발생되는 제3자에 의한 부정사용 또는 회사의 의사와 무관한 서비스 사용상의 불법행위
          내지 과실에 대한 모든 책임은 이용자에게 있습니다. 다만, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.
          <br />
          <span>③</span>회사는 이용자의 아이디가 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 또는 회사의 운영자로 오인할 우려가 있는
          경우 해당 아이디의 활용을 제한할 수 있습니다.
          <br />
          <span>④</span>이용자는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
          <br />
          <span>⑤</span>본 조 제4항의 경우에 해당 이용자가 회사에 그 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여
          회사는 책임지지 않습니다.
          <br />
        </div>
        <h3>제9조 [이용자 정보의 변경]</h3>
        <div className="pl20">
          <span>①</span>이용자는 개인정보관리화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은
          홈페이지에 기재된 회사의 전화번호 또는 이메일로 수정을 요청하여야 합니다.
          <br />
          <span>②</span>이용자는 서비스 이용신청 시 기재한 사항이나 서비스 이용 과정에서 회사에 제공한 정보가 변경되었을 경우 본 조 제1항의 방법으로 이용자
          정보를 변경하여야 하며, 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다
          <br />
        </div>
        <h3>제10조 [이용자에 대한 통지]</h3>
        <div className="pl20">
          <span>①</span>회사가 이용자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자의 등록된 이메일, 전화번호 등으로 통지할 수 있습니다.
          <br />
          <span>②</span>회사는 전체에 대한 통지의 경우 7일 이상 서비스 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
          <br />
          <span>③</span>이용자는 회사에 실제로 연락이 가능한 이메일, 전화번호 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며 회사의 통지를
          확인하여야 합니다.
          <br />
        </div>
        <h3>제11조 [회사의 의무]</h3>
        <div className="pl20">
          <span>①</span>회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여
          노력합니다.
          <br />
          <span>②</span>회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
          준수합니다.
          <br />
          <span>③</span>회사는 서비스 제공과 관련하여 알고 있는 이용자의 개인정보를 이용자의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의한
          관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.
          <br />
          <span>④</span>회사는 이용자에게 제공하는 서비스(무료 서비스 제외)를 계속적이고 안정적으로 제공하기 위하여 설비에 장애가 생기거나 멸실 되었을 때 지체
          없이 이를 수리 또는 복구합니다. 다만, 천재지변이나 비상사태 등 부득이한 경우에는 서비스를 일시 중단하거나 영구 중지할 수 있습니다.
          <br />
          <span>⑤</span>회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는
          이용자에게 그 사유와 처리일정을 전자메일 등으로 통보하여야 합니다.
          <br />
          <span>⑥</span>회사는 서비스 제공목적에 맞는 서비스 이용 여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다.
          <br />
          <span>⑦</span>회사는 이용자가 일정기간 생성한 데이터에 대하여 별도의 파일 형태로 제공을 요청하면 이에 응할 수 있습니다. 단, 회사는 이용자에게 요청
          파일에 대한 일정 수수료를 청구할 수 있습니다.
          <br />
        </div>
        <h3>제12조 [이용자의 의무]</h3>
        <div className="pl20">
          <span>①</span>이용자는 다음 행위를 하여서는 안 됩니다.
          <br />
          1) 서비스 이용 신청 또는 서비스 내용 변경 시 허위내용을 등록하는 행위
          <br />
          2) 타인의 정보나 명의를 도용하거나 부정하게 사용하는 행위
          <br />
          3) 다른 이용자의 개인정보를 동의 없이 수집, 저장, 공개하는 행위
          <br />
          4) 회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위
          <br />
          5) 회사 또는 제3자의 저작권 등 지식재산권에 대한 침해 행위
          <br />
          6) 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          <br />
          7) 외설 또는 폭력적인 메시지, 팩스, 음성, 메일, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
          <br />
          8) 회사의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로 서비스를 사용하는 행위
          <br />
          9) 타인의 의사에 반하는 내용을 지속적으로 전송하는 행위
          <br />
          10) 범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위
          <br />
          11) 선량한 풍속 또는 기타 사회질서를 해치는 행위
          <br />
          12) 현행 법령, 회사가 제공하는 서비스에 정한 약관, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항, 기타 서비스 이용에 관한 규정을
          위반하는 행위
          <br />
          13) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
          <br />
          14) 제3자에게 임의로 서비스를 임대하는 행위
          <br />
          15) 전기통신사업법 제84조의2 제1항을 위반하여 메시지 전송 시 발신번호를 변작하는 등 거짓으로 표시하는 행위
          <br />
          16) 서비스를 불법 스팸 전송에 이용하는 행위
          <br />
          17) 기타 불법적이거나 부당한 행위
          <br />
          <span>②</span>이용자는 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의
          업무에 방해되는 행위를 하여서는 안 됩니다.
          <br />
          <span>③</span>이용자는 회사가 정한 서비스 이용요금을 지정된 날짜에 납입할 의무가 있습니다.
          <br />
          <span>④</span>이용자는 정보통신망법의 광고성 정보 전송 시 의무사항 및 회사의 이용약관을 준수하여야 합니다.
          <br />
          <span>⑤</span>이용자는 불법 스팸 전송 등 불법행위를 하거나 전기통신사업법 등 관련 법령을 준수하지 않아 발생하는 모든 민ㆍ형사상의 책임을 부담합니다.
          <br />
          <span>⑥</span>전기통신사업법 제84조의2에 의거 이용자는 본인의 발신번호를 사전에 등록하고 등록된 번호로만 발송해야 하며, 메시지 전송 시 발신번호를
          변작하는 등 거짓으로 표시하여서는 안됩니다.
          <br />
        </div>
        <h3>제13조 [불만처리]</h3>
        <div className="pl20">
          <span>①</span>회사는 개인정보와 관련하여 이용자의 의견을 수렴하고 불만을 처리하기 위한 절차를 마련하여야 합니다.
          <br />
          <span>②</span>회사는 전화, 전자우편 또는 서비스 화면의 상담창구를 통하여 이용고객의 불만사항을 접수, 처리하여야 합니다.
          <br />
        </div>
        <h3>제14조 [서비스 제공]</h3>
        <div className="pl20">
          <span>①</span>회사는 이용자에게 제공하는 서비스를 홈페이지에 게시합니다.
          <br />
          <span>②</span>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 단, 회사는 서비스 제공에 필요한 경우 정기점검을 실시할 수 있으며 정기점검시간은
          회사가 사전에 통지한 바에 따릅니다.
          <br />
          <span>③</span>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 지정할 수 있습니다. 다만 이러한 경우에는 그 내용을 사전에
          공지합니다.
          <br />
          <span>④</span>회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
          <br />
          1) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
          <br />
          2) 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
          <br />
          3) 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
          <br />
          4) 기타 서비스를 유지할 수 없는 중대한 사유가 발생한 경우
          <br />
          <span>⑤</span>본 조 제4항의 경우 회사는 제10조에서 정한 방법으로 이용자에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
          사후에 통지할 수 있습니다.
          <br />
          <span>⑥</span>회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며 정기점검시간은 서비스 제공 화면에 공지한 바에 따릅니다.
          <br />
          <span>⑦</span>이용자의 서비스 이용기간, 서비스 이용요금, 납부절차 등은 별도의 서비스 약정서를 통하여 정합니다.
          <br />
          <span>⑧</span>회사는 이용자의 무료서비스 이용 시 이용자가 업로드 하는 데이터를 관리해야 할 의무가 없으며 무료서비스 이용과 관련한 어떠한 책임도
          부담하지 않습니다.
          <br />
        </div>
        <h3>제15조 [서비스의 변경 또는 중단]</h3>
        <div className="pl20">
          <span>①</span>회사는 상당한 이유가 있는 경우에 회사의 정책상, 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경 또는 중단할 수
          있으며, 이에 대하여 관련 법에 특별한 규정이 없는 한 이용자에게 별도 보상을 하지 않습니다.
          <br />
          <span>②</span>서비스의 내용, 이용방법, 이용시간에 대하여 변경 또는 중단이 있는 경우에는 변경 또는 중단 사유, 변경 또는 중단될 서비스의 내용 및
          제공일자 등을 그 변경 또는 중단 전에 제10조의 방법으로 이용자에게 통지합니다.
          <br />
        </div>
        <h3>제16조 [서비스 이용의 제한 및 정지]</h3>
        <div className="pl20">
          <span>①</span>회사는 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법 통신,
          해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시
          서비스 내의 혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.
          <br />
          <span>②</span>회사는 다음 각 호에 해당하는 경우에 이용자의 서비스 이용을 제한할 수 있습니다.
          <br />
          1) 이용자가 서비스 이용 기간 만료일 경과 후에도 이용요금을 납부하지 않을 경우
          <br />
          2) 제12조 규정에 의한 이용자의 의무를 이행하지 아니한 경우
          <br />
          3) 이용자의 상품DB수, 쇼핑몰 ID, 사용자 ID, 매입SCM ID, 물류SCM ID, 주문건수 등이 서비스 종류별 정해진 기준을 초과할 경우
          <br />
          4) 이용자의 이름 등 이용자 정보가 정확하지 않은 경우
          <br />
          5) 다른 이용자 또는 제3자의 지식재산권을 침해하거나 명예를 손상시킨 경우
          <br />
          6) 방송통신심의위원회의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우
          <br />
          7) 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
          <br />
          8) 이용자가 서비스를 임의로 재판매 하거나 변형하여 제3자가 이용하도록 하는 경우
          <br />
          9) 서비스를 이용하여 얻은 정보를 이용고객의 개인적인 이용 이외에 복사, 가공, 번역, 2차적 저작물 등을 제작하여 복제, 공연, 방송, 전시, 배포, 출판 등에
          사용하는 경우
          <br />
          10) 기타 회사가 이용자로 부적당하다고 판단한 경우
          <br />
          <span>③</span>회사는 이용자가 다음 중 하나에 해당하는 경우 1개월 동안의 기간을 정하여 당해 서비스의 이용을 정지할 수 있습니다.
          <br />
          1) 방송통신위원회 또는 한국인터넷진흥원 등 관계기관이 불법 스팸 전송 사실을 확인하여 이용 정지를 요청하는 경우
          <br />
          2) 이용자가 전송하는 광고로 인하여 회사의 서비스 제공에 장애를 야기하거나 야기할 우려가 있는 경우
          <br />
          3) 이용자가 전송하는 광고의 수신자가 불법스팸으로 신고하는 경우
          <br />
          4) 이용자가 제12조 제1항 제15호를 위반하여 발신번호를 변작하는 등 거짓으로 표시한 경우
          <br />
          5) 과학기술정보통신부장관 또는 한국인터넷진흥원 등 관련 기관이 발신번호 변작 등을 확인하여 이용 정지를 요청하는 경우
          <br />
          <span>④</span>회사가 본 조 제2항 내지 제3항의 규정에 의하여 서비스를 제한 또는 정지하는 경우, 그 사실을 이용자에게 사전 통보하여야 합니다. 다만,
          사전에 통보하는 것이 곤란할 경우 이용정지 조치 후 통보할 수 있습니다.
          <br />
          <span>⑤</span>회사는 12개월 이상 서비스를 이용하지 아니하는 이용자의 개인정보를 보호하기 위해 이용자의 계정을 휴면계정으로 분류하고 서비스 이용계약을
          해지하거나 개인정보의 파기 또는 별도 분리보관 등 필요한 조치를 할 수 있습니다. 단, 휴면계정으로 분류된 이용자의 계정에 선불 충전금이 존재하는 경우,
          회사는 해당 이용자의 선불 충전금의 상사소멸시효가 완성되는 시점에 서비스 이용계약을 해지할 수 있습니다.
          <br />
          <span>⑥</span>회사는 휴면계정으로 분류되기 30일 전까지 개인정보가 파기되거나 분리되어 저장되는 사실과 기간 만료일 및 해당 개인정보의 항목을 이메일,
          서면, 모사전송, 전화 또는 유사한 방법 중 하나의 방법으로 이용자에게 알리도록 합니다. (단, 이용자의 정보가 정확하지 않은 경우 알림 대상에서 제외될 수
          있습니다.)
          <br />
          <span>⑦</span>본 조에 의한 서비스 이용 정지 또는 이용 계약의 해지 시 데이터 삭제에 대한 책임은 이용자가 부담하며, 회사는 고의 또는 과실이 없는 한
          데이터 삭제에 따른 책임을 부담하지 않습니다.
          <br />
          <span>⑧</span>회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우, 회사는 제10조에서 정한 방법으로
          이용자에게 통지하고 서비스를 종료할 수 있습니다.
          <br />
        </div>
        <h3>제17조 [이용요금 등의 종류]</h3>
        <div className="pl20">
          <span>①</span>서비스 이용과 관련하여 이용자가 납부하여야 할 이용요금은 개별 서비스 약정서 또는 홈페이지에 게재한 바에 따릅니다. 단, 개별 서비스
          약정서와 홈페이지에 게재된 이용요금이 상이한 경우 개별 서비스 약정서가 우선하여 적용됩니다.
          <br />
          <span>②</span>이용자가 서비스 이용 시 납입해야 하는 이용요금의 종류는 다음과 같습니다.
          <br />
          1) 가입비 : 신규 서비스 개통 또는 서비스 이용 중에 재설치, 변경을 하는 경우에 지급하는 1회성 요금
          <br />
          2) 기본 이용요금 : 서비스 종류별 정해져 있는 기본요금
          <br />
          3) 부가서비스 요금 : 서비스 종류 별 기준 초과에 따른 추가비용 또는 자동화 서비스 등 별도의 부가 서비스에 대한 이용요금
          <br />
          <span>③</span>서비스 이용요금은 선불결제를 원칙으로 합니다.
          <br />
          <span>④</span>이용자는 문자메시지 발송 서비스 이용 시 이용요금을 선불 충전하여 이용하여야 하며 문자메시지 서비스 이용요금은 서비스 화면에 게시한 바에
          따릅니다.
          <br />
          <span>⑤</span>이용자의 문자메시지 발송 서비스 이용요금은 이용자의 메시지 발송 성공 건수에 따라 계산되며, 성공 건수는 수신자가 속한 이동통신사에서
          전달하는 성공 값을 기준으로 합니다.
          <br />
        </div>
        <h3>제18조 [불법 면탈 요금의 청구]</h3>
        <div className="pl20">
          <span>①</span>이용자가 불법으로 서비스 이용요금 등을 면탈할 경우에는 면탈한 금액의 2배에 해당하는 금액을 청구합니다.
          <br />
          <span>②</span>회사는 2개월 이상 서비스 이용요금이 연체된 이용자를 신용기관에 신용불량자로 등록할 수 있습니다.
          <br />
        </div>
        <h3>제19조 [이용요금 등의 환불]</h3>
        <div className="pl20">
          <span>①</span>회사는 서비스 이용요금 등의 과납 또는 오납이 있을 때에는 그 과납 또는 오납된 서비스 이용요금을 반환하고, 회사의 귀책사유로 과납 또는
          오납이 발생한 경우에는 법정이율로서 적정이자를 함께 반환합니다.
          <br />
          <span>②</span>서비스 이용기간 중 서비스 이용계약이 중도 해지되는 경우, 회사는 이용자에게 잔여기간에 대한 서비스 이용요금을 일할 계산 하여 잔여금액을
          환불하며, 서비스 약정기간에 따라 이용자가 서비스 이용요금을 할인 받았을 경우 잔여금액에서 할인 받은 금액을 공제하고 환불합니다. 단, 잔여금액보다
          이용자가 할인 받은 금액이 더 클 경우, 이용자는 초과된 금액(=할인금액-잔여금액)을 ‘회사’에 즉시 지급하여야 합니다.
          <br />
          <span>③</span>이용자가 선불 결제한 서비스 이용요금 중 가입비는 환불금액에서 제외하며, 회사가 무상으로 제공한 서비스 이용기간은 잔여기간에 포함되지
          않습니다.
          <br />
          <span>④</span>이용자가 문자서비스 이용을 위하여 충전한 선불 충전금의 잔액에 대한 환불을 요청하면 환불 받을 수 있습니다. 단, 이용자가 무료로 지급받은
          충전금은 환불 받을 수 없습니다.
          <br />
          <span>⑤</span>본 조 제2항 또는 제4항에 따른 환불 시에는 송금비용, PG수수료 등의 사유로 잔여금액의 10%이내 금액 또는 잔여금액이 10,000원 이내인
          경우에는 1,000원을 공제한 금액을 환불하여 드립니다. 단, 회사의 귀책사유가 있는 경우에는 환불수수료가 공제되지 않습니다.
          <br />
          <span>⑥</span>선불 충전한 이용자가 문자메시지의 발송을 완료하였을 경우에는 차감된 이용요금은 환불이 불가합니다.
          <br />
          <span>⑦</span>문자메시지 발송 서비스 이용을 위한 선불 충전금은 마지막 사용일(충전 이후 사용이 없었을 경우 충전일)로부터 5년이 경과하면 소멸됩니다.
          <br />
        </div>
        <h3>제20조 [이용계약 해지]</h3>
        <div className="pl20">
          <span>①</span>이용자는 이용계약을 해지하고자 할 때 홈페이지 및 서비스내의 탈퇴신청 또는 전자메일, 전화 등의 방법을 통하여 회사에 신청하여 이용계약을
          해지할 수 있습니다.
          <br />
          <span>②</span>회사는 본 조 제1항의 규정에 의하여 해지신청이 접수되면 이용계약을 해지합니다. 단, 별도의 채권·채무관계가 있을 경우에는 그러하지
          아니합니다.
          <br />
          <span>③</span>회사는 이용자가 다음 각 호에 해당할 경우에는 이용자의 동의 없이 이용계약을 해지할 수 있으며 그 사실을 이용자에게 통지합니다. 다만 회사가
          긴급하게 해지할 필요가 있다고 인정하는 경우나 이용자의 귀책사유로 인하여 통지할 수 없는 경우에는 지체 없이 사후 통지로 대체할 수 있습니다.
          <br />
          1) 타인의 명의로 서비스 계약을 체결하였거나 서비스 계약 체결 시 제출한 자료 및 정보가 허위 또는 누락되었음이 확인된 경우
          <br />
          2) 이용자가 제12조를 포함한 본 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는 경우
          <br />
          3) 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우
          <br />
          4) 방송통신위원회 또는 한국인터넷진흥원 등이 불법 스팸의 전송 사실을 확인하여 회사에게 서비스 계약 해지를 요청하는 경우
          <br />
          5) 이용자가 제12조 제6항을 위반하여 발신번호를 변작하는 등 거짓으로 표시한 경우
          <br />
          6) 제16조 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우
          <br />
          7) 회사의 이용요금 등의 납입청구에 대하여 이용자가 이용요금을 체납할 경우
          <br />
          <span>④</span>이용자 또는 회사가 계약을 해지할 경우 관련법 및 개인정보처리방침에 따라 회사가 이용자 정보를 보유하는 경우를 제외하고는 해지 즉시
          이용자의 모든 데이터는 소멸될 수 있으며, 소멸된 데이터는 어떤 경우에도 이용자에게 반환되지 않습니다. 다만, 회사는 조작 실수 등으로 인하여 발생하는
          피해를 예방하기 위해 유예기간(신청일로부터 7일)이 만료된 후 서비스 이용기록 등을 삭제(탈퇴 처리)하며, 이용자는 유예기간내에는 언제든지 서비스
          이용계약의 해지(탈퇴)신청을 철회할 수 있습니다.
          <br />
        </div>
        <h3>제 21 조 [지식재산권]</h3>
        <div className="pl20">
          <span>①</span>서비스 자체에 대한 지식재산권은 회사에 귀속됩니다. 다만, 이용자가 서비스 페이지에 게시하거나 등록한 자료의 지식재산권은 이용자에게
          귀속됩니다.
          <br />
          <span>②</span>이용자가 서비스를 통하여 업로드하는 이미지나 자료가 제3자의 저작권 및 지식재산권을 침해하여서는 안되며 이로 인해 발생하는 모든 문제는
          전적으로 이용자의 책임이며, 회사와 제3자 사이에 분쟁이 발생시 이용자의 비용으로 이를 보상 혹은 배상해야 합니다.
          <br />
        </div>
        <h3>제 22 조 [커넥트웨이브 이용 데이터의 저장 및 활용]</h3>
        <div className="pl20">
          <span>①</span>회사는 이용자에게 더 좋은 서비스를 제공하기 위하여 이용자가 커넥트웨이브 서비스를 이용하는 과정에서 입력하는 데이터(재고 정보, 상품 정보
          등)를 저장하여 커넥트웨이브 서비스의 품질 개선 및 성능 향상, 이용자에 대한 서비스의 고도화 및 최적화 등의 목적으로 활용할 수 있습니다.
        </div>
        <h3>제 23 조 [손해배상의 범위 및 청구]</h3>
        <div className="pl20">
          <span>①</span>회사의 귀책사유로 인한 서비스의 장애 발생 시 이용자가 서비스를 연속 3시간 이상 이용하지 못하였을 경우 최근 3개월(3개월 미만인 경우는
          해당 기간 적용)의 1일 평균 이용요금을 24로 나눈 수에서 서비스 제공 중지시간을 곱하여 산출한 금액의 5배에 해당하는 금액만큼 보상합니다.
          <br />
          <span>②</span>전 1항의 서비스 장애 이외의 회사가 고의 또는 과실로 이용자에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.
          <br />
          <span>③</span>회사는 그 손해가 천재지변 등 불가항력이거나 이용자의 고의 또는 과실로 인하여 발생된 때에는 손해배상을 하지 않습니다.
          <br />
          <span>④</span>이용자가 고의 또는 과실로 회사에 손해를 끼친 경우, 이용자는 회사에 대하여 발생한 손해를 배상할 책임이 있습니다.
          <br />
          <span>⑤</span>이용자는 불법 스팸 전송 등 불법행위를 하거나 전기통신사업법 등 관련 법령을 준수하지 못해 발생하는 모든 민형사상의 책임을 부담하며,
          회사는 면책됩니다.
          <br />
          <span>⑥</span>손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 전자우편, 전화 등으로 신청하여야 합니다.
          <br />
          <span>⑦</span>회사 및 타인에게 피해를 주어 피해자의 고발 또는 소송 제기로 인하여 손해배상이 청구된 이용자는 회사 및 수사기관의 요청에 적극 협조하여야
          합니다.
          <br />
        </div>
        <h3>제 24 조 [면책]</h3>
        <div className="pl20">
          <span>①</span>회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자 또는 제3자에게 발생한 손해에 대해서는 책임을 부담하지
          않습니다.
          <br />
          1) 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
          <br />
          2) 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우
          <br />
          3) 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우
          <br />
          4) 회사의 고의 과실이 없는 사유로 인한 경우
          <br />
          5) 회사에게 회선, 통신망, 전용선을 제공하고 있는 이동통신사 또는 부가통신사업자 측의 장애·귀책사유로 인한 서비스의 불완전 또는 불능으로 이용자 또는
          제3자에게 야기된 손해
          <br />
          <span>②</span>회사는 이용자가 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터 발생하거나 기대하는
          손익 등에 대하여 책임을 면합니다.
          <br />
          <span>③</span>회사는 서비스 이용의 장애로 인하여 발생한 이용자의 부가적, 영업적인 손해에 대해서는 책임을 지지 않습니다.
          <br />
          <span>④</span>회사는 이용자가 게시 또는 전송한 데이터의 내용에 대해서는 책임을 면합니다.
          <br />
          <span>⑤</span>회사는 이용자 상호 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임을 면합니다.
          <br />
          <span>⑥</span>회사는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
          <br />
          <span>⑦</span>회사는 이용자가 서비스 이용 종료 후 제20조에 따라 파기된 데이터와 이용자가 직접 삭제를 요청한 데이터에 대해서는 책임을 지지 않습니다.
          <br />
          <span>⑧</span>회사는 이용자가 서비스를 통하여 전송한 메시지의 내용에 대해서는 그 정확성, 신뢰성, 시기적절성 등을 보장하지 않으며 해당 내용이 관현
          법령을 위반하거나 제3자의 권리를 침해하는 경우 이에 대한 책임을 지지 않습니다.
          <br />
          <span>⑨</span>회사는 회사의 고의 또는 과실이 없는 한 서비스를 활용하여 발송된 메시지 관련 발신자와 수신자 간 분쟁에 대하여 개입할 의무가 없으며 이로
          인한 손해를 배상할 책임이 없습니다.
          <br />
          <span>⑩</span>회사는 제16조에 따라 이용자의 서비스 이용을 정지 또는 제한하는 경우, 이용의 제한으로 발생할 수 있는 이용자의 손해 등에 대해서는 책임이
          면제됩니다.
          <br />
        </div>
        <h3>제 25 조 [분쟁조정]</h3>
        <div className="pl20">
          <span>①</span>회사와 이용자 간 제기된 소송은 대한민국법을 준거법으로 합니다.
          <br />
          <span>②</span>서비스 이용과 관련하여 회사와 이용자 간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를 관할하는 법원을 합의관할로 합니다.
          <br />
          <span>③</span>해외에 주소나 거소가 있는 이용자의 경우 회사와 이용자간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을
          관할법원으로 합니다.
          <br />
        </div>
        <div className="date">시행일자 : 2023. 08. 31</div>
      </div>
    </>
  );
}

/**
 * 개인정보 수집 및 이용
 * @returns
 */
function PrivateScrap() {
  return (
    <>
      <div className="policyBox">
        <div className="pt10">회사는 서비스 제공을 위해 아래와 같이 개인정보를 수집 및 이용합니다.</div>
        <h3>1. 개인정보 수집항목(필수)</h3>
        <div>- 회원가입 시</div>
        <div className="pl8">사업자번호, 사업자명, 대표자명, 이메일, 비밀번호, 담당자명, 휴대폰번호</div>
        <div className="pt10">- 환불 시</div>
        <div className="pl8">계좌정보(거래은행명, 계좌번호, 거래자 성명)</div>
        <div className="pt10">- 서비스 이용 과정이나 사업 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.</div>
        <div className="pl8">서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록, 이용정지기록, 불량이용기록</div>
        <h3>2. 개인정보 수집목적</h3>
        <div>
          회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정이용 방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동의
          개인정보 수집 시 법정대리인 동의여부 확인, 추후 법정대리인 본인확인, 분쟁조정을 위한 기록 보존, 불만처리 등 민원처리, 고지사항 전달
        </div>
        <div className="pt10">
          서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
          <br />
          서비스 및 콘텐츠 제공, 물품배송 또는 청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심
        </div>
        <div className="pt10">
          서비스 개선, 통계 및 분석
          <br />
          서비스 개선, 신규 서비스 개발, 서비스 방문 및 이용기록 통계 및 분석, 서비스 만족도 조사 및 관리
        </div>
        <h3>3. 보유 및 이용기간</h3>
        <div>
          원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
          보존합니다.
        </div>
        <div className="pt10">- 회사 내부 정책에 의한 정보보유 사유</div>
        <div className="pl8">
          보유 이유: 서비스의 부정 및 불량 이용 방지 및 부정/불량이용자의 재가입방지
          <br />
          보존 기간: 1년
        </div>
        <div className="pt10">- 관련 법령에 의한 정보보유 사유</div>
        <div className="pl8">
          상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계 법령에서 정한 일정한 기간 동안
          회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
        </div>
        <div className="pl20 pt10">
          <span>①</span>계약 또는 청약철회 등에 관한 기록
          <br />
          - 보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
          <br />- 보존기간 : 5년
        </div>
        <div className="pl20 pt10">
          <span>②</span>대금결제 및 재화 등의 공급에 관한 기록
          <br />
          - 보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
          <br />- 보존기간 : 5년
        </div>
        <div className="pl20 pt10">
          <span>③</span>소비자의 불만 및 분쟁처리에 관한 기록
          <br />
          - 보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
          <br />- 보존기간 : 3년
        </div>
        <div className="pl20 pt10">
          <span>④</span>서비스 방문 기록
          <br />
          - 보존이유 : 통신비밀보호법
          <br />- 보존기간 : 3개월
        </div>
        <div className="pl20 pt10">
          <span>⑤</span>세법에서 규정하는 모든 거래에 관한 장부 및 증거서류
          <br />
          - 보존이유 : 국세기본법
          <br />- 보존기간 : 5년
        </div>
        <div className="pl20 pt10">
          <span>⑥</span>전자금융거래기록
          <br />
          - 보존이유 : 전자금융거래법
          <br />- 보존기간 : 5년
        </div>
        <div className="pt10">
          ※ 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으나, 거부할 경우 ㈜커넥트웨이브 서비스 이용이 제한될 수 있습니다.
        </div>
      </div>
    </>
  );
}

/**
 * 개인정보처리 위수탁 계약
 * @returns
 */
function Trust() {
  return (
    <>
      <div className="policyBox">
        <div className="pt10">
          “이용자” (이하 “위탁자”라 한다)과 ㈜커넥트웨이브(이하 “수탁자”라 한다)는 “위탁자”가 쇼핑몰 주문 처리 시 수집한 “주문의 고객정보”(이하 “주문정보”라
          한다)를 저장 및 관리 업무를 “수탁자”에게 위탁함에 있어 다음과 같은 내용으로 본 업무위탁계약을 체결한다.
        </div>
        <h3>제 1 조 [목적]</h3>
        <div>
          이 계약은 “수탁자”가 제공하는 쇼핑몰 통합 관리 솔루션을 이용해 “위탁자”가 수집한 주문정보의 저장 및 관리 위탁 업무를 수행함에 있어 「개인정보
          보호법」에 따라 양 당사자간의 고객 개인정보 보호와 관련된 사항과 책임을 정함을 그 목적으로 한다.
        </div>
        <h3>제 2 조 [용어의 정의]</h3>
        <div>
          본 계약에서 별도로 정의되지 아니한 용어는 「개인정보 보호법」, 같은 법 시행령 및 시행규칙, 「개인정보의 안전성 확보조치 기준」(개인정보 보호위원회
          고시 제2020-2호) 및 「표준 개인정보 보호지침」(개인정보 보호위원회 고시 제2020-1호)에서 정의된 바에 따른다.
        </div>
        <div className="pl20 pt10">
          <span>①</span>쇼핑몰 통합관리솔루션 : “수탁자”이 “위탁자”의 쇼핑몰 주문관리를 위해 제공하는 서비스.
          <br />
          <span>②</span>주문정보 : “위탁자”가 주문 처리를 위해 쇼핑몰에서 제공받은 고객정보 (주문자명, 주문자연락처, 수취인명, 수취인 주소 등)
          <br />
        </div>
        <h3>제 3 조 [위탁업무의 목적 및 범위]</h3>
        <div>“수탁자”는 계약이 정하는 바에 따라 위탁계약 업무수행 목적으로 다음과 같은 개인정보 처리 업무를 수행한다.</div>
        <div className="pl20 pt10">
          <span>①</span>“수탁자”의 일반 수탁업무 목적은 “위탁자”가 “쇼핑몰 통합관리솔루션” 주문관리 기능을 이용함에 있어 원활한 서비스를 제공하기 위함에 그
          목적이 있다.
          <br />
          <span>②</span>“수탁자”는 “위탁자”가 주문관리를 위해 수집한 주문정보를 저장 및 관리하며, “위탁자”가 안전하게 주문정보를 관리할 수 있도록 관리적, 기술적
          조치를 제공한다.
          <br />
        </div>
        <h3>제 4 조 [위탁업무 기간]</h3>
        <div>
          이 계약서에 의한 개인정보 처리업무의 기간은 “위탁자”가 “수탁자”의 통합관리솔루션 이용기간과 동일하게 적용되며, 서비스 탈퇴일 또는 이용종료일까지
          효력이 유지됩니다.
        </div>
        <h3>제 5 조 [재 위탁 제한]</h3>
        <div className="pl20">
          <span>①</span>“수탁자”는 “위탁자”의 사전 승낙을 얻은 경우를 제외하고 “위탁자” 와의 계약상의 권리와 의무의 전부 또는 일부를 제3자에게 양도하거나 재
          위탁할 수 없다.
          <br />
          <span>②</span>“수탁자”는 다른 제3의 회사와 수탁계약을 할 경우에는 “수탁자”는 해당 사실을 계약 체결 7일 이전에 “위탁자”에게 통보하고 협의하여야 한다.
          <br />
        </div>
        <h3>제 6 조 [개인정보의 안전성 확보조치]</h3>
        <div>
          “수탁자”는 「개인정보 보호법」 제23조제2항 및 제24조 제3항 및 제29조, 같은 법 시행령 제21조 및 제30조, 「개인정보의 안전성 확보조치 기준」(개인정보
          보호위원회 고시 제2020-2호)에 따라 개인정보의 안전성 확보에 필요한 기술적, 관리적 조치를 취하여야 한다.
        </div>
        <h3>제 7 조 [개인정보의 처리제한]</h3>
        <div className="pl20">
          <span>①</span>“수탁자”는 계약기간은 물론 계약 종료 후에도 위탁업무 수행 목적 범위를 넘어 개인정보를 이용하거나 이를 제3자에게 제공 또는 누설하여 서는
          안 된다.
          <br />
          <span>②</span>“위탁자”는 목적에 따른 사용이 완료되거나 기타 보유가 불필요해진 주문정보는 “을”이 제공하는 쇼핑몰 통합관리 솔루션의 개인정보 처리 기능을
          이용하여 직접 파기 및 관리하여야 한다.
          <br />
          <span>③</span>“수탁자”는 계약이 해지되거나 또는 계약기간이 만료된 경우 위탁업무와 관련하여 보유하고 있는 개인정보를 「개인정보 보호법 시행령」 제16조
          및 「개인정보의 안전성 확보조치 기준」(개인정보 보호위원회 고시 제2020-2호)에 따라 즉시 파기하거나 “위탁자”에게 반납하여야 한다.
          <br />
          <span>④</span>제3항에 따라 “수탁자”가 개인정보를 파기한 경우 지체없이 “위탁자”에게 그 결과를 통보하여야 한다.
          <br />
        </div>
        <h3>제 8 조 [수탁자에 대한 관리·감독 등]</h3>
        <div className="pl20">
          <span>①</span>“위탁자”는 “수탁자”에 대하여 다음 각 호의 사항을 감독할 수 있으며, “수탁자”는 특별한 사유가 없는 한 이에 응하여야 한다.
          <br />
          1. 개인정보의 처리 현황 <br />
          2. 개인정보의 접근 또는 접속기록 <br />
          3. 개인정보 접근 또는 접속 대상자 <br />
          4. 목적 외 이용, 제공 및 재 위탁 금지 준수여부 <br />
          5. 암호화 등 안전성 확보조치 이행여부 <br />
          6. 그 밖에 개인정보의 보호를 위하여 필요한 사항
          <br />
          <span>②</span>“수탁자”는 수탁업무의 처리와 관련하여 주문정보에 접근할 수 있는 직원을 필요 최소한의 범위로 제한, 관리하여 고객정보 침해 사고가 발생하지
          않도록 최선의 노력을 다하여야 한다.
          <br />
          <span>③</span>“수탁자”는 위탁자의 개인정보 처리 방침을 준수하여야 하며, 원활한 고객정보 처리와 보호를 위하여 개인정보 보호담당자를 지정하여
          “위탁자”에게 통지하여야 한다.
          <br />
          <span>④</span>“수탁자”는 개인정보를 보호를 위하여 “수탁자”의 직원에 대한 관리, 감독과 교육을 실시하여야 하며, “위탁자”의 요청이 있는 경우 교육 결과를
          “위탁자”에게 제출한다.
          <br />
        </div>
        <h3>제 9 조 [정보주체 권리보장]</h3>
        <div>“수탁자”은 정보주체의 개인정보 열람, 정정·삭제, 처리 정지 요청 등에 대응하기 위한 연락처 등 민원 창구를 마련해야 한다.</div>
        <h3>제 10 조 [개인정보의 파기]</h3>
        <div>“수탁자”은 제4항의 위탁업무기간이 종료되면 특별한 사유가 없는 한 지체 없이 개인정보를 파기하고 이를 “위탁자”에게 통지해야 한다.</div>
        <h3>제 11 조 [손해배상]</h3>
        <div className="pl20">
          <span>①</span>“수탁자” 또는 “수탁자”의 임직원 기타 “수탁자”의 수탁자가 이 계약에 의하여 위탁 또는 재위탁 받은 업무를 수행함에 있어 이 계약에 따른
          의무를 위반하거나 “수탁자” 또는 “수탁자”의 임직원 기타 “수탁자”의 수탁자의 귀책사유로 인하여 이 계약이 해지되어 “위탁자” 또는 개인정보주체 기타
          제3자에게 손해가 발생한 경우 “수탁자”는 그 손해를 배상하여야 한다.
          <br />
          <span>②</span>제1항과 관련하여 개인정보주체 기타 제3자에게 발생한 손해에 대하여 “위탁자”가 전부 또는 일부를 배상한 때에는 “위탁자”은 이를 “수탁자”에게
          구상할 수 있다. 단, “위탁자” 가 제7조 2항의 개인정보 안전성 확보조치를 태만히 함으로 인하여 발생한 “위탁자” 또는 개인정보주체 기타 제3자의 손해에
          대하여 “수탁자”는 그 손해를 배상할 책임이 없다.
          <br />
        </div>
      </div>
    </>
  );
}

const Wrap = styled.div`
  div {
    font-size: 15px;
  }

  .policyBox {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5715;

    padding-top: 30px;
    padding-bottom: 30px;

    h2 {
      font-size: 18px;
      padding-top: 30px;
      font-weight: bold;
      &:first-child {
        padding-top: 0;
      }
    }

    h3 {
      font-size: 16px;
      padding: 30px 0;
      font-weight: bold;
      &:first-child {
        padding-top: 0;
      }
    }

    h4 {
      font-size: 14px;
      padding: 10px 0;
      font-weight: bold;
      &:first-child {
        padding-top: 0;
      }
    }

    .pl20 {
      padding-left: 20px;
      position: relative;
      span {
        position: absolute;
        left: 0;
      }
    }
  }

  .policyBox .pt10 {
    padding-top: 10px;
  }

  .policyBox .pl8 {
    padding-left: 8px;
  }
`;
function Policy({ type }: any) {
  return (
    <Wrap>
      {type === 'combine' && <Combine />}
      {type === 'privateScrap' && <PrivateScrap />}
      {type === 'trust' && <Trust />}
    </Wrap>
  );
}
export default Policy;
