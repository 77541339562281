import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';
import { axiosCustom } from 'common/common.function';

interface HmacInfo {
  accessKey: string;
  accessorId: string;
  hmacStatus: string;
  secretKey: string;
}

interface CoupangInfo {
  openapiHmac?: HmacInfo; // '?' denotes that the field is optional
  playautoHmac?: HmacInfo; // same here
}

function JokerTest() {
  const [loading, setLoading] = React.useState(false); //스마트스토어
  const [loading2, setLoading2] = React.useState(false); //2차인증이 필요할경우
  const [loading3, setLoading3] = React.useState(false); //2차인증없이 성공했을 경우
  const [info, setInfo] = React.useState({
    accountId: '',
    apiId: '',
    url: '',
  });

  const [naverShoppingInfo, setNaverShoppingInfo] = React.useState({
    success: false,
    question: '',
    image: '',
    sessionKey: '',
    cookieString: '',
  });

  const [naverShoppingGetInfo, setNaverShoppingGetInfo] = React.useState({
    cookie: '',
    isTrue: false,
    rtn_cd: 0,
    sellerInfo: {
      businessAddressInfo: {
        address: '', //'충청남도 천안시 서북구 두정동 579-3',
        apiType: '', //'NAVER',
        basicAddress: '', //'충청남도 천안시 서북구',
        detailAddress: '', //'3층 302호 트라이몰',
        fullAddressInfo: '', //'충청남도 천안시 서북구 오성1길 12-11 (영화빌딩) 3층 302호 트라이몰 (우 : 31090) ',
        hasJibunAddress: false,
        hasRoadNameAddress: false,
        jibunAddress: '',
        latitude: '', //'36.8315314',
        longitude: '', //'127.1291571',
        massiveAddress: '', //'영화빌딩',
        naverMapCode: '', //'15133104',
        newZipCode: '', //'31090',
        overseas: false,
        placeId: '', //'15133104',
        representAddressType: '', //'road',
        zipCode: '', //'31090',
      },
      chrgrEmail: '',
      declaredToOnlineMarkettingNumber: '', //2022-충남천안-2472
      identity: '',
      representName: '', //주식회사 내의미
      representTelephoneNumber: '', //010-4069-5010
      representativeBirthDay: '', //1991-12-16T15:00:00.000+00:00
      representativeName: '', //강세영
    },
  });

  const [loading5, setloading5] = React.useState(false);
  const [loading7, setloading7] = React.useState(false);

  const [coupangInfo1, setCoupangInfo1] = React.useState({ cookie: [], url: '', page: {} });
  const [realCoupangInfo, setRealCoupangInfo] = React.useState<CoupangInfo[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
  } = useForm();

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
  } = useForm();

  const {
    register: register5,
    handleSubmit: handleSubmit5,
    formState: { errors: errors5 },
  } = useForm();

  const {
    register: register6,
    handleSubmit: handleSubmit6,
    formState: { errors: errors6 },
  } = useForm();

  const [selectedShop, setSelectedShop] = React.useState('SmartStore');

  const handleSelectChange = (e: any) => {
    setSelectedShop(e.target.value);
    setValue('id', e.target.value === 'SmartStore' ? 'okfactory1025@gmail.com' : 'hyuna9204');
    setValue('password', e.target.value === 'SmartStore' ? 'ssong151025!' : 'qkrslah123');
  };

  const onSubmit = async (data: any) => {
    try {
      const result = await axiosCustom.post(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/shop/scrap-shop-info`, {
        id: data.id,
        password: data.password,
        shopCode: data.shop === 'SmartStore' ? 'A077' : 'F999',
      });
      console.log(result);
      if (!result.success) {
        return await Swal.fire({
          title: '실패',
          html: '데이터 가져오기 실패 - 폰팀에 문의하세요',
          icon: 'error',
        });
      }
      setInfo({
        accountId: result.accountId,
        apiId: result.apiId,
        url: result.url,
      });
      setLoading(true);
      return await Swal.fire({
        title: '성공',
        html: '데이터 가져오기 성공',
        icon: 'success',
      });
    } catch (e: any) {
      return await Swal.fire({
        title: '실패',
        html: '데이터 가져오기 실패 - 아이디,비밀번호를 확인해 주세요',
        icon: 'error',
      });
    }
  };

  const onSubmit2 = async (data: any) => {
    try {
      const agreeResult = await Swal.fire({
        title: '사용자 동의',
        html: `이 기능을 사용하게 되면 스마트스토어 API 사용 및 대행사를 PLAYAUTO로 변경하게 됩니다.`,
        icon: 'info',
        confirmButtonText: '확인',
        showCancelButton: true,
        cancelButtonText: '취소',
      });

      if (agreeResult.isConfirmed) {
        const result = await axiosCustom.post(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/shop/modify-shop-info`, {
          id: data.id,
          password: data.password,
          shopCode: 'A077',
        });
        if (!result.success) {
          return await Swal.fire({
            title: '실패',
            html: '대행사 수정하기 실패 - 폰팀에 문의하세요',
            icon: 'error',
          });
        }
        setLoading(true);
        return await Swal.fire({
          title: '성공',
          html: '대행사 수정하기 성공',
          icon: 'success',
        });
      }
      return false;
    } catch (e: any) {
      return await Swal.fire({
        title: '실패',
        html: '대행사 수정하기 실패 - 아이디,비밀번호를 확인해 주세요',
        icon: 'error',
      });
    }
  };

  const onSubmit3 = async (data: any) => {
    try {
      const coupangData1 = await axiosCustom.post(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/get-coupang-info3`, {
        id: data.id,
        password: data.password,
      });
      if (!coupangData1.success) {
        setCoupangInfo1({
          cookie: coupangData1.cookie,
          url: coupangData1.url,
          page: coupangData1.page,
        });
        setLoading2(true);
        return await Swal.fire({
          title: '실패',
          html: '데이터 가져오기 실패 - 2차인증이 필요합니다. 밑에 2차인증 코드를 입력해주세요',
          icon: 'error',
        });
      }
      setLoading3(true);
      setRealCoupangInfo(coupangData1.coupangInfoDataResultArray);
      return await Swal.fire({
        title: '성공',
        html: '데이터 가져오기 성공',
        icon: 'success',
      });
    } catch (e: any) {
      return await Swal.fire({
        title: '실패',
        html: '데이터 가져오기 실패 - 아이디,비밀번호를 확인해 주세요',
        icon: 'error',
      });
    }
  };

  const onSubmit4 = async (data: any) => {
    try {
      const { data: coupangData222 } = await axiosCustom.post(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/get-coupang-info4`, {
        url: coupangInfo1.url,
        cookie: coupangInfo1.cookie,
        code: data.code,
      });
      if (!coupangData222.success) {
        setCoupangInfo1({
          cookie: coupangData222.cookie,
          url: coupangData222.url,
          page: coupangData222.page,
        });
        setLoading2(true);
        return await Swal.fire({
          title: '실패',
          html: '데이터 가져오기 실패 - 2차인증이 필요합니다. 밑에 2차인증 코드를 입력해주세요',
          icon: 'error',
        });
      }
      setLoading3(true);
      setRealCoupangInfo(coupangData222.coupangInfoDataResultArray);
      return await Swal.fire({
        title: '성공',
        html: '데이터 가져오기 성공',
        icon: 'success',
      });
    } catch (e: any) {
      return await Swal.fire({
        title: '실패',
        html: '데이터 가져오기 실패 - 아이디,비밀번호를 확인해 주세요',
        icon: 'error',
      });
    }
  };

  /**
   * @alias 네이버 쇼핑 테스트
   */
  const onSubmit5 = async (data: any) => {
    try {
      const result = await axiosCustom.post(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/shop/scrap-shop-info`, {
        id: data.id,
        password: data.password,
        shopCode: 'F998',
      });
      console.log(result);
      if (!result.success) {
        return await Swal.fire({
          title: '실패',
          html: '데이터 가져오기 실패 - 폰팀에 문의하세요',
          icon: 'error',
        });
      }
      setNaverShoppingInfo({
        success: true,
        question: result.question,
        image: result.image,
        sessionKey: result.sessionKey,
        cookieString: result.cookieString,
      });
      setloading5(true);
      return await Swal.fire({
        title: '성공',
        html: '데이터 가져오기 성공',
        icon: 'success',
      });
    } catch (e: any) {
      return await Swal.fire({
        title: '실패',
        html: '데이터 가져오기 실패 - 아이디,비밀번호를 확인해 주세요',
        icon: 'error',
      });
    }
  };

  /**
   * @alias rCapchar 값 전송하기
   */
  const onSubmit6 = async (data: any) => {
    try {
      const result = await axiosCustom.post(`${process.env.REACT_APP_PAWN_ADMIN_BACKEND_ENDPOINT}/api/shop/scrap-shop-info`, {
        id: 'test1',
        password: 'test2',
        sessionKey: naverShoppingInfo.sessionKey,
        answer: data.rcap,
        cookieString: naverShoppingInfo.cookieString,
        shopCode: 'F997',
      });
      console.log(result);
      if (!result.success) {
        return await Swal.fire({
          title: '실패',
          html: '데이터 가져오기 실패 - 폰팀에 문의하세요',
          icon: 'error',
        });
      }
      console.log(result.result.sellerInfo.representTelephoneNumber);
      setNaverShoppingGetInfo({
        cookie: result.result.cookie,
        isTrue: result.result.isTrue,
        rtn_cd: result.result.rtn_cd,
        sellerInfo: result.result.sellerInfo,
      });
      setloading7(true);
      return await Swal.fire({
        title: '성공',
        html: '데이터 가져오기 성공',
        icon: 'success',
      });
    } catch (e: any) {
      return await Swal.fire({
        title: '실패',
        html: '데이터 가져오기 실패 - 아이디,비밀번호를 확인해 주세요',
        icon: 'error',
      });
    }
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <h1>네이버쇼핑테스트</h1>
      <br />
      <form onSubmit={handleSubmit5(onSubmit5)}>
        <span /> id: <input {...register5('id', { required: true })} placeholder="ID" defaultValue="gudrhksk000@naver.com" size={30} />
        {errors.id && <span>This field is required</span>}
        <br />
        <br />
        <span /> password : <input {...register5('password', { required: true })} placeholder="Password" type="password" defaultValue="djzkdns2@@" />
        {errors.password && <span>This field is required</span>}
        <br />
        <br />
        <input type="submit" value="가져오기" />
        <br />
        <br />
      </form>
      <br />
      {loading5 && (
        <>
          <h1>{naverShoppingInfo.question}</h1>
          <img src={naverShoppingInfo.image} alt="API Image" />
          <br />
          <form onSubmit={handleSubmit6(onSubmit6)}>
            <span /> rcap: <input {...register6('rcap', { required: true })} placeholder="rcap" defaultValue="" size={30} />
            {errors.rcap && <span>This field is required</span>}
            <br />
            <br />
            <input type="submit" value="캡챠 전송하기" />
            <br />
            <br />
          </form>

          {loading7 && (
            <>
              <h1>
                {naverShoppingGetInfo.isTrue}, ${naverShoppingGetInfo.rtn_cd}
              </h1>
              <span>tel: {naverShoppingGetInfo.sellerInfo.representTelephoneNumber}</span>
            </>
          )}
          <br />
        </>
      )}
      <br />
      <br />
      <br />
      <h1>스마트스토어 API 키 가져오기</h1>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <span /> id: <input {...register('id', { required: true })} placeholder="ID" defaultValue="" size={30} />
        {errors.id && <span>This field is required</span>}
        <br />
        <br />
        <span /> password : <input {...register('password', { required: true })} placeholder="Password" type="password" defaultValue="" />
        {errors.password && <span>This field is required</span>}
        <br />
        <br />
        <label>
          Shop:
          <select {...register('shop', { required: true })} defaultValue={'SmartStore'} onChange={handleSelectChange}>
            <option value="SmartStore">스마트스토어</option>
            <option value="Naver">네이버</option>
          </select>
          {errors.shop && <span>This field is required</span>}
        </label>
        <br />
        <br />
        <input type="submit" value="가져오기" />
        <br />
        <br />
      </form>
      <br />
      {loading && (
        <>
          <br />
          <span>accountId(판매자 연동용 아이디) : {info.accountId} </span>
          <br />
          <span>apiId : {info.apiId} </span>
          <br />
          <span>url : {info.url} </span>
        </>
      )}
      <br />
      <br />
      <h1>스마트스토어 API 사용 승인 & 대행사 변경하기</h1>
      <br />
      <form onSubmit={handleSubmit2(onSubmit2)}>
        <span /> id: <input {...register2('id', { required: true })} placeholder="ID" defaultValue="okfactory1025@gmail.com" size={30} />
        {errors.id && <span>This field is required</span>}
        <br />
        <br />
        <span /> password : <input {...register2('password', { required: true })} placeholder="Password" type="password" defaultValue="ssong151025!" />
        {errors.password && <span>This field is required</span>}
        <br />
        <br />
        <input type="submit" value="변경하기" />
        <br />
        <br />
      </form>
      <h1>쿠팡 API 키 가져오기</h1>
      <br />
      <form onSubmit={handleSubmit3(onSubmit3)}>
        <span /> id: <input {...register3('id', { required: true })} placeholder="ID" defaultValue="wakeup1" size={30} />
        {errors.id && <span>This field is required</span>}
        <br />
        <br />
        <span /> password : <input {...register3('password', { required: true })} placeholder="Password" type="password" defaultValue="1a2a3a!!!" />
        {errors.password && <span>This field is required</span>}
        <br />
        <br />
        <input type="submit" value="가져오기" />
        <br />
        <br />
      </form>
      <br />

      {loading2 && (
        <>
          <form onSubmit={handleSubmit4(onSubmit4)}>
            <span /> code: <input {...register4('code', { required: true })} placeholder="CODE" size={30} />
            {errors.code && <span>This field is required</span>}
            <br />
            <br />
            <br />
            <input type="submit" value="전송하기" />
            <br />
            <br />
          </form>
        </>
      )}

      {!loading3 ? (
        <div>Loading...</div>
      ) : realCoupangInfo.length === 0 ? (
        <div>API 키가 존재하지 않습니다.</div>
      ) : (
        realCoupangInfo.map((item, index) => (
          <div key={index}>
            {item.openapiHmac && (
              <div>
                <h3>OpenAPI HMAC:</h3>
                <p>Access Key: {item.openapiHmac.accessKey}</p>
                <p>Accessor ID: {item.openapiHmac.accessorId}</p>
                <p>HMAC Status: {item.openapiHmac.hmacStatus}</p>
                <p>Secret Key: {item.openapiHmac.secretKey}</p>
              </div>
            )}
            {item.playautoHmac && (
              <div>
                <h3>PlayautoHmac HMAC:</h3>
                <p>Access Key: {item.playautoHmac.accessKey}</p>
                <p>Accessor ID: {item.playautoHmac.accessorId}</p>
                <p>HMAC Status: {item.playautoHmac.hmacStatus}</p>
                <p>Secret Key: {item.playautoHmac.secretKey}</p>
              </div>
            )}
          </div>
        ))
      )}
      <br />
    </div>
  );
}

export default JokerTest;
