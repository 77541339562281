import { TargetSolTypeRole, WAKEUP, checkUseSpringSolType } from 'common/common.constants';
import { ITokenLogin } from 'components/form/interfaces/member.interface';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSuccessRequest } from 'redux_modules/login';
import Swal from 'sweetalert2';

import { axiosCustom } from '../../common/common.function';
import { ITokenLoginProps } from '../../common/interface/props';

export function TokenLoginForm({ token, jobId, isFirstLogin, sendAddressSeq, returnAddressSeq, coupangSellerType }: ITokenLoginProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!token) {
      Swal.fire({
        title: '로그인 실패',
        html: `로그인 토큰이 존재하지 않습니다.`,
        icon: 'error',
      });
      navigate(`/error`);
    }

    const fetchData = async () => {
      try {
        const param = {
          token,
        };

        const solType = WAKEUP.find((one) => window.location.pathname.toLowerCase().includes(one.toLowerCase()));
        // GMP에서 넘겨준 토큰 통해서 WAKEUP LOGIN 시켜줌
        let result: ITokenLogin;
        // if (solType && checkUseSpringSolType(solType)) {
        //   result = await axiosCustom.post(
        //     `${process.env.REACT_APP_NEW_BACKEND_ENDPOINT}/api/user/token-login`,
        //     {},
        //     {
        //       headers: {
        //         Authentication: `Bearer ${token}`,
        //       },
        //     },
        //   );
        // } else {
        result = await axiosCustom.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/member/token-login`, param);
        // }

        if (result.success) {
          // 로그인 성공시 redux에 저장
          // 2.0 솔루션에 귀속 되므로 따로 url 통해 접근되는걸 막기 위해 새로고침하면 풀리는 redux에서 넣어놓음
          dispatch(
            loginSuccessRequest({
              isLogined: true,
              isSolExpired: result.email === 'ktra9@naver.com' ? true : result.solExpired,
            }),
          );

          // 토큰 값 로컬스토리지에 넣어놓고 백엔드랑 통신 시 해당 값 body에 넣어서 인증 함
          sessionStorage.setItem('wakeupInfo', JSON.stringify({ gmpToken: token }));
          // 이메일이랑 솔타입 편하게 꺼내쓰기 위해 넣음
          sessionStorage.setItem('userInfo', JSON.stringify({ email: result.email, solType: result.solType }));

          if (result.solType === TargetSolTypeRole.ST11) {
            sessionStorage.setItem('addressSeq', JSON.stringify({ sendAddressSeq, returnAddressSeq }));
          }

          // 쿠팡서플라이 사용 여부
          // 3P = 일반
          // Retail = 쿠팡 서플라이
          if (result.solType === TargetSolTypeRole.COUPANG) {
            sessionStorage.setItem('sellerType', coupangSellerType || '3P');
          }

          // 2.0에서 최초 로그인인 경우, 설명 페이지 출력해줌
          if (isFirstLogin) {
            navigate(`/all/job/guide`);
            return;
          }

          // jobId가 있으면 해당 작업 현황 페이지로 이동
          if (jobId && jobId > 0) {
            navigate(`/${result.solType.toLowerCase()}/job/${jobId}`);

            return;
          }

          // 아무것도 없으면 사이트선택 페이지로 이동
          navigate(`/${result.solType.toLowerCase()}/job/shop-login`);
          return;
        }
      } catch (err) {
        Swal.fire({
          title: '로그인 실패',
          html: `유효한 로그인 토큰이 존재하지 않습니다.`,
          icon: 'error',
        });
        navigate(`/error`);
      }
    };

    fetchData();
  }, []);

  return <></>;
}
