import { SweetAlertCustomClass } from 'sweetalert2';

interface CustomSwalClass extends SweetAlertCustomClass {
  //지정상품가져오기 여부
  specificProduct?: boolean;
}

export default function customClass({ specificProduct }: CustomSwalClass) {
  return {
    popup: 'swal-custom-popup',
    title: 'swal-custom-title',
    loaderHtml: specificProduct ? 'swal2-html-container specificProduct' : 'swal2-html-container',
    actions: 'swal-custom-actions',
    confirmButton: 'swal-custom-confirmButton',
    cancelButton: 'swal-custom-cancleBtn',
  };
}
// export default function customClass ()

// {
//  return (
//     popup: 'swal-custom-popup',
//     header: 'swal-custom-header',
//     title: 'swal-custom-title',
//     content: 'swal-custom-content specificProduct',
//     actions: 'swal-custom-actions',
//     confirmButton: 'swal-custom-confirmButton',
//     cancelButton: 'swal-custom-cancleBtn'
//  )
// }
