import { IMemberProps, ISolTypeInState } from 'common/interface/props';
import { ScrapProdForm } from 'components/form/ScrapProdForm';
import Container from 'components/UI/atoms/Container';
import { useLocation } from 'react-router-dom';

export default function ScrapProd({ solType }: IMemberProps) {
  const location = useLocation();

  const { targetSolType } = location.state as ISolTypeInState;

  return (
    <Container noBody={true}>
      <ScrapProdForm solType={solType} targetSolType={targetSolType} />
    </Container>
  );
}
