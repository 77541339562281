import styled from 'styled-components';

interface IProps {
  variant?: 'filled' | 'outlined';
}

const Select = styled.select<IProps>`
  ${({ variant }) =>
    variant === 'outlined'
      ? `
      width: auto;
      background: none;
      border: 1px solid #e2e2e2;
    `
      : `
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid black;
`}

  padding: 5px 8px;
  cursor: pointer;
`;

export default Select;
