import Grid from 'components/UI/atoms/Grid';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IProps {
  currentTab?: string;

  tabNames: string[];

  tabNodes: ReactNode[];

  size?: 'small' | 'full';

  tabSwitchEvent?: any;

  tabSwitchValues?: string[] | number[];
}

interface ITabTagProps extends Pick<IProps, 'size'> {
  swtichState: number;
  index: number;
}

interface ITabWrapProps extends Pick<IProps, 'size'> {}

const TabWrap = styled.div<ITabWrapProps>`
  border-bottom: ${({ size }) => (size && size === 'small' ? `` : '1px solid #aaaa')};
  padding-bottom: 20px;
  width: '100%';
  display: 'flex';
`;

const TabTag = styled.span<ITabTagProps>`
  border-bottom: ${({ swtichState, index }) => (swtichState === index ? `3px solid #0072CE` : '')};
  font-weight: ${({ swtichState, index }) => (swtichState === index ? `500` : '400')};
  color: ${({ swtichState, index }) => (swtichState === index ? `#0072CE` : '#aaaa')};
  font-size: ${({ size }) => (size && size === 'small' ? `16px;` : '18px;')};
  padding: ${({ size }) => (size && size === 'small' ? `0px 5px 10px 5px;` : '0px 60px 20px 60px;')};
  margin: ${({ size }) => (size && size === 'small' ? `0px 40px 0px 0px;` : '')};
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

function Tab({ currentTab, tabNames, tabNodes, size, tabSwitchEvent, tabSwitchValues }: IProps) {
  const [swtichState, setSwitchState] = useState<number>(0);

  const switchTab = (state: number) => {
    setSwitchState(state);
    tabSwitchValues && tabSwitchEvent && tabSwitchEvent(tabSwitchValues[state]);
  };

  useEffect(() => {
    if (currentTab && tabNames.indexOf(currentTab) !== swtichState) {
      switchTab(tabNames.indexOf(currentTab));
    }
  }, [currentTab]);

  return (
    <Grid>
      <TabWrap size={size}>
        {tabNames.map((name, index) => {
          return (
            <TabTag key={name} size={size} onClick={() => switchTab(index)} swtichState={swtichState} index={index}>
              {name}
            </TabTag>
          );
        })}
      </TabWrap>
      <Grid>{tabNodes[swtichState]}</Grid>
    </Grid>
  );
}

export default Tab;
